import React from "react";
import Header from "../../Components/Header";

function News() {
    return (
        <>
            <Header />


            <div className="container-fluid p-0">
                <div className="news">
                    <div className="row m-0">
                        <div className="col-md-9 col-lg-9 col-xxl-10 ms-md-5">
                            <div className="main-heading">
                                <h3> News</h3>
                            </div>
                            <div className="row">
                                <div className="col-xl-10 ms-xl-5">
                                    <div className="news-page">
                                        <p> Banks and financial services providers represent the backbone of the global economy. According to The Banker's Top 1000 World Banks Report, banks hold over $125 Trillion Dollars in financial assets. As banks adopt digital assets and cryptographic cash, the cryptocurrency market could exponential explode. </p>

                                        <p> Unicoin introduces the next wave of cryptocurrency innovations suitable for adoption in the banking and financial services industries. The Unicoin innovation stack consists of three layers. </p>

                                        <p>The foundation layer is our Staked Proof of Trust (SPOT) protocol, a hybrid balance of both centralized and decentralized governance. Secondly, Unicoin innovates cryptographic technologies for transacting both regulated electronic cash and truly private point-to-point physical cash. Unicoin's third layer of innovation is its 3-dimensional store of value network. The Unicoin network supports all 3 classes of store of value digital assets (fixed, income-producing, and speculative store of value).</p>

                                        <p>The Unicoin innovation stack represents the next wave of cryptographic adoption in financial technology. This Section posts news articles and related links validating the Unicoin innovations and the next new wave of cryptocurrency investment and adoption.</p>
                                        <div className="news-page-2 ">

                                            <div className="row mb-5">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <img src="../images/news2-img-1.png" className="img-fluid" alt="news-img" />
                                                </div>
                                                <div className="col-sm-6  col-md-12 col-lg-6">
                                                    <h3>Not Bitcoin, Here's Where The Next $30T In Crypto Could Go To, According To BofA Analyst</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.msn.com/en-us/money/savingandinvesting/not-bitcoin-here-s-where-the-next-30t-in-crypto-could-go-to-according-to-bofa-analyst/ar-AAVoOXX">MORE</a>
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <img src="../images/news2-img-2.png" className="img-fluid" alt="news-img" />
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <h3>Global Crypto and Digital Assets Webinar</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=6b85sycl3fU&t=290s">MORE</a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <img src="../images/news2-img-3.png" className="img-fluid" alt="news-img" />
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <h3>Value Innovation: The Strategic Logic of High Growth</h3>
                                                    <p>This article provides insights into innovation valuations and a framework for how Unicoin may be valued.</p>
                                                    <a target="_blank" rel="noreferrer" href="https://hbr.org/2004/07/value-innovation-the-strategic-logic-of-high-growth">MORE</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default News;