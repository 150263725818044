import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ValidateWalletLink } from "../../../../../api/PayeesApi";

import { decrypytdata } from "../../../../../api/walletKey";

function Email({ setVdata, nextstep, result }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [data, setData] = useState("");
  let APPID = "";
  let APIKEY = "";
  const initialValues = {
    walletLinkTypecode: "EMAIL",
    emailAddress: data.emailAddress ? data.emailAddress : "",
  };
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await ValidateWalletLink(
        APPID,
        APIKEY,
        initialValues
      );
      console.log(
        "🚀 ~ file: Email.js ~ line 27 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Email.js ~ line 29 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      setVdata({
        accountClasscode: "EMAIL",
        email: data.emailAddress
          ? data.emailAddress
          : "",
      });
      nextstep();
      // debugger;

      // let jsonData;
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // } else {
      //   dispatch(setPrivateId(""));
      //   // dispatch(setResult(jsonData))
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log("values", values);
              // debugger;
              closeModal(values);
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <div className="">
                  <Form>
                    <div className="row align-items-center">
                      <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        {/* <label>Email</label> */}
                        <div className="">
                          <div className="input-box mb-5 email-floting  form-floating">
                            <Field
                              type="text"
                              name="emailAddress"
                              id="emailAddress"
                              className="form-control email-floting-input"
                              onChange={(e) => {
                                setData((prev) => {
                                  return {
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  };
                                });
                              }}
                              value={formik.emailAddress}
                            />
                            <label className="ps-0 pb-0">Email</label>
                            <div
                              className={
                                errors.emailAddress && touched.emailAddress
                                  ? "input-error"
                                  : null
                              }
                            >
                              <ErrorMessage
                                name="emailAddress"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        <div className="mb-5">
                          <button
                            type="button"
                            onClick={() => {
                              closeModal();
                              
                            }}
                            className="btn next-btn"
                          >
                            Validate
                          </button>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-md-5">
                        <button
                          type="button"
                          class="btn cancel-btn mb-3 mb-md-0 me-4"
                        >
                          CANCEL
                        </button>
                        <button
                          class="btn next-btn mb-3 mb-md-0"
                          type="submit"
                          // onClick={async () => {
                          //   setPrivateModal(true);
                          // }}
                        >
                          SUBMIT
                        </button>
                      </div> */}
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default Email;
