import React from "react";
function Originator({ hashname }) {

    return (
        <>
            <div className="row">
                <div className="col-xl-11 col-xxl-8">
                    <div className="originating-account">
                        <h3>Originating Account</h3>
                        {hashname === "#WithdrawLedger" && <h4>Select how you want the beneficiary to identify this money transfer.</h4>}
                        <div className="originating-account">
                            <h5>Select Public Wallet to continue</h5>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Currency Code</th>
                                            <th>Token Symbol</th>
                                            <th>Public Wallet Address</th>
                                            <th className="text-end">Balance</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>USD</td>
                                            <td>User</td>
                                            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>EUR</td>
                                            <td>User</td>
                                            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>USD</td>
                                            <td>User</td>
                                            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>EUR</td>
                                            <td>User</td>
                                            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>EUR</td>
                                            <td>User</td>
                                            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>USD</td>
                                            <td>User</td>
                                            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Originator;
