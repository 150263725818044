import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  getDailingDropDown,
  getDropdownvalues,
} from "../../../../api/dropdown";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { importAgentBatch } from "../../../../api/Agents";
import { setPrivateId } from "../../../../action";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";

function CreateAgents({ nextstep, setdata }) {
  const [dailingCode, setDailingCode] = useState([]);
  const [AgentTypedata, setAgentTypedata] = useState([]);
  const [AgentData, setAgentData] = useState({});
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();

  const image = AgentData;
  const formData = new FormData();
  formData.append("image", image);
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 29 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 24 ~ closeModal ~ APPID",
        APPID
      );

      const encryptedIssuer = await importAgentBatch(APPID, APIKEY, formData);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
      console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 42 ~ closeModal ~ jsonData",
        jsonData
      );
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      dispatch(setPrivateId(""));
      // debugger;
    }
    // setPrivateModal(false);
  };

  const initialValues = {
    branchcode: "",
    agentRoleKeycode: "",
    uuid: "",
    username: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    jobTitle: "",
    imageFN: "",
    email: "",
    mobileDialingcode: "",
    mobilePhone: "",
    password: "",
  };
  const validationSchema = Yup.object({
    branchcode: Yup.string().required("Issuer/Branch Code required!"),
    agentRoleKeycode: Yup.string().required("User role is required!"),
    mobileDialingcode: Yup.string().required("Dialing Code is required!"),
    firstname: Yup.string().required("First Name is required!"),
    lastname: Yup.string().required("First Name is required!"),
    mobilePhone: Yup.number()
      .typeError("you must specify a number")
      .max(9999999999, "Must be 10 Digit")
      .min(1111111111, "Must be 10 Digit")
      .required("PhoneNumber is required!"),
  });
  const fetchdropdwon = async () => {
    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);

    let dropdownvalues = await getDropdownvalues("LKAgentType");
    setAgentTypedata(dropdownvalues);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  let { pathname } = useLocation();
  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md  ms-lg-4 ms-xl-5">
          <div className="main-heading">
            <h3>Agents</h3>
            <h6>
              {pathname === "/webwallet/modifyagent"
                ? "Modify Agents"
                : "Create Agents"}
            </h6>
            <p>
              {" "}
              {pathname === "/webwallet/modifyagent"
                ? "This function modifies an agent's profile information."
                : " This function associates a financial or bank officer with a branch."}
            </p>
          </div>
          <div className="transfer-rates-page">
            <div className="transfer-rates">
              <ul
                class="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <h3 className="m-0 ">Single </h3>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <h3 className="m-0">Batch </h3>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <h3 className="m-0">File Format</h3>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-content mt-4" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="ledger-information create-issuer-information">
                <div className="row m-0">
                  <div className="col-12">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        setdata(values);
                        nextstep();
                        console.log("values====", values);
                      }}
                    >
                      {(formik) => {
                        const { errors, touched, setFieldValue } = formik;
                        return (
                          <div className="wallet-information-box m-0">
                            <Form className="wallet-information-form">
                              <div className="row mb-0">
                                <h4>Primary Contact Information</h4>
                                <div className="row mb-xl-5">
                                  <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                    <h6>Primary Contact</h6>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="firstname"
                                          id="firstname"
                                          placeholder="First Name"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          First Name
                                        </label>
                                        <div
                                          className={
                                            errors.firstname &&
                                            touched.firstname
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="firstname"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="secondFirstname"
                                          id="secondFirstname"
                                          placeholder="Second First name"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Second First Name
                                        </label>
                                        <div
                                          className={
                                            errors.secondFirstname &&
                                            touched.secondFirstname
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="secondFirstname"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="lastname"
                                          id="lastname"
                                          placeholder="Last Name"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Last Name
                                        </label>
                                        <div
                                          className={
                                            errors.lastname && touched.lastname
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="lastName"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="secondLastname"
                                          id="secondLastname"
                                          placeholder="Second Last Name"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Second Last Name
                                        </label>
                                        <div
                                          className={
                                            errors.secondLastname &&
                                            touched.secondLastname
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="secondLastname"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                    <h6>Contact Information</h6>
                                    <div className="mb-5">
                                      <label>Dialing Code</label>
                                      <Field
                                        as="select"
                                        className="form-select form-control"
                                        id="mobileDialingcode"
                                        name="mobileDialingcode"
                                        onChange={(e) => {
                                          // console.log(e.target.value, "Dialing Code");
                                          setFieldValue(
                                            "mobileDialingcode",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      >
                                        {dailingCode &&
                                          dailingCode.map((value, index) => {
                                            return (
                                              <>
                                                <option
                                                  value={value.keycode}
                                                  key={index}
                                                >
                                                  {value.displayname}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </Field>
                                      <div>
                                        <ErrorMessage
                                          name="mobileDialingcode"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="mobilePhone"
                                          id="mobilePhone"
                                          placeholder="Phone Number"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Phone Number
                                        </label>
                                        <div
                                          className={
                                            errors.mobilePhone &&
                                            touched.mobilePhone
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="mobilePhone"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="email"
                                          id="email"
                                          placeholder="Email Address"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Email Address{" "}
                                        </label>
                                        <i className="bi bi-check-lg"></i>
                                        <div
                                          className={
                                            errors.email && touched.email
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <ErrorMessage
                                            name="email"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                    <h6>Primary Contact Account Information</h6>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="branchcode"
                                          id="branchcode"
                                          placeholder="Issuer/Branch code"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Issuer/Branch code
                                        </label>
                                        <div
                                          className={
                                            errors.branchcode &&
                                            touched.branchcode
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="branchcode"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="jobTitle"
                                          id="jobTitle"
                                          placeholder="Job Title"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Job Title
                                        </label>
                                        <div
                                          className={
                                            errors.jobTitle && touched.jobTitle
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="jobTitle"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <div className="input-box form-floating">
                                        <Field
                                          type="text"
                                          name="username"
                                          id="username"
                                          placeholder="Username"
                                          className="form-control"
                                        />
                                        <label className="ps-0 pb-0">
                                          Username
                                        </label>
                                        <div
                                          className={
                                            errors.username && touched.username
                                              ? "input-error"
                                              : null
                                          }
                                        >
                                          <i className="bi bi-check-lg"></i>
                                          <ErrorMessage
                                            name="username"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-5">
                                      <label>User Role</label>
                                      <Field
                                        as="select"
                                        className="form-select form-control"
                                        id="agentRoleKeycode"
                                        name="agentRoleKeycode"
                                        onChange={(e) => {
                                          // console.log(e.target.value, "Dialing Code");
                                          setFieldValue(
                                            "agentRoleKeycode",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      >
                                        {AgentTypedata &&
                                          AgentTypedata.map((value, index) => {
                                            return (
                                              <>
                                                <option
                                                  value={value.keycode}
                                                  key={index}
                                                >
                                                  {value.displayName}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </Field>
                                      <div>
                                        <ErrorMessage
                                          name="agentRoleKeycode"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                <button
                                  type="button"
                                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn mb-3 mb-md-0"
                                >
                                  NEXT
                                </button>
                              </div>
                            </Form>
                          </div>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="row">
                {/*<h5 className="mb-3">*/}
                {/*    Upload a Limit Rules File in the proper*/}
                {/*    file format for batched processing*/}
                {/*</h5>*/}
                <div className="col-sm-6 col-xl-4">
                  <div className="mb-5">
                    <label className="mb-3">Batch Upload</label>
                    <div class="input-box">
                      <div class="input-group custom-file-button">
                        <input
                          type="file"
                          class="form-control"
                          id="inputGroupFile"
                          onChange={(e) => {
                            setAgentData(e.target.files[0]);
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile">
                          BROWSE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-11">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                  {/* <button
                type="button"
                className="btn cancel-btn mb-3 mb-md-0 me-4"
                onClick={previous}
              >
                PREVIOUS
              </button> */}
                  <button
                    type="submit"
                    className="btn next-btn mb-3 mb-md-0"
                    onClick={closeModal}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div className="row">
                <h5 className="mb-3">
                  Upload a pipe delimited ("|") file with the following fields
                  in sequential order. The first row is the field names header
                  and is ignored.
                </h5>
                <div className="wallet-balances-box m-0">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Field Name</th>
                          <th>Field Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*<tr>*/}
                        {/*    <td>First Name</td>*/}
                        {/*    <td>*/}
                        {/*        The first name of the user{" "}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Middle Name</td>*/}
                        {/*    <td>*/}
                        {/*        The middle name of the user{" "}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Email</td>*/}
                        {/*    <td>The email address </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Password</td>*/}
                        {/*    <td>The login password </td>*/}
                        {/*</tr>*/}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateAgents;
