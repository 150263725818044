import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import { useSearchParams } from "react-router-dom";
import { LimitSuspendAPI } from "../../../api/limit";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import TokenConfirm from "../Token/TokenConfirm";
// import PrivateModal from "../../../Components/PrivateModal";

function LimitsSuspend() {
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState();
  const [searchParams] = useSearchParams();
  const [popUp, setPopUp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    limitcode: searchParams.get("id"),
    // limitcode: "",
  };

  let APPID = "";
  let APIKEY = "";

  const SuspendlimitData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY && data) {
      dispatch(showLoader(true));
      const encryptedToken = await LimitSuspendAPI(APPID, APIKEY, data);
      dispatch(showLoader(false));
      const tokens = await decrypytdata(encryptedToken.data, APIKEY);
      // const jsonData = JSON.parse(tokens);

      let jsonData;
      if (tokens) {
        jsonData = JSON.parse(tokens);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedToken));
        navigate("/result");
      }
      if (jsonData.ReturnMessage) {
        success(jsonData.ReturnMessage);
      }
    }
    
    // setPrivateModal(false);
  };

  return (
    <>
      {/* {privateModal && (
        <PrivateModal
          modalpriate={privateModal}
          closeModal={SuspendlimitData}
        />
      )} */}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3>Limits</h3>
              <h6> Suspend Limits</h6>
              <p>Enables the suspension of a money transfer amount limit.</p>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                setdata(values);
                setPopUp(true);
              }}
            >
              {(formik) => {
                return (
                  <Form className="wallet-information-box ms-0">
                    <div className="suspend-text wallet-information-form">
                      <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        <div className="input-box form-floating mb-5">
                          <Field
                            type="text"
                            name="limitcode"
                            id="limitcode"
                            placeholder="Limit Code"
                            className="form-control"
                          />
                          <label className="ps-0 pb-0">Limit Code</label>
                        </div>
                      </div>
                      <div className="d-flex me-0 me-md-4 me-xxl-5">
                        <button
                          type="button"
                          className="btn cancel-btn mb-3 mb-md-0 me-4"
                        >
                          CANCEL
                        </button>
                        <button
                          type="submit"
                          className="btn next-btn mb-3 mb-md-0"
                          // onClick={() => {
                          //   // setPrivateModal(true);
                          //   SuspendlimitData();
                          // }}
                        >
                          SUSPEND
                        </button>
                      </div>
                    </div>
                    {popUp && (
                      <TokenConfirm
                        heading={"Suspend Entity"}
                        body={initialValues.limitcode}
                        suspendData={SuspendlimitData}
                        setPopUp={setPopUp}
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default LimitsSuspend;
