import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDropdownvalues } from "../../../../api/dropdown";
import { FetchBanks, FetchBanksChild } from "../../../../api/MoneyServices";
// import Pagination from "../../../Headermenu/Pagination";
// import { decrypytdata } from "../../../../api/walletKey";
function Originator({ nextstep, previous, setBanksData, transferData }) {
  console.log(
    "🚀 ~ file: Beneficiary.js ~ line 8 ~ Originator ~ transferData",
    transferData
  );
  const publicKeys = useSelector((state) => state.keyReducer);

  const [privateModal, setPrivateModal] = useState(false);
  const [bankClassdropdown, setBankClassdropdown] = useState([]);
  const [bankTypedropdown, setBankTypedropdown] = useState([]);
  // const [country, setCountry] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [bankclassData, setBankClassItem] = useState("");
  const [bankTypeData, setBankTypeData] = useState("");
  const [bankData, setBankData] = useState();
  console.log(
    "bankData====",
    bankData
  );

  // const [centralBanksData, setCentralBanksData] = useState("");
  // const [branchData, setBranchData] = useState("");
  // const [memberBanksData, setMemberBanksData] = useState("");
  // const [CountryCodeData, setCountryCodeData] = useState([]);
  // const [bankTreeDatas, setbankTreeDatas] = useState([]);

  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  //paginason
  const [currentPage] = useState(1);
  // const [currentPage] = useState(1);
  const [postparPage] = useState(100);
  const indexofLastpage = currentPage * postparPage;
  const indexofFirstpost = indexofLastpage - postparPage;

  const fetchdropdwon = async () => {
    let LKBankClass = await getDropdownvalues("LKBankClass");
    setBankClassdropdown(LKBankClass);
    let LKBankType = await getDropdownvalues("LKInstitutionType");
    setBankTypedropdown(LKBankType);
    // let LKBankCoutnryType = await getDropdownvalues("LKCentralBanks");
    // setCountry(LKBankCoutnryType);
  };

  const datass = {
    bankclasscode: bankclassData,
    bankTypecode: bankTypeData,
    // countrycode: "",
    // statecode: memberBanksData,
    // citycode: branchData,
    // parentBankID: centralBanksData,
  };

  //   const datass = {
  //     bankclasscode: "CB",
  //     bankTypecode: "01",
  //     countrycode: "IN",
  //     statecode: "13006",
  //     citycode: "121082",
  //     parentBankID: 213565,
  //   };
  let APPID = "";
  let APIKEY = "";
  // const closeModal = () => {
  //   setPrivateModal(false);
  // };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      // let values;
      // if (bankclassData && bankTypeData) {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     bankclasscode: bankclassData,
      //     bankTypecode: bankTypeData,
      //     // parentBankID: centralBanksData,
      //     // countrycode: "",
      //     // statecode: memberBanksData,
      //     // citycode: branchData,
      //   };
      // } else {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     // parentBankID:"",
      //   };
      // }
      const encryptedLedger = await FetchBanks(APPID, APIKEY, datass);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      const data = encryptedLedger?.data;
      // setbankTreeDatas(encryptedLedger?.data?.bankTree);
      // let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  const fetchdataChildHandler = async (ndata) => {
    console.log(ndata, "ndata");
    console.log("hellooo");
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      // let values;
      // if (bankclassData && bankTypeData) {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     bankclasscode: bankclassData,
      //     bankTypecode: bankTypeData,
      //   };
      // } else {
      //   values = {
      //     sourcePublicAddress: APPID,
      //     // parentBankID:"",
      //   };
      // }

      const encryptedLedger = await FetchBanksChild(APPID, APIKEY, ndata);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      const data = encryptedLedger?.data;
      // setbankTreeDatas(encryptedLedger?.data?.bankTree);
      // let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datass.bankclasscode,
    datass.bankTypecode,
    // datass.parentBankID,
    // datass.countrycode,
    // datass.statecode,
    // datass.citycode,
  ]);

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);

  return (
    <>
      <div className="row mt-5">
        <div className="col-xxl-10">
          <div className="wallet-balances-box">
            <div className="d-md-flex justify-content-between px-4">
              <div className="">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control line-input"
                    placeholder="Amount"
                  ></input>
                </div>
              </div>
              <div className="">
                              <div className="line-input input-group mb-3">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive p-3 p-md-4">
                          <table className="table table-hover table-striped">
                <thead>
                  <tr className="data-table-row">
                    <th className="data-table-head">
                      <div className="lable-div">
                        <span className="text-dark"> Select</span>
                      </div>
                    </th>

                    <th className="data-table-head">
                      <div className="lable-div">
                        <span className="text-dark">Bank Name</span>
                      </div>
                    </th>

                    <th className="mi-w-165">
                      <span className="text-dark">Bank Class</span>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        // value={e.target.value}
                        onChange={(e) => setBankClassItem(e.target.value)}
                      >
                        {/* <option selected> Bank Class</option> */}

                        {bankClassdropdown.map((index) => {
                          return (
                            <>
                              <option value={index.keycode}>
                                {index.displayName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </th>
                    <th>
                      <span className="text-dark"> Bank Type</span>

                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setBankTypeData(e.target.value)}
                        placeholder="Bank Type"
                      >
                        <option selected>Bank Type</option>
                        {bankTypedropdown.map((index) => {
                          return (
                            <>
                              <option value={index.keycode}>
                                {index.displayName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </th>

                    <th className="data-table-head">
                      <div className="lable-div">
                        <span className="text-dark">Country</span>
                      </div>
                    </th>

                    <th className="data-table-head">
                      <div className="lable-div">
                        <span className="text-dark">State Province</span>
                      </div>
                    </th>

                    <th className="data-table-head">
                      <div className="lable-div">
                        <span className="text-dark">City Town</span>
                      </div>
                    </th>
                    {/* <th className="mi-w-165">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setCentralBanksData(e.target.value)}
                      >
                        <option selected>CENTAL BANKS</option> */}
                    {/* <option value="All">All</option> */}
                    {/* {country.map((index) => {
                          return (
                            <>
                              <option value={index.keycode}>
                                {index.displayName}
                              </option>
                            </>
                          );
                        })} */}
                    {/* </select>
                    </th> */}
                    {/* <th className="mi-w-165">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setMemberBanksData(e.target.value)}
                      >
                        <option selected>Member Banks</option> */}
                    {/* <option value="All">All</option> */}
                    {/* {bankTreeDatas?.map((index) => {
                          return (
                            <>
                              <option value={index.stateProvince}>
                                {index.stateProvince}
                              </option>
                            </>
                          );
                        })} */}
                    {/* </select>
                    </th> */}
                    {/* <th>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setBranchData(e.target.value)}
                      >
                        <option selected>Branches</option> */}
                    {/* <option value="All">All</option> */}
                    {/* {bankTreeDatas?.map((index) => {
                          return (
                            <>
                              <option value={index.bankID}>
                                {index.cityTown}
                              </option>
                            </>
                          );
                        })} */}
                    {/* </select>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* {rows?.length > 0 &&
                    rows
                      ?.slice(indexofFirstpost, indexofLastpage)
                      ?.filter((user) => {
                        if (
                          tokenClassData === "" &&
                          bankclassItem === "" &&
                          tokenTypeData === ""
                        ) {
                          return user;
                        } else if (
                          user.tokenClass
                            .toLowerCase()
                            .includes(tokenClassData.toLowerCase()) &&
                          user.currencycodeList
                            .toLowerCase()
                            .includes(bankclassItem.toLowerCase()) &&
                          user.tokenType
                            .toLowerCase()
                            .includes(tokenTypeData.toLowerCase())
                        ) {
                          return user;
                        }
                      }) */}
                  {rows
                    ?.slice(indexofFirstpost, indexofLastpage)
                    ?.map((items, index) => {
                      return (
                        <>
                          <tr
                            onDoubleClick={() => {
                              fetchdataChildHandler(items);
                              console.log(items.bankID, "bankIDDDDDD");
                            }}
                            // key={index}
                            // onClick={() => setActive(items)}
                            // id={index}
                            // className={isActive === items ? "is-checked" : ""}
                          >
                            {/* <span>{index}</span> */}
                            <td>
                              <div className=" table-form-check ">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexCheckDefault"
                                  name="flexCheckDefault"
                                  onClick={() => {
                                    setBankData(items);
                                  }}
                                />
                              </div>
                            </td>
                            <td>{items.banknameShort}</td>
                            <td>{items.bankclass}</td>
                            <td>{items.bankType}</td>
                            <td>{items.countrycode}</td>
                            <td>{items.stateProvince}</td>
                            <td>{items.cityTown}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <Pagination 
                  postsperPage={postparPage}
                  totalPost={rows?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                /> */}
      </div>
      <div className="col-xxl-10">
        {/* <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0 me-4"
            onClick={nextstep}
          >
            Next
          </button>
        </div> */}
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn cancel-btn mb-3 mb-md-0 me-4"
            onClick={previous}
          >
            PREVIOUS
          </button>
          <button
            type="submit"
            className="btn next-btn mb-3 mb-md-0"
            // onClick={nextstep}
            onClick={() => {
              setBanksData(bankData)
              nextstep()}}
            disabled={!bankData}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
}
export default Originator;
