import React from "react";

function LedgerBalances() {

    return (
        <>
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row ms-lg-4 ms-xl-5 m-0">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-xxl-10">
                                <div className="main-heading">
                                    <h3> Ledgers</h3>
                                    <h6>Ledger Balance</h6>
                                    <p>
                                        This function accumulates and returns the total balance across all wallet balances.
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default LedgerBalances;
