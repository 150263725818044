import React, { useState } from "react";
import Money from "./Money";

import CreateIssuer from "./CreateIssuer";

import { useLocation } from "react-router-dom";
import ReviewIssuer from "./ReviewIssuer";
import BatchUpload from "./BatchUpload";

function Unipay() {
  const [step, setstep] = useState(1);
  const [token, setToken] = useState(1);
  const [value, setValue] = useState();

  console.log("🚀 ~ file: IndexCrtIssuer.js ~ line 14 ~ Unipay ~ value", value);
  let { pathname } = useLocation();
  const cleandata = () => {
    setToken(1);
  };

  const nextstep = () => {
    if (step < 4) {
      setstep(step + 1);
    }
  };

  // const handleimage = (e) => {
  //   setImage({ ...image, [e.target.name]: e.target.files[0].name });
  // };

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <CreateIssuer
            nextstep={nextstep}
            previous={previous}
            setValue={setValue}
            cleandata={cleandata}
          />
        );
      case 2:
        return (
          <Money
            nextstep={nextstep}
            previous={previous}
            cleandata={cleandata}
            setToken={setToken}
          />
        );
      case 3:
        return (
          <ReviewIssuer
            nextstep={nextstep}
            previous={previous}
            cleandata={cleandata}
            token={token}
            value={value}
          />
        );
      default:
        return false;
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };

  return (
    <>
      <div className="col-md ms-lg-4 ms-xl-5">
        <div className="main-heading">
          <h3>Issuer</h3>
          <h6>
            {pathname === "/webwallet/modifyissuer"
              ? "Modify Issuer"
              : "Create Issuer"}
          </h6>
          <p>
            This function establishes a financial institution on the Unicoin
            network capable of creating customer wallets.
          </p>
        </div>
        <div className="transfer-rates-page">
          <div className="transfer-rates">
            <ul
              class="nav nav-tabs justify-content-around"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  <h3 className="m-0 ">Single </h3>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  <h3 className="m-0">Batch </h3>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  <h3 className="m-0">File Format</h3>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content mt-4" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="container-fluid p-0 ">
              <div className="row  ">
                <div className="col-12">
                  <div className="deposit-funds-tabs m-0">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li
                        className={
                          step > 1
                            ? "nav-item originator-mr-u originator-mr-t disableElement"
                            : "nav-item originator-mr-u originator-mr-t"
                        }
                      >
                        <button
                          className={
                            step === 1 ? "nav-link active" : "nav-link"
                          }
                          id="profile-tab"
                          type="button"
                        >
                          <div className="beneficiary">
                            <h3>1</h3>
                            <i className="bi bi-check-lg"></i>
                          </div>
                          <h6>Issuer </h6>
                        </button>
                      </li>
                      <li
                        className={
                          step > 2
                            ? "nav-item money-mr-t disableElement"
                            : "nav-item money-mr-t "
                        }
                      >
                        <button
                          className={
                            step === 2 ? "nav-link active" : "nav-link"
                          }
                          id="contact-tab"
                          type="button"
                        >
                          <div className="money">
                            <h3>2</h3>
                            <i className="bi bi-check-lg"></i>
                          </div>
                          <h6>Currencies</h6>
                        </button>
                      </li>
                      <li
                        className={
                          step > 3 ? "nav-item  disableElement" : "nav-item  "
                        }
                      >
                        <button
                          className={
                            step === 3 ? "nav-link active" : "nav-link"
                          }
                          id="contact-tab"
                          type="button"
                        >
                          <div className="money mony-last">
                            <h3>3</h3>
                            <i className="bi bi-check-lg"></i>
                          </div>
                          <h6>Review</h6>
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="home">
                        {renderstep(step)}
                      </div>
                    </div>
                  </div>
                  {/* 
            <div className="col-xxl-11">
              <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                <button
                  type="button"
                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                  onClick={previous}
                >
                  PREVIOUS
                </button>
                <button
                  type="submit"
                  className="btn next-btn mb-3 mb-md-0"
                  onClick={nextstep}
                >
                  Next
                </button>
              </div>
            </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <BatchUpload />
          </div>
          <div
            class="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div className="row">
              <h5 className="mb-3">
                Upload a pipe delimited ("|") file with the following fields in
                sequential order. The first row is the field names header and is
                ignored.
              </h5>
              <div className="wallet-balances-box m-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>Field Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*<tr>*/}
                      {/*    <td>First Name</td>*/}
                      {/*    <td>*/}
                      {/*        The first name of the user{" "}*/}
                      {/*    </td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*    <td>Middle Name</td>*/}
                      {/*    <td>*/}
                      {/*        The middle name of the user{" "}*/}
                      {/*    </td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*    <td>Email</td>*/}
                      {/*    <td>The email address </td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*    <td>Password</td>*/}
                      {/*    <td>The login password </td>*/}
                      {/*</tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Unipay;
