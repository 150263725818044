import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
function EscrowInformation({ nextstep }) {
    const formik = useFormik({
        initialValues: {
            PurchaseDescriptionShort: "",
            TypeofPurchase: "",
            TransactionReferenceNumber: "",
            Defaultfileinputexample: "",
        },
        validationSchema: Yup.object({
            PurchaseDescriptionShort: Yup.string().required(
                "Purchase Description is required!"
            ),
            TypeofPurchase: Yup.string().required("Type of Purchase is required!"),
            Defaultfileinputexample: Yup.string().required("Default file input example is required!"),
            TransactionReferenceNumber: Yup.string().required(
                "Transaction Reference Number is required!"
            ),
        }),
        onSubmit: (values) => {
            nextstep();
        },
    });
    return (
        <>
            <div className="row">
                <div className="col-xl-11 col-xxl-8">
                    <div className="originating-account wallet-information-box ms-0">
                        <h3>Escrow Information</h3>
                        <h4>
                            Select how you want the beneficiary to identify this money
                            transfer.
                        </h4>
                        <div className="row">
                            <div className="col-md-6 col-xl-4 mb-4 mb-xl-5 wallet-information-form">
                                <label>Type of Purchase</label>
                                <select
                                    className="form-select form-control w-100"
                                    id="TypeofPurchase"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.TypeofPurchase}
                                >
                                    <option defaultValue>User</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                {formik.errors.TypeofPurchase ? (
                                    <div style={{ color: "red" }}>
                                        {formik.errors.TypeofPurchase}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-md-6 col-xl-4 mb-4 mb-xl-5 wallet-information-form">
                                <label>Purchase Description Short</label>
                                <select
                                    className="form-select form-control w-100"
                                    id="PurchaseDescriptionShort"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.PurchaseDescriptionShort}
                                >
                                    <option defaultValue>User</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                {formik.errors.PurchaseDescriptionShort ? (
                                    <div style={{ color: "red" }}>
                                        {formik.errors.PurchaseDescriptionShort}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                                <div className="mb-5 wallet-information-form">
                                    <div className="input-box  form-floating">
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            placeholder="Number"
                                            id="TransactionReferenceNumber"
                                            onChange={formik.handleChange}
                                            value={formik.values.TransactionReferenceNumber}
                                        />
                                        <label className="ps-0 pb-0">Transaction Reference Number (if applicable)</label>
                                        {formik.errors.TransactionReferenceNumber ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.TransactionReferenceNumber}
                                            </div>
                                        ) : null}
                                        <i className="bi bi-check-lg"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                                <div className="mb-5 wallet-information-form">
                                    <div className="mb-3">
                                        <label>Default file input example</label>
                                        <input
                                            className="form-control-file w-100"
                                            type="file"
                                            id="Defaultfileinputexample"
                                            onChange={formik.handleChange}
                                            value={formik.values.Defaultfileinputexample}
                                        />
                                        {formik.errors.Defaultfileinputexample ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.Defaultfileinputexample}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EscrowInformation;
