import React, { useEffect } from "react";
import Bank from "../wallettowallettransfer/SourceSubmenu/Bank";
import Ledger from "../wallettowallettransfer/SourceSubmenu/Ledger";
import Entity from "../wallettowallettransfer/SourceSubmenu/Entity";
import People from "../wallettowallettransfer/SourceSubmenu/People";
import Exchange from "../wallettowallettransfer/SourceSubmenu/Exchange";

function Originator({ nextstep, setsourceData, step ,setPaymentData,sourceData,paymentData}) {
  console.log("🚀 ~ file: Originator.js ~ line 9 ~ Originator ~ step", step)
  useEffect(() => {
    // 👇️ scroll to top on page load
    if (step === 1) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [step]);
  return (
    <>
      <ul class="nav nav-pills mt-5 mb-4" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4 active"
            id="Bank-tab"
            data-bs-toggle="pill"
            data-bs-target="#Bank"
            type="button"
          >
            Bank
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4"
            id="Exchange-tab"
            data-bs-toggle="pill"
            data-bs-target="#Exchange"
            type="button"
          >
            Exchange
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link  py-2 px-4"
            id="Ledger-tab"
            data-bs-toggle="pill"
            data-bs-target="#Ledger"
            type="button"
          >
            Ledger
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4"
            id="Entity-tab"
            data-bs-toggle="pill"
            data-bs-target="#Entity"
            type="button"
          >
            Merchant
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4"
            id="People-tab"
            data-bs-toggle="pill"
            data-bs-target="#People"
            type="button"
          >
            Person
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="Bank">
          <Bank setsourceData={setsourceData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="Ledger" role="tabpanel">
          <Ledger setsourceData={setsourceData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="Entity" role="tabpanel">
          <Entity setsourceData={setsourceData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="People" role="tabpanel">
          <People setsourceData={setsourceData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="Exchange" role="tabpanel">
          <Exchange setsourceData={setsourceData} nextstep={nextstep} />
        </div>
      </div>

      <div className="wallet-information-box ms-0">
        <div className="row mt-5">
          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
            <form className=" wallet-information-form">
              <div className="mb-5">
                <div className="input-box form-floating">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Send Amount"
                    onChange={(e) => {
                      setPaymentData({
                        TargetAmount: Number(e.target.value),
                      });
                    }}
                  />
                  <label className="ps-0 pb-0">TRANSFER AMOUNT</label>
                  <i className="bi bi-check-lg"></i>
                </div>
              </div>
              <div className="mb-5">
                <div className="input-box form-floating">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Reference Code (optional)"
                  />
                  <label className="ps-0 pb-0"> REFERENCE CODE</label>
                  <i className="bi bi-check-lg"></i>
                </div>
              </div>
              <div className="mb-5">
                <div className="input-box form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Comments (optional)"
                  />
                  <label className="ps-0 pb-0">Comments (optional)</label>
                  <i className="bi bi-check-lg"></i>
                </div>
              </div>
              <div className="mb-5">
                <label>Attach Documents (optional)</label>
                <div class="input-box">
                  <div class="input-group custom-file-button">
                    <input
                      type="file"
                      class="form-control"
                      id="inputGroupFile"
                    />
                    <label class="input-group-text" for="inputGroupFile">
                      BROWSE
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-xxl-11">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mb-5">
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
            onClick={nextstep}
            disabled={sourceData?.Balance <= paymentData?.TargetAmount}
            // onClick={() => {
            //   setPrivateModal(true);
            // }}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
}
export default Originator;
