import React from "react";
import { useState } from "react";
import CreateWallet from "./CreateWallet";
// import ReviewWallet from "./ReviewWallet";
// import LinkWallet from "./LinkWallet";
function CreateWallets() {
  const [step, setstep] = useState(1);
  const renderstep = (step) => {
    switch (step) {
      case 1:
        return <CreateWallet nextstep={nextstep} previous={previous} />;
      // case 2:
      //   return <LinkWallet nextstep={nextstep} previous={previous} />;
      // case 3:
      //   return <ReviewWallet nextstep={nextstep} previous={previous} />;
      default:
        return false;
    }
  };
  const nextstep = () => {
    if (step < 3) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 3) {
      setstep(step - 1);
    }
  };
  
  return <>{renderstep(step)}</>;
}

export default CreateWallets;
