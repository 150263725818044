import React from 'react';
function Originator() {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="originating-account link-wallet-box ms-0">
                        <h3>Originating Account</h3>
                        <h4>Select how you want the beneficiary to identify this money transfer.</h4>
                        <div className="link-wallet-tabs">
                            <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-payment-tab" data-bs-toggle="pill" data-bs-target="#pills-payment" type="button" role="tab" aria-controls="pills-payment" aria-selected="false" >
                                        <img src="../icons/Public Wallet-light.png" className="link-payment-img none" alt="link-payment-img" ></img>
                                        <img src="../icons/Public Wallet.png" className="link-payment-img show" alt="link-payment-img" ></img>
                                         Public Wallet
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-email-tab" data-bs-toggle="pill" data-bs-target="#pills-email" type="button" role="tab" aria-controls="pills-email" aria-selected="true" >
                                        <img src="../icons/link-email.png" className="link-email-img none" alt="link-email-img" ></img>
                                        <img src="../icons/link-email-light.png" className="link-email-img show" alt="link-email-img" ></img>
                                        Email
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-phone-tab" data-bs-toggle="pill" data-bs-target="#pills-phone" type="button" role="tab" aria-controls="pills-phone" aria-selected="false" >
                                        <img src="../icons/link-phone.png" className="link-phone-img none" alt="link-phone-img" ></img>
                                        <img src="../icons/link-phone-light.png" className="link-phone-img show" alt="link-phone-img" ></img>
                                        Phone
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button" role="tab" aria-controls="pills-account" aria-selected="false" >
                                        <img src="../icons/link-bank.png" className="link-bank-img none" alt="link-bank-img" ></img>
                                        <img src="../icons/link-bank-light.png" className="link-bank-img show" alt="link-bank-img" ></img>
                                        Bank Account
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-username-tab" data-bs-toggle="pill" data-bs-target="#pills-username" type="button" role="tab" aria-controls="pills-username" aria-selected="false" >
                                        <img src="../icons/link-username.png" className="link-username-img none" alt="link-username-img" ></img>
                                        <img src="../icons/link-username-light.png" className="link-username-img show" alt="link-username-img" ></img>
                                         Username
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link me-0" id="pills-cryptocurrency-tab" data-bs-toggle="pill" data-bs-target="#pills-cryptocurrency" type="button" role="tab" aria-controls="pills-cryptocurrency" aria-selected="false" >
                                        <img src="../icons/link-cryptocurrency.png" className="link-cryptocurrency-img none" alt="link-cryptocurrency-img" ></img>
                                        <img src="../icons/link-cryptocurrency-light.png" className="link-cryptocurrency-img show" alt="link-cryptocurrency-img" ></img>
                                        Cryptocurrency
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">

                                <div className="tab-pane fade" id="pills-payment" role="tabpanel" aria-labelledby="pills-payment-tab" >
                                    <div className="row">
                                        <div className="col-xxl-8">
                                            <div className="originating-account">
                                                <h5>Select Public Wallet to continue</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Currency Code</th>
                                                                <th>Token Symbol</th>
                                                                <th>Public Wallet Address</th>
                                                                <th className="text-end">Balance</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>USD</td>
                                                                <td>User</td>
                                                                <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                                                <td className="text-end">823.00 USD</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>EUR</td>
                                                                <td>User</td>
                                                                <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                                                <td className="text-end">823.00 USD</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>USD</td>
                                                                <td>User</td>
                                                                <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                                                <td className="text-end">823.00 USD</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>EUR</td>
                                                                <td>User</td>
                                                                <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                                                <td className="text-end">823.00 USD</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>EUR</td>
                                                                <td>User</td>
                                                                <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                                                <td className="text-end">823.00 USD</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>USD</td>
                                                                <td>User</td>
                                                                <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
                                                                <td className="text-end">823.00 USD</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="pills-email" role="tabpanel" aria-labelledby="pills-email-tab" >

                                    <div className="row">
                                        <div className="col-lg-auto">
                                            <div className="originating-account">
                                                <h5>Select Email Address to continue</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Email</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>sofia@gmail.com</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>albert1342@gmail.com</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>rueuuu@gmail.com</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>09893ert1342@gmail.com</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>rueuuu@gmail.com</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>andy@gmail.com</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-phone" role="tabpanel" aria-labelledby="pills-phone-tab" >
                                    <div className="row">
                                        <div className="col-lg-6 col-xxl-4">
                                            <div className="originating-account">
                                                <h5>Select Phone Number to continue</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Phone Number</th>
                                                                <th>Country Code</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>+34 1348 3233</td>
                                                                <td>13</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>+90 1348 2148</td>
                                                                <td>09</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>+22 1348 3248</td>
                                                                <td>23</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>+43 1348 3248</td>
                                                                <td>17</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>+01 1348 3248</td>
                                                                <td>54</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>+82 1348 3248</td>
                                                                <td>33</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-account" role="tabpanel" aria-labelledby="pills-account-tab" >
                                    <div className="row">
                                        <div className="col-lg-11 col-xxl-9">
                                            <div className="originating-account">
                                                <h5>Select Bank Account to continue</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Bank Name</th>
                                                                <th>Swift Code</th>
                                                                <th>Routing Number</th>
                                                                <th>Account Number</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Wells Fargo & Co.</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>JPMorgan Chase & Co.</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Wells Fargo & Co.</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Wells Fargo & Co.</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>JPMorgan Chase & Co.</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>JPMorgan Chase & Co.</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-username" role="tabpanel" aria-labelledby="pills-username-tab" >
                                    <div className="row">
                                        <div className="col-lg-5 col-xxl-3">
                                            <div className="originating-account">
                                                <h5>Select Username to continue</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Username</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>sofia</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>albert1342</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>rueuuu</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>09893ert1342</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>rueuuu</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>andy</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-cryptocurrency" role="tabpanel" aria-labelledby="pills-cryptocurrency-tab" >
                                    <div className="row">
                                        <div className="col-lg-7 col-xxl-5">
                                            <div className="originating-account">
                                                <h5>Select Cryptocurrency to continue</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Cryptocurrency Type</th>
                                                                <th>Public Wallet Address</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>BITCOIN</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>LITECOIN</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>ETHER</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>BITCOIN</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>BITCOIN</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>ETHER</td>
                                                                <td>131134324139</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Originator;