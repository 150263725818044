import { Modal } from "react-responsive-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { setPrivateId } from "../action/index";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { decrypytlocalStoragedata } from "../api/walletKey";
import { encryptionString } from "../Constatnts/config";

var CryptoJS = require("crypto-js");

function PrivateModal({ modalpriate, closeModal }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [openPrivate, setPrivate] = useState({ modalpriate });
    // eslint-disable-next-line no-unused-vars
  const [publicAddress, setPublicAddress] = useState([]);
  const [PrivateIdlocal, setPrivateIdlocal] = useState("");
  const [checkedPrivate, setCheckedPrivate] = useState(false);
  //   const onOpenModalPrivate = () => setPrivate(true);
  const onCloseModalPrivate = () => closeModal();
  const fetchdata = async () => {
    let PublicAddress = localStorage.getItem("publicAddress");
    let PrivateAddress = localStorage.getItem("privateKey");
    if (PrivateAddress) {
      var APIKEY = await decrypytlocalStoragedata(PrivateAddress);
      setPrivateIdlocal(APIKEY[0].privateKey.PrivateWalletAddress);
    
    }
    if (PublicAddress) {
      var APPId = await decrypytlocalStoragedata(PublicAddress);

      setPublicAddress(APPId[0].publicKey.publicAddress);
      // onOpenModal();
    } 
  };
  useEffect(() => {
    fetchdata();
  }, []);
  const validationSchemaPrivate = Yup.object({
    PrivateWalletAddress: Yup.string().required(
      "Private Wallet Address is required"
    ),
  });

  const handlePrivateKey = async (privateKey) => {
    if (privateKey) {
      const addressArray = [{ privateKey: privateKey }];
      let encryptPrivateKey = CryptoJS.AES.encrypt(
        JSON.stringify(addressArray),
        encryptionString
      ).toString();
      if (encryptPrivateKey) {
        localStorage.setItem("privateKey", encryptPrivateKey);
        // success("PrivateKey is Sucessfully Match");
        // navigate("/webwallet/createwallet");
      } else {
        // error("Please enter Valid privateKey");
      }
    }
  };
  return (
    <>
      <Modal
        open={openPrivate}
        onClose={onCloseModalPrivate}
        center
        position="unset"
      >
        <Formik
          enableReinitialize
          initialValues={{ PrivateWalletAddress: PrivateIdlocal }}
          validationSchema={validationSchemaPrivate}
          onSubmit={async (privateKey) => {
            if (checkedPrivate) {
              handlePrivateKey(privateKey);
              await dispatch(setPrivateId(privateKey));
              onCloseModalPrivate();
              // fetchData();
            } else {
              await dispatch(setPrivateId(privateKey));
              onCloseModalPrivate();
              // fetchData();
            }
          }}
        >
          {(formik) => {
            const { errors, touched } = formik;
            return (
              <Form className="welcome">
                <div className="welcome-bg-img">
                  <div className="wallet-icon">
                    <img
                      src="../images/dl.beatsnoop-1.png"
                      alt="dl.beatsnoop 1-img"
                    />
                  </div>
                  <div className="carousel-caption">
                            <img src="../images/coins/U-1.png" alt="logo" />
                    <h3>Unicoin Network Security</h3>
                    <div className="text-start">
                      <label>Private Key</label>
                      <div className="input-box">
                        <Field
                          type="text"
                          name="PrivateWalletAddress"
                          id="PrivateWalletAddress"
                          className="form-control"
                          placeholder="Private Wallet Address"
                        />
                        <div>
                          <img src="../icons/key.png" alt="icon" />
                        </div>
                      </div>
                      <div
                        className={
                          errors.PrivateWalletAddress &&
                          touched.PrivateWalletAddress
                            ? "input-error mt-2"
                            : null
                        }
                      >
                        <ErrorMessage
                          name="PrivateWalletAddress"
                          component="span"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="form-check mt-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault2"
                          checked={checkedPrivate}
                          onChange={(e) => setCheckedPrivate(e.target.checked)}
                        />
                        <label htmlFor="flexCheckDefault">
                          Encrypt and Remember Me
                        </label>
                      </div>
                    </div>

                    <button type="submit" className="validate">
                      VALIDATE
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

export default PrivateModal;
