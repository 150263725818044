import React, { useState } from "react";
import Money from "./Unipay/Money";
import Originator from "./Unipay/Originator";
import Review from "./Unipay/Review";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";

function Unipay() {
    const [step, setstep] = useState(1);
    // let { hash } = useLocation();

    const nextstep = () => {
        if (step < 3) {
            setstep(step + 1);
        }
    };
    const renderstep = (step) => {
        switch (step) {

            case 1:
                return <Originator />;
            case 2:
                return <Money />;
            case 3:
                return <Review />;
            default:
                return false;
        }
    };
    const previous = () => {
        if (step > 1) {
            setstep(step - 1);
        }
    };

    return (
        <>
            {/* <Header /> */}

            <div className="col-md-9 col-lg-9">
                <div className="container-fluid p-0 ">
                     <div className="row ms-lg-4 ms-xl-5 m-0"> 
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}
                    <div className="col-12">
                        <div className="main-heading">
                            <h3> Balances</h3>
                            <h6>Unipay</h6>
                            <p>
                                This function conducts a money transfer using Unicoin with the payout in any Unicoin network digital currency.
                            </p>
                        </div>
                        <div className="deposit-funds-tabs m-0">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li
                                    className={
                                        step > 1
                                            ? "nav-item originator-mr-u disableElement"
                                            : "nav-item originator-mr-u"
                                    }
                                >
                                    <button
                                        className={step === 1 ? "nav-link active" : "nav-link"}
                                        id="profile-tab"
                                        type="button"
                                    >
                                        <div className="beneficiary">
                                            <h3>1</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Beneficiary</h6>
                                    </button>
                                </li>
                                <li
                                    className={
                                        step > 2
                                            ? "nav-item money-mr disableElement"
                                            : "nav-item money-mr "
                                    }
                                >
                                    <button
                                        className={step === 2 ? "nav-link active" : "nav-link"}
                                        id="contact-tab"
                                        type="button"
                                    >
                                        <div className="money">
                                            <h3>2</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Money</h6>
                                    </button>
                                </li>
                                <li
                                    className={
                                        step > 3
                                            ? "nav-item review-mr disableElement"
                                            : "nav-item review-mr "
                                    }
                                >
                                    <button
                                        className={step === 3 ? "nav-link active" : "nav-link"}
                                        id="contact-tab"
                                        type="button"
                                    >
                                        <div className="money mony-last">
                                            <h3>3</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Review</h6>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home">
                                    {renderstep(step)}
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-11">
                            <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                <button
                                    type="button"
                                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                                    onClick={previous}
                                >
                                    PREVIOUS
                                </button>
                                <button
                                    type="submit"
                                    className="btn next-btn mb-3 mb-md-0"
                                    onClick={nextstep}
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default Unipay;
