import React, { useState } from "react";
import Beneficiary from "../MoneyServices/Transfer/Beneficiary";
import Review from "./Transfer/Review";
import Originator from "../MoneyServices/Transfer/Originator";

function Transfer() {
  const [step, setstep] = useState(1);
  const [targetData, setTargetData] = useState("");
  console.log(
    "🚀 ~ file: Transfer.js ~ line 9 ~ Transfer ~ targetData",
    targetData
  );

  const [sourceData, setsourceData] = useState("");
  const [paymentData, setpaymentData] = useState("");
  console.log(
    "🚀 ~ file: Transfer.js ~ line 9 ~ Transfer ~ transferData",
    sourceData
  );

  const setdata = (data) => {
    setTargetData(data);
  };

  const nextstep = () => {
    if (step < 3) {
      setstep(step + 1);
    }
  };
  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <Beneficiary
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            setsourceData={setsourceData}
            setpaymentData={setpaymentData}
            paymentData={paymentData}
          />
        );
      case 2:
        return (
          <Originator
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            setTargetData={setTargetData}
          />
        );
      case 3:
        return (
          <Review
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            targetData={targetData}
            sourceData={sourceData}
            paymentData={paymentData}
          />
        );
      default:
        return false;
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };

  return (
    <>
      {/* <Header /> */}

      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="container-fluid p-0 ">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}
            <div className="col-12">
              <div className="main-heading">
                <h3> Money Services</h3>
                <h6>Money Transfer</h6>
                <p>
                  This function enables the sending of digital currency from one
                  wallet to another wallet. A wallet could be a token wallet or
                  a ledger wallet.
                </p>
              </div>
              <div className="deposit-funds-tabs m-0">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    className={
                      step > 1
                        ? "nav-item side-line originator-m disableElement"
                        : "nav-item side-line originator-m "
                    }
                  >
                    <button
                      className={step === 1 ? "nav-link active" : "nav-link"}
                      id="home-tab"
                      type="button"
                    >
                      <div className="originator">
                        <h3>1</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Payment</h6>
                    </button>
                  </li>
                  <li
                    className={
                      step > 2
                        ? "nav-item side-line beneficiary-m disableElement"
                        : "nav-item  side-line beneficiary-m"
                    }
                  >
                    <button
                      className={step === 2 ? "nav-link active" : "nav-link"}
                      id="profile-tab"
                      type="button"
                    >
                      <div className="beneficiary">
                        <h3>2</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Payee</h6>
                    </button>
                  </li>
                  <li
                    className={
                      step > 3
                        ? "nav-item money-m disableElement"
                        : "nav-item money-m "
                    }
                  >
                    <button
                      className={step === 3 ? "nav-link active" : "nav-link"}
                      id="contact-tab"
                      type="button"
                    >
                      <div className="review">
                        <h3>3</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Review</h6>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home">
                    {/* <Originator /> */}
                    {renderstep(step)}
                  </div>
                </div>
                {step !== 3 && (
                  <div className="col-xxl-11">
                    <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                      <button
                        type="button"
                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                        onClick={previous}
                      >
                        PREVIOUS
                      </button>
                      <button
                    disabled={paymentData >= sourceData.Balance}
                    type="button"
                    className="btn next-btn mb-3 mb-md-0"
                    onClick={nextstep}
                  >
                    {step === 1 || step === 2 ? "NEXT" : "SUBMIT"}
                  </button>
                      {/* <button
                        type="button"
                        className="btn next-btn mb-3 mb-md-0"
                        disabled={paymentData >= sourceData.Balance}
                        // onClick={nextstep()}
                        // onClick={() => {
                        //   console.log(
                        //     paymentData <= sourceData.Balance,
                        //     "fddfsdas",
                        //     typeof parseInt(paymentData),
                        //     sourceData.Balance
                        //   );
                        //   if (Number(paymentData) <= sourceData.Balance) {
                        //     nextstep();
                        //   }
                        //   // nextstep();
                        // }}
                      >
                        NEXT
                      </button> */}
                    </div>
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
export default Transfer;
