import keyReducer, {
  EncryptDataReducer,
  resultReducer,
  ShowLoader,
} from "./Sidemenu";
import { combineReducers } from "redux";
const allReducers = combineReducers({
  keyReducer: keyReducer,
  resultReducer: resultReducer,
  EncryptDataReducer: EncryptDataReducer,
  // HideLoader: HideLoader,
  ShowLoader: ShowLoader,
  // setErrorAction:setErrorAction,
});

export default allReducers;
