//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function multifunctionaldlt() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/multifunctional-dlt.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Multi-Functional DLT</p>
                            <h1>Full Service Digital <br className="d-none d-md-block" /> Banking with Multi-<br className="d-none d-md-block" /> Functional DLT<br className="d-none d-md-block" /> (mDLT)</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default multifunctionaldlt;