import React, { useEffect, useState } from "react";
import { getBranches } from "../../../api/branches";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { Branchescolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";
function BranchesBalances() {
  // const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedBranch = await getBranches(APPID, APIKEY);
      dispatch(showLoader(false));
      const Branch = await decrypytdata(encryptedBranch?.data, APIKEY);
      console.log(
        "🚀 ~ file: BranchesBalances.js ~ line 26 ~ fetchdata ~ Branch",
        Branch
      );
      // console.log(JSON.parse(Branch));
      let data = JSON.parse(JSON.parse(Branch).CommandResponse);
      console.log(
        "🚀 ~ file: BranchesBalances.js ~ line 33 ~ fetchdata ~ data",
        data
      );

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          dataArr.push({
            ...dataMain,
            rowid: dataMain.RowID,
          });
        });
        setRows(dataArr);
      }
    }
  };
  

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="row">
                <div className="col-xxl-10">
                  <div className="main-heading">
                    <h3> Branches</h3>
                    <h6>Branches</h6>
                    <p>
                      This function lists all the branches associated with an
                      issuer wallet.
                    </p>
                  </div>
                  <div className="wallet-balances-box">
                    <div className="d-md-flex justify-content-between">
                      <div className="search-box d-md-flex">
                        <div className="input-group mb-3"></div>
                      </div>
                      <Link to={{ pathname: "/webwallet/createbranch" }}>
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{ marginBottom: "10px" }}
                        >
                          + ADD Branches
                        </button>
                      </Link>
                    </div>
                    <div style={{ height: 800, width: "100%" }}>
                      <Grid row={rows} coloums={Branchescolumns} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BranchesBalances;
