import http from "./httpService";
import { ADMIN_URL, WEB_WALLET_URL } from "../Constatnts/config.js";
import { hmacencrypt } from "./walletKey";
import axios from "axios";

export async function CreateLedgerApi(publickey, privateKey, ledgerdata) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/CreateLedger",
    ledgerdata,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/CreateLedger",

    ledgerdata,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function getLedgers(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // ledgerTypecode: "",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/GetLedgers",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/GetLedgers",
    { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function getLedgerByCode(publickey, privateKey, ledgercode) {
  let values = {
    sourcePublicAddress: publickey,
    ledgercode: ledgercode,
    // objectcode:"",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/GetLedgers",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/GetLedgers",

    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function ModifyLedgerApi(publickey, privateKey, LedgerData) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/ModifyLedger",
    LedgerData,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post("/ledger/ModifyLedger", LedgerData, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function GetTokenSymbols(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetTokenSymbols", {
    LookupTable: type,
  });
  return dataDictionary.data;
}

export async function ledgerSuspend(publickey, privateKey, data) {
  let values = {
    // sourcePublicAddress: publickey,
    ledgercode: data.ledgercode,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/SuspendLedger",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/ledger/SuspendLedger", values, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function LedgerDepositAPI(publickey, privateKey, ledgerdata) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/Deposit",
    ledgerdata,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post("/ledger/Deposit", ledgerdata, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function LedgerWithdrawAPI(publickey, privateKey, ledgerdata) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/Withdraw",
    ledgerdata,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post("/ledger/Withdraw", ledgerdata, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}
