import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getAgents(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // issuercode: "",
    // username: "",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/GetAgents",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/GetAgents",
    { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function CreateAgentApi(publickey, privateKey, AgentData) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/CreateAgent",
    AgentData,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/CreateAgent",

    AgentData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function ModifyAgentApi(publickey, privateKey, AgentData) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ModifyAgent",
    AgentData,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post("/issuer/ModifyAgent", AgentData, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function AgentSuspendAPI(publickey, privateKey, data) {
  let values = {
    agentcode: data.agentcode,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/SuspendAgent",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/issuer/SuspendAgent", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function importAgentBatch(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ImportAgentBatch",
    issuerData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/ImportAgentBatch",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
