import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const community = () => {
    return (
        <>
            <Header />
            <div className="monetary-policy community">
                <div className="container-fluid p-0">
                    <div className="monetary-policy-slider">
                        <img src="../images/community.jpg" className="img-fluid" alt="community-img" />
                        <div className="monetary-policy-slider-text">
                            <h1>A Letter to the Community</h1>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <div className="monetary-policy-question community-question">
                            <h2><span>01</span>A Letter to the Community</h2>
                            <p>Now is an exciting time for both the cryptocurrency and the banking industries.</p>
                            <p>In the current state, political governments are attempting to regulate cryptocurrencies including the consideration of adopting cryptocurrency as legal tender, whereas central banks are researching and developing central bank digital currency (CBDC).</p>
                            <p>As cryptocurrency converges with the traditional monetary system, both industries are trying to figure out the blueprint for coexistence.</p>
                            <p>Bitcoin will go down in history as one of the most genius innovations in financial technology. However, with most revolutionary innovations, the first generation is usually not the innovation that becomes the most widely adopted. It usually serves as the launching pad for further research and development.</p>
                            <p>Bitcoin, with all its forks and the thousands of alt coins, is constructed to operate in fully decentralized networks of untrusted participants without the influence of intermediary financial institutions.</p>
                            <p>Central banks are responsible for regulating, governing, and instituting policies for the establishment and maintenance of a monetary system.</p>
                            <p className="mb-0">Central banks have three primary roles in establishing a robust and sustainable monetary system: </p>
                            <p className="mb-0 ps-4">   (1) the issuance of a stable national currency for the payment of debts, goods, and services; </p>
                            <p className="mb-0 ps-4">   (2) the governance of monetary policy for establishing and growing a stable economy; </p>
                            <p className="ps-4">   (3) the establishment of a safe and secure financial system free of nefarious activity.</p>
                            <p>Bitcoin and the first generation of fully decentralized cryptocurrencies are purposefully contradictory to the role and the mandate of central banks and do not allow them to fulfill their institutional role to provide a sustainable monetary system that safeguards their country�s monetary sovereignty.</p>
                            <p>The Digital Currency Monetary Authority (DCMA) was founded with the mission of providing the global banking industry with the research and development of next generation technologies and governance suitable for adoption in the current digital transformation age of banking and financial services.</p>
                            <p>Specifically, the DCMA has been researching and developing the next generation of hybrid distributed ledger technology (DLT) converging the best practices of both decentralized and centralized digital technology and monetary governance.
                                In other words, Unicoin is not forking Bitcoin. We are forking cryptocurrency.</p>
                            <p>Unicoin is a global digital financial services (DFS) network. As you need an internet service provider (ISP) to access the Internet, you will need a financial service provider (FSP) to access the Unicoin network.</p>
                            <p>An FSP could be a regulated bank, a licensed Fintech company, or a cryptocurrency exchange.</p>
                            <p>Unicoin solves the business, technology, and governance needs for the adoption of cryptocurrency and digital assets for the global banking and financial services industry.</p>
                            <div className="monetary-policy-question-two community-question-text my-5">
                                <h2><span>1.1</span>Unicoin Business Innovations</h2>
                                <p>Unicoin recognizes and addresses the motivations and needs of the four constituencies of a money economy: central banks, commercial banks, merchants, and private citizens and corporations.</p>
                                <h4>Central Banking Innovations</h4>
                                <p>Unicoin is keenly engineered to meet the cash reserve requirements of central banks and to work in congruency with their monetary policies. </p>
                                <p>In a trickledown effect, central banks can hold Unicoin cash reserve accounts for their member banks using our cash reserve ledger technology.</p>
                                <p>Further, Unicoin has implemented a cash-backed digital asset (CBDA) for each central bank national currency worldwide. Central banks could adopt Unicoin CBDAs as an alternative to issuing their own CBDC or they could opt to have the Unicoin CBDAs operate in conjunction with their issued CBDC.</p>
                                <h4>Retail and Commercial Banking Innovations</h4>
                                <p>The traditional banking industry monetizes customer deposits using a fractional reserve lending business model. Private enterprise Stablecoins are not issued by the banking industry and do not quality for fractional reserve monetization. As a result, banks have minimal incentive to custody Stablecoins.</p>
                                <p>Unicoin offer monetization programs for the banking industry for holding Unicoin and our cash-backed digital currencies (CBDAs). Unicoin offers the banking industry a CBDA for each national currency worldwide.</p>
                                <h4>Merchant Banking Innovations</h4>
                                <p>For merchants to widely adopt cryptocurrency or digital currency as means of payment, there must be minimal to no risk in the loss of value in holding these digital assets. Speculative cryptocurrencies such as Bitcoin are too volatile for merchants to risk accepting payments.</p>
                                <p>With Unicoin, customers can pay merchants with Unicoin, and the merchant can receive a CBDA in their local currency that is not subject to volatility using the Unipay function.</p>
                                <p>Merchants and trading partners engaged in international trade can also mitigate their exposure to forex currency depreciation by utilizing our neutral monetary units (NMUs). </p>
                                <p>Unicoin NMUs lock in the FX rates at the time of purchase and are valid for the life of holding the NMUs. NMUs work like FX forward contracts with enhanced features.</p>
                                <h4>Private Citizen Innovations</h4>
                                <p>Private citizens and corporations are primarily attracted to cryptocurrency for the enhanced investment and income opportunities not widely available in the traditional banking industry.</p>
                                <p>Unicoin and its network of digital currencies offer compelling investment and income-generating digital assets for the private sector.</p>
                            </div>
                            <div className="monetary-policy-question-two community-question-text mb-5">
                                <h2><span>1.2</span>Unicoin Technology Innovations</h2>
                                <p>The technology requirements of digital currency center around transaction speed, transaction costs, and cryptocurrency pricing volatility. </p>
                                <p>Fully decentralized DLTs are challenged in each of these areas.</p>
                                <p>Unicoin has scientifically engineered Staked Proof of Trust (SPOT) Protocol as the fastest consensus protocol in the world. With SPOT, transactions are written instantly to the DLT by trusted meganodes and validating mesonodes do not send confirmation messages if they agree with the transactions. They only send reject messages to dispute a transaction.</p>
                                <p>In many fully decentralized DLTs, the transaction costs can become highly volatile. This uncertain volatility makes it challenging for widespread adoption where financial services providers need certainty in their transaction cost structure.</p>
                                <p>Leveraging a monetary policy framework, the Unicoin network will support a sustainable low-cost transaction fee model.</p>
                                <p>The Unicoin monetary policy framework is also written to minimize aggressive asset price volatility to meet cash reserve requirements of central banks.</p>
                            </div>
                            <div className="monetary-policy-question-two community-question-text mb-5">
                                <h2><span>1.3</span>Unicoin Governance Innovations</h2>
                                <p>Fully decentralized cryptocurrency networks have approached governance with decentralized autonomous organizations (DAOs). DAOs are subject to majority control attacks potentially enabling nefarious bad actors to take over and control the network.</p>
                                <p>DLTs should be immune to nefarious attacks and should be able to evolve over time to sustain the goals of the monetary system including unexpected shocks to the system.</p>
                                <p>The strategic intent of a monetary policy framework is to establish a governance model that performs for all the constituents of the monetary system and safeguards the monetary system from nefarious attacks and transient shocks.</p>
                                <p>In a hybrid model, Unicoin layers its decentralized network with a Unicoin Monetary Policy Committee (UMPC) responsible for ensuring Unicoin meets its goals and satisfies key performance metrics.</p>
                                <p>We all should be grateful for the Bitcoin innovations. However, as the banking and financial services industries seek to embrace digital money, new innovations must arise to meet the distinct needs of the regulated money servicing economy.</p>
                                <p>Unicoin is poised to lead the next wave of cryptocurrency, digital currency, and DLT innovations.</p>
                                <p>We invite you to join us on this journey. Reach out if you have questions or would like to explore how to embrace Unicoin within your banking or financial services organization.</p>
                            </div>
                            <h2><span>02</span>Our Team</h2>
                            <div className="about pb-md-5">
                                <div className="container">
                                    <div className="row align-items-center pt-4">
                                        <div className="col-lg-6">
                                            <div className="about-img">
                                                <img src="../images/darrell-img.png" className="img-fluid" alt="about-img" />
                                                <div className="client-name">
                                                    <h6>Executive Director</h6>
                                                    <h3>Darrell Hubbard</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/"><img src="../images/LinkedIn_Logo 1.png" className="mt-md-3" alt="LinkedIn_Logo 1" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about-text">
                                                <h3>Darrell Hubbard</h3>
                                                <p className="ms-0 mb-3">Darrell Hubbard has an exceptional record of achievement in Computer Science and Information Technology. He received his master's in computer science at the age of 19 from North Carolina State University (NCSU) and started his career as a Chief Architect at AT&T Bell Laboratories.</p>

                                                <p className="ms-0 mb-3">He served as a member of the National Institute of Science and Technology (NIST) during its release of TCP/IP, known as the Internet. He was appointed as a US Expert in the American National Standards Institute (ANSI) and elected as a Chairperson within the International Standards Organization (ISO).</p>

                                                <p className="ms-0 mb-3">Darrell has over 25 years of high-technology and financial technology experience serving as the Head of Financial Management Architect at AT&T and consulting for leading corporations in America. His clients include Amgen, Warner Bros., Toyota, Sony Pictures, Fannie Mae, TD Ameritrade, Screen Actors Guild (SAG), Philips Healthcare, McKesson, Kiewit, Ansys, Ferguson, Oracle, HP, Dell, and many others.</p>

                                                <p className="ms-0 mb-3">Over the past several years he has dedicated his talents to Distributed Ledger Technology (DLT) and digital currencies. Darrell has collaborated with or completed projects for the European Union and other central banks around the world.</p>

                                                <p className="ms-0 mb-3">His work is featured by the European Commission, the United Nations, and the World Economic Forum. In 2018, Darrell was inducted into the Computer Science Alumni Hall of Fame at NCSU. </p>

                                                <p className="ms-0 mb-3">Darrell graduated with an MBA from Harvard University.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about py-ms-5">
                                <div className="container">
                                    <div className="row align-items-center pt-4">
                                        <div className="col-lg-6">
                                            <div className="about-img">
                                                <img src="../images/Michael.png" className="img-fluid" alt="about-img" />
                                                <div className="client-name">
                                                    <h6>Managing Director, North America</h6>
                                                    <h3>Michael O'Connell</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/"><img src="../images/LinkedIn_Logo 1.png" className="mt-md-3" alt="LinkedIn_Logo 1" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about-text">
                                                <h3>Michael O'Connell</h3>
                                                <p className="ms-0 mb-3">Michael has an extensive experience in bringing technology-centric businesses to market globally, primarily in the hospitality, telecom, and travel industries.</p>
                                                <p className="ms-0 mb-3"> In his role, Michael is responsible for business development and investor relations within the region. </p>
                                                <p className="ms-0 mb-3">He is currently engaged in a high potential project to deploy Unicoin as a B2B payment currency within the travel and airline industries.</p>
                                                <p className="ms-0 mb-3">Michael has a degree in International Affairs from Georgetown University and resides in Washington, DC.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about pt-md-5">
                                <div className="container">
                                    <div className="row align-items-center pt-4">
                                        <div className="col-lg-6">
                                            <div className="about-img">
                                                <img src="../images/A-Sun.png" className="img-fluid" alt="about-img" />
                                                <div className="client-name">
                                                    <h6>Managing Director, Africa</h6>
                                                    <h3>A-Sun Truth</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/"><img src="../images/LinkedIn_Logo 1.png" className="mt-md-3" alt="LinkedIn_Logo 1" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about-text">
                                                <h3>A-Sun Truth</h3>
                                                <p className="ms-0 mb-3">A-Sun has served honorably as a United States Marine and has over 20 years of international business development experience primary in the continent of Africa.</p>
                                                <p className="ms-0 mb-3"> He serves as the Managing Director for the African Continent and is current working with the African Union on various Unicoin initiatives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about pt-md-5">
                                <div className="container">
                                    <div className="row align-items-center pt-4">
                                        <div className="col-lg-6">
                                            <div className="about-img">
                                                <img src="../images/Shashank-Singh.png" className="img-fluid" alt="about-img" />
                                                <div className="client-name">
                                                    <h6>Managing Director, India</h6>
                                                    <h3>Shashank Singh</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/"><img src="../images/LinkedIn_Logo 1.png" className="mt-md-3" alt="LinkedIn_Logo 1" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about-text">
                                                <h3>Shashank Singh</h3>
                                                <p className="ms-0 mb-3">Shashank is a private businessman with well-established client relationships with the Ministry of Finance and other government officials in India. </p>
                                                <p className="ms-0 mb-3"> He has been contracted by the government to assist in various political party campaigns. </p>
                                                <p className="ms-0 mb-3"> In his role, Shashank has established direct relations with the Ministry of Finance and the Reserve Bank of India. </p>
                                                <p className="ms-0 mb-3">We are currently educating the Reserve Bank of India on how to adopt Unicoin in its banking system.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about pt-md-5">
                                <div className="container">
                                    <div className="row align-items-center pt-4">
                                        <div className="col-lg-6">
                                            <div className="about-img">
                                                <img src="../images/Bryan-Feinberg.png" className="img-fluid" alt="about-img" />
                                                <div className="client-name">
                                                    <h6>Unicoin Advisor</h6>
                                                    <h3>Bryan Feinberg</h3>
                                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/bryanfeinberg/"><img src="../images/LinkedIn_Logo 1.png" className="mt-md-3" alt="LinkedIn_Logo 1" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about-text">
                                                <h3>Bryan Feinberg</h3>
                                                <p className="ms-0 mb-3">Bryan Feinberg is the CEO and Founder of Etheralabs, a NYC-based BlockchAin Advisory and Venture Accelerator. </p>
                                                <p className="ms-0 mb-3"> Bryan brings nearly two decades of serial technology & media focus and solid track record of both identifying and participating across the startup universe. </p>
                                                <p className="ms-0 mb-3"> Bryan is a Licensed Investment Banker, holding series 7, 63 & 79 FINRA licenses. </p>
                                                <p className="ms-0 mb-3"> With strong ties and an extensive network, Bryan's added value is measured in his hands-on approach to deal positioning, strategic modeling, market analysis & enabling technologies in any data transaction.   </p>
                                                <p className="ms-0 mb-3"> A serial entrepreneur, Bryan's key-focus areas encompass both investment banking and early stage company development and drives his expertise across multiple areas including Banking & Regulatory Compliance, Syndication, Intelligence, Wallet Security, Search, Machine Learning and Big Data.  </p>
                                                <p className="ms-0 mb-3"> Bryan has completed over $200M in financial transactions including taking a startup from scratch to over $130M in revenues and an integral balance sheet component to public listing on the TASE in 2007.  </p>
                                                <p className="ms-0 mb-3"> Bryan is also CEO and Founder of Zephyr Financial Technologies and on the Advisory Board multiple emerging blockchain companies.  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default community;
