import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import { Formik, Form, Field } from "formik";
// import PrivateModal from "../../../Components/PrivateModal";
import { TokenSuspendAPI } from "../../../api/Token";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import { useNavigate, useSearchParams } from "react-router-dom";
import TokenConfirm from "./TokenConfirm";

function TokenSuspend() {
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [data, setdata] = useState();
  const [popUp, setPopUp] = useState(false);

  const [searchParams] = useSearchParams();
  let tokensymbolData = searchParams.get("id");

  const initialValues = {
    tokenSymbol: tokensymbolData,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let APPID = "";
  let APIKEY = "";

  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await TokenSuspendAPI(APPID, APIKEY, data);
      console.log(
        "🚀 ~ file: TokenSuspend.js ~ line 35 ~ suspendData ~ data",
        data
      );
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      // const jsonData = JSON.parse(devryptedIssuer);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
    }
    // setPrivateModal(false);
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={suspendData} />
      )} */}
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <div className="main-heading">
                  <h3>Tokens</h3>
                  <h6>Suspend Token</h6>
                  <p>
                    This function suspends the sending and receiving of a
                    digital currency.
                  </p>
                </div>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      console.log(
                        "🚀 ~ file: TokenSuspend.js ~ line 84 ~ TokenSuspend ~ values",
                        values
                      );
                      setdata(values);
                      setPopUp(true);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form className="wallet-information-form">
                          <div className="suspend-text wallet-information-form">
                            <div className="row">
                              <div className="col-sm-6 col-xl-4 col-xxl-5">
                                <div className="mb-5">
                                  <div className="input-box  form-floating">
                                    <Field
                                      type="text"
                                      name="tokenSymbol"
                                      id="tokenSymbol"
                                      placeholder="Token Symbol"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Token Symbol
                                    </label>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                  <button
                                    type="button"
                                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn next-btn mb-3 mb-md-0"
                                  >
                                    SUSPEND
                                  </button>
                                </div>
                                {popUp && (
                                  <TokenConfirm
                                    heading={"Suspend Token"}
                                    body={tokensymbolData}
                                    suspendData={suspendData}
                                    setPopUp={setPopUp}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TokenSuspend;
