import React from "react";
function UnicoinVSBitcoin() {
    return (
        <>
            <div className="unicoin-store">
                <div className="container">
                    <div className="unicoin-bitcoin-heading unicoin-store-heading text-center">
                        <h3>Unicoin vs Bitcoin</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src="images/coins/unicoin vs bitcoin.png" className="img-fluid" alt="alt" />
                        </div>
                        <div className="col-md-6">
                            <div className="unicoin-store-text">
                                <p>Bitcoin will go down in history as one of the most genius innovations in financial technology. However, like most first-generation technologies, subsequent evolutions of the technology make it more practical for scale and widespread adoption.</p>
                                <p>The Bitcoin Whitepaper is titled "Bitcoin: A Peer-to-Peer Electronic Cash System," and the first sentence of the abstract states "A purely peer-to-peer version of electronic cash would allow online payments to be sent directly from one party to another without going through a financial institution."</p>
                                <p className="mb-0">While these statements may be true, they do not reflect the two primary methods of how cash is represented and needed in society: electronic cash and physical cash.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UnicoinVSBitcoin;
