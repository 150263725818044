// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import { GetTokenSymbols } from "../../../api/LedgerAPI";
import { TokenUnPauseAPI } from "../../../api/Token";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";
function TokenUnpause() {
    const publicKeys = useSelector((state) => state.keyReducer);
    // const [privateModal, setPrivateModal] = useState(false);
    const [data, setdata] = useState("");
    const [TokenSymbol, setTokenSymbol] = useState([]);
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const [searchParams] = useSearchParams();
    const TokenSymbolData = searchParams.get("id");
    const fetchdropdwon = async () => {
        let tokenSymboldropdownvalues = await GetTokenSymbols("GetTokenSymbols");
        setTokenSymbol(tokenSymboldropdownvalues);
    };
    const initialValues = {
        tokenSymbol: TokenSymbolData ? TokenSymbolData : "",
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let APPID = "";
    let APIKEY = "";
    const UnPauseData = async () => {
        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }
        if (APPID && APIKEY && data) {
            dispatch(showLoader(true));
            const encryptedIssuer = await TokenUnPauseAPI(APPID, APIKEY, data);
            dispatch(showLoader(false));
            const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
            // const jsonData = JSON.parse(devryptedIssuer);

            let jsonData;
            if (devryptedIssuer) {
              jsonData = JSON.parse(devryptedIssuer);
              dispatch(setPrivateId(""));
              dispatch(setResult(jsonData));
              dispatch(setResponseAPI(encryptedIssuer));
              navigate("/result");
            }
            if (jsonData.ReturnMessage) {
                success(jsonData.ReturnMessage);
            }
        }
        // setPrivateModal(false);
    };
    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
          setisValidPublicAddress(true);
        }
        fetchdropdwon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [publicKeys]);

    return (
        <>
            {/* {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={UnPauseData} />
            )} */}
            {isValidPublicAddress && (
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row ms-lg-4 ms-xl-5  m-0">
                    <div className="col-12">
                        <div className="main-heading">
                            <h3>Tokens</h3>
                            <h6>Unpause</h6>
                            <p>
                                This function reactivates the sending and receiving of a digital currency.
                            </p>
                        </div>
                        <div className="wallet-information-box m-0">
                            <div className="suspend-text wallet-information-form">
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={(values) => {
                                        setdata(values);
                                        console.log(
                                            "🚀 ~ file: TokenMint.js ~ line 71 ~ TokenMint ~ values",
                                            values
                                        );
                                    }}
                                >
                                    {(formik) => {
                                        const { setFieldValue } = formik;
                                        return (
                                            <Form className="wallet-information-form">
                                                <div className="suspend-text wallet-information-form">
                                                    <div class="row">
                                                        <div class="col-xxl-6 mb-4">
                                                            <div className="mb-3">
                                                                <label>Token Public Address</label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-select form-control"
                                                                    id="tokenSymbol"
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "tokenSymbol",
                                                                            e.target.value
                                                                        );
                                                                        console.log("🚀 ~ file: TokenUnpause.js ~ line 101 ~ TokenUnpause ~  e.target.value", e.target.value)
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.tokenSymbol}
                                                                >
                                                                    {TokenSymbol &&
                                                                        TokenSymbol.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.tokenSymbol}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.displayname}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="tokenSymbol"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-6">
                                                            <div className="d-flex me-0 me-md-4 me-xxl-5 justify-content-end">
                                                                <button
                                                                    type="button"
                                                                    class="btn cancel-btn mb-3 mb-md-0 me-4"
                                                                >
                                                                    CANCEL
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        // setPrivateModal(true);
                                                                        UnPauseData()
                                                                    }}
                                                                    type="submit"
                                                                    class="btn next-btn mb-3 mb-md-0"
                                                                >
                                                                    SUBMIT
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </>
    );
}

export default TokenUnpause;
