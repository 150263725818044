import React from "react";

function Transaction() {

    return (
        <>
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0 ">
                    {/* <div className="row m-0"> */}
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            </div> */}

                    <div className="col-12">
                        <div className="main-heading">
                            <i className="bi bi-arrow-left"></i>
                            <h3>
                                Money Services
                            </h3>
                        </div>

                        <div className="review-wallet-box deposit-funds-tabs">
                            <h6>Unipay</h6>
                            <p>This function enables the sending of digital currency from one wallet to another wallet. A wallet could be a token wallet or a ledger wallet.</p>
                            <div className="row">
                                <div className="col-xxl-11">
                                    <div className="review-wallet-info-box mb-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="heading">
                                                        <div className="money-heading">
                                                            <h3>Transaction <p>247wwbrwhr24y2rhqef35uhrkfh34irf</p></h3>
                                                        </div>
                                                        <i className="bi bi-pencil-square"></i>
                                                    </div>
                                                    <hr className="mb-4" />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>TxtHash</h3>
                                                            </div>
                                                        </div>
                                                        <div className=" col-md">
                                                            <div className="money-transaction-text">
                                                                <p>247wwbrwhr24y2rhqef35uh rkfh34irf</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>TxtReceiptStatus</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <h6><span></span>PENDING</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Block</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>1348193
                                                            <span>6 block confirmation</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Timestamp</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>a few second ago (Wed May 25 2022 11:00:58 GMT-0400)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>From</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>4hbrgf724t2jtgi2j49giuj3 oi5jg3i4weu</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>To</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>247wwbrwhr24y2rhqef35uhr kfh34irf</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Value</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>0 XDC <h2>$0</h2></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Actual TX/Cost Fee</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>0,0000245204958<h2>$0,01348</h2></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Gas Used by TX</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>134,298</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Gas Price</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>134,298 (0.25 Gwei)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Nonce</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <p>312</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <div className="money-transaction-icon">
                                                                <h3><i class="bi bi-question-lg"></i>Nonce</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="money-transaction-text">
                                                                <textarea type="text" placeholder="5,000.00" rows="3" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-11 p-0">
                                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                    <button
                                        type="button"
                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                    >
                                        CANCEL
                    </button>
                                    <button type="button" className="btn next-btn mb-3 mb-md-0">
                                        SUBMIT
                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default Transaction;
