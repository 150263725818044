/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../../api/Login";
import { useLocation, useNavigate } from "react-router-dom";

const initialValues = {
  OTP: "",
  Password: "",
};
const validationSchema = Yup.object().shape({
  OTP: Yup.string()
    .required("OTP required")
    .min(6, "OTP should be min 6 character")
    .max(6, "OTP should be max 6 character"),
  Password: Yup.string()
    .required("Password required")
    .min(6, "Password is too short - should be 4 chars min"),
});

const NewCredential = () => {
  let navigate = useNavigate();
  const location = useLocation();

  function ShowPassBtn() {
    var x = document.getElementById("Password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="login-page">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              let response = await resetPassword({
                email: location.state.email,
                password: values.Password,
                otp: values.OTP,
              });
              if (response === "success") {
                navigate("/");
              }
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <div className="row align-items-center">
                  <div className="col-md-7 col-lg-8 p-0">
                    <div className="login-img">
                      <img
                        src="../images/dl.beatsnoop2.png"
                        className="img-fluid"
                        alt="login-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4">
                    <div className="login-box">
                      <div className="login-text">
                                  <img src="../images/coins/U-1.png" alt="logo" />
                        <h3>Welcome Back</h3>
                        <Form className="login-form">
                          <div className="mb-3 mb-md-5">
                            <label className="form-label" htmlFor="OTP">
                              OTP
                            </label>
                            <div className="password-eye">
                              <Field
                                type="OTP"
                                name="OTP"
                                className="form-control"
                                id="OTP"
                              />
                            </div>
                            <div
                              className={
                                errors.OTP && touched.OTP ? "input-error" : null
                              }
                            >
                              <ErrorMessage
                                name="OTP"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <div className="password-eye">
                              <Field
                                type="password"
                                name="Password"
                                className="form-control"
                                id="Password"
                              />
                              <i
                                className="bi bi-eye-slash eye-img"
                                onClick={ShowPassBtn}
                              />
                            </div>
                            <div
                              className={
                                errors.Password && touched.Password
                                  ? "input-error"
                                  : null
                              }
                            >
                              <ErrorMessage
                                name="Password"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <button type="submit" id="btn">
                            Submit
                          </button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default NewCredential;
