import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetActivityApi } from "../../../api/Balance";
import { decrypytdata } from "../../../api/walletKey";
import Grid from "../../../Components/Grid";
import { GetActivitycolumns } from "../../../Components/GridHeaders";

// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function GetActivity() {
  // const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";

  //eslint-disable-next-line
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedLedger = await GetActivityApi(APPID, APIKEY);
      const Ledgers = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: GetActivity.js ~ line 30 ~ fetchdata ~ Ledgers",
        Ledgers
      );
      let data = JSON.parse(JSON.parse(Ledgers).CommandResponse);
      // console.log(JSON.parse(Ledgers));
      // setRows(JSON.parse(Ledgers));
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
          // console.log(dataMain, "dataMain")
        });
        setRows(dataArr);
      }
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="row">
                <div className="col-xxl-10">
                  <div className="main-heading">
                    <h3> Balances</h3>
                    <h6>Activity</h6>
                    <p>
                      This function provides this transaction history for a
                      ledger account.
                    </p>
                  </div>
                  <div className="wallet-balances-box m-0">
                    <div className="d-md-flex justify-content-between">
                      <div className="search-box d-md-flex">
                        <div className="input-group mb-3"></div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginBottom: "10px" }}
                      >
                        <Link
                          to="/webwallet/transfer"
                          //to=""
                          className={"nav-link"}
                          style={{ color: "white" }}
                        >
                          TRANSFER FUNDS
                        </Link>
                      </button>
                    </div>
                    <div style={{ height: 800, width: "100%" }}>
                      <Grid row={rows} coloums={GetActivitycolumns} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GetActivity;
