import React from 'react'

const Ledgers = () => {
    return (
        <>
            <div className="documentation-accordion">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="Issuer">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#issuerOne" aria-expanded="true" aria-controls="issuerOne">
                                Create Ledger
                                                                        <i className="bi bi-plus-lg none"></i>
                                <i className="bi bi-dash-lg show"></i>
                            </button>
                        </h2>
                        <div id="issuerOne" className="accordion-collapse collapse show" aria-labelledby="Issuer" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Create Ledger	Enables the creation of any type of asset or liability ledger for transferring digital currencies.
                                                                    </div>
                        </div>
                    </div>
                    <hr />
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="Modify">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyTwo" aria-expanded="false" aria-controls="ModifyTwo">
                                Modify Ledger
                                                                        <i className="bi bi-plus-lg none"></i>
                                <i className="bi bi-dash-lg show"></i>
                            </button>
                        </h2>
                        <div id="ModifyTwo" className="accordion-collapse collapse  show" aria-labelledby="Modify" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Create Ledger	Enables the creation of any type of asset or liability ledger for transferring digital currencies.
                                                                    </div>
                        </div>
                    </div>
                    <hr />
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="Suspend">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#SuspendThree" aria-expanded="false" aria-controls="SuspendThree">
                                Suspend Ledger
                                                                        <i className="bi bi-plus-lg none"></i>
                                <i className="bi bi-dash-lg show"></i>
                            </button>
                        </h2>
                        <div id="SuspendThree" className="accordion-collapse collapse show" aria-labelledby="Suspend" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Suspend Ledger	Suspends a ledger from sending or receiving digital currencies.
                                                                    </div>
                        </div>
                    </div>
                    <hr />
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="BranchOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateOne" aria-expanded="false" aria-controls="CreateOne">
                                Deposit
                                                                        <i className="bi bi-plus-lg none"></i>
                                <i className="bi bi-dash-lg show"></i>
                            </button>
                        </h2>
                        <div id="CreateOne" className="accordion-collapse collapse show" aria-labelledby="BranchOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Deposit	Enables a wallet to add funds to a ledger account from their cash account.
                                                                    </div>
                        </div>
                    </div>
                    <hr />
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="BranchTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateTwo" aria-expanded="false" aria-controls="CreateTwo">
                                Withdraw
                                                                        <i className="bi bi-plus-lg none"></i>
                                <i className="bi bi-dash-lg show"></i>
                            </button>
                        </h2>
                        <div id="CreateTwo" className="accordion-collapse collapse show" aria-labelledby="BranchTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Withdraw	Enables a wallet to subtract funds from a ledger account to their cash account.
                                                                    </div>
                        </div>
                    </div>
                    <hr />
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="BranchThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateThree" aria-expanded="false" aria-controls="CreateThree">
                                Ledger Balance
                                                                        <i className="bi bi-plus-lg none"></i>
                                <i className="bi bi-dash-lg show"></i>
                            </button>
                        </h2>
                        <div id="CreateThree" className="accordion-collapse collapse" aria-labelledby="BranchThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Withdraw	Enables a wallet to subtract funds from a ledger account to their cash account.
                                                                    </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        </>
    )
}

export default Ledgers