//eslint-disable-next-line
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ValidateWalletLink } from "../../../../../api/PayeesApi";
import { decrypytdata } from "../../../../../api/walletKey";

// import { Buffer } from "buffer";

// @ts-ignore
// window.Buffer = Buffer;
function PublicAddress({ setVdata, nextstep, setResult }) {
  //eslint-disable-next-line
  const [data, setData] = useState("");
  //eslint-disable-next-line
  const [image, setImage] = useState();
  console.log(
    "🚀 ~ file: Payment Device.js ~ line 14 ~ PaymentDevice ~ image",
    image
  );
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  let APPID = "";
  let APIKEY = "";
  const initialValues = {
    Walletlinktypecode: "WALLET",
    PublicAddress: data.PublicAddress ? data.PublicAddress : "",
  };
  console.log(
    "🚀 ~ file: Payment Device.js ~ line 31 ~ PaymentDevice ~ initialValues",
    initialValues
  );
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: Email.js ~ line 26 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      // console.log("Bank Account");
      const encryptedIssuer = await ValidateWalletLink(
        APPID,
        APIKEY,
        initialValues
      );
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 42 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 44 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      setVdata({
        accountClasscode: "WALLET",
        PublicAddress: data.PublicAddress ? data.PublicAddress : "",
      });
      nextstep();
      // let jsonData;
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // } else {
      //   dispatch(setPrivateId(""));
      //   // dispatch(setResult(jsonData))
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        // closeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              // console.log("values", values);
              // debugger;
              // setData(values);
            }}
          >
            {(formik) => {
              //eslint-disable-next-line
              const { errors, touched } = formik;
              return (
                <div className="">
                  <Form>
                    <div className="row align-items-center">
                      <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        {/* <label>Email</label> */}
                        <div className="">
                          <div className="input-box mb-5 email-floting  form-floating">
                            <Field
                              type="text"
                              name="PublicAddress"
                              id="PublicAddress"
                              className="form-control email-floting-input"
                              onChange={(e) => {
                                // setVdata((prev) => {
                                //   return {
                                //     ...prev,
                                //     [e.target.name]: e.target.value,
                                //   };
                                // });
                                setData((prev) => {
                                  return {
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  };
                                });
                              }}
                              // value={formik.PublicAddress}
                            />
                            <label className="ps-0 pb-0"> Public Address</label>
                            <div
                              className={
                                errors.PublicAddress && touched.PublicAddress
                                  ? "input-error"
                                  : null
                              }
                            >
                              <ErrorMessage
                                name="PublicAddress"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        <div className="mb-5">
                          <button
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="btn next-btn"
                          >
                            Validate
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default PublicAddress;
