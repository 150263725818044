import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const show = useSelector((state) => state.ShowLoader.loading);

  return (
    <>
      {show && (
        <div className="position-fixed loadercoin">
          <div className="overlay"></div>
          <div className="spiner">
            <img heitgh="70" width="100" src="../logo192.png" alt="spinimg" />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
