import { toast } from "react-toastify";
export function success(message, options) {
  toast.success(message, {
    options,
  });
}

export function info(message, options) {
  toast.info(message, { 
    options,
  });
}

export function warn(message, options) {
  toast.warn(message, {
    options,
  });
}

export function error(message, options) {
  toast.error(message, {
    options,
  });
}