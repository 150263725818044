import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Email from "../LinkWallet/Email";
import Username from "../LinkWallet/Username";
import Phone from "../LinkWallet/Phone";
import BankAccount from "../LinkWallet/Bank Acoount";
import PublicAddress from "../LinkWallet/PublicAddress";

function Owner({ previous, nextstep, setVdata }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [result, setResult] = useState("");
  const [step, setstep] = useState(2);

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-username"
            role="tabpanel"
            aria-labelledby="pills-username-tab"
          >
            <PublicAddress
              setVdata={setVdata}
              nextstep={nextstep}
              setResult={setResult}
            />
          </div>
        );
      case 2:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-email"
            role="tabpanel"
            aria-labelledby="pills-email-tab"
          >
            <Username setVdata={setVdata} nextstep={nextstep} />
          </div>
        );
      case 3:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-phone"
            role="tabpanel"
            aria-labelledby="pills-phone-tab"
          >
            <BankAccount setVdata={setVdata} nextstep={nextstep} />
          </div>
        );
      case 4:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-account"
            role="tabpanel"
            aria-labelledby="pills-account-tab"
          >
            <Email setVdata={setVdata} nextstep={nextstep} result={result} />
          </div>
        );
      case 5:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-payment"
            role="tabpanel"
            aria-labelledby="pills-payment-tab"
          >
            <Phone setVdata={setVdata} nextstep={nextstep} />
          </div>
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {isValidPublicAddress && (
        <div class="col-md-9 col-lg-9 col-xxl-10 mt-4">
          <div class="link-wallet-box m-0">
            <div class="link-wallet-tabs">
              <h4>Wallet Link</h4>
              <p>
                You must link the payee to their network wallet with either of
                their following information.
              </p>
              <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link "
                    id="pills-payment-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-payment"
                    type="button"
                    role="tab"
                    aria-controls="pills-payment"
                    aria-selected="false"
                    onClick={() => {
                      setstep(1);
                    }}
                  >
                    <img
                      src="../icons/link-payment.png"
                      class="link-payment-img none"
                      alt="link-payment-img"
                    />
                    <img
                      src="../icons/link-payment-light.png"
                      class="link-payment-img show"
                      alt="link-payment-img"
                    />
                    PUBLIC ADDRESS
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-username-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-username"
                    type="button"
                    role="tab"
                    aria-controls="pills-username"
                    aria-selected="true"
                    onClick={() => {
                      setstep(2);
                    }}
                  >
                    <img
                      src="../icons/link-username.png"
                      class="link-username-img none"
                      alt="link-username-img"
                    />
                    <img
                      src="../icons/link-username-light.png"
                      class="link-username-img show"
                      alt="link-username-img"
                    />
                    USERNAME
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-account-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-account"
                    type="button"
                    role="tab"
                    aria-controls="pills-account"
                    aria-selected="false"
                    onClick={() => {
                      setstep(3);
                    }}
                  >
                    <img
                      src="../icons/link-bank.png"
                      class="link-bank-img none"
                      alt="link-bank-img"
                    />
                    <img
                      src="../icons/link-bank-light.png"
                      class="link-bank-img show"
                      alt="link-bank-img"
                    />
                    BANK
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-email-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-email"
                    type="button"
                    role="tab"
                    aria-controls="pills-email"
                    aria-selected="false"
                    onClick={() => {
                      setstep(4);
                    }}
                  >
                    <img
                      src="../icons/link-email.png"
                      class="link-email-img none"
                      alt="link-email-img"
                    />
                    <img
                      src="../icons/link-email-light.png"
                      class="link-email-img show"
                      alt="link-email-img"
                    />
                    EMAIL
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link me-0"
                    id="pills-phone-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-phone"
                    type="button"
                    role="tab"
                    aria-controls="pills-phone"
                    aria-selected="false"
                    onClick={() => {
                      setstep(5);
                    }}
                  >
                    <img
                      src="../icons/link-phone.png"
                      class="link-phone-img none"
                      alt="link-phone-img"
                    />
                    <img
                      src="../icons/link-phone-light.png"
                      class="link-phone-img show"
                      alt="link-phone-img"
                    />
                    MOBILE
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-username"
                  role="tabpanel"
                  aria-labelledby="pills-username-tab"
                >
                  {/* <div class="suspend-text wallet-information-form">
                    <form>
                      <div class="row align-items-center">
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <div className="input-box form-floating">
                              <input
                                type="text"
                                placeholder="User Name"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">Username</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <button type="button" className="btn next-btn">
                              Validate
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> */}
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-payment"
                  role="tabpanel"
                  aria-labelledby="pills-payment-tab"
                >
                  {/* <div class="suspend-text wallet-information-form">
                    <form>
                      <div class="row align-items-center">
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <div className="input-box form-floating">
                              <input
                                type="text"
                                placeholder="Public Name"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">
                                Public Address
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <button type="button" className="btn next-btn">
                              Validate
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> */}
                </div>
                {/* <div
                  class="tab-pane fade"
                  id="pills-email"
                  role="tabpanel"
                  aria-labelledby="pills-email-tab"
                >
                  <div class="suspend-text wallet-information-form">
                    <form>
                      <div class="row align-items-center">
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <div className="input-box form-floating">
                              <input
                                type="text"
                                placeholder="Email Name"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">Email</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <button type="button" className="btn next-btn">
                              Validate
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
                {/* <div
                  class="tab-pane fade"
                  id="pills-phone"
                  role="tabpanel"
                  aria-labelledby="pills-phone-tab"
                >
                  <div class="suspend-text wallet-information-form">
                    <form>
                      <div class="row align-items-center">
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <label className="ps-0 pb-0">Mobile</label>
                            <div class="input-group">
                              <div className="d-flex">
                                <button
                                  class="form-control btn  dropdown-toggle"
                                  style={{ width: "unset" }}
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  +91
                                </button>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a class="dropdown-item" href="/">
                                      +8{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="/">
                                      +9
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="/">
                                      +2
                                    </a>
                                  </li>
                                </ul>
                                <input
                                  type="text"
                                  class="form-control"
                                  aria-label="Text input with dropdown button"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <button type="button" className="btn next-btn">
                              Validate
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
                {/* <div
                  class="tab-pane fade"
                  id="pills-account"
                  role="tabpanel"
                  aria-labelledby="pills-account-tab"
                >
                  <div class="suspend-text wallet-information-form">
                    <form>
                      <div class="row align-items-center">
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <label>Account Type</label>
                            <div className="input-box">
                              <select
                                as="select"
                                className="form-select form-control"
                              >
                                <option>Account Type</option>
                                <option>1</option>
                                <option>1</option>
                                <option>1</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mt-3 mb-4 mb-xl-0">
                          <div className="mb-5">
                            <div className="input-box form-floating">
                              <input
                                type="text"
                                placeholder="Account Number"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">
                                Account Number
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="home">
                    {renderstep(step)}
                  </div>
                </div>
                <div class="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-md-5">
                  <button
                    type="button"
                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                    onClick={() => previous()}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="submit"
                    className="btn next-btn mb-3 mb-md-0"
                    // onClick={nextstep}
                    onClick={() => nextstep()}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Owner;
