import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import Header from "../../../../Components/Header";
// import Sidebar from "../../../../Components/Sidebar";
function PostRates({ nextstep }) {
    const formik = useFormik({
        initialValues: {
            Domestic: "",
            From: "",
            FromTo: "",
            OriginationFee: "",
            TransferRate: "",
        },
        validationSchema: Yup.object({
            Domestic: Yup.number().required("Domestic is required!"),
            From: Yup.string().required("From is required!"),
            FromTo: Yup.string().required("From To is required!"),
            OriginationFee: Yup.string().required("Origination Fee is required!"),
            TransferRate: Yup.string().required("Transfer Rate is required!"),
        }),
        onSubmit: (values) => {
            nextstep();
        },
    });
    return (
        <>
            {/* <Header /> */}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row  ms-lg-4 ms-xl-5 m-0">
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            <Sidebar />
          </div> */}
                    <div className="col-12">
                        <div className="main-heading">
                            <h3>Issuers</h3>
                            <h6>Post Rate</h6>
                            <p>
                                This function allows an issuer to post their domestic and international rates for digital currency transfers.
                            </p>
                        </div>

                        <div className="wallet-information-box m-0">
                            <form
                                className="wallet-information-form"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="row mb-xl-5">
                                    <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                        <label className="mb-3">Rate Type</label>
                                        <select
                                            id="Domestic"
                                            className="form-select form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Domestic}
                                        >
                                            <option defaultValue>Domestic</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        {formik.errors.Domestic ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.Domestic}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                        <label>From</label>
                                        <div className="input-group d-flex mb-3">
                                            <input
                                                type="text"
                                                id="From"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                onChange={formik.handleChange}
                                                value={formik.values.From}
                                            />
                                            <span className="input-group-text">.00</span>
                                            {formik.errors.From ? (
                                                <div style={{ color: "red" }}>{formik.errors.From}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                        <label>To</label>
                                        <div className="input-group d-flex mb-3">
                                            <input
                                                type="text"
                                                id="FromTo"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                onChange={formik.handleChange}
                                                value={formik.values.FromTo}
                                            />
                                            <span className="input-group-text">.00</span>
                                            {formik.errors.FromTo ? (
                                                <div style={{ color: "red" }}>
                                                    {formik.errors.FromTo}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-xl-5">
                                    <div className="col-md-6 col-xl-3">
                                        <label>Origination Fee</label>
                                        <div className="input-group d-flex mb-3">
                                            <input
                                                type="text"
                                                id="OriginationFee"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                onChange={formik.handleChange}
                                                value={formik.values.OriginationFee}
                                            />
                                            <span className="input-group-text">.00</span>
                                            {formik.errors.OriginationFee ? (
                                                <div style={{ color: "red" }}>
                                                    {formik.errors.OriginationFee}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-3">
                                        <label>Transfer Rate</label>
                                        <div className="input-group d-flex mb-3">
                                            <input
                                                type="text"
                                                id="TransferRate"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                onChange={formik.handleChange}
                                                value={formik.values.TransferRate}
                                            />
                                            <span className="input-group-text">%</span>
                                            {formik.errors.TransferRate ? (
                                                <div style={{ color: "red" }}>
                                                    {formik.errors.TransferRate}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-5">
                                    <button
                                        type="button"
                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                    >
                                        CANCEL
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn next-btn mb-3 mb-md-0"
                                        onClick={() => nextstep()}
                                    >
                                        NEXT
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostRates;
