import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showLoader } from "../../../../action";
import { GetBalance } from "../../../../api/Balance";
import { decrypytdata } from "../../../../api/walletKey";
import Pagination from "../../../Headermenu/NewPagination";
function Beneficiary({ setTargetData, nextstep, step }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [rows, setRows] = useState([]);
  const [checkedRows, setCheckedRows] = useState();
  const [maxRows, setMaxRows] = useState("");
  const dispatch = useDispatch();
  console.log("🚀 ~ file: Beneficiary.js ~ line 8 ~ Beneficiary ~ rows", rows);
  const publicKeys = useSelector((state) => state.keyReducer);

  //paginason
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postparPage, setPostparPage] = useState(25);

  const initialValues = {
    ledgerBalanceTypecode: "",
    ledgerBalancecode: "",
    ledgerTypecode: null,
    tokenSymbol: "",
  };

  let APPID = "";
  let APIKEY = "";

  //eslint-disable-next-line
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedLedger = await GetBalance(APPID, APIKEY, initialValues);
      const Ledgers = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: GetBalances.js ~ line 31 ~ fetchdata ~ Ledgers",
        Ledgers
      );
      let data = JSON.parse(JSON.parse(Ledgers).CommandResponse);
      console.log(
        "🚀 ~ file: GetBalances.js ~ line 33 ~ fetchdata ~ data",
        data
      );
      // setRows(JSON.parse(Ledgers));
      if (data) {
        let dataArr = [];
        setMaxRows(data[0].maxRows);
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
          // console.log(dataMain, "dataMain")
        });
        setRows(dataArr);
      }
    }
    dispatch(showLoader(false));
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    if (step === 1) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [step]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="row mt-0">
          <div className="col-12">
            <div className="originating-account link-wallet-box ms-0">
              <div className="link-wallet-tabs">
                <div className="row ">
                  <div className="col-xxl-11">
                    <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-end px-4">
                        <div className="">
                          <div className="line-input input-group mb-3">
                            <span className="input-group-text">
                              <i className="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="search"
                              onChange={(e) => {
                                // setBankSearch(e.target.value);
                                console.log("abdkjbadb", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" ">
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            border: 1,
                            borderColor: "#c6c6c6",
                          }}
                        >
                          <TableContainer
                            component={Paper}
                            sx={{
                              boxShadow: "none",
                              maxHeight: 500,
                              borderBottom: 1,
                              borderColor: "#c6c6c6",
                            }}
                          >
                            <Table
                              stickyHeader
                              className="table table-hover table-striped"
                              sx={{ minWidth: 650, mb: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead
                                className="sticky-top"
                                sx={{
                                  zIndex: "10",
                                  borderBottom: 1,
                                  borderColor: "#c6c6c6",
                                }}
                              >
                                <TableRow className="data-table-row">
                                  <TableCell className="data-table-head">
                                    <div className="lable-div">
                                      <span className="text-dark">Select</span>
                                    </div>
                                  </TableCell>

                                  <TableCell className="data-table-head">
                                    <div className="lable-div">
                                      <span className="text-dark">
                                        Digital Currency Account
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="data-table-head"
                                    sx={{ minWidth: 250 }}
                                  >
                                    <div className="lable-div">
                                      <span className="text-dark">
                                        Token Symbol
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="data-table-head"
                                    sx={{ minWidth: 150 }}
                                  >
                                    <div className="lable-div">
                                      <span className="text-dark">
                                        Currency Code
                                      </span>
                                    </div>
                                  </TableCell>

                                  <TableCell className="data-table-head">
                                    <div className="lable-div">
                                      <span className="text-dark">Balance</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* {rows?.length > 0 &&
                      rows
                        ?.slice(indexofFirstpost, indexofLastpage)
                        ?.filter((user) => {
                          if (
                            tokenClassData === "" &&
                            bankclassItem === "" &&
                            tokenTypeData === ""
                          ) {
                            return user;
                          } else if (
                            user.tokenClass
                              .toLowerCase()
                              .includes(tokenClassData.toLowerCase()) &&
                            user.currencycodeList
                              .toLowerCase()
                              .includes(bankclassItem.toLowerCase()) &&
                            user.tokenType
                              .toLowerCase()
                              .includes(tokenTypeData.toLowerCase())
                          ) {
                            return user;
                          }
                        }) */}
                                {rows?.map((items, index) => {
                                  return (
                                    <>
                                      <TableRow
                                        className={
                                          " " + (checkedRows ? "rowselect" : "")
                                        }
                                        onDoubleClick={() => {
                                          console.log("double");
                                          setTargetData(items);
                                          nextstep();
                                        }}
                                        // key={index}
                                        // onClick={() => setActive(items)}
                                        // id={index}
                                        // className={isActive === items ? "is-checked" : ""}
                                      >
                                        {/* <span>{index}</span> */}
                                        <TableCell>
                                          <div className=" table-form-check ">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="flexCheckDefault"
                                              name="flexCheckDefault"
                                              checked={
                                                checkedRows === items.rowid
                                              }
                                              onChange={() => {
                                                setCheckedRows(items.rowid);
                                                // setInChecked(true);
                                              }}
                                              onClick={() => {
                                                console.log(
                                                  "selectedItem===",
                                                  items
                                                );
                                                setTargetData(items);
                                              }}
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell>
                                          {items.Displayname}
                                        </TableCell>
                                        <TableCell>
                                          {items.TokenSymbol}
                                        </TableCell>
                                        <TableCell>
                                          {items.Currencycode}
                                        </TableCell>
                                        <TableCell>
                                          {items.Balance.toFixed(6)}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Box sx={{ width: "100%" }}>
                            <Box className="row py-3 px-2">
                              <Box className="col-12 col-md-3 col-xl-2">
                                <div className="row-select">
                                  <select
                                    class="form-select pagination-select"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setPostparPage(e.target.value);
                                    }}
                                    // placeholder="Bank Type"
                                  >
                                    {/* <option selected>Select rows</option> */}

                                    <option value={10}>10 Rows</option>
                                    <option selected value={25}>
                                      25 Rows
                                    </option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={200}>200 Rows</option>
                                  </select>
                                </div>
                              </Box>

                              <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                                <Pagination
                                  postsperPage={postparPage}
                                  maxRows={maxRows}
                                  paginate={paginate}
                                  currentPage={currentPage}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Beneficiary;
