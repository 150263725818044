import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import React from 'react'
import { Link } from "react-router-dom";

function Umbrella() {
    return (
        <>
            <Header />
            <div className="umbrella global network digital-page">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/hero-section.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <h3>Digital Financial Services</h3>
                            <p>for Regulated Financial Institutions</p>
                            <img src="../images/coins/Unicoin.png" className="my-md-4 w-50" alt="umbrella-icon" />
                            <p className="introducing">Introducing Crypto 2.0</p>
                        </div>
                    </div>
                </div>
                <div className="umbrella-box">
                    <div className="container">
                        <div className="digital-financial">
                            {/*<h4>Digital Financial Services</h4>*/}
                            <h3>The Unicoin Network</h3>
                            <h2>Global. Decentralized. Secure.</h2>
                            <p>The next generation of cryptographic technology, solutions, and services suitable for global banking and digital financial services.</p>
                            <div className="d-inline-block d-md-flex justify-content-center mt-5">
                                <Link to="/subscription"> <button type="button" className="btn subscribr-btn mb-4 mb-md-0" > SUBSCRIBE</button></Link>
                                <a href="https://www.youtube.com/watch?v=6b85sycl3fU&t=290s" target="_blank" rel="noreferrer" className="btn next-btn-video" >
                                    <div>
                                        <i class="bi bi-play-circle-fill"></i>
                                    </div>
                                    <div className="text-strat">
                                        <h3>Video overview </h3>
                                        <p>Learn the future of Crypto 2.0</p>
                                    </div>
                                </a>
                            </div>
                            <div className="round-bg"></div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="unicoin-stakeholders">
                            <div className="row justify-content-evenly">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="https://admin.unicoinnetwork.com" rel="noreferrer" target="_blank" className="administration">
                                        <h4>Administration System</h4>
                                        <div className="p-4 pb-0 text-center">
                                            <img src="../images/Administration-System.png" className="img-fluid" alt="physical" />
                                        </div>
                                        <div className="administration-text last">
                                            <img src="../images/unicoin-icon.png" alt="coin" />
                                            <p>Financial Service Providers can onboard branches, bankers, and customer service agents and customize their digital currency experience.</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/webwallet/gettransfers" rel="noreferrer" target="_blank" className="administration">
                                        <h4>Digital Financial Services</h4>
                                        <div className="p-4 pb-0 text-center">
                                            <img src="../images/Digital-Financial.png" className="img-fluid" alt="physical" />
                                        </div>
                                        <div className="administration-text">
                                            <img src="../images/coins/U-1-small.png" alt="coin" />
                                            <p>Financial Service Providers can connect and transact worldwide according to their local jurisdiction regulations.</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="https://office.unicoinnetwork.com" target="_blank" rel="noreferrer" className="administration">
                                        <h4>Communications System</h4>
                                        <div className="p-4 pb-0 text-center">
                                            <img src="../images/Communications.png"  className="img-fluid" alt="physical" />
                                        </div>
                                        <div className="administration-text last">
                                            <img src="../images/unicoin-icon.png" alt="coin" />
                                            <p>Leveraging our inter-banking network, Financial Service Providers can email, audio, and video conference to discuss and resolve any customer transactions.</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="unicoin-solutions pt-0 unicoin-stakeholders">
                            <h3>Unicoin Stakeholders</h3>
                            <div className="row justify-content-evenly">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/monetary-authorities" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/authorities.png" alt="physical" />
                                        <h4>
                                            Monetary Authorities
                                        </h4>
                                        <p>Monetary sovereignty, governance, and control over digital currency solutions.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/banks" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/issuer.png" alt="physical" />
                                        <h4>
                                            Banks
                                        </h4>
                                        <p>New monetization and revenue models for digital currency solutions.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/fintechs" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/FINTECH (2).png" alt="physical" />
                                        <h4>
                                            Fintechs
                                        </h4>
                                        <p>Build full-service digital banking solutions completely on digital currency rails.</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row justify-content-evenly mt-md-5">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/crypto-exchanges" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/EXCNAHE.png" alt="physical" />
                                        <h4>
                                            Crypto Exchanges

                                        </h4>
                                        <p>Provide access to fixed, income-producing, and speculative store of value products.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/merchants" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/merchants.png" alt="physical" />
                                        <h4>
                                            Merchants

                                        </h4>
                                        <p>Streamline purchases and reduce transaction costs with digital currency payments.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/citizens-and-corporations" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/CITIZENS.png" alt="physical" />
                                        <h4>
                                            Citizens and Corporations
                                        </h4>
                                        <p>Purchase both Unicoin and CBDC over a global digital financial services network.</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="unicoin-technology">
                            <h3>Unicoin Technology</h3>
                            <div className="row justify-content-evenly">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/digital-cash" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/physical.png" alt="physical" />
                                        <h4>
                                            Cryptographic <br className="d-none d-md-block" />
                                            Physical Cash
                                        </h4>
                                        <p>Cash transacted point-to-point without an intermediary.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/electronic-cash" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/electronc.png" alt="physical" />
                                        <h4>
                                            Cryptographic <br className="d-none d-md-block" />
                                            Electronic Cash
                                        </h4>
                                        <p>Cash transacted through a financial institution.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/smart-card" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/Chip.png" alt="physical" />
                                        <h4>
                                            Cryptographic <br className="d-none d-md-block" />
                                            Smart Card
                                        </h4>
                                        <p>Cash transacted on Smart Cards using wireless communications.</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row justify-content-evenly mt-md-5">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/multi-functional-dlt" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/DLT.png" alt="physical" />
                                        <h4>
                                            Multi-Functional
                                            DLT
                                        </h4>
                                        <p>Support all asset and liability bank ledgers for full-service digital banking.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/global-e-kyc" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/FINTECH.png" alt="physical" />
                                        <h4>
                                            Global e-KYC

                                        </h4>
                                        <p>More easily track fraud and suspicious activities through global network monitoring.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/cash-reserve-ledgers" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/Cash.png" alt="physical" />
                                        <h4>
                                            Cash Reserve Ledgers

                                        </h4>
                                        <p>Central banks and their member banks can hold digital currency cash reserves.    </p>
                                    </a>
                                </div>
                            </div>
                            <div className="row justify-content-evenly mt-md-5">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/staked-proof-of-trust-protocol" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/proof.png" alt="physical" />
                                        <h4>
                                            Staked Proof of Trust (SPOT) Protocol
                                        </h4>
                                        <p>A ultrafast protocol consensus for trusted networks.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/cryptocurrency-monetary-policy" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/crypto.png" alt="physical" />
                                        <h4>
                                            Cryptocurrency <br className="d-none d-md-block" />
                                            Monetary Policy
                                        </h4>
                                        <p>A central banking monetary policy framework for Unicoin.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/digital-monetization-technology" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/decenraized.png" alt="physical" />
                                        <h4>
                                            Decentralized Monetization Technology
                                        </h4>
                                        <p>A global localization framework for monetizing Unicoin.</p>
                                    </a>
                                </div>
                            </div>
                            <div className="round-bg-1"></div>

                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="unicoin-solutions">
                            <h3>Unicoin Solutions</h3>
                            <div className="row justify-content-evenly">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/central-bank-digital-currency-CBDC" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/fixed sov.png" alt="physical" />
                                        <h4>
                                            Central Bank Digital Currency (CBDC) </h4>
                                        <p>We Offer Central Banks the Most Leading CBDC Technology</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/income-producing-store-of-value" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/income.png" alt="physical" />
                                        <h4>
                                            Income Producing <br className="d-none d-md-block" />
                                            Store of Value
                                        </h4>
                                        <p>Earn yield without risking principal.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/digital-cash-store-of-value" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/speculative sov.png" alt="physical" />
                                        <h4>
                                            Cryptocurrency <br className="d-none d-md-block" />
                                            Store of Value
                                        </h4>
                                        <p>Unicoin is a store of value cryptocurrency with monetary policy controls.</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row justify-content-evenly mt-md-5">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/cash-backed-digital-assets" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/safe.png" alt="physical" />
                                        <h4>
                                            Cash-Backed Digital Asset
                                            (CBDA)
                                        </h4>
                                        <p>Digital currencies pegged to a legal national currency.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/composite-digital-currency" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/compoaite.png" alt="physical" />
                                        <h4>
                                            Composite Digital Currency (CDC)
                                        </h4>
                                        <p>A basket of legal national currencies.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/neutral-monetary-unit" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/neutral.png" alt="physical" />
                                        <h4>
                                            Neutral Monetary Unit
                                            (NMU)
                                        </h4>
                                        <p>A basket of legal national currencies with fixed exhange rates.</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="unicoin-services">
                            <h3>Unicoin Services    </h3>
                            <div className="row justify-content-evenly">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/issuer-services" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/issuer.png" alt="physical" />
                                        <h4>
                                            Issuer Services
                                        </h4>
                                        <p>Enables the creation and management of issuers on the Unicoin network.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/ledger-services" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/ledger.png" alt="physical" />
                                        <h4>
                                            Ledger Services
                                        </h4>
                                        <p>Enables the creation and management of ledgers on the Unicoin network.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/token-services" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/token.png" alt="physical" />
                                        <h4>
                                            Token Services
                                        </h4>
                                        <p>Enables the creation and management of tokens on the Unicoin network.</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row justify-content-evenly mt-md-5">
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/wallet-services" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/wallet.png" alt="physical" />
                                        <h4>
                                            Wallet Services
                                        </h4>
                                        <p>Enables the creation and management of wallets on the Unicoin network.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/money-services" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/money.png" alt="physical" />
                                        <h4>
                                            Money Services
                                        </h4>
                                        <p>Enables the transfer of value between wallets on the Unicoin network.</p>
                                    </a>
                                </div>
                                <div className="col-md-4 col-xxl-3 mb-5 mb-md-0">
                                    <a href="/staking-services" rel="noreferrer" className="unicoin-tab">
                                        <img src="../icons/staking.png" alt="physical" />
                                        <h4>
                                            Staking Services
                                        </h4>
                                        <p>Enables the ability to earn yield staking Unicoin.</p>
                                    </a>
                                </div>
                            </div>
                            <div className="round-bg-2"></div>

                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </>
    )
}

export default Umbrella;