import React from "react";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function TokenSupply1() {
	return (
		<>
			{/* <Header /> */}
			<div className="col-md-9 col-lg-9 col-xxl-10">
				<div className="row m-0">
					{/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
					<div className="col-12">
						<div className="main-heading">
							<h3> Token</h3>
						</div>
						<div className="ms-lg-5 wallet-information-box">
							<div className="suspend-text wallet-information-form">
								<h4 className="mb-3">Token Supply</h4>
								<p>This function provides all the Tokenomics of a digital currency including the current market price.</p>
								<div class="row">
									<div class="col-xxl-6 mb-4">
										<div class="mb-5">
											<div class="input-box">
												<select className="form-select form-control" >
													<option defaultValue>Token Public Address</option>
													<option value="1">One</option>
													<option value="2">Two</option>
													<option value="3">Three</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-6 ">
										<div className="d-flex me-0 me-md-4 me-xxl-5 justify-content-end">
											<button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
											<button type="submit" class="btn next-btn mb-3 mb-md-0">SUBMIT</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default TokenSupply1;
