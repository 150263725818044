import React from "react";
function ModifyMilestone() {

    return (
        <>
            <div className="col-md-9 ">
                <div className="main-heading ms-lg-4 ms-xl-5">
                    <h3> Money Services</h3>
                    <h6>Modify Milestone</h6>
                    <p>
                        This function allows a beneficiary to lessen an existing milestone payment within an existing escrow transaction.
                    </p>
                </div>
                    <div className="originating-account ms-lg-4 ms-xl-5 m-0">
                        <div className="row">
                            <div className="col-md-auto">
                                <div className="originating-account">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="mb-4">
                                                <tr>
                                                    <th>Milestone Type</th>
                                                    <th className="text-end">Milestone Amount</th>
                                                    <th className="text-end">Modify Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="pt-4">Purchase Agreement Signing</td>
                                                    <td className="pt-4 text-end">5,000.00</td>
                                                    <td className=" pt-4 text-end">
                                                        <div class="money-transaction-text">
                                                            <input type="text" placeholder="5,000.00" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end ms-0 mt-5 wallet-information-box m-0">
                                    <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                    <button type="submit" class="btn next-btn mb-3 mb-md-0">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}

export default ModifyMilestone;
