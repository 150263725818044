import React from "react";
import { useState } from "react";
import CreateToken from "./CreateToken";
import CompositeCurrencies from "../CreateToken/CompositeCurrencies";
import ReviewNewToken from "../CreateToken/ReviewNewToken";

function CreateTokens() {
  const [step, setstep] = useState(1);
  const renderstep = (step) => {
    switch (step) {
      case 1:
        return <CreateToken nextstep={nextstep} previous={previous} />;
      case 2:
        return <CompositeCurrencies nextstep={nextstep} previous={previous} />;
      case 3:
        return <ReviewNewToken nextstep={nextstep} previous={previous} />;
      default:
        return false;
    }
  };
  const nextstep = () => {
    if (step < 3) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 3) {
      setstep(step - 1);
    }
  };
  return <>{renderstep(step)}</>;
}

export default CreateTokens;
