import React from "react";
import { Link } from "react-router-dom";
function LandingPage() {
  return (
    <>
      <div className="slider">
        <div className="container">
                  <div className="row align-items-center">
            <div className="col-md-6">
              <div className="slider-heading">
                <span>Direct mobile to mobile crypto payments</span>
                <h3>
                                  Introducing the World’s First Digital Cash Cryptocurrency
                </h3>
                              <div className="launching-btn">
                                  <Link to="/subscription">SUBSCRIBE</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="slider-img">
                              <img src="images/slider-img.png" alt="alt" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
