import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action";
import { decrypytdata } from "../../../../api/walletKey";
import { AttacheWallet } from "../../../../api/Wallets";

function Email() {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async (values) => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await AttacheWallet(APPID, APIKEY, values);
      console.log(
        "🚀 ~ file: Email.js ~ line 27 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Email.js ~ line 29 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // debugger;

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
    }
    dispatch(showLoader(false));
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const initialValues = {
    walletLinkTypecode: "EMAIL",
    emailAddress: "",
  };
  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log("values", values);
              // debugger;
              closeModal(values);
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <div className="">
                  <Form className="wallet-information-form">
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        <label>Email</label>
                        <div className="input-box mb-5">
                          <Field
                            type="text"
                            name="emailAddress"
                            id="emailAddress"
                            placeholder="Enter Email Address"
                            className="form-control"
                          />
                          <div
                            className={
                              errors.emailAddress && touched.emailAddress
                                ? "input-error"
                                : null
                            }
                          >
                            <ErrorMessage
                              name="emailAddress"
                              component="span"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-md-5">
                        <button
                          type="button"
                          class="btn cancel-btn mb-3 mb-md-0 me-4"
                        >
                          CANCEL
                        </button>
                        <button
                          class="btn next-btn mb-3 mb-md-0"
                          type="submit"
                          // onClick={async () => {
                          //   setPrivateModal(true);
                          // }}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default Email;
