import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateTrasferApi } from "../../../../api/MoneyServices";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
import PrivateModal from "../../../../Components/PrivateModal";
function Money({ previous, nextstep }) {
    const [privateModal, setPrivateModal] = useState(false);
    const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await CreateTrasferApi(APPID, APIKEY);
      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      const jsonData = JSON.parse(devryptedIssuer);

      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // nextstep();
    }
    setPrivateModal(false);
  };
  return (
    <>
     {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
          <div className="row mt-5">
              <div className="col-xxl-10">
                  <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-end px-4">
                          <div className="">
                              <div className="line-input input-group mb-3">
                                  <span className="input-group-text"><i className="bi bi-search"></i></span>
                                  <input type="text" className="form-control" placeholder="search" />
                              </div>
                          </div>
                      </div>
                      <div className="table-responsive p-3 p-md-4">
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th>Bank</th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>Bank Class</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>Bank Type</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>Country</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>State</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>City</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>Bank Name </td>
                                      <td>Bank Class</td>
                                      <td>Bank Type</td>
                                      <td>Country</td>
                                      <td>State</td>
                                      <td>City</td>
                                  </tr>
                                  <tr>
                                      <td>Bank Name </td>
                                      <td>Bank Class</td>
                                      <td>Bank Type</td>
                                      <td>Country</td>
                                      <td>State</td>
                                      <td>City</td>
                                  </tr>
                                  <tr>
                                      <td>Bank Name </td>
                                      <td>Bank Class</td>
                                      <td>Bank Type</td>
                                      <td>Country</td>
                                      <td>State</td>
                                      <td>City</td>
                                  </tr>
                                  <tr>
                                      <td>Bank Name </td>
                                      <td>Bank Class</td>
                                      <td>Bank Type</td>
                                      <td>Country</td>
                                      <td>State</td>
                                      <td>City</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>

      <div className="col-xxl-10">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn cancel-btn mb-3 mb-md-0 me-4"
            onClick={previous}
          >
            PREVIOUS
          </button>
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
                onClick = { nextstep }
                    >
                    NEXT
          </button>
        </div>
      </div>
    </>
  );
}
export default Money;
