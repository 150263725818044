import React from "react";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { createWallet } from "../../../../../api/Wallets";
import { decrypytdata } from "../../../../../api/walletKey";
import { success } from "./../../../../../Components/alert";
import { useNavigate } from "react-router-dom";

function Review({
  walletData,
  peopleData,
  entityData,
  cleandata,
  previous,
  uuid,
}) {
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
      console.log("🚀 ~ file: Review.js ~ line 23 ~ closeModal ~ APPID", APPID);
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: Review.js ~ line 27 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await createWallet(APPID, APIKEY, {
        ...walletData,
        uuid,
      });
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Review.js ~ line 30 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // debugger;
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      dispatch(setPrivateId(""));
      // debugger;
    }
    // setPrivateModal(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-11">
          <div className="review-wallet review-new-token">
            <div className="container-fluid p-0 ">
              <div className="row m-0">
                <div className="col-md-12 mt-5">
                  <div className="review-wallet-box ms-0">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="review-wallet-info-box mb-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="heading">
                                <h3>Wallet Information</h3>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <hr className="mb-4" />
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Basic Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>First Name</h4>
                                  <p>{peopleData.firstname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Middle Name</h4>
                                  <p>{peopleData.secondFirstname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Last Name</h4>
                                  <p>{peopleData.lastname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Second Last Name</h4>
                                  <p>{peopleData.secondLastname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Business Name</h4>
                                  <p> </p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Industry</h4>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                            {console.log(
                              "peopleData.birthCountrycode",
                              peopleData.birthCountrycode
                            )}
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Home Address</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Country</h4>
                                  <p>{peopleData.birthCountrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>State</h4>
                                  <p>{entityData.stateProvince}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>City</h4>
                                  <p>{entityData.cityTown}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 1</h4>
                                  <p>{entityData.addressLine1}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 2</h4>
                                  <p>{entityData.addressLine2}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Postal Code</h4>
                                  <p>{entityData.postalcode}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Identification</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Date of Birth</h4>
                                  <p>{peopleData.dob}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Government Birth Number</h4>
                                  <p>{peopleData.birthGovernmentNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Passport Country</h4>
                                  <p>{peopleData.passportCountrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Passport Number</h4>
                                  <p>{peopleData.passportNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Driver's License Number</h4>
                                  <p>{peopleData.driversLicenseNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Other Government ID Number</h4>
                                  <p>{peopleData.otherIDNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Contact Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Email Address</h4>
                                  <p>{peopleData.tertiaryEmail}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Dialing Country Code</h4>
                                  <p>{peopleData.tertiaryDialingcode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Number</h4>
                                  <p>{peopleData.tertiaryPhoneNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Wallet Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Primary Currency</h4>
                                  <p>{walletData.currencycodeList}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Overdraft Protection Enabled</h4>
                                  <p>{walletData.overdraftProtection}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Overdraft Limit</h4>
                                  <p>{walletData.overdraftLimit}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 p-0">
          <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
            <button
              type="button"
              className="btn cancel-btn mb-3 mb-md-0 me-4"
              onClick={() => {
                cleandata();
                previous();
                dispatch(setPrivateId(""));
              }}
            >
              Previous
            </button>
            <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">
              CANCEL
            </button>
            <button
              type="button"
              className="btn next-btn mb-3 mb-md-0"
              onClick={() => {
                closeModal();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review;
