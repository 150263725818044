/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./Style/App.css";
// import Login from "./Pages/Login/Login";
import Transactions from "./Pages/Headermenu/Transactions";
import Subscription from "./Pages/Headermenu/Subscription";
import Result from "./Pages/Headermenu/Result";
import Commands1 from "./Pages/Headermenu/Commands1";
import Home from "./Pages/physical-cash";
// import ModifyEntity from "./Pages/WEB-WALLET/EntityKYC/index";
import CreatePeople from "./Pages/WEB-WALLET/PeopleKYC/index";
import CreatePayeeNew from "./Pages/WEB-WALLET/Payee/CreatePayeePlus/index";
// import CreatePayeePlus from "./Pages/WEB-WALLET/Payee/CreatePayeePlus/CreatePayeePlus";
import CreateWallets from "./Pages/WEB-WALLET/Wallet/CreatWallets/index";
import ModiyWallets from "./Pages/WEB-WALLET/Wallet/ModifyWallets/index";
import WalletsBalances from "./Pages/WEB-WALLET/Wallet/WalletsBalances";
import LinkWallet from "./Pages/WEB-WALLET/Wallet/CreatWallets/LinkWallet";
import Tokens from "./Pages/Headermenu/Tokens";
import Ledgers from "./Pages/Headermenu/Ledgers";
import Balances from "./Pages/Headermenu/Balances";
import CreateTokens from "./Pages/WEB-WALLET/Token/CreateToken/index";
import ModifyToken from "./Pages/WEB-WALLET/Token/ModiyToken/index";
import DepositFunds from "./Pages/WEB-WALLET/Ledgers/DepositFunds";
import WithdrawFunds from "./Pages/WEB-WALLET/Balances/WithdrawFunds";
import GetLedger from "./Pages/WEB-WALLET/Ledgers/GetLedger";
import LedgerBalances from "./Pages/WEB-WALLET/Ledgers/LedgerBalances";
import IssuresBalances from "./Pages/WEB-WALLET/Issures/IssuresBalances";
import AgentsBalances from "./Pages/WEB-WALLET/Agents/AgentsBalances";
import GetGeoSanctions from "./Pages/WEB-WALLET/GeoSanctions/GetGeoSanctions";
import BranchesBalances from "./Pages/WEB-WALLET/Branches/BranchesBalances";
import TokensBalances from "./Pages/WEB-WALLET/Token/TokensBalances";
import UnicoinFunction from "./Pages/WEB-WALLET/Function/UnicoinFunction";
import TokenPause from "./Pages/WEB-WALLET/Token/TokenPause";
import TokenUnpause from "./Pages/WEB-WALLET/Token/TokenUnpause";
import TokenMint from "./Pages/WEB-WALLET/Token/TokenMint";
import TokenBurn from "./Pages/WEB-WALLET/Token/TokenBurn";
import TokenRedeem from "./Pages/WEB-WALLET/Token/TokenRedeem";
import TokenSwap from "./Pages/WEB-WALLET/Token/TokenSwap";
import GetEscrow from "./Pages/WEB-WALLET/MoneyServices/GetEscrow";
import GetCompliance from "./Pages/WEB-WALLET/Compliance/GetCompliance";
import GetRates from "./Pages/WEB-WALLET/Rates/GetRates";
import GetSanctions from "./Pages/WEB-WALLET/Sanctions/GetSanctions";
import GetPayee from "./Pages/WEB-WALLET/MoneyServices/GetPayee";
import TokenSupply1 from "./Pages/WEB-WALLET/Token/TokenSupply1";
import TokenPrice from "./Pages/WEB-WALLET/Token/TokenPrice";
import TokensList from "./Pages/WEB-WALLET/Token/TokensList";
import TokenCreate from "./Pages/WEB-WALLET/Token/TokenCreate";
import TokenModify from "./Pages/WEB-WALLET/Token/TokenModify";
import WalletPause from "./Pages/WEB-WALLET/Wallet/WalletPause";
import WalletUnpause from "./Pages/WEB-WALLET/Wallet/WalletUnpause";
import AttachPhoto from "./Pages/WEB-WALLET/Wallet/AttachPhoto";
import Confirm from "./Pages/WEB-WALLET/Wallet/Confirm";
import LedgersConfirm from "./Pages/WEB-WALLET/Ledgers/LedgersConfirm";
import MilestoneConfirm from "./Pages/WEB-WALLET/MoneyServices/MilestoneConfirm";
import ReleaseMilestone from "./Pages/WEB-WALLET/MoneyServices/ReleaseMilestone";
import EscrowSuspend from "./Pages/WEB-WALLET/MoneyServices/EscrowSuspend";
import PayeeSuspend from "./Pages/WEB-WALLET/MoneyServices/PayeeSuspend";
import EscrowConfirm from "./Pages/WEB-WALLET/MoneyServices/EscrowConfirm";
import GetMilestone from "./Pages/WEB-WALLET/MoneyServices/GetMilestone";
import CreateMilestone from "./Pages/WEB-WALLET/MoneyServices/CreateMilestone";
import ModifyMilestone from "./Pages/WEB-WALLET/MoneyServices/ModifyMilestone";
import TokenConfirm from "./Pages/WEB-WALLET/Token/TokenConfirm";
import IssuresConfirm from "./Pages/WEB-WALLET/Issures/IssuresConfirm";
import BranchesConfirm from "./Pages/WEB-WALLET/Branches/BranchesConfirm";
import AgentsConfirm from "./Pages/WEB-WALLET/Agents/AgentsConfirm";
import MoneyServicesBalances from "./Pages/WEB-WALLET/MoneyServices/MoneyServicesBalances";
import GetBalances from "./Pages/WEB-WALLET/Balances/GetBalances";
import GetActivity from "./Pages/WEB-WALLET/Balances/GetActivity";
import EntityBalances from "./Pages/WEB-WALLET/EntityKYC/EntityBalances";
import PeopleBalances from "./Pages/WEB-WALLET/PeopleKYC/PeopleBalances";
// import FXRatesBalances from "./Pages/WEB-WALLET/FxRates/FXRatesBalances";
import SpotBalances from "./Pages/WEB-WALLET/SpotStaking/SpotBalances";
import AuthorizationsBalances from "./Pages/WEB-WALLET/Authorizations/AuthorizationsBalances";
import AgentSuspend from "./Pages/WEB-WALLET/Agents/AgentSuspend";
import IssuresSuspend from "./Pages/WEB-WALLET/Issures/IssuresSuspend";
import EntitySuspend from "./Pages/WEB-WALLET/EntityKYC/EntitySuspend";
import PeopleSuspend from "./Pages/WEB-WALLET/PeopleKYC/PeopleSuspend";
import RatesSuspend from "./Pages/WEB-WALLET/Rates/RatesSuspend";
import SanctionsSuspend from "./Pages/WEB-WALLET/Sanctions/SanctionsSuspend";
import ComplianceSuspend from "./Pages/WEB-WALLET/Compliance/ComplianceSuspend";
import LedgersSuspend from "./Pages/WEB-WALLET/Ledgers/LedgersSuspend";
import MilestoneSuspend from "./Pages/WEB-WALLET/MoneyServices/MilestoneSuspend";
import TokenSuspend from "./Pages/WEB-WALLET/Token/TokenSuspend";
import WalletSuspend from "./Pages/WEB-WALLET/Wallet/WalletSuspend";
import BranchesSuspend from "./Pages/WEB-WALLET/Branches/BranchesSuspend";
import CreateBranch from "./Pages/WEB-WALLET/Branches/CreateBranch";
import ModifyBranchs from "./Pages/WEB-WALLET/Branches/ModifyBranchs";
import CreateAgents from "./Pages/WEB-WALLET/Agents/CreateAgents";
import GetLimits from "./Pages/WEB-WALLET/Limits/GetLimits";
import LimitsSuspend from "./Pages/WEB-WALLET/Limits/LimitsSuspend";
import GetPeopleSanctions from "./Pages/WEB-WALLET/PeopleSanctions/GetPeopleSanctions";
import PeopleSanctionsSuspend from "./Pages/WEB-WALLET/PeopleSanctions/PeopleSanctionsSuspend";
import CreateGeoSanctions from "./Pages/WEB-WALLET/GeoSanctions/CreateGeoSanctions/CreateGeoSanctions";
import ModifyGeoSanctions from "./Pages/WEB-WALLET/GeoSanctions/ModifyGeoSanctions/ModifyGeoSanctions";
import ModifyEntitySanction from "./Pages/WEB-WALLET/createEntitySanction/ModifyEntitySanction/ModifyEntitySanction";
import CreatePeopleSanctions from "./Pages/WEB-WALLET/PeopleSanctions/CreatePeopleSanctions/CreatePeopleSanctions";
import ModifyPeopleSanctions from "./Pages/WEB-WALLET/PeopleSanctions/ModifyPeopleSanctions/ModifyPeopleSanctions";
import ModifyPayee from "./Pages/WEB-WALLET/MoneyServices/ModifyPayee/ModifyPayee";
import CreateLimits from "./Pages/WEB-WALLET/Limits/CreateLimits/CreateLimits";
import ModifyLimits from "./Pages/WEB-WALLET/Limits/ModifyLimits/ModifyLimits";
import ModifyRates from "./Pages/WEB-WALLET/Rates/ModifyRates/ModifyRates";
import GeoSanctionsSuspend from "./Pages/WEB-WALLET/GeoSanctions/GeoSanctionsSuspend";
import CreateCompliance from "./Pages/WEB-WALLET/Compliance/CreateCompliance";
import CreateSanctions from "./Pages/WEB-WALLET/Sanctions/CreateSanctions";
import CreateRates from "./Pages/WEB-WALLET/Rates/CreateRates";
import GrantAuthorization from "./Pages/WEB-WALLET/Authorizations/GrantAuthorization";
import RevokeAuthorization from "./Pages/WEB-WALLET/Authorizations/RevokeAuthorization";
import WalletAuthorizations from "./Pages/WEB-WALLET/Authorizations/WalletAuthorizations";
import LedgerAuthorizations from "./Pages/WEB-WALLET/Authorizations/LedgerAuthorizations";
import TokenAuthorizations from "./Pages/WEB-WALLET/Authorizations/TokenAuthorizations";
import CreateLedgers from "./Pages/WEB-WALLET/Ledgers/CreateLedger/index";
import TokenSupply from "./Pages/WEB-WALLET/Token/TokenSupply";
import ModifyLedgers from "./Pages/WEB-WALLET/Ledgers/ModifyLedger";
import FXRates from "./Pages/WEB-WALLET/FxRates/FXRates";
import CreateEscrow from "./Pages/WEB-WALLET/MoneyServices/CreateEscrow";
import EscrowMilestone from "./Pages/WEB-WALLET/MoneyServices/EscrowMilestone";
import CancelMilestone from "./Pages/WEB-WALLET/MoneyServices/CancelMilestone";
import Transaction from "./Pages/WEB-WALLET/MoneyServices/Unipay/Transaction";
import Unipay from "./Pages/WEB-WALLET/MoneyServices/Unipay";
import Stack from "./Pages/WEB-WALLET/SpotStaking/Stack";
import Cashout from "./Pages/WEB-WALLET/SpotStaking/Cashout";
import Reject from "./Pages/WEB-WALLET/SpotStaking/Reject";
import PostRate from "./Pages/WEB-WALLET/Issures/PostRates/index";

// import CreatePayee from "./Pages/WEB-WALLET/MoneyServices/CreatePayee/index";

import TransferRates from "./Pages/WEB-WALLET/Issures/TransferRates";
import Transfer from "./Pages/WEB-WALLET/MoneyServices/Transfer";
import WalletToLedger from "./Pages/WEB-WALLET/Demo/wallettoledger";
import WalletToWallet from "./Pages/WEB-WALLET/Demo/wallettowallet";
import LedgerToWallet from "./Pages/WEB-WALLET/Demo/ledgertowallet";
import LedgerToLedger from "./Pages/WEB-WALLET/Demo/ledgertoledger";
import AcceptEscrow from "./Pages/WEB-WALLET/MoneyServices/AcceptEscrow";
import Transation_Result from "./Pages/WEB-WALLET/Wallet/Transation_Result";

import Issuer from "./Pages/Documentation/Issuer";
import Wallets from "./Pages/Documentation/Wallets";
import DocTokens from "./Pages/Documentation/Tokens";
import DocLedgers from "./Pages/Documentation/Ledgers";
import Authorizations from "./Pages/Documentation/Authorizations";
import MoneyService from "./Pages/Documentation/MoneyService";
import FxRates from "./Pages/Documentation/FxRates";
import SpotStaking from "./Pages/Documentation/SpotStaking/SpotStaking";
import BissnessFAQ from "./Pages/Documentation/FAQ/BissnessFAQ";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import NewCredential from "./Pages/Login/NewCreadential";
import DigitalCurrencies from "./Pages/DigitalCurrencies/digital-currencies";
import News from "./Pages/News/News";
import Monetaryauthorities from "./Pages/Umbrella/monetary-authorities";
import Banks from "./Pages/Umbrella/banks";
import Fintechs from "./Pages/Umbrella/fintechs";
import Cryptoexchanges from "./Pages/Umbrella/crypto-exchanges";
import Merchants from "./Pages/Umbrella/merchants";
import Citizensandcorporations from "./Pages/Umbrella/citizens-and-corporations";
import MultiFunctionalDLT from "./Pages/Umbrella/multi-functional-dlt";
import GlobalEKYC from "./Pages/Umbrella/global-e-kyc";
import CashReserveLedgers from "./Pages/Umbrella/cash-reserve-ledgers";
import StakedProofOfTrustProtocol from "./Pages/Umbrella/staked-proof-of-trust-protocol";
import CryptocurrencyMonetaryPolicy from "./Pages/Umbrella/cryptocurrency-monetary-policy";
import DigitalMonetizationTechnology from "./Pages/Umbrella/digital-monetization-technology";
import StakingServices from "./Pages/Umbrella/staking-services";
import TokenServices from "./Pages/Umbrella/token-services";
import IssuerServices from "./Pages/Umbrella/issuer-services";
import LedgerServices from "./Pages/Umbrella/ledger-services";
import MoneyServices from "./Pages/Umbrella/money-services";
import WalletServices from "./Pages/Umbrella/wallet-services";
import CentralBankDigitalCurrencyCBDC from "./Pages/Umbrella/CentralBankDigitalCurrency/central-bank-digital-currency-CBDC";
import IncomeProducingStoreOfValue from "./Pages/Umbrella/income-producing-store-of-value";
import CryptocurrencyStoreofValue from "./Pages/Umbrella/cryptocurrency-store-of-value";
import CashBackedDigitalAssets from "./Pages/Umbrella/cash-backed-digital-assets";
import CompositeDigitalCurrency from "./Pages/Umbrella/composite-digital-currency";
import NeutralMonetaryUnit from "./Pages/Umbrella/neutral-monetary-unit";
import News2 from "./Pages/News/News2";
import SmartCard from "./Pages/SmartCard/smart-card";
import SmartCard2 from "./Pages/SmartCard/SmartCard2";
import About from "./Pages/electronic-cash/About";
import About1 from "./Pages/electronic-cash/About1";
import About2 from "./Pages/electronic-cash/About2";
import Umbrella from "./Pages/Umbrella/Umbrella";
import Summit from "./Pages/Summit/Summit";
import Download from "./Pages/Download/Download";
import MonetaryPolicy from "./Pages/MonetaryPolicy/monetary-policy";
import Community from "./Pages/Community/Community";
import DemoLanding from "./Pages/DemoLanding/demolanding";
import Crypto2 from "./Pages/Crypto2.0/crypto-2";
import Download2 from "./Pages/Download/Download2";
import { useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";
import FXRatesPrice from "./Pages/WEB-WALLET/FxRates/FXRatesPrice";
import Attach from "./Pages/WEB-WALLET/Wallet/Attach";
// import PeopleSuspend from "./Pages/WEB-WALLET/PeopleKYC/PeopleSuspend";
// import PeopleBalances from "./Pages/WEB-WALLET/PeopleKYC/PeopleBalances";
// import CreatePeople from "./Pages/WEB-WALLET/PeopleKYC/Index";
// import CreatePeople from "./Pages/WEB-WALLET/PeopleKYC/Index";
import { webwalletnavbar } from "./Constatnts/Sidebaritems";
//import Unipay from "./Pages/WEB-WALLET/MoneyServices/Unipay";
import RequireAuth from "./Components/walletauthentication";
import ReactGA from "react-ga";
// import ModifyOriginator from "./Pages/WEB-WALLET/PeopleKYC/Modify/ModifyOriginator";
import ModifyPeople from "./Pages/WEB-WALLET/PeopleKYC/ModifyPeople";
import LedgerRuleBalance from "./Pages/WEB-WALLET/LeadgerRules/LedgerRuleBalance";
import CreateLedgerRule from "./Pages/WEB-WALLET/LeadgerRules/CreateLedgerRule";
import SuspendLedgerRule from "./Pages/WEB-WALLET/LeadgerRules/SuspendLedgerRule";
import ModifyLedgerRule from "./Pages/WEB-WALLET/LeadgerRules/ModifyLedgerRule";
import CreateEntitySanction from "./Pages/WEB-WALLET/createEntitySanction/CreateEntitySanction";
import EntitySanctionBalances from "./Pages/WEB-WALLET/createEntitySanction/EntitySanctionBalances";
import EntitySanctionSuspend from "./Pages/WEB-WALLET/createEntitySanction/EntitySanctionSuspend";
import ModifyEntity from "./Pages/WEB-WALLET/EntityKYC/ModifyEntity";
import CreateEntityKYC from "./Pages/WEB-WALLET/EntityKYC/CreateEntity";
import ModifyAgent from "./Pages/WEB-WALLET/Agents/ModifyAgents";
// import EntityBalances from "./Pages/WEB-WALLET/EntityKYC/EntityBalances"
// import EntityKYC from "./Pages/WEB-WALLET/EntityKYC/CreateEntity";
// import EntitySuspend from "./Pages/WEB-WALLET/EntityKYC/EntitySuspend";
// import ModifyEntity from "./Pages/WEB-WALLET/EntityKYC";
import IndexCrtIssuer from "./Pages/WEB-WALLET/Issures/MainCreateIsuuer/IndexCrtIssuer";
import IndexMdfyIssuer from "./Pages/WEB-WALLET/Issures/ModifyIssuer/IndexMdfyIssuer";
import Loader from "./Components/Loader";
const TRACKING_ID = "UA-230448186-1";
ReactGA.initialize(TRACKING_ID);
function App() {
  let { pathname } = useLocation();
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
      <Loader />
      {webwalletnavbar.includes(pathname) && (
        <>
          <Header></Header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                <Sidebar />
              </div>
              <Routes>
                <Route
                  path="/webwallet/createissuer"
                  element={
                    <RequireAuth>
                      <IndexCrtIssuer />
                    </RequireAuth>
                  }
                />
                {/* <Route
                  path="/webwallet/createpayee"
                  element={
                    <RequireAuth>
                      <CreatePayee />
                    </RequireAuth>
                  }
                /> */}

                {/* <Route
                  path="/webwallet/createpayeeplus"
                  exact
                  element={
                    <RequireAuth>
                      <CreatePayeeNew />
                    </RequireAuth>
                  }
                /> */}
                <Route
                  path="/webwallet/createpayee"
                  exact
                  element={
                    <RequireAuth>
                      <CreatePayeeNew />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyissuer"
                  element={
                    <RequireAuth>
                      <IndexMdfyIssuer />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/unipay"
                  element={
                    <RequireAuth>
                      <Unipay />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/webwallet/createwallet"
                  exact
                  element={
                    <RequireAuth>
                      <CreateWallets />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/webwallet/getentitysanctions"
                  exact
                  element={
                    <RequireAuth>
                      <EntitySanctionBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createentitysanction"
                  exact
                  element={
                    <RequireAuth>
                      <CreateEntitySanction />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendentitysanction"
                  exact
                  element={
                    <RequireAuth>
                      <EntitySanctionSuspend />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/webwallet/createentity"
                  exact
                  element={
                    <RequireAuth>
                      <CreateEntityKYC />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyentity"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyEntity />
                    </RequireAuth>
                  }
                />
                {/* <Route
                  path="/webwallet/createperson"
                  exact
                  element={
                    <RequireAuth>
                      <CreatePeople />
                    </RequireAuth>
                  }
                /> */}
                <Route path="/webwallet/transfer" element={<Transfer />} />
                <Route
                  path="/demo/wallet2ledger"
                  element={<WalletToLedger />}
                />
                <Route
                  path="/demo/wallet2wallet"
                  element={<WalletToWallet />}
                />
                <Route
                  path="/demo/ledger2wallet"
                  element={<LedgerToWallet />}
                />
                <Route
                  path="/demo/ledger2ledger"
                  element={<LedgerToLedger />}
                />
                <Route
                  path="/webwallet/modifywallet"
                  exact
                  element={
                    <RequireAuth>
                      <ModiyWallets />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createtoken"
                  exact
                  element={
                    <RequireAuth>
                      <CreateTokens />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifytoken"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyToken />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/cancelescrow"
                  exact
                  element={
                    <RequireAuth>
                      <EscrowSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/releaseescrow"
                  exact
                  element={
                    <RequireAuth>
                      <EscrowMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/cancelmilestone"
                  exact
                  element={
                    <RequireAuth>
                      <CancelMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/acceptescrow"
                  exact
                  element={
                    <RequireAuth>
                      <AcceptEscrow />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createledger"
                  exact
                  element={
                    <RequireAuth>
                      <CreateLedgers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyledger"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyLedgers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getwallets"
                  exact
                  element={
                    <RequireAuth>
                      <WalletsBalances />
                    </RequireAuth>
                  }
                />
                {/* <Route
          <Header></Header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                <Sidebar />
              </div>
              <Routes>
                <Route
                  path="/webwallet/createissuer"
                  element={
                    <RequireAuth>
                      <CreateIssue />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyissuer"
                  element={
                    <RequireAuth>
                      <ModifyIssue />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/unipay"
                  element={
                    <RequireAuth>
                      <Unipay />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createwallet"
                  exact
                  element={
                    <RequireAuth>
                      <CreateWallets />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createentity"
                  exact
                  element={
                    <RequireAuth>
                      <CreateEntity />
                    </RequireAuth>
                  }
                />
                 <Route
                  path="/webwallet/modifyentity"
                  exact
                  element={
                    <RequireAuth>
                      <CreateEntity />
                    </RequireAuth>
                  }
                />
                
              
                <Route path="/webwallet/transfer" element={<MoneyTransfer />} />
                <Route
                  path="/webwallet/modifywallet"
                  exact
                  element={
                    <RequireAuth>
                      <ModiyWallets />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createtoken"
                  exact
                  element={
                    <RequireAuth>
                      <CreateTokens />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifytoken"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyToken />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/cancelescrow"
                  exact
                  element={
                    <RequireAuth>
                      <EscrowSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/releaseescrow"
                  exact
                  element={
                    <RequireAuth>
                      <EscrowMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/cancelmilestone"
                  exact
                  element={
                    <RequireAuth>
                      <CancelMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/acceptescrow"
                  exact
                  element={
                    <RequireAuth>
                      <AcceptEscrow />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createledger"
                  exact
                  element={
                    <RequireAuth>
                      <CreateLedgers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyledger"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyLedgers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getwallets"
                  exact
                  element={
                    <RequireAuth>
                      <WalletsBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getauths"
                  exact
                  element={
                    <RequireAuth>
                      <AuthorizationsBalances />
                    </RequireAuth>
                  }
                />
                {/* <Route
                  path="/webwallet/ledgerdeposit"
                  exact
                  element={
                    <RequireAuth>
                      <DepositFunds />
                    </RequireAuth>
                  }
                /> */}
                <Route
                  path="/webwallet/tokensupply"
                  element={
                    <RequireAuth>
                      <TokenSupply />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getescrows"
                  element={
                    <RequireAuth>
                      <GetEscrow />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getcompliance"
                  element={
                    <RequireAuth>
                      <GetCompliance />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getrates"
                  element={
                    <RequireAuth>
                      <GetRates />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/webwallet/getsanctions"
                  element={
                    <RequireAuth>
                      <GetSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getpayees"
                  element={
                    <RequireAuth>
                      <GetPayee />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getmilestones"
                  element={
                    <RequireAuth>
                      <GetMilestone />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/webwallet/ledgerwithdraw"
                  exact
                  element={
                    <RequireAuth>
                      <WithdrawFunds />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/deposit"
                  exact
                  element={
                    <RequireAuth>
                      <DepositFunds />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/withdraw"
                  exact
                  element={
                    <RequireAuth>
                      <WithdrawFunds />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/stake"
                  element={
                    <RequireAuth>
                      <Stack />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createescrow"
                  element={
                    <RequireAuth>
                      <CreateEscrow />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getledgers"
                  exact
                  element={
                    <RequireAuth>
                      <GetLedger />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/ledgerbalance"
                  exact
                  element={
                    <RequireAuth>
                      <LedgerBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getissuers"
                  exact
                  element={
                    <RequireAuth>
                      <IssuresBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getagents"
                  exact
                  element={
                    <RequireAuth>
                      <AgentsBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getgeosanctions"
                  exact
                  element={
                    <RequireAuth>
                      <GetGeoSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getbranches"
                  exact
                  element={
                    <RequireAuth>
                      <BranchesBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/gettokens"
                  exact
                  element={
                    <RequireAuth>
                      <TokensBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/unicoinfunction"
                  exact
                  element={
                    <RequireAuth>
                      <UnicoinFunction />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/pause"
                  exact
                  element={
                    <RequireAuth>
                      <TokenPause />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/unpause"
                  exact
                  element={
                    <RequireAuth>
                      <TokenUnpause />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/mint"
                  exact
                  element={
                    <RequireAuth>
                      <TokenMint />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/burn"
                  exact
                  element={
                    <RequireAuth>
                      <TokenBurn />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/redeem"
                  exact
                  element={
                    <RequireAuth>
                      <TokenRedeem />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/swap"
                  exact
                  element={
                    <RequireAuth>
                      <TokenSwap />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/supply1token"
                  exact
                  element={
                    <RequireAuth>
                      <TokenSupply1 />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/tokenprice"
                  exact
                  element={
                    <RequireAuth>
                      <TokenPrice />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/tokenslist"
                  exact
                  element={
                    <RequireAuth>
                      <TokensList />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/tokencreate"
                  exact
                  element={
                    <RequireAuth>
                      <TokenCreate />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/tokenmodify"
                  exact
                  element={
                    <RequireAuth>
                      <TokenModify />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/pausewallet"
                  exact
                  element={
                    <RequireAuth>
                      <WalletPause />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/unpausewallet"
                  exact
                  element={
                    <RequireAuth>
                      <WalletUnpause />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/attachphoto"
                  exact
                  element={
                    <RequireAuth>
                      <AttachPhoto />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/confirm"
                  exact
                  element={
                    <RequireAuth>
                      <Confirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/ledgersconfirm"
                  exact
                  element={
                    <RequireAuth>
                      <LedgersConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/tokenconfirm"
                  exact
                  element={
                    <RequireAuth>
                      <TokenConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/issuresconfirm"
                  exact
                  element={
                    <RequireAuth>
                      <IssuresConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/branchesconfirm"
                  exact
                  element={
                    <RequireAuth>
                      <BranchesConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createbranch"
                  exact
                  element={
                    <RequireAuth>
                      <CreateBranch />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifybranch"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyBranchs />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getlimits"
                  exact
                  element={
                    <RequireAuth>
                      <GetLimits />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createlimit"
                  exact
                  element={
                    <RequireAuth>
                      <CreateLimits />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifylimit"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyLimits />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendlimit"
                  exact
                  element={
                    <RequireAuth>
                      <LimitsSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createagent"
                  exact
                  element={
                    <RequireAuth>
                      <CreateAgents />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifypayee"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyPayee />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendgeosanction"
                  exact
                  element={
                    <RequireAuth>
                      <GeoSanctionsSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getpeoplesanctions"
                  exact
                  element={
                    <RequireAuth>
                      <GetPeopleSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendpeoplesanction"
                  exact
                  element={
                    <RequireAuth>
                      <PeopleSanctionsSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifypeoplesanction"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyPeopleSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyrate"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyRates />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createpeoplesanction"
                  exact
                  element={
                    <RequireAuth>
                      <CreatePeopleSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/creategeosanction"
                  exact
                  element={
                    <RequireAuth>
                      <CreateGeoSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyentitysanction"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyEntitySanction />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifygeosanction"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyGeoSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createcompliance"
                  exact
                  element={
                    <RequireAuth>
                      <CreateCompliance />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createsanctions"
                  exact
                  element={
                    <RequireAuth>
                      <CreateSanctions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createrate"
                  exact
                  element={
                    <RequireAuth>
                      <CreateRates />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyagent"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyAgent />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/agentsconfirm"
                  exact
                  element={
                    <RequireAuth>
                      <AgentsConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/gettransfers"
                  exact
                  element={
                    <RequireAuth>
                      <MoneyServicesBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getentities"
                  exact
                  element={
                    <RequireAuth>
                      <EntityBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getbalances"
                  exact
                  element={
                    <RequireAuth>
                      <GetBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getactivity"
                  exact
                  element={
                    <RequireAuth>
                      <GetActivity />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getpeople"
                  exact
                  element={
                    <RequireAuth>
                      <PeopleBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getfxrates"
                  exact
                  element={
                    <RequireAuth>
                      <FXRatesPrice />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getspot"
                  exact
                  element={
                    <RequireAuth>
                      <SpotBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getauths"
                  exact
                  element={
                    <RequireAuth>
                      <AuthorizationsBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendagent"
                  exact
                  element={
                    <RequireAuth>
                      <AgentSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendissuer"
                  exact
                  element={
                    <RequireAuth>
                      <IssuresSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendentity"
                  exact
                  element={
                    <RequireAuth>
                      <EntitySuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendperson"
                  exact
                  element={
                    <RequireAuth>
                      <PeopleSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendrate"
                  exact
                  element={
                    <RequireAuth>
                      <RatesSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendsanctions"
                  exact
                  element={
                    <RequireAuth>
                      <SanctionsSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendledger"
                  exact
                  element={
                    <RequireAuth>
                      <LedgersSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendtoken"
                  exact
                  element={
                    <RequireAuth>
                      <TokenSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendwallet"
                  exact
                  element={
                    <RequireAuth>
                      <WalletSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendbranch"
                  exact
                  element={
                    <RequireAuth>
                      <BranchesSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/grantauthorization"
                  exact
                  element={
                    <RequireAuth>
                      <GrantAuthorization />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/revokeauthorization"
                  exact
                  element={
                    <RequireAuth>
                      <RevokeAuthorization />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/postrates"
                  element={
                    <RequireAuth>
                      <PostRate />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/cashout"
                  element={
                    <RequireAuth>
                      <Cashout />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/reject"
                  element={
                    <RequireAuth>
                      <Reject />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/releasemilestone"
                  element={
                    <RequireAuth>
                      <ReleaseMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/milestoneconfirm"
                  element={
                    <RequireAuth>
                      <MilestoneConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/escrowsuspend"
                  element={
                    <RequireAuth>
                      <EscrowSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendpayee"
                  element={
                    <RequireAuth>
                      <PayeeSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createmilestone"
                  element={
                    <RequireAuth>
                      <CreateMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifymilestone"
                  element={
                    <RequireAuth>
                      <ModifyMilestone />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/escrowconfirm"
                  element={
                    <RequireAuth>
                      <EscrowConfirm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/milestonesuspend"
                  element={
                    <RequireAuth>
                      <MilestoneSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/milestonesuspend"
                  element={
                    <RequireAuth>
                      <MilestoneSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/transaction"
                  element={
                    <RequireAuth>
                      <Transaction />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/attachwallet"
                  element={
                    <RequireAuth>
                      <Attach />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendpeople"
                  exact
                  element={
                    <RequireAuth>
                      <PeopleSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendcompliance"
                  exact
                  element={
                    <RequireAuth>
                      <ComplianceSuspend />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getpeople"
                  exact
                  element={
                    <RequireAuth>
                      <PeopleBalances />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createpeople"
                  exact
                  element={
                    <RequireAuth>
                      <CreatePeople />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/webwallet/modifypeople"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyPeople />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/getledgerrules"
                  exact
                  element={
                    <RequireAuth>
                      <LedgerRuleBalance />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/createledgerrule"
                  exact
                  element={
                    <RequireAuth>
                      <CreateLedgerRule />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/suspendledgerrule"
                  exact
                  element={
                    <RequireAuth>
                      <SuspendLedgerRule />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/webwallet/modifyledgerrule"
                  exact
                  element={
                    <RequireAuth>
                      <ModifyLedgerRule />
                    </RequireAuth>
                  }
                />
              </Routes>
            </div>
          </div>
        </>
      )}
      <Routes>
        <Route path="/" exact element={<Umbrella />} />
        <Route path="/forgotPassword" exact element={<ForgotPassword />} />
        <Route path="/newCreadential" exact element={<NewCredential />} />
        <Route path="/digital-cash" exact element={<Home />} />
        <Route path="/Documentation" element={<Issuer />} />
        <Route path="/Documentation/Wallets" element={<Wallets />} />
        <Route path="/Documentation/Token" element={<DocTokens />} />
        <Route path="/Documentation/Ledgers" element={<DocLedgers />} />
        {/* <Route path="/FXRatesPrice" element={<FXRatesPrice />} /> */}
        <Route
          path="/documentation/Authorizations"
          element={<Authorizations />}
        />
        <Route path="/Documentation/MoneyService" element={<MoneyService />} />
        <Route path="/Documentation/FxRates" element={<FxRates />} />
        <Route path="/Documentation/SpotStaking" element={<SpotStaking />} />
        <Route path="/faq" element={<BissnessFAQ />} />
        <Route path="/digital-currencies" element={<DigitalCurrencies />} />
        <Route path="/news" element={<News />} />
        <Route path="/monetary-authorities" element={<Monetaryauthorities />} />
        <Route path="/banks" element={<Banks />} />
        <Route path="/fintechs" element={<Fintechs />} />
        <Route path="/crypto-exchanges" element={<Cryptoexchanges />} />
        <Route path="/merchants" element={<Merchants />} />
        <Route
          path="/citizens-and-corporations"
          element={<Citizensandcorporations />}
        />
        <Route path="/multi-functional-dlt" element={<MultiFunctionalDLT />} />
        <Route path="/global-e-kyc" element={<GlobalEKYC />} />
        <Route path="/cash-reserve-ledgers" element={<CashReserveLedgers />} />
        <Route
          path="/staked-proof-of-trust-protocol"
          element={<StakedProofOfTrustProtocol />}
        />
        <Route
          path="/cryptocurrency-monetary-policy"
          element={<CryptocurrencyMonetaryPolicy />}
        />
        <Route
          path="/digital-monetization-technology"
          element={<DigitalMonetizationTechnology />}
        />
        <Route path="/staking-services" element={<StakingServices />} />
        <Route path="/token-services" element={<TokenServices />} />
        <Route path="/issuer-services" element={<IssuerServices />} />
        <Route path="/ledger-services" element={<LedgerServices />} />
        <Route path="/money-services" element={<MoneyServices />} />
        <Route path="/wallet-services" element={<WalletServices />} />
        <Route
                  path="/central-bank-digital-currency-CBDC"
                  element={<CentralBankDigitalCurrencyCBDC />}
        />
        <Route
          path="/income-producing-store-of-value"
          element={<IncomeProducingStoreOfValue />}
        />
        <Route
                  path="/cryptocurrency-store-of-value"
          element={<CryptocurrencyStoreofValue />}
        />
        <Route
          path="/cash-backed-digital-assets"
          element={<CashBackedDigitalAssets />}
        />
        <Route
          path="/composite-digital-currency"
          element={<CompositeDigitalCurrency />}
        />
        <Route
          path="/neutral-monetary-unit"
          element={<NeutralMonetaryUnit />}
        />
        <Route path="/News2" element={<News2 />} />
        <Route path="/smart-card" element={<SmartCard />} />
        <Route path="/SmartCard2" element={<SmartCard2 />} />
        {/* <Route path="/News" element={<News />} /> */}
        <Route path="/Download2" element={<Download />} />
        <Route path="/monetary-policy" element={<MonetaryPolicy />} />
        <Route path="/community" element={<Community />} />
        <Route path="/demo-landing" element={<DemoLanding />} />
        <Route path="/crypto-2" element={<Crypto2 />} />
        <Route path="/Download" element={<Download2 />} />
        <Route path="/About" element={<About />} />
        <Route path="/electronic-cash/About1" element={<About1 />} />
        <Route path="/electronic-cash" element={<About2 />} />
        <Route path="/home" element={<Umbrella />} />
        <Route path="/summit" element={<Summit />} />
        <Route path="/BALANCES" exact element={<Balances />} />
        <Route path="/LEDGERS" exact element={<Ledgers />} />
        <Route path="/TOKENS" exact element={<Tokens />} />
        {/* <Route path="/webwallet/createissuser" exact element={<CreateIssue />} /> */}
        <Route path="/TRANSACTIONS" exact element={<Transactions />} />
        <Route path="/result" exact element={<Result />} />
        <Route path="/Commands1" exact element={<Commands1 />} />

        {/* <Route
          path="/webwallet/createwallet"
          exact
          element={<CreateWallets />}
        /> */}
        <Route
          path="/transation_result"
          exact
          element={<Transation_Result />}
        />
        <Route path="/subscription" exact element={<Subscription />} />

        <Route path="/webwallet/linkwallet" exact element={<LinkWallet />} />

        <Route
          path="/webwallet/ledger-authorizations"
          element={<LedgerAuthorizations />}
        />
        <Route
          path="/webwallet/token-authorizations"
          element={<TokenAuthorizations />}
        />
        <Route
          path="/webwallet/wallet-authorizations"
          element={<WalletAuthorizations />}
        />

        <Route path="/webwallet/transfer-rates" element={<TransferRates />} />
        <Route path="/webwallet/get-fx-rates" element={<FXRates />} />
      </Routes>
    </>
  );
}

export default App;
