import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";
import { getPayee } from "../../../api/payee";
import { decrypytdata } from "../../../api/walletKey";
import Grid from "../../../Components/Grid";
import { Payeecolumns } from "../../../Components/GridHeaders";

// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function GetPayee() {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedLedger = await getPayee(APPID, APIKEY);
      dispatch(showLoader(false));
      const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: GetPayee.js ~ line 32 ~ fetchdata ~ tokens",
        tokens
      );
      let data = JSON.parse(JSON.parse(tokens).CommandResponse);
      console.log(
        "🚀 ~ file: TokensBalances.js ~ line 31 ~ fetchdata ~ data",
        data
      );
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
    
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="container-fluid p-0">
            <div className="row ms-lg-4 ms-xl-5 m-0">
              <div className="col-12">
                <div className="main-heading">
                  <h3> Payee</h3>
                  <h6>Get Payee</h6>
                  <p>
                    This function lists all payees (beneficiaries) associated
                    with a wallet.
                  </p>
                </div>
                <div className="row">
                  <div className="col-xxl-10">
                    <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-between">
                        <div className="search-box d-md-flex">
                          <div className="input-group mb-3"></div>
                        </div>
                        <Link to={`/webwallet/createpayee`}>
                          <button
                            type="button"
                            className="btn btn-primary "
                            style={{ marginBottom: "10px" }}
                          >
                            + ADD Payee
                          </button>
                        </Link>
                      </div>
                      <div style={{ height: 800, width: "100%" }}>
                        <Grid row={rows} coloums={Payeecolumns} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GetPayee;

// {/* <>
//             {/* <Header /> */}
//             <div className="col-md-9 col-lg-9 col-xxl-10">
//                 <div className="container-fluid p-0">
//                     <div className="row ms-lg-4 ms-xl-5 m-0">
//                         {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
//                         <Sidebar />
//                     </div> */}
//                         <div className="col-12">
//                             <div className="main-heading">
//                                 <h3> Payee</h3>
//                                 <h6>Get Payee</h6>
//                                 <p>
//                                     This function lists the registered beneficiaries for this wallet.
//                                 </p>
//                             </div>
//                             <div className="row">
//                                 <div className="col-xxl-10">
//                                     <div className="wallet-balances-box m-0">
//                                         <div className="table-responsive p-3 p-md-4">
//                                             <table className="table table-striped">
//                                                 <thead>
//                                                     <tr>
//                                                         <td>
//                                                             <div className="input-group">
//                                                                 <span className="input-group-text">
//                                                                     <i className="bi bi-search"></i>
//                                                                 </span>
//                                                                 <input
//                                                                     type="text"
//                                                                     className="form-control"
//                                                                     placeholder="Search"
//                                                                 ></input>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="input-group">
//                                                                 <span className="input-group-text">
//                                                                     <i className="bi bi-search"></i>
//                                                                 </span>
//                                                                 <input
//                                                                     type="text"
//                                                                     className="form-control"
//                                                                     placeholder="Search"
//                                                                 ></input>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="input-group">
//                                                                 <span className="input-group-text">
//                                                                     <i className="bi bi-search"></i>
//                                                                 </span>
//                                                                 <input
//                                                                     type="text"
//                                                                     className="form-control"
//                                                                     placeholder="Search"
//                                                                 ></input>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="input-group">
//                                                                 <span className="input-group-text">
//                                                                     <i className="bi bi-search"></i>
//                                                                 </span>
//                                                                 <input
//                                                                     type="text"
//                                                                     className="form-control"
//                                                                     placeholder="Search"
//                                                                 ></input>
//                                                             </div>
//                                                         </td>
//                                                         <td className="text-end">
//                                                             <button type="button" className="ms-0 px-md-3 btn btn-primary ">
//                                                                 + ADD PAYEE
//                                                             </button>
//                                                         </td>
//                                                     </tr>
//                                                 </thead>
//                                                 <thead>
//                                                     <tr>
//                                                         <th className="d-flex justify-content-between">Photo<span><img src="../icons/arrow-2.png" alt="icon" /></span></th>
//                                                         <th><div className="d-flex justify-content-between">Beneficiary<span><img src="../icons/arrow-1.png" alt="icon" /></span></div> </th>
//                                                         <th className="text-end"><div className="d-flex justify-content-between">Country<span><img src="../icons/arrow-1.png" alt="icon" /></span></div></th>
//                                                         <th><div className="d-flex justify-content-between"><span><img src="../icons/arrow-1.png" alt="icon" /></span>Last Payment</div></th>
//                                                         <th className="text-end">Actions</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <tr>
//                                                         <td><img src="../images/user-photo.jpg" className="user-photo " alt="user" /></td>
//                                                         <td>Maya </td>
//                                                         <td><img src="../icons/flags/us_32.png" className="me-2" alt="flag" />United State of America </td>
//                                                         <td className="text-end">12 February 2022</td>
//                                                         <td>
//                                                             <ul className="d-flex justify-content-end mb-0 p-0">
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-12.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-13.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-14.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                             </ul>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td><img src="../images/user-photo-1.jpg" className="user-photo " alt="user" /></td>
//                                                         <td>Daniel </td>
//                                                         <td><img src="../icons/flags/ca_32.png" className="me-2" alt="flag" />Canada </td>
//                                                         <td className="text-end">12 February 2022</td>
//                                                         <td>
//                                                             <ul className="d-flex justify-content-end mb-0 p-0">
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-12.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-13.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-14.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                             </ul>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td><img src="../images/user-photo-2.jpg" className="user-photo " alt="user" /></td>
//                                                         <td>John Doe </td>
//                                                         <td><img src="../icons/flags/hs_32.png" className="me-2" alt="flag" />Honduras </td>
//                                                         <td className="text-end">12 February 2022</td>
//                                                         <td>
//                                                             <ul className="d-flex justify-content-end mb-0 p-0">
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-12.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-13.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-14.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                             </ul>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td><img src="../images/user-photo-4.png" className="user-photo " alt="user" /></td>
//                                                         <td>Michelle </td>
//                                                         <td><img src="../icons/flags/bs_32.png" className="me-2" alt="flag" />Bahamas </td>
//                                                         <td className="text-end">12 February 2022</td>
//                                                         <td>
//                                                             <ul className="d-flex justify-content-end mb-0 p-0">
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-12.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-13.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-14.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                             </ul>
//                                                         </td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td><img src="../images/user-photo-3.jpg" className="user-photo " alt="user" /></td>
//                                                         <td>Andrew </td>
//                                                         <td><img src="../icons/flags/ca_32.png" className="me-2" alt="flag" />Canada </td>
//                                                         <td className="text-end">12 February 2022</td>
//                                                         <td>
//                                                             <ul className="d-flex justify-content-end mb-0 p-0">
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-12.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-13.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                                 <li className="ms-2">
//                                                                     {" "}
//                                                                     <img
//                                                                         src="../icons/action-14.png"
//                                                                         alt="Wallets-icon-img"
//                                                                     ></img>
//                                                                 </li>
//                                                             </ul>
//                                                         </td>
//                                                     </tr>
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* </div> */}
//         </> */}
