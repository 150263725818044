import React from "react";
import {  useLocation } from "react-router-dom";
import Header from "../../../../Components/Header";
import Sidebar from "../../../../Components/Sidebar";
function CompositeCurrencies({nextstep}) {
  let { pathname } = useLocation();
  return (
    <>
      <Header />
      <div className="reject modify-token">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
              <Sidebar />
            </div>
            <div className="col-md-9 col-lg-9 col-xxl-10">
              <div className="main-heading">
                <i className="bi bi-arrow-left"></i>
                <h3>
                  {pathname === '/webwallet/modifytoken' ? "Modify Token" : "Create Token"}
                </h3>
              </div>

              <div className="reject-page review-page">
                <h3>Composite Currencies</h3>
                <p>
                  Select the national currencies that comprise this composite
                  currency.
                </p>

                <div className="row">
                  <div className="col-xl-11 col-xxl-6">
                    <div className="review-box reject-box">
                      <div className="review-box-head reject-head">
                        <div className="modify-token-head mb-3 mb-sm-0">
                          <label>Currencies List</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Transaction Hash"
                            ></input>
                          </div>
                        </div>
                        <div className="modify-token-head">
                          <label>Selected Currencies</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Transaction Hash"
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="review-list">
                        <div className="review-originator mb-4 mb-sm-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                            />
                            <label>AMD Armenian dram</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                            />
                            <label>AOA Angolan kwanza</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                            />
                            <label>AUD Australian dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                            />
                            <label>AWG Aruban florin</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>AZN Azerbaijani manat</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>BDT Bangladeshi taka</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>BSD Bahamian dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>EUR Euro</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>USD United States Dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                            />
                            <label>XCD Eastern Caribbean dollar</label>
                          </div>
                        </div>
                        <div className="review-originator">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>AZN Azerbaijani manat</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>BDT Bangladeshi taka</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>BND Brunei dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>BSD Bahamian dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>CRC Costa Rican colon</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>DKK Danish krone</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>EUR Euro</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label> KYD Cayman Islands dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>SGD Singapore dollar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value=""
                              checked
                            />
                            <label>USD United States Dollar</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-11">
                    <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                      <button
                        type="button"
                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        className="btn next-btn mb-3 mb-md-0"
                        onClick={() =>
                          nextstep()
                        }
                      >
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompositeCurrencies;
