import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

function News2() {
    return (
        <>
            <Header />
            <div className="container-fluid p-0">
                <div className="news">
                    <div className="row m-0">
                        <div className="col-md-9 col-lg-9 col-xxl-10 ms-md-5">
                            <div className="main-heading">
                                <h3> News</h3>
                            </div>
                            <div className="row">
                                <div className="col-xl-10 ms-xl-5">
                                    <div className="news-page">
                                        <p> Banks and financial services providers represent the backbone of the global economy. According to The Banker's Top 1000 World Banks Report, banks hold over $125 Trillion Dollars in financial assets. As banks adopt digital assets and cryptographic cash, the cryptocurrency market could exponential explode. </p>

                                        <p> Unicoin introduces the next wave of cryptocurrency innovations suitable for adoption in the banking and financial services industries. The Unicoin innovation stack consists of three layers. </p>

                                        <p>The foundation layer is our Staked Proof of Trust (SPOT) protocol, a hybrid balance of both centralized and decentralized governance. Secondly, Unicoin innovates cryptographic technologies for transacting both regulated electronic cash and truly private point-to-point physical cash. Unicoin's third layer of innovation is its 3-dimensional store of value network. The Unicoin network supports all 3 classes of store of value digital assets (fixed, income-producing, and speculative store of value).</p>

                                        <p>The Unicoin innovation stack represents the next wave of cryptographic adoption in financial technology. This Section posts news articles and related links validating the Unicoin innovations and the next new wave of cryptocurrency investment and adoption.</p>
                                        <h3>Not Bitcoin, Here's Where The Next $30T In Crypto Could Go To, According To BofA Analyst</h3>
                                        <a target="_blank" rel="noreferrer" href="https://www.msn.com/en-us/money/savingandinvesting/not-bitcoin-here-s-where-the-next-30t-in-crypto-could-go-to-according-to-bofa-analyst/ar-AAVoOXX"><img src="../images/news-img-1.png" alt="news-img" /></a>
                                        <h3>Global Crypto and Digital Assets Webinar</h3>
                                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=6b85sycl3fU&t=290s"><img src="../images/news-img-2.png" className="mb-5" alt="news-img" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>


        </>
    );
}
export default News2;