import React, { useState } from "react";
/* import { useLocation } from "react-router-dom";*/
import Originator from "../../Sanctions/Create/CreateSanctionsInfo/Originator";
import Review from "../../Sanctions/Create/CreateSanctionsInfo/Review";

function SanctionsInfo() {
    const [step, setstep] = useState(1);
    const [people, setPeople] = useState(1);

    // let { hash } = useLocation();

    const nextstep = () => {
        // debugger;
        if (step < 2) {
            setstep(step + 1);
        }
        // formData();
    };
    const cleandata = () => {
        setPeople(1);
    };
    const previous = () => {
        if (step > 1) {
            setstep(step - 1);
        }
    };
    const setdata = (data) => {
        setPeople(data);
    };

    // const formData = (data) => {
    //   console.log("🚀 ~ file: CreatePeople.js ~ line 37 ~ formData ~ data", data);
    // };
    const renderstep = (step) => {
        switch (step) {
            case 1:
                return (
                    <Originator
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        cleandata={cleandata}
                        people={people}
                    // formData={formData}
                    />
                );
            case 2:
                return (
                    <Review
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        cleandata={cleandata}
                        people={people}
                    />
                );
            default:
                return false;
        }
    };

    return (
        <>
            {/* <Header /> */}

            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0 ">
                    {/* <div className="row m-0"> */}
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}
                    <div className="col-12 mt-4">
                        <div className="deposit-funds-tabs ms-0 ms-lg-4 ms-xl-5">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li
                                    className={
                                        step > 1
                                            ? "nav-item money-mr-w disableElement"
                                            : "nav-item money-mr-w"
                                    }
                                >
                                    <button
                                        className={step === 1 ? "nav-link tab-btn active" : "nav-link tab-btn"}
                                        id="profile-tab"
                                        type="button"
                                    >
                                        <div className="beneficiary">
                                            <h3>1</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Owner</h6>
                                    </button>
                                </li>

                                <li
                                    className={step > 2 ? "nav-item disableElement" : "nav-item"}
                                >
                                    <button
                                        className={step === 2 ? "nav-link tab-btn active" : "nav-link tab-btn"}
                                        id="cot-tab"
                                        type="button"
                                    >
                                        <div className="review">
                                            <h3>2</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Review</h6>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home">
                                     <Originator /> 
                                    {renderstep(step)}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}
export default SanctionsInfo;
