import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EntitySanctionSuspendAPI } from "../../../api/EntitySanction";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import TokenConfirm from "../Token/TokenConfirm";

function EntitySanctionSuspend() {
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [data, setdata] = useState("");
  const [popUp, setPopUp] = useState(false);

  const initialValues = {
    entitySanctioncode: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let APPID = "";
  let APIKEY = "";
  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await EntitySanctionSuspendAPI(
        APPID,
        APIKEY,
        data
      );
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // const jsonData = JSON.parse(devryptedIssuer);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // debugger;
    }
    
    // setPrivateModal(false);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={suspendData} />
      )} */}
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5  m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>Entity Sanction</h3>
                <h6>Suspend Entity Sanction</h6>
                <p>
                  This function enables the suspension of an entity sanction.
                </p>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log("values", values);
                      setdata(values);
                      setPopUp(true);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched } = formik;
                      return (
                        <div className="">
                          <Form className="wallet-information-form">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <div className="input-box  form-floating mb-5">
                                <Field
                                  type="text"
                                  name="entitySanctioncode"
                                  id="entitySanctioncode"
                                  placeholder="Public Entity Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Public Entity Address
                                </label>
                                <div
                                  className={
                                    errors.entitySanctioncode &&
                                    touched.entitySanctioncode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="entitySanctioncode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="d-flex me-0 me-md-4 me-xxl-5">
                              <button
                                type="button"
                                class="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button
                                // onClick={() => {
                                //   // setPrivateModal(true);
                                //   suspendData();
                                // }}
                                type="submit"
                                class="btn next-btn mb-3 mb-md-0"
                              >
                                SUSPEND
                              </button>
                            </div>
                            {popUp && (
                              <TokenConfirm
                                heading={"Suspend Entity Sanction"}
                                body={initialValues.entitySanctioncode}
                                suspendData={suspendData}
                                setPopUp={setPopUp}
                              />
                            )}
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EntitySanctionSuspend;
