import { WEB_WALLET_URL } from "../Constatnts/config";
import { hmacencrypt } from "./walletKey";
import http from "./httpService.js";

export async function getEntitySanctionAPI(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // limitcode:data
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/GetEntitySanctions",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/compliance/GetEntitySanctions",
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
export async function createEntitySanctionAPI(publickey, privateKey, entityData) {

    // let values = {
    //   sourcePublicAddress: publickey,
    // };
  
    const token = await hmacencrypt(
      "POST",
      WEB_WALLET_URL + "/compliance/CreateEntitySanction",
      entityData,
      publickey,
      privateKey
    );
    const response = await http.post(
      "/compliance/CreateEntitySanction",entityData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log("🚀 ~ file: Entity.js ~ line 51 ~ createEntityAPI ~ response", response.data)
    return response;
  }

export async function EntitySanctionSuspendAPI(publickey, privateKey,data) {
    
    const token = await hmacencrypt(
      "POST",
      WEB_WALLET_URL + "/compliance/SuspendEntitySanction",
      data,
      publickey,
      privateKey
    );
    const response = await http.post("/compliance/SuspendEntitySanction", data,{
      headers: {
        Authorization: token,
      },
    });
    return response;
  }