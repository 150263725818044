import React from "react";

import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
function Tokens() {
  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            <Sidebar />
          </div>
          <div className="col-md-9 col-lg-9 col-xxl-10">
            <div className="balance-page">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-9 mb-5">
                    <div className="balance-head">
                      <h3>Tokens</h3>
                      <p>
                        Creates a new store of value digital asset for savings,
                        commerce and trade.
                      </p>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">COIN</th>
                            <th scope="col">COIN NAME</th>
                            <th scope="col">CURRENCY CODE</th>
                            <th scope="col">TOKEN SYMBOL</th>
                            <th scope="col">USD PRICE</th>
                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <th className="text-center">
                            <img
                                                          src="../images/coins/U-1-small.png"
                              className=""
                              alt="U-1-img"
                            ></img>
                          </th>
                          <td>UNICOIN</td>
                          <td>Worldwide</td>
                          <td>UNC</td>
                          <td>$124.00</td>
                          <td>
                            <button type="button" className="btn">
                              BUY
                            </button>
                          </td>
                        </tbody>
                        <tbody>
                          <th className="text-center">
                            <img
                                                          src="../images/coins/U-1-small.png"
                              className=""
                              alt="U-1-img"
                            ></img>
                          </th>
                          <td>UNICOIN</td>
                          <td>Worldwide</td>
                          <td>UNC</td>
                          <td>$124.00</td>
                          <td>
                            <button type="button" className="btn">
                              BUY
                            </button>
                          </td>
                        </tbody>
                        <tbody>
                          <th className="text-center">
                            <img
                                                          src="../images/coins/U-1-small.png"
                              className=""
                              alt="U-1-img"
                            ></img>
                          </th>
                          <td>UNICOIN</td>
                          <td>Worldwide</td>
                          <td>UNC</td>
                          <td>$124.00</td>
                          <td>
                            <button type="button" className="btn">
                              BUY
                            </button>
                          </td>
                        </tbody>
                        <tbody>
                          <th className="text-center">
                            <img
                                                          src="../images/coins/U-1-small.png"
                              className=""
                              alt="U-1-img"
                            ></img>
                          </th>
                          <td>UNICOIN</td>
                          <td>Worldwide</td>
                          <td>UNC</td>
                          <td>$124.00</td>
                          <td>
                            <button type="button" className="btn">
                              BUY
                            </button>
                          </td>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokens;
