import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getEntity(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/GetEntities",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/kyc/GetEntities",
    { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
export async function createEntityAPI(publickey, privateKey, entityData) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/CreateEntity",
    entityData,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/CreateEntity", entityData, {
    headers: {
      Authorization: token,
    },
  });
  console.log(
    "🚀 ~ file: Entity.js ~ line 51 ~ createEntityAPI ~ response",
    response.data
  );
  return response;
}

export async function modifyEntityAPI(publickey, privateKey, entityData) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/ModifyEntity",
    entityData,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/ModifyEntity", entityData, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function suspendEntityAPI(publickey, privateKey, data) {
  console.log("🚀 ~ file: Entity.js ~ line 77 ~ suspendEntityAPI ~ data", data);

  let values = {
    sourcePublicAddress: publickey,
    entitycode: data.entitycode,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/SuspendEntity",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/SuspendEntity", values, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}
export async function importEntityBatch(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/ImportEntityBatch",
    issuerData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/kyc/ImportEntityBatch",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
