import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action";
import {
  // getCountryCodeDropDown,
  getDailingDropDown,
} from "../../../../api/dropdown";
import { decrypytdata } from "../../../../api/walletKey";
import { AttacheWallet } from "../../../../api/Wallets";

function Phone() {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  // const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  const [dial, setDial] = useState("");

  const fetchdropdwon = async () => {
    // let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    // setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async (values) => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      console.log("phone");
      const encryptedIssuer = await AttacheWallet(APPID, APIKEY, values);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
    }
    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const initialValues = {
    walletLinkTypecode: "MOBILE",
    countrycode: "",
    dialingcode: "",
    mobilephone: "",
  };

  return (
    <>
      {isValidPublicAddress && (
        <div className="phone-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              console.log("values", values);
              // debugger;
              closeModal({
                walletLinkTypecode: "MOBILE",
                countrycode: values.countrycode ? values.countrycode : "",
                dialingcode: dial ? dial : "",
                mobilephone: values.mobilephone ? values.mobilephone : "",
              });
            }}
          >
            {(formik) => {
              const { errors, touched, setFieldValue } = formik;
              return (
                <Form className="wallet-information-form">
                  <div className="row">
                    <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                      <label>Country</label>
                      <div className="input-box">
                        <Field
                          as="select"
                          name="countrycode"
                          id="countrycode"
                          placeholder="Country"
                          className="form-control form-select"
                          onChange={(e) => {
                            console.log(
                              "🚀 ~ file: Originator.js ~ line 213 ~ Originator ~ e",
                              e.target.value
                            );
                            setFieldValue("countrycode", e.target.value);
                            // setFieldValue("dialingcode", formik.values.keycode)
                          }}
                          onBlur={formik.handleBlur}
                          // value={formik.values.countrycode}
                        >
                          {dailingCode &&
                            dailingCode.map((value, index) => {
                              if (
                                formik.values.countrycode === value.countrycode
                              ) {
                                setDial(value.keycode);
                              }
                              return (
                                <>
                                  <option value={value.countrycode} key={index}>
                                    {value.displayname}
                                  </option>
                                </>
                              );
                            })}
                        </Field>
                        <div>
                          <ErrorMessage
                            name="countrycode"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                      <label>Dialing Code</label>
                      <div className="input-box">
                        <Field
                          // as="select"
                          type="text"
                          name="dialingcode"
                          id="dialingcode"
                          placeholder="Dialing Code"
                          className="form-control form-select"
                          // onChange={(e) => {
                          //   console.log(
                          //     "🚀 ~ file: Originator.js ~ line 213 ~ Originator ~ e",
                          //     e.target.value
                          //   );
                          //   setFieldValue("dialingcode", e.target.value);
                          // }}
                          onBlur={formik.handleBlur}
                          value={dial}
                        >
                          {/* {dailingCode &&
                            dailingCode.map((value, index) => {
                              if (
                                formik.values.countrycode === value.countrycode
                              ) {
                                return (
                                  <>
                                    <option key={index}>{value.keycode}</option>
                                  </>
                                );
                              }
                            })} */}
                        </Field>
                        <div>
                          <ErrorMessage
                            name="dialingcode"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                      <label>Mobile Number</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="mobilephone"
                          id="mobilephone"
                          placeholder="Enter Mobile Number"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.mobilephone && touched.mobilephone
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="mobilephone"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-md-5">
                      <button
                        type="button"
                        class="btn cancel-btn mb-3 mb-md-0 me-4"
                      >
                        CANCEL
                      </button>
                      <button class="btn next-btn mb-3 mb-md-0" type="submit">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default Phone;
