import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetMilestoneAPI } from "../../../api/Milestones";
import { decrypytdata } from "../../../api/walletKey";
import { Tokenscolumns } from "../../../Components/GridHeaders";
import PrivateModal from "../../../Components/PrivateModal";
import Grid from "../../../Components/Grid";

function GetMilestone() {
    const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);

  let APPID = "";
  let APIKEY = "";
  const closeModal = () => {
    setPrivateModal(false);
  };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedLedger = await GetMilestoneAPI(APPID, APIKEY);
      const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      let data = JSON.parse(JSON.parse(tokens).CommandResponse);
      console.log("🚀 ~ file: TokensBalances.js ~ line 31 ~ fetchdata ~ data", data)
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);
    return (
        <>
        {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0">
                     <div className="row ms-lg-4 ms-xl-5 m-0"> 
                    
                    <div className="col-12">
                        <div className="main-heading">
                            <h3> Money Services</h3>
                            <h6>Get Milestones</h6>
                            <p>
                                This function list all the milestone payments associated with a wallet.
                            </p>
                        </div>
                        <div className="row">
              <div className="col-xxl-10">
                <div className="wallet-balances-box">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>
                    <Link to={`/webwallet/createescrow`}>
                    <button
                      type="button"
                      className="btn btn-primary "
                      style={{ marginBottom: "10px" }} 
                    >
                      + ADD ESCROW
                    </button>
                    </Link>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={Tokenscolumns} />
                  </div>
                </div>
              </div>
            </div>
                    </div>
                </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default GetMilestone;
