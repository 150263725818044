//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function WalletServices() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/wallet-services.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Wallet Services</p>
                            <h1>Smart Wallet   <br className="d-none d-md-block" /> Technology Optimized<br className="d-none d-md-block" /> for Each Stakeholder</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalletServices;