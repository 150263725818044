import React from "react";
import { useState } from "react";
import CreateRates from "./CreateRates";

function PeopleKYC() {
  const [step, setstep] = useState(1);
  const renderstep = (step) => {
    switch (step) {
      case 1:
            return <CreateRates nextstep={nextstep} previous={previous} />;
      default:
        return false;
    }
  };
  const nextstep = () => {
    if (step < 1) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };
  return <>{renderstep(step)}</>;
}

export default PeopleKYC;
