import React from "react";
function Unicoin() {
  return (
      <>
          <div className="unicoin">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="unicoin-heading text-center">
                              <h3>UNICOIN<span>TM</span></h3>
                              <h6>A Universal Monetary Unit </h6>
                              <img src="../images/coins/fx-u-logo.png" alt="alt" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
}

export default Unicoin;
