import React from 'react';

function PhysicalCash() {
    return  <>
        <div className="unicoin-store">
            <div className="container">
                <div className="unicoin-physical-heading unicoin-store-heading text-center">
                    <h3>Unicoin as Digital Cash</h3>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6 text-center">
                        <img src="images/physical cash.png" className="physical-cash-img" alt="physical-cash-img" />
                    </div>
                    <div className="col-md-6">
                        <div className="unicoin-store-text">
                            <p>Physical cash can be transacted outside the electronic cash system. In this scenario, the exchange of value between two parties is private, anonymous, and is not recorded on any intermediary ledger.</p>
                            <p>Even if using encryption and other cryptographic technology, the mere use of a decentralized or centralized ledger that is transparent and visible to others would violate the properties of physical cash.</p>
                            <p>When Bitcoin emerged, the cryptographic addresses were considered anonymous. Today, many network monitoring technologies have been developed to monitor, track, and trace Bitcoin addresses. </p>
                            <p>Many times, when large Bitcoin transfers are transacted it becomes global news with Bitcoin addresses being publicized. Cybersecurity experts have classified Bitcoin addresses by major industry classifications such as crypto exchanges, mixers, and other known entities.</p>
                            <p className="mb-0">So, although Bitcoin operates outside of financial institutions, it does not operate as physical cash. The primary characteristics of physical cash are hand-to-hand or point-to-point, private, anonymous, untraceable, and self-governance. Unicoin is the world’s first physical cash cryptocurrency.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </>;
}

export default PhysicalCash;