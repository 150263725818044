//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function MoneyServices() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/money-services.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Money Services</p>
                            <h1>Domestic and  <br className="d-none d-md-block" /> International Money<br className="d-none d-md-block" /> Payment Services</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoneyServices;