import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getPayee(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // TokenSymbol:"USDA",
    // displayname:"Unicoin"
    // objectcode:"",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/GetPayees",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/GetPayees",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
