import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getDailingDropDown } from "../../../../../api/dropdown";
import { ValidateWalletLink } from "../../../../../api/PayeesApi";
import { decrypytdata } from "../../../../../api/walletKey";

function Phone({ setVdata, nextstep }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  // const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  const [data, setData] = useState("");
  console.log("🚀 ~ file: Phone.js ~ line 17 ~ Phone ~ data", data);

  const fetchdropdwon = async () => {
    // let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    // setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };

  const initialValues = {
    walletLinkTypecode: "MOBILE",
    mobileDialingcode: data.mobileDialingcode ? data.mobileDialingcode : " ",
    mobilePhone: data.mobilePhone ? data.mobilePhone : " ",
  };

  let APPID = "";
  let APIKEY = "";

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      console.log("phone");
      const encryptedIssuer = await ValidateWalletLink(
        APPID,
        APIKEY,
        initialValues
      );
      console.log(
        "🚀 ~ file: Phone.js ~ line 52 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData = JSON.parse(devryptedIssuer);
      console.log(
        "🚀 ~ file: Phone.js ~ line 56 ~ closeModal ~ jsonData",
        jsonData
      );
      setVdata({
        accountClasscode: "MOBILE",
        mobileDialingcode: data.mobileDialingcode
          ? data.mobileDialingcode
          : " ",
        mobilePhone: data.mobilePhone ? data.mobilePhone : " ",
      });
      nextstep();
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // } else {
      //   dispatch(setPrivateId(""));
      //   // dispatch(setResult(jsonData))
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  console.log(
    "🚀 ~ file: Phone.js ~ line 74 ~ Phone ~ initialValues",
    initialValues
  );

  return (
    <>
      {isValidPublicAddress && (
        <div className="phone-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              console.log("values", values);
              // debugger;
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form className="wallet-information-form">
                  <div className="row">
                    <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                      <label>Mobile</label>
                      <div className="input-box mb-5">
                        <div className="mobile-num-main">
                          <Field
                            as="select"
                            name="mobileDialingcode"
                            className="mobile-num-select"
                            onChange={(e) => {
                              setVdata((prev) => {
                                return {
                                  ...prev,
                                  [e.target.name]: e.target.value,
                                };
                              });
                              setData((prev) => {
                                return {
                                  ...prev,
                                  [e.target.name]: e.target.value,
                                };
                              });
                            }}
                          >
                            {dailingCode &&
                              dailingCode.map((value, index) => {
                                return (
                                  <>
                                    <option
                                      value={value.dialingcodeID}
                                      key={index}
                                    >
                                      {value.dialingcodeID}
                                    </option>
                                  </>
                                );
                              })}
                          </Field>
                          <Field
                            type="text"
                            name="mobilePhone"
                            id="mobilePhone"
                            // placeholder="Enter Mobile Number"
                            className="form-control mobile-num-input"
                            onChange={(e) => {
                              setVdata((prev) => {
                                return {
                                  ...prev,
                                  [e.target.name]: e.target.value,
                                };
                              });
                              setData((prev) => {
                                return {
                                  ...prev,
                                  [e.target.name]: e.target.value,
                                };
                              });
                            }}
                            value={formik.mobilePhone}
                          />
                        </div>
                        <div
                          className={
                            errors.mobilePhone && touched.mobilePhone
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="mobilePhone"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <div className="mb-5">
                        <button
                          type="button"
                          onClick={() => {
                            closeModal();
                          }}
                          className="btn next-btn"
                        >
                          Validate
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default Phone;
