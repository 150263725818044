import React from "react";
// import Header from "../../../../Components/Header";
// import Sidebar from "../../../../Components/Sidebar";
function RateInformation() {
    return (
        <>
            {/* <Header /> */}
            <div className="col-md-9 col-lg-9 col-xxl-10col-9">
                <div className="container-fluid p-0 ">
                    {/* <div className="row m-0"> */}
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}

                    <div className="row ms-lg-4 ms-xl-5">
                        <div className="col-12">
                            <div className="main-heading">
                                <h3>Issuers</h3>
                                <h6>Review Rate</h6>
                                <p>
                                    This function allows an issuer to post their domestic and international rates for digital currency transfers.
                                </p>
                            </div>
                            <div className="review-wallet-box m-0">
                                <div className="row">
                                    <div className="col-xxl-11">
                                        <div className="review-wallet-info-box mb-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="heading">
                                                        <h3>Wallet Information</h3>
                                                        <i className="bi bi-pencil-square"></i>
                                                    </div>
                                                    <hr className="mb-4" />
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="wallet-list">
                                                        <i className="bi bi-check"></i>
                                                        <div>
                                                            <h4>Rate Type</h4>
                                                            <p>Domestic</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="wallet-list">
                                                        <i className="bi bi-check"></i>
                                                        <div>
                                                            <h4>From</h4>
                                                            <p>$ 2.00</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="wallet-list">
                                                        <i className="bi bi-check"></i>
                                                        <div>
                                                            <h4>To</h4>
                                                            <p>$ 100.00</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="wallet-list">
                                                        <i className="bi bi-check"></i>
                                                        <div>
                                                            <h4>Origination Fee</h4>
                                                            <p>$ 2.00</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="wallet-list">
                                                        <i className="bi bi-check"></i>
                                                        <div>
                                                            <h4>Transfer Rate</h4>
                                                            <p>1%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-11 p-0">
                                    <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                        <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                        <button type="button" className="btn next-btn mb-3 mb-md-0">SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}
export default RateInformation;
