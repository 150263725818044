import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  getCountryCodeDropDown,
  getDialingcodesAPI,
} from "../../../../../api/dropdown";
import { CreatePeople } from "../../../../../api/People";
import { decrypytdata } from "../../../../../api/walletKey";
import { success } from "../../../../../Components/alert";

function Owner({ setPeople, previous, nextstep, setResponse }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  // eslint-disable-next-line
  const [Peoples, setPeoplesData] = useState();
  let APPID = "";
  let APIKEY = "";

  const closeModal = async (values) => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await CreatePeople(APPID, APIKEY, values);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Beneficiary.js ~ line 35 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // debugger;
      let jsonData;
      if (JSON.parse(JSON.parse(devryptedIssuer).CommandResponse)) {
        jsonData = JSON.parse(JSON.parse(devryptedIssuer).CommandResponse);

        setResponse(jsonData.UUID);
      }
      console.log(
        "🚀 ~ file: Beneficiary.js ~ line 39 ~ closeModal ~ jsonData",
        jsonData
      );
      if (JSON.parse(devryptedIssuer).ReturnMessage) {
        success(JSON.parse(devryptedIssuer).ReturnMessage);

        // nextstep();
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
    }
  };

  const initialValues = {
    globalPeopleTypeID: 1,
    nativeID: "",
    globalPeople: "",
    displayname: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    dob: "",
    birthCountrycode: "",
    birthGovernmentNumber: "",
    passportCountrycode: "",
    passportNumber: "",
    driversLicenseCountrycode: "",
    driversLicenseNumber: "",
    governmentIDCountrycode: "",
    governmentIDNumber: "",
    otherIDCountrycode: "",
    otherIDNumber: "",
    primaryCountrycode: "",
    primaryStateProvince: "",
    primaryCityTown: "",
    primaryPostalcode: "",
    primaryAddressLine1: "",
    primaryAddressLine2: "",
    primaryAddressLine3: "",
    primaryDialingcode: "",
    primaryPhoneNumber: "",
    primaryEmail: "",
    primaryDIDKeycode: "",
    primaryDID: "",
    primaryEntity: "",
    primaryRegistrationNumber: "",
    secondaryCountrycode: "",
    secondaryStateProvince: "",
    secondaryCityTown: "",
    secondaryPostalcode: "",
    secondaryAddressLine1: "",
    secondaryAddressLine2: "",
    secondaryAddressLine3: "",
    secondaryDialingcode: "",
    secondaryPhoneNumber: "",
    secondaryEmail: "",
    secondaryDIDKeycode: "",
    secondaryDID: "",
    secondaryEntity: "",
    secondaryRegistrationNumber: "",
    tertiaryCountrycode: "",
    tertiaryStateProvince: "",
    tertiaryCityTown: "",
    tertiaryPostalcode: "",
    tertiaryAddressLine1: "",
    tertiaryAddressLine2: "",
    tertiaryAddressLine3: "",
    tertiaryDialingcode: "",
    tertiaryPhoneNumber: "",
    tertiaryEmail: "",
    tertiaryDIDKeycode: "",
    tertiaryDID: "",
    tertiaryEntity: "",
    tertiaryRegistrationNumber: "",
  };
  const validationSchema = Yup.object({
    globalPeopleTypeID: Yup.number(),
    nativeID: Yup.string(),
    globalPeople: Yup.string(),
    displayname: Yup.string(),
    firstname: Yup.string().required("First name is required"),
    secondFirstname: Yup.string(),
    lastname: Yup.string().required("Last name is required"),
    secondLastname: Yup.string(),
    dob: Yup.string().required("Date of Birth is required"),
    birthCountrycode: Yup.string(),
    birthGovernmentNumber: Yup.string(),
    passportCountrycode: Yup.string(),
    passportNumber: Yup.string(),
    driversLicenseCountrycode: Yup.string(),
    driversLicenseNumber: Yup.string(),
    governmentIDCountrycode: Yup.string(),
    governmentIDNumber: Yup.string(),
    otherIDCountrycode: Yup.string(),
    otherIDNumber: Yup.string(),
    primaryCountrycode: Yup.string().required("Country Code is required"),
    primaryStateProvince: Yup.string(),
    primaryCityTown: Yup.string(),
    primaryPostalcode: Yup.string(),
    primaryAddressLine1: Yup.string(),
    primaryAddressLine2: Yup.string(),
    primaryAddressLine3: Yup.string(),
    primaryDialingcode: Yup.string(),
    primaryPhoneNumber: Yup.string(),
    primaryEmail: Yup.string(),
    primaryDIDKeycode: Yup.string(),
    primaryDID: Yup.string(),
    primaryEntity: Yup.string(),
    primaryRegistrationNumber: Yup.string(),
    secondaryCountrycode: Yup.string(),
    secondaryStateProvince: Yup.string(),
    secondaryCityTown: Yup.string(),
    secondaryPostalcode: Yup.string(),
    secondaryAddressLine1: Yup.string(),
    secondaryAddressLine2: Yup.string(),
    secondaryAddressLine3: Yup.string(),
    secondaryDialingcode: Yup.string(),
    secondaryPhoneNumber: Yup.string(),
    secondaryEmail: Yup.string(),
    secondaryDIDKeycode: Yup.string(),
    secondaryDID: Yup.string(),
    secondaryEntity: Yup.string(),
    secondaryRegistrationNumber: Yup.string(),
    tertiaryCountrycode: Yup.string(),
    tertiaryStateProvince: Yup.string(),
    tertiaryCityTown: Yup.string(),
    tertiaryPostalcode: Yup.string(),
    tertiaryAddressLine1: Yup.string(),
    tertiaryAddressLine2: Yup.string(),
    tertiaryAddressLine3: Yup.string(),
    tertiaryDialingcode: Yup.string(),
    tertiaryPhoneNumber: Yup.string(),
    tertiaryEmail: Yup.string(),
    tertiaryDIDKeycode: Yup.string(),
    tertiaryDID: Yup.string(),
    tertiaryEntity: Yup.string(),
    tertiaryRegistrationNumber: Yup.string(),
  });
  const fetchdropdwon = async () => {
    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDialingcodesAPI("GetDialingcodes");
    setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="row">
          <div className="col-12 mt-5">
            <div className="originating-account link-wallet-box create-page ms-0">
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  setPeople(values);
                  nextstep();
                  // setPeoplesData(() => {
                  //   return {
                  //     globalPeopleTypeID: values.globalPeopleTypeID,
                  //     nativeID: values.nativeID,
                  //     globalPeople: values.globalPeople,
                  //     displayname: values.displayname,
                  //     firstname: values.firstname,
                  //     secondFirstname: values.secondFirstname,
                  //     lastname: values.lastname,
                  //     secondLastname: values.secondLastname,
                  //     dob: values.dob.replaceAll("-", ""),
                  //     birthCountrycode: values.birthCountrycode,
                  //     birthGovernmentNumber: values.birthGovernmentNumber,
                  //     passportCountrycode: values.passportCountrycode,
                  //     passportNumber: values.passportNumber,
                  //     driversLicenseCountrycode:
                  //       values.driversLicenseCountrycode,
                  //     driversLicenseNumber: values.driversLicenseNumber,
                  //     governmentIDCountrycode: values.governmentIDCountrycode,
                  //     governmentIDNumber: values.governmentIDNumber,
                  //     otherIDCountrycode: values.otherIDCountrycode,
                  //     otherIDNumber: values.otherIDNumber,
                  //     primaryCountrycode: values.primaryCountrycode,
                  //     primaryStateProvince: values.primaryStateProvince,
                  //     primaryCityTown: values.primaryCityTown,
                  //     primaryPostalcode: values.primaryPostalcode,
                  //     primaryAddressLine1: values.primaryAddressLine1,
                  //     primaryAddressLine2: values.primaryAddressLine2,
                  //     primaryAddressLine3: values.primaryAddressLine3,
                  //     primaryDialingcode: values.primaryDialingcode,
                  //     primaryPhoneNumber: values.primaryPhoneNumber,
                  //     primaryEmail: values.primaryEmail,
                  //     primaryDIDKeycode: values.primaryDIDKeycode,
                  //     primaryDID: values.primaryDID,
                  //     primaryEntity: values.primaryEntity,
                  //     primaryRegistrationNumber:
                  //       values.primaryRegistrationNumber,
                  //     secondaryCountrycode: values.secondaryCountrycode,
                  //     secondaryStateProvince: values.secondaryStateProvince,
                  //     secondaryCityTown: values.secondaryCityTown,
                  //     secondaryPostalcode: values.secondaryPostalcode,
                  //     secondaryAddressLine1: values.secondaryAddressLine1,
                  //     secondaryAddressLine2: values.secondaryAddressLine2,
                  //     secondaryAddressLine3: values.secondaryAddressLine3,
                  //     secondaryDialingcode: values.secondaryDialingcode,
                  //     secondaryPhoneNumber: values.secondaryPhoneNumber,
                  //     secondaryEmail: values.secondaryEmail,
                  //     secondaryDIDKeycode: values.secondaryDIDKeycode,
                  //     secondaryDID: values.secondaryDID,
                  //     secondaryEntity: values.secondaryEntity,
                  //     secondaryRegistrationNumber:
                  //       values.secondaryRegistrationNumber,
                  //     tertiaryCountrycode: values.tertiaryCountrycode,
                  //     tertiaryStateProvince: values.tertiaryStateProvince,
                  //     tertiaryCityTown: values.tertiaryCityTown,
                  //     tertiaryPostalcode: values.tertiaryPostalcode,
                  //     tertiaryAddressLine1: values.tertiaryAddressLine1,
                  //     tertiaryAddressLine2: values.tertiaryAddressLine2,
                  //     tertiaryAddressLine3: values.tertiaryAddressLine3,
                  //     tertiaryDialingcode: values.tertiaryDialingcode,
                  //     tertiaryPhoneNumber: values.tertiaryPhoneNumber,
                  //     tertiaryEmail: values.tertiaryEmail,
                  //     tertiaryDIDKeycode: values.tertiaryDIDKeycode,
                  //     tertiaryDID: values.tertiaryDID,
                  //     tertiaryEntity: values.tertiaryEntity,
                  //     tertiaryRegistrationNumber:
                  //       values.tertiaryRegistrationNumber,
                  //   };
                  // });
                  closeModal({
                    globalPeopleTypeID: values.globalPeopleTypeID,
                    nativeID: values.nativeID,
                    globalPeople: values.globalPeople,
                    displayname: values.displayname,
                    firstname: values.firstname,
                    secondFirstname: values.secondFirstname,
                    lastname: values.lastname,
                    secondLastname: values.secondLastname,
                    dob: values.dob.replaceAll("-", ""),
                    birthCountrycode: values.birthCountrycode,
                    birthGovernmentNumber: values.birthGovernmentNumber,
                    passportCountrycode: values.passportCountrycode,
                    passportNumber: values.passportNumber,
                    driversLicenseCountrycode: values.driversLicenseCountrycode,
                    driversLicenseNumber: values.driversLicenseNumber,
                    governmentIDCountrycode: values.governmentIDCountrycode,
                    governmentIDNumber: values.governmentIDNumber,
                    otherIDCountrycode: values.otherIDCountrycode,
                    otherIDNumber: values.otherIDNumber,
                    primaryCountrycode: values.primaryCountrycode,
                    primaryStateProvince: values.primaryStateProvince,
                    primaryCityTown: values.primaryCityTown,
                    primaryPostalcode: values.primaryPostalcode,
                    primaryAddressLine1: values.primaryAddressLine1,
                    primaryAddressLine2: values.primaryAddressLine2,
                    primaryAddressLine3: values.primaryAddressLine3,
                    primaryDialingcode: values.primaryDialingcode,
                    primaryPhoneNumber: values.primaryPhoneNumber,
                    primaryEmail: values.primaryEmail,
                    primaryDIDKeycode: values.primaryDIDKeycode,
                    primaryDID: values.primaryDID,
                    primaryEntity: values.primaryEntity,
                    primaryRegistrationNumber: values.primaryRegistrationNumber,
                    secondaryCountrycode: values.secondaryCountrycode,
                    secondaryStateProvince: values.secondaryStateProvince,
                    secondaryCityTown: values.secondaryCityTown,
                    secondaryPostalcode: values.secondaryPostalcode,
                    secondaryAddressLine1: values.secondaryAddressLine1,
                    secondaryAddressLine2: values.secondaryAddressLine2,
                    secondaryAddressLine3: values.secondaryAddressLine3,
                    secondaryDialingcode: values.secondaryDialingcode,
                    secondaryPhoneNumber: values.secondaryPhoneNumber,
                    secondaryEmail: values.secondaryEmail,
                    secondaryDIDKeycode: values.secondaryDIDKeycode,
                    secondaryDID: values.secondaryDID,
                    secondaryEntity: values.secondaryEntity,
                    secondaryRegistrationNumber:
                      values.secondaryRegistrationNumber,
                    tertiaryCountrycode: values.tertiaryCountrycode,
                    tertiaryStateProvince: values.tertiaryStateProvince,
                    tertiaryCityTown: values.tertiaryCityTown,
                    tertiaryPostalcode: values.tertiaryPostalcode,
                    tertiaryAddressLine1: values.tertiaryAddressLine1,
                    tertiaryAddressLine2: values.tertiaryAddressLine2,
                    tertiaryAddressLine3: values.tertiaryAddressLine3,
                    tertiaryDialingcode: values.tertiaryDialingcode,
                    tertiaryPhoneNumber: values.tertiaryPhoneNumber,
                    tertiaryEmail: values.tertiaryEmail,
                    tertiaryDIDKeycode: values.tertiaryDIDKeycode,
                    tertiaryDID: values.tertiaryDID,
                    tertiaryEntity: values.tertiaryEntity,
                    tertiaryRegistrationNumber:
                      values.tertiaryRegistrationNumber,
                  });
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box  m-0">
                      <Form className="wallet-information-form">
                        <div className="row mb-xl-5">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Information</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  placeholder="First Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">First Name</label>
                                <div
                                  className={
                                    errors.firstname && touched.firstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="firstname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="secondFirstname"
                                  id="secondFirstname"
                                  placeholder="Middle  Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Second First Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  placeholder="Last Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Last Name</label>
                                <div
                                  className={
                                    errors.lastname && touched.lastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="lastname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="secondLastname"
                                  id="secondLastname"
                                  placeholder="Second Last  Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Second Last Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            {/* <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="TokenName"
                                  id="TokenName"
                                  placeholder="Business Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Business Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="TokenName"
                                  id="TokenName"
                                  placeholder="Industry"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Industry</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Home Address </h6>
                            <div className="mb-5">
                              <label>Birth Country Code</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="primaryCountrycode"
                                  id="primaryCountrycode"
                                  placeholder="Country"
                                  className="form-control form-select"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "primaryCountrycode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {countryCode &&
                                    countryCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.countrycode}
                                            key={index}
                                          >
                                            {value.isoCountryname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="primaryCountrycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryStateProvince"
                                  id="primaryStateProvince"
                                  placeholder="State"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">State</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryCityTown"
                                  id="primaryCityTown"
                                  placeholder="City"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">City</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryAddressLine1"
                                  id="primaryAddressLine1"
                                  placeholder="Address Line 1"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Address Line 1{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryAddressLine2"
                                  id="primaryAddressLine2"
                                  placeholder="Address Line 2"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Address Line 2{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryPostalcode"
                                  id="primaryPostalcode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Postal Code{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Identification</h6>
                            <div className="mb-5">
                              <label>Date of Birth</label>
                              <div className="input-box">
                                <Field
                                  type="date"
                                  name="dob"
                                  id="dob"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.dob && touched.dob
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  {/* <i className="bi bi-check-lg"></i> */}
                                  <ErrorMessage
                                    name="dob"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="birthGovernmentNumber"
                                  id="birthGovernmentNumber"
                                  placeholder="Government Birth Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Government Birth Number
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="passportCountrycode"
                                  id="passportCountrycode"
                                  placeholder="Passport Country"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Passport Country
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="passportNumber"
                                  id="passportNumber"
                                  placeholder="Passport Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Passport Number{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="driversLicenseNumber"
                                  id="driversLicenseNumber"
                                  placeholder="Driver's License Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Driver's License Number{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="governmentIDNumber"
                                  id="governmentIDNumber"
                                  placeholder="Other Government ID Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Other Government ID Number{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Contact Information</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryEmail"
                                  id="primaryEmail"
                                  placeholder="Email Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Email Address
                                </label>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Mobile Dialing Country Code</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="primaryDialingcode"
                                  id="primaryDialingcode"
                                  placeholder="Mobile Dialing Country Code"
                                  className="form-control form-select"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "primaryDialingcode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {dailingCode &&
                                    dailingCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryPhoneNumber"
                                  id="primaryPhoneNumber"
                                  placeholder="Mobile Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Mobile Number
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                            onClick={previous}
                          >
                            Previous
                          </button>
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                            // onClick={() => nextstep()}
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Owner;
