import React from "react";

function ListType(props) {
  console.log("🚀 ~ file: ListType.js ~ line 4 ~ ListType ~ props", props)
  
  // console.log("🚀 ~ file: ListType.js ~ line 4 ~ ListType ~ props", props);
  // {let BaseImageFN = props.BaseImageFN.replace("/public", " ")}
  // console.log(BaseImageFN, "basecoin");
  return (
    <>
      <tr>
        <td>
          <img src={props.QuoteImageFN} className="me-2" alt="logo" />
          {props.QuoteCountryname}
        </td>
        <td>{props.BasePrice}</td>
        <td>
          <img
            src={props.CoinImageFN}
            width="32px"
            className="me-2"
            alt="logo"
          />
          {props.CoinPrice}
          <span className="ms-4">{props.CoinCurrencycode}</span>
        </td>
        <td>
          <img
            src={props.PayoutImageFN}
            width="32px"
            className="me-2"
            alt="logo"
          />
          {props.PayoutCurrencycode}
        </td>
      </tr>
    </>
  );
}

export default ListType;
