import React from "react";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../action/index";
// import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
import { modifyBranch } from "../../../../api/branches";
import { useNavigate } from "react-router-dom";

function ReviewModifyBranch({ data, previous, cleandata }) {
  // let { pathname } = useLocation();
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await modifyBranch(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: ReviewBranch.js ~ line 33 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // debugger;
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
      dispatch(setPrivateId(""));
      // debugger;
    }
    dispatch(showLoader(false));
  };
  // useEffect(() => {
  //   closeModal();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [publicKeys]);
  return (
    <>
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="container-fluid p-0 ">
          {/* <div className="row m-0"> */}
          {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            </div> */}

          <div className="col-12">
            <div className="main-heading">
              <h3>Branch</h3>
            </div>
            <div className="sub-heading">
              <h3>
                {/* {pathname === "/webwallet/modifyissuer"
                  ? "Modify Issuer"
                  : "Create Issuer"} */}
              </h3>
              <p>This function modify a Branch for an Issuer.</p>
            </div>
            <div className="review-wallet-box">
              <div className="row">
                <div className="col-xxl-11">
                  <div className="review-wallet-info-box mb-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="heading">
                          <h3>Branches Information</h3>
                          <i
                            className="bi bi-pencil-square"
                            onClick={() => {
                              previous();
                              dispatch(setPrivateId(""));
                            }}
                          ></i>
                        </div>
                        <hr className="mb-4" />
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Basic Information</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Branches</h4>
                            <p>{data?.Branches}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Display Name</h4>
                            <p>{data?.displayname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Branch Type</h4>
                            <p>{data?.issuerTypecode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Branch Code</h4>
                            <p>{data?.issuercode}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Institution </h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Institution Typecode</h4>
                            <p>{data?.institutionTypecode}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Department Typecode</h4>
                            <p>{data?.departmentTypecode}</p>
                          </div>
                        </div> */}
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Office Typecode</h4>
                            <p>{data?.officeTypecode}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Identifier</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Location Identifier</h4>
                            <p>{data?.accuityID}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Native Identifier</h4>
                            <p>{data?.NativeIdentifier}</p>
                          </div>
                        </div> */}
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>UUID</h4>
                            <p>{data?.uuid}</p>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Additional Informaiton</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Incorporation Number</h4>
                            <p>{data?.incorporationNumber}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Federal Tax ID</h4>
                            <p>{data?.federal_TaxID}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>ISO LEI</h4>
                            <p>{data?.isO_LEI}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Basic Address Information</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Country Code</h4>
                            <p>{data?.countrycode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>State Province</h4>
                            <p>{data?.stateProvince}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>City Town</h4>
                            <p>{data?.cityTown}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Address</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Postalcode</h4>
                            <p>{data?.postalCode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Address Line 1</h4>
                            <p>{data?.addressLine1}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Address Line 2</h4>
                            <p>{data?.addressLine2}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="review-wallet-info-box mb-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="heading">
                          <h3>Primary Contact Information</h3>
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <hr className="mb-4" />
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Primary Contact</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>First Name</h4>
                            <p>{data?.firstname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Middle Name</h4>
                            <p>{data?.secondFirstname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Last Name</h4>
                            <p>{data?.lastname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Second Last Name</h4>
                            <p>{data?.secondLastname}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Contact Information </h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Dialing Code</h4>
                            <p>{data?.mobileDialingcode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Phone Number</h4>
                            <p>{data?.mobilePhone}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Extension</h4>
                            <p>{data?.extension}</p>
                          </div>
                        </div> */}
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Email Address</h4>
                            <p>{data?.email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Primary Contact Account Information</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Job Title</h4>
                            <p>{data?.jobTitle}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Username</h4>
                            <p>{data?.username}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Password</h4>
                            <p>Password</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-11 p-0">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                  <button
                    type="button"
                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                    onClick={() => {
                      cleandata();
                      dispatch(setPrivateId(""));
                      previous();
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn next-btn mb-3 mb-md-0"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewModifyBranch;

// import React, { useState, useEffect } from "react";
// import { setPrivateId } from "../../../../action/index";
// import { useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// // import { insertBranch } from "../../../../api/branches";
// // import { decrypytdata } from "../../../../api/walletKey";
// import PrivateModal from "./../../../../Components/PrivateModal";

// // import { success } from "./../../../../Components/alert";

// function ReviewBranch({ data, previous, cleandata }) {
//   let { pathname } = useLocation();
//   const [privateModal, setPrivateModal] = useState(false);
//   const publicKeys = useSelector((state) => state.keyReducer);
//   let APPID = "";
//   let APIKEY = "";
//   const dispatch = useDispatch();
//   const closeModal = async () => {
//     if (publicKeys.publicKey) {
//       APPID = publicKeys.publicKey.publicAddress;
//     }
//     if (publicKeys.privateKey) {
//       APIKEY = publicKeys.privateKey.PrivateWalletAddress;
//     }
//     if (APPID && APIKEY) {
//       // const encryptedIssuer = await insertBranch(APPID, APIKEY, data);
//       // const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
//       // const jsonData = JSON.parse(devryptedIssuer);
//       // if (jsonData.returnMessage) {
//       //   success(jsonData.returnMessage);
//       // }
//       // dispatch(setPrivateId(""));
//       // debugger;
//     }
//     setPrivateModal(false);
//   };
//   useEffect(() => {
//     closeModal();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [publicKeys]);
//   return (
//     <>
//       <>
//         {privateModal && (
//           <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
//         )}
//         <div className="col-md-9 col-lg-9 col-xxl-10">
//           <div className="container-fluid p-0 ">
//             {/* <div className="row m-0"> */}
//             {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
//             </div> */}

//             <div className="col-12">
//               <div className="main-heading">
//                 <i className="bi bi-arrow-left"></i>
//                 <h3>Branches</h3>
//               </div>
//               <div className="sub-heading">
//                 <h3>
//                   {pathname === "/webwallet/modifyissuer"
//                     ? "Modify Issuer"
//                     : "Create Issuer"}
//                 </h3>
//                 <p>
//                   This function establishes a financial institution on the
//                   Unicoin network capable of creating customer wallets.
//                 </p>
//               </div>
//               <div className="review-wallet-box">
//                 <div className="row">
//                   <div className="col-xxl-11">
//                     <div className="review-wallet-info-box mb-5">
//                       <div className="row">
//                         <div className="col-12">
//                           <div className="heading">
//                             <h3>Branches Information</h3>
//                             <i
//                               className="bi bi-pencil-square"
//                               onClick={() => {
//                                 previous();
//                                 dispatch(setPrivateId(""));
//                               }}
//                             ></i>
//                           </div>
//                           <hr className="mb-4" />
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Basic Information</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Branches</h4>
//                               <p>{data.Branches}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Display Name</h4>
//                               <p>{data.displayname}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Branch Type</h4>
//                               <p>{data.BranchTypeCode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Branch Code</h4>
//                               <p>{data.brachescode}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Institution </h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Institution Typecode</h4>
//                               <p>{data.institutionTypecode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Department Typecode</h4>
//                               <p>{data.departmentTypecode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Office Typecode</h4>
//                               <p>{data.officeTypecode}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Identifier</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Location Identifier</h4>
//                               <p>{data.LocationIdentifier}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Native Identifier</h4>
//                               <p>{data.NativeIdentifier}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>UUID</h4>
//                               <p>{data.uuid}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Additional Informaiton</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Incorporation Number</h4>
//                               <p>{data.incorporationNumber}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Federal Tax ID</h4>
//                               <p>{data.federal_TaxID}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>ISO LEI</h4>
//                               <p>{data.isO_LEI}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Basic Address Information</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Country Code</h4>
//                               <p>{data.countrycode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>State Province</h4>
//                               <p>{data.stateProvince}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>City Town</h4>
//                               <p>{data.cityTown}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Address</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Postalcode</h4>
//                               <p>{data.postalCode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Address Line 1</h4>
//                               <p>{data.addressLine1}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Address Line 2</h4>
//                               <p>{data.addressLine2}</p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="review-wallet-info-box mb-5">
//                       <div className="row">
//                         <div className="col-12">
//                           <div className="heading">
//                             <h3>Primary Contact Information</h3>
//                             <i className="bi bi-pencil-square"></i>
//                           </div>
//                           <hr className="mb-4" />
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Primary Contact</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>First Name</h4>
//                               <p>{data.firstName}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Middle Name</h4>
//                               <p>{data.middlename}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Last Name</h4>
//                               <p>{data.lastName}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Second Last Name</h4>
//                               <p>{data.secondLastname}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Contact Information </h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Dialing Code</h4>
//                               <p>{data.mobileDialingcode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Phone Number</h4>
//                               <p>{data.mobilePhone}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Extension</h4>
//                               <p>{data.extension}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Email Address</h4>
//                               <p>{data.email}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Primary Contact Account Information</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Job Title</h4>
//                               <p>{data.jobTitle}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Username</h4>
//                               <p>{data.username}</p>
//                             </div>
//                           </div>
//                           {/* <div className="wallet-list">
//                           <i className="bi bi-check"></i>
//                           <div>
//                             <h4>Password</h4>
//                             <p>Password</p>
//                           </div>
//                         </div> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-md-11 p-0">
//                   <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//                     <button
//                       type="button"
//                       className="btn cancel-btn mb-3 mb-md-0 me-4"
//                       onClick={() => {
//                         cleandata();
//                         dispatch(setPrivateId(""));
//                         previous();
//                       }}
//                     >
//                       CANCEL
//                     </button>
//                     <button
//                       type="button"
//                       className="btn next-btn mb-3 mb-md-0"
//                       onClick={() => {
//                         setPrivateModal(true);
//                       }}
//                     >
//                       SUBMIT
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* </div> */}
//       </>
//       {/* </div> */}
//     </>
//   );
// }

// export default ReviewBranch;
