import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getPeopleSanctions(publickey, privateKey, data) {
  let values = {
    sourcePublicAddress: publickey,
    uuid: data,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/GetPeopleSanctions",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/compliance/GetPeopleSanctions", values, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}

export async function CreatePeople(publickey, privateKey, people) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/CreatePeople",
    people,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/CreatePeople", people, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function ModifyPeople(publickey, privateKey, people) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/ModifyPeople",
    people,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/ModifyPeople", people, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function suspendPeopleSanction(publickey, privateKey, people) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/SuspendPeopleSanction",
    people,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/compliance/SuspendPeopleSanction",
    people,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
