import React from "react";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function TokensList() {
    return (
        <>
            {/* <Header /> */}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row m-0">
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
                    <div className="col-12">
                        <div className="main-heading">
                            <i className="bi bi-arrow-left"></i>
                            <h3> Tokens</h3>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xxl-10">
                                <div className="wallet-balances-box">
                                    <h4 className="mb-3">List of Tokens</h4>
                                    <p className="mb-5">This function lists all the tokens associated with a wallet.</p>
                                    <div className="d-md-flex justify-content-between px-4">
                                        <div className="search-box d-md-flex">
                                            <div className="input-group mb-3">
                                                <span className="input-group-text"><i className="bi bi-search"></i></span>
                                                <input type="text" className="form-control" placeholder="Search"></input>
                                                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Tokens Name</button>
                                                <ul className="dropdown-menu dropdown-menu-end">

                                                </ul>
                                            </div>
                                            <button type="button" className="btn btn-primary mb-3 mb-md-0">SEARCH</button>

                                        </div>

                                        <button type="button" className="btn btn-primary ">+ ADD Tokens</button>
                                    </div>
                                    <div className="table-responsive p-3 p-md-4">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Tokens Name</th>
                                                    <th>Token Symbol</th>
                                                    <th>Public Address</th>
                                                    <th className="text-end">Balance</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>John Matthew</td>
                                                    <td>User</td>
                                                    <td>8934802983</td>
                                                    <td className="text-end">823.00 USD</td>
                                                    <td>
                                                        <ul className="d-flex mb-0 p-0">
                                                            <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Teegan Wu</td>
                                                    <td>Jacob</td>
                                                    <td>8934802983</td>
                                                    <td className="text-end">23.00 USD</td>
                                                    <td>
                                                        <ul className="d-flex mb-0 p-0">
                                                            <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rohan Storey</td>
                                                    <td>User</td>
                                                    <td>8934802983</td>
                                                    <td className="text-end">222.00 USD</td>
                                                    <td>
                                                        <ul className="d-flex mb-0 p-0">
                                                            <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Kaiya Squires</td>
                                                    <td>User</td>
                                                    <td>8934802983</td>
                                                    <td className="text-end" >314.00 USD</td>
                                                    <td>
                                                        <ul className="d-flex mb-0 p-0">
                                                            <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Owen Hendrix</td>
                                                    <td>User</td>
                                                    <td>8934802983</td>
                                                    <td className="text-end">100.00 USD</td>
                                                    <td>
                                                        <ul className="d-flex mb-0 p-0">
                                                            <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                            <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default TokensList;
