import Header from "../../../Components/Header";
import Sidebar from "../../../Components/Sidebar";
function Transation_Result() {
    return (<>
        <Header />
        <div className="container-fluid p-0">
            <div className="transaction-result-page">
            <div className="row m-0">
                <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                    <Sidebar />
                </div>
                <div className="col-md-9 col-lg-9 col-xxl-10">
                    <div className="main-heading">
                        <h3 className="ms-0">
                            Transaction Result
                        </h3>
                    </div>
                    <div className="row ms-md-4">
                            <div className="col-lg-10 col-xl-8 col-xxl-5">
                            <div className="transaction-result-error">
                                <div className="transaction-result-error-head">
                                    <div className="d-flex align-items-center">
                                        <img src="../icons/alert-triangle.png" alt="alert-triangle-icon" />
                                        <h3>Transaction Error</h3>
                                    </div>
                                    <div>
                                        <img src="../icons/x-circle.png" alt="x-circle-icon" />
                                    </div>
                                </div>
                                <div className="transaction-result-error-body">
                                    <div className="d-flex align-items-md-center mb-3">
                                        <div>
                                            <img src="../icons/x.png" alt="x-icon" />
                                        </div>
                                        <p>Passport Number should consist 9 digit number</p>
                                    </div>
                                        <div className="d-flex align-items-md-center mb-3">
                                        <div>
                                            <img src="../icons/x.png" alt="x-icon" />
                                        </div>
                                        <p>Driver License Number should consist 9 digit number</p>
                                    </div>
                                        <div className="d-flex align-items-md-center mb-3">
                                        <div>
                                            <img src="../icons/x.png" alt="x-icon" />
                                        </div>
                                        <p>Please select country of origin</p>
                                    </div>
                                        <div className="d-flex align-items-md-center mb-3">
                                        <div>
                                            <img src="../icons/x.png" alt="x-icon" />
                                        </div>
                                        <p>Expiration Date format is mm/dd/yy</p>
                                    </div>
                                        <div className="d-flex align-items-md-center mb-3">
                                        <div>
                                            <img src="../icons/x.png" alt="x-icon" />
                                        </div>
                                        <p>Please upload document of passport infomaton</p>
                                    </div>
                                        <div className="d-flex align-items-md-center">
                                        <div>
                                            <img src="../icons/x.png" alt="x-icon" />
                                        </div>
                                        <p>Please select state of origin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ms-md-4 my-5">
                            <div className="col-lg-10 col-xl-8 col-xxl-5">
                            <div className="transaction-successful">
                                <div className="transaction-successful-head">
                                    <div className="d-flex align-items-center">
                                        <img src="../icons/Transaction is successful.png" alt="alert-triangle-icon" />
                                        <h3>Transaction is successful!</h3>
                                    </div>
                                </div>
                                <div className="transaction-successful-body">
                                    <h3>Create Wallet</h3>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h6>Date/ Time</h6>
                                        <p>April 20, 2022 @ 12:34:09</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h6>Transaction Hash</h6>
                                        <p>LAADFHAJ:DKJKFJA </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h6>Result Code</h6>
                                        <p>0</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h6>Result Message</h6>
                                        <p>Success</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6>Other data set fields ae listed 1 by 1</h6>
                                        <p>With the values</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </div>
    </>);
}

export default Transation_Result;