import React from "react";
// import LandingPage from "./LandingPage";
// import Unicoin from "./Unicoin";
// import CurrencyTable from "./CurrencyTable";
// import StoreValue from "./StoreValue";
// import PhysicalCash from "./PhysicalCash";
// import ElectronicCash from "./ElectronicCash";
// import UnicoinVSBitcoin from "./UnicoinVSBitcoin";
// import RatingUnicoinAndBitcoin from "./RatingUnicoinAndBitcoin";
// import DifferenceTable from "./DifferenceTable";
// import Info from "./Info";
// import Award from "./Award";
// import Roadmap from "./Roadmap";
// import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import LandingPage from "./LandingPage";
import Unicoin from "./Unicoin";
import CurrencyTable from "./CurrencyTable";
import StoreValue from "./StoreValue";
import PhysicalCash from "./PhysicalCash";
import ElectronicCash from "./ElectronicCash";
import UnicoinVSBitcoin from "./UnicoinVSBitcoin";
import RatingUnicoinAndBitcoin from "./RatingUnicoinAndBitcoin";
import DifferenceTable from "./DifferenceTable";
import Info from "./Info";
import Award from "./Award";
import Roadmap from "./Roadmap";
import Footer from "../../Components/Footer";

const Home = () => {
  return (
    <div className="root">
      <Header />
      <LandingPage />
      <Unicoin />
      <CurrencyTable />
      <StoreValue />
      <PhysicalCash />
      <ElectronicCash />
      <UnicoinVSBitcoin />
      <RatingUnicoinAndBitcoin />
      <DifferenceTable />
      <Info />
      <Award />
      <Roadmap />
      <Footer />
    </div>
  );
};

export default Home;
