import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
} from "../../../../api/dropdown";
import { decrypytdata } from "../../../../api/walletKey";
import { useSearchParams } from "react-router-dom";
import { GetPeople } from "../../../../api/PeopleApi";
function ModifyOriginator({ nextstep, setdata, formData }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  const [searchParams] = useSearchParams();
  const [tokensData, settokensData] = useState([]);
  // const [privateModal, setPrivateModal] = useState(false);
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState();
  console.log(
    "🚀 ~ file: ModifyOriginator.js ~ line 20 ~ ModifyOriginator ~ initialValues",
    initialValues
  );
  // const initialValues = {

  //   globalPeopleTypeID: 0,
  //   nativeID: "",
  //   globalPeople: "",
  //   displayname: "",
  //   firstname: "",
  //   secondFirstname: "",
  //   lastname: "",
  //   secondLastname: "",
  //   dob: "",
  //   birthCountrycode: "",
  //   birthGovernmentNumber: "",
  //   passportCountrycode: "",
  //   passportNumber: "",
  //   driversLicenseCountrycode: "",
  //   driversLicenseNumber: "",
  //   governmentIDCountrycode: "",
  //   governmentIDNumber: "",
  //   otherIDCountrycode: "",
  //   otherIDNumber: "",
  //   primaryCountrycode: "",
  //   primaryStateProvince: "",
  //   primaryCityTown: "",
  //   primaryPostalcode: "",
  //   primaryAddressLine1: "",
  //   primaryAddressLine2: "",
  //   primaryAddressLine3: "",
  //   primaryDialingcode: "",
  //   primaryPhoneNumber: "",
  //   primaryEmail: "",
  //   primaryDIDKeycode: "",
  //   primaryDID: "",
  //   primaryEntity: "",
  //   primaryRegistrationNumber: "",
  //   secondaryCountrycode: "",
  //   secondaryStateProvince: "",
  //   secondaryCityTown: "",
  //   secondaryPostalcode: "",
  //   secondaryAddressLine1: "",
  //   secondaryAddressLine2: "",
  //   secondaryAddressLine3: "",
  //   secondaryDialingcode: "",
  //   secondaryPhoneNumber: "",
  //   secondaryEmail: "",
  //   secondaryDIDKeycode: "",
  //   secondaryDID: "",
  //   secondaryEntity: "",
  //   secondaryRegistrationNumber: "",
  //   tertiaryCountrycode: "",
  //   tertiaryStateProvince: "",
  //   tertiaryCityTown: "",
  //   tertiaryPostalcode: "",
  //   tertiaryAddressLine1: "",
  //   tertiaryAddressLine2: "",
  //   tertiaryAddressLine3: "",
  //   tertiaryDialingcode: "",
  //   tertiaryPhoneNumber: "",
  //   tertiaryEmail: "",
  //   tertiaryDIDKeycode: "",
  //   tertiaryDID: "",
  //   tertiaryEntity: "",
  //   tertiaryRegistrationNumber: "",
  // };
  // eslint-disable-next-line
  const validationSchema = Yup.object({
    globalPeopleTypeID: Yup.number(),
    nativeID: Yup.string(),
    globalPeople: Yup.string(),
    displayname: Yup.string(),
    firstname: Yup.string().required("First name is required"),
    secondFirstname: Yup.string(),
    lastname: Yup.string().required("Last name is required"),
    secondLastname: Yup.string(),
    dob: Yup.string().required("Date of Birth is required"),
    birthCountrycode: Yup.string().required("Birth Country Code is required"),
    birthGovernmentNumber: Yup.string(),
    passportCountrycode: Yup.string(),
    passportNumber: Yup.string(),
    driversLicenseCountrycode: Yup.string(),
    driversLicenseNumber: Yup.string(),
    governmentIDCountrycode: Yup.string(),
    governmentIDNumber: Yup.string(),
    otherIDCountrycode: Yup.string(),
    otherIDNumber: Yup.string(),
    primaryCountrycode: Yup.string(),
    primaryStateProvince: Yup.string(),
    primaryCityTown: Yup.string(),
    primaryPostalcode: Yup.string(),
    primaryAddressLine1: Yup.string(),
    primaryAddressLine2: Yup.string(),
    primaryAddressLine3: Yup.string(),
    primaryDialingcode: Yup.string(),
    primaryPhoneNumber: Yup.string(),
    primaryEmail: Yup.string(),
    primaryDIDKeycode: Yup.string(),
    primaryDID: Yup.string(),
    primaryEntity: Yup.string(),
    primaryRegistrationNumber: Yup.string(),
    secondaryCountrycode: Yup.string(),
    secondaryStateProvince: Yup.string(),
    secondaryCityTown: Yup.string(),
    secondaryPostalcode: Yup.string(),
    secondaryAddressLine1: Yup.string(),
    secondaryAddressLine2: Yup.string(),
    secondaryAddressLine3: Yup.string(),
    secondaryDialingcode: Yup.string(),
    secondaryPhoneNumber: Yup.string(),
    secondaryEmail: Yup.string(),
    secondaryDIDKeycode: Yup.string(),
    secondaryDID: Yup.string(),
    secondaryEntity: Yup.string(),
    secondaryRegistrationNumber: Yup.string(),
    tertiaryCountrycode: Yup.string(),
    tertiaryStateProvince: Yup.string(),
    tertiaryCityTown: Yup.string(),
    tertiaryPostalcode: Yup.string(),
    tertiaryAddressLine1: Yup.string(),
    tertiaryAddressLine2: Yup.string(),
    tertiaryAddressLine3: Yup.string(),
    tertiaryDialingcode: Yup.string(),
    tertiaryPhoneNumber: Yup.string(),
    tertiaryEmail: Yup.string(),
    tertiaryDIDKeycode: Yup.string(),
    tertiaryDID: Yup.string(),
    tertiaryEntity: Yup.string(),
    tertiaryRegistrationNumber: Yup.string(),
  });

  // const location = useLocation();
  // const find = location.search.replace("?id=", "");

  const fetchdropdwon = async () => {
    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }

    if (APPID && APIKEY) {
      const encryptedIssuer = await GetPeople(
        APPID,
        APIKEY,
        searchParams.get("id")
      );
      const Issuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      let data = JSON.parse(JSON.parse(Issuer).CommandResponse);
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        const dataTokens = Object.assign({}, ...dataArr);
        settokensData(dataTokens);
      }
      // let data;
      // if (JSON.parse(Issuer).CommandResponse) {
      //   data = JSON.parse(JSON.parse(Issuer).CommandResponse);

      //   data.forEach((dataMain) => {
      //     console.log(
      //       "🚀 ~ file: ModifyOriginator.js ~ line 194 ~ data.forEach ~ dataMain",
      //       dataMain
      //     );
      //     console.log(find, "dddd");
      //     // if (dataMain.UUID === find) {
      //     //   // function addStr(str) {
      //     //   //   return (
      //     //   //     str.substring(0, 4) +
      //     //   //     "-" +
      //     //   //     str.substring(4, 6) +
      //     //   //     "-" +
      //     //   //     str.substring(6, str.length)
      //     //   //   );
      //     //   // }
      //     //   // return setInitialValues({
      //     //   //   globalPeopleTypeID: 0,
      //     //   //   nativeID: "",
      //     //   //   globalPeople: "",
      //     //   //   displayname: dataMain.Displayname ? dataMain.Displayname : "",
      //     //   //   firstname: dataMain.Firstname ? dataMain.Firstname : "",
      //     //   //   secondFirstname: dataMain.SecondFirstname
      //     //   //     ? dataMain.SecondFirstname
      //     //   //     : "",
      //     //   //   lastname: dataMain.Lastname ? dataMain.Lastname : "",
      //     //   //   secondLastname: dataMain.SecondLastname
      //     //   //     ? dataMain.SecondLastname
      //     //   //     : "",
      //     //   //   dob: dataMain.DOB ? addStr(dataMain.DOB) : "",
      //     //   //   birthCountrycode: dataMain.BirthCountrycode
      //     //   //     ? dataMain.BirthCountrycode
      //     //   //     : "",
      //     //   //   birthGovernmentNumber: dataMain.BirthGovernmentNumber
      //     //   //     ? dataMain.BirthGovernmentNumber
      //     //   //     : "",
      //     //   //   passportCountrycode: dataMain.PassportCountrycode
      //     //   //     ? dataMain.PassportCountrycode
      //     //   //     : "",
      //     //   //   passportNumber: dataMain.PassportNumber
      //     //   //     ? dataMain.PassportNumber
      //     //   //     : "",
      //     //   //   driversLicenseCountrycode: dataMain.DriversLicenseCountrycode
      //     //   //     ? dataMain.DriversLicenseCountrycode
      //     //   //     : "",
      //     //   //   driversLicenseNumber: dataMain.DriversLicenseNumber
      //     //   //     ? dataMain.DriversLicenseNumber
      //     //   //     : "",
      //     //   //   governmentIDCountrycode: dataMain.GovernmentIDCountrycode
      //     //   //     ? dataMain.GovernmentIDCountrycode
      //     //   //     : "",
      //     //   //   governmentIDNumber: dataMain.GovernmentIDNumber
      //     //   //     ? dataMain.GovernmentIDNumber
      //     //   //     : "",
      //     //   //   otherIDCountrycode: dataMain.OtherIDCountrycode
      //     //   //     ? dataMain.OtherIDCountrycode
      //     //   //     : "",
      //     //   //   otherIDNumber: dataMain.OtherIDNumber
      //     //   //     ? dataMain.OtherIDNumber
      //     //   //     : "",
      //     //   //   primaryCountrycode: dataMain.PrimaryCountrycode
      //     //   //     ? dataMain.PrimaryCountrycode
      //     //   //     : "",
      //     //   //   primaryStateProvince: dataMain.PrimaryStateProvince
      //     //   //     ? dataMain.PrimaryStateProvince
      //     //   //     : "",
      //     //   //   primaryCityTown: dataMain.PrimaryCityTown
      //     //   //     ? dataMain.PrimaryCityTown
      //     //   //     : "",
      //     //   //   primaryPostalcode: dataMain.PrimaryPostalcode
      //     //   //     ? dataMain.PrimaryPostalcode
      //     //   //     : "",
      //     //   //   primaryAddressLine1: dataMain.PrimaryAddressLine1
      //     //   //     ? dataMain.PrimaryAddressLine1
      //     //   //     : "",
      //     //   //   primaryAddressLine2: dataMain.PrimaryAddressLine2
      //     //   //     ? dataMain.PrimaryAddressLine2
      //     //   //     : "",
      //     //   //   primaryAddressLine3: dataMain.PrimaryAddressLine3
      //     //   //     ? dataMain.PrimaryAddressLine3
      //     //   //     : "",
      //     //   //   primaryDialingcode: dataMain.PrimaryDialingcode
      //     //   //     ? dataMain.PrimaryDialingcode
      //     //   //     : "",
      //     //   //   primaryPhoneNumber: dataMain.PrimaryPhoneNumber
      //     //   //     ? dataMain.PrimaryPhoneNumber
      //     //   //     : "",
      //     //   //   primaryEmail: dataMain.PrimaryEmail
      //     //   //     ? dataMain.PrimaryEmail
      //     //   //     : "",
      //     //   //   primaryDIDKeycode: dataMain.PrimaryDIDKeycode
      //     //   //     ? dataMain.PrimaryDIDKeycode
      //     //   //     : "",
      //     //   //   primaryDID: dataMain.PrimaryDID ? dataMain.PrimaryDID : "",
      //     //   //   primaryEntity: dataMain.PrimaryEntity
      //     //   //     ? dataMain.PrimaryEntity
      //     //   //     : "",
      //     //   //   primaryRegistrationNumber: dataMain.PrimaryRegistrationNumber
      //     //   //     ? dataMain.PrimaryRegistrationNumber
      //     //   //     : "",
      //     //   //   secondaryCountrycode: dataMain.SecondaryCountrycode
      //     //   //     ? dataMain.SecondaryCountrycode
      //     //   //     : "",
      //     //   //   secondaryStateProvince: dataMain.SecondaryStateProvince
      //     //   //     ? dataMain.SecondaryStateProvince
      //     //   //     : "",
      //     //   //   secondaryCityTown: dataMain.SecondaryCityTown
      //     //   //     ? dataMain.SecondaryCityTown
      //     //   //     : "",
      //     //   //   secondaryPostalcode: dataMain.SecondaryPostalcode
      //     //   //     ? dataMain.SecondaryPostalcode
      //     //   //     : "",
      //     //   //   secondaryAddressLine1: dataMain.SecondaryAddressLine1
      //     //   //     ? dataMain.SecondaryAddressLine1
      //     //   //     : "",
      //     //   //   secondaryAddressLine2: dataMain.SecondaryAddressLine2
      //     //   //     ? dataMain.SecondaryAddressLine2
      //     //   //     : "",
      //     //   //   secondaryAddressLine3: dataMain.SecondaryAddressLine3
      //     //   //     ? dataMain.SecondaryAddressLine3
      //     //   //     : "",
      //     //   //   secondaryDialingcode: dataMain.SecondaryDialingcode
      //     //   //     ? dataMain.SecondaryDialingcode
      //     //   //     : "",
      //     //   //   secondaryPhoneNumber: dataMain.SecondaryPhoneNumber
      //     //   //     ? dataMain.SecondaryPhoneNumber
      //     //   //     : "",
      //     //   //   secondaryEmail: dataMain.SecondaryEmail
      //     //   //     ? dataMain.SecondaryEmail
      //     //   //     : "",
      //     //   //   secondaryDIDKeycode: dataMain.SecondaryDIDKeycode
      //     //   //     ? dataMain.SecondaryDIDKeycode
      //     //   //     : "",
      //     //   //   secondaryDID: dataMain.SecondaryDID
      //     //   //     ? dataMain.SecondaryDID
      //     //   //     : "",
      //     //   //   secondaryEntity: dataMain.SecondaryEntity
      //     //   //     ? dataMain.SecondaryEntity
      //     //   //     : "",
      //     //   //   secondaryRegistrationNumber: dataMain.SecondaryRegistrationNumber
      //     //   //     ? dataMain.SecondaryRegistrationNumber
      //     //   //     : "",
      //     //   //   tertiaryCountrycode: dataMain.TertiaryCountrycode
      //     //   //     ? dataMain.TertiaryCountrycode
      //     //   //     : "",
      //     //   //   tertiaryStateProvince: dataMain.TertiaryStateProvince
      //     //   //     ? dataMain.TertiaryStateProvince
      //     //   //     : "",
      //     //   //   tertiaryCityTown: dataMain.TertiaryCityTown
      //     //   //     ? dataMain.TertiaryCityTown
      //     //   //     : "",
      //     //   //   tertiaryPostalcode: dataMain.TertiaryPostalcode
      //     //   //     ? dataMain.TertiaryPostalcode
      //     //   //     : "",
      //     //   //   tertiaryAddressLine1: dataMain.TertiaryAddressLine1
      //     //   //     ? dataMain.TertiaryAddressLine1
      //     //   //     : "",
      //     //   //   tertiaryAddressLine2: dataMain.TertiaryAddressLine2
      //     //   //     ? dataMain.TertiaryAddressLine2
      //     //   //     : "",
      //     //   //   tertiaryAddressLine3: dataMain.TertiaryAddressLine3
      //     //   //     ? dataMain.TertiaryAddressLine3
      //     //   //     : "",
      //     //   //   tertiaryDialingcode: dataMain.TertiaryDialingcode
      //     //   //     ? dataMain.TertiaryDialingcode
      //     //   //     : "",
      //     //   //   tertiaryPhoneNumber: dataMain.TertiaryPhoneNumber
      //     //   //     ? dataMain.TertiaryPhoneNumber
      //     //   //     : "",
      //     //   //   tertiaryEmail: dataMain.TertiaryEmail
      //     //   //     ? dataMain.TertiaryEmail
      //     //   //     : "",
      //     //   //   tertiaryDIDKeycode: dataMain.TertiaryDIDKeycode
      //     //   //     ? dataMain.TertiaryDIDKeycode
      //     //   //     : "",
      //     //   //   tertiaryDID: dataMain.TertiaryDID ? dataMain.TertiaryDID : "",
      //     //   //   tertiaryEntity: dataMain.TertiaryEntity
      //     //   //     ? dataMain.TertiaryEntity
      //     //   //     : "",
      //     //   //   tertiaryRegistrationNumber: dataMain.TertiaryRegistrationNumber
      //     //   //     ? dataMain.TertiaryRegistrationNumber
      //     //   //     : "",
      //     //   // });
      //     // }
      //   });
      // }
    }
  };
  const initialValuesData = {
    globalPeopleTypeID: 0,
    nativeID: "",
    globalPeople: "",
    displayname: tokensData.Displayname ? tokensData.Displayname : "",
    firstname: tokensData.Firstname ? tokensData.Firstname : "",
    secondFirstname: tokensData.SecondFirstname
      ? tokensData.SecondFirstname
      : "",
    lastname: tokensData.Lastname ? tokensData.Lastname : "",
    secondLastname: tokensData.SecondLastname ? tokensData.SecondLastname : "",
    dob: tokensData.DOB ? tokensData.DOB : "",
    birthCountrycode: tokensData.BirthCountrycode
      ? tokensData.BirthCountrycode
      : "",
    birthGovernmentNumber: tokensData.BirthGovernmentNumber
      ? tokensData.BirthGovernmentNumber
      : "",
    passportCountrycode: tokensData.PassportCountrycode
      ? tokensData.PassportCountrycode
      : "",
    passportNumber: tokensData.PassportNumber ? tokensData.PassportNumber : "",
    driversLicenseCountrycode: tokensData.DriversLicenseCountrycode
      ? tokensData.DriversLicenseCountrycode
      : "",
    driversLicenseNumber: tokensData.DriversLicenseNumber
      ? tokensData.DriversLicenseNumber
      : "",
    governmentIDCountrycode: tokensData.GovernmentIDCountrycode
      ? tokensData.GovernmentIDCountrycode
      : "",
    governmentIDNumber: tokensData.GovernmentIDNumber
      ? tokensData.GovernmentIDNumber
      : "",
    otherIDCountrycode: tokensData.OtherIDCountrycode
      ? tokensData.OtherIDCountrycode
      : "",
    otherIDNumber: tokensData.OtherIDNumber ? tokensData.OtherIDNumber : "",
    primaryCountrycode: tokensData.PrimaryCountrycode
      ? tokensData.PrimaryCountrycode
      : "",
    primaryStateProvince: tokensData.PrimaryStateProvince
      ? tokensData.PrimaryStateProvince
      : "",
    primaryCityTown: tokensData.PrimaryCityTown
      ? tokensData.PrimaryCityTown
      : "",
    primaryPostalcode: tokensData.PrimaryPostalcode
      ? tokensData.PrimaryPostalcode
      : "",
    primaryAddressLine1: tokensData.PrimaryAddressLine1
      ? tokensData.PrimaryAddressLine1
      : "",
    primaryAddressLine2: tokensData.PrimaryAddressLine2
      ? tokensData.PrimaryAddressLine2
      : "",
    primaryAddressLine3: tokensData.PrimaryAddressLine3
      ? tokensData.PrimaryAddressLine3
      : "",
    primaryDialingcode: tokensData.PrimaryDialingcode
      ? tokensData.PrimaryDialingcode
      : "",
    primaryPhoneNumber: tokensData.PrimaryPhoneNumber
      ? tokensData.PrimaryPhoneNumber
      : "",
    primaryEmail: tokensData.PrimaryEmail ? tokensData.PrimaryEmail : "",
    primaryDIDKeycode: tokensData.PrimaryDIDKeycode
      ? tokensData.PrimaryDIDKeycode
      : "",
    primaryDID: tokensData.PrimaryDID ? tokensData.PrimaryDID : "",
    primaryEntity: tokensData.PrimaryEntity ? tokensData.PrimaryEntity : "",
    primaryRegistrationNumber: tokensData.PrimaryRegistrationNumber
      ? tokensData.PrimaryRegistrationNumber
      : "",
    secondaryCountrycode: tokensData.SecondaryCountrycode
      ? tokensData.SecondaryCountrycode
      : "",
    secondaryStateProvince: tokensData.SecondaryStateProvince
      ? tokensData.SecondaryStateProvince
      : "",
    secondaryCityTown: tokensData.SecondaryCityTown
      ? tokensData.SecondaryCityTown
      : "",
    secondaryPostalcode: tokensData.SecondaryPostalcode
      ? tokensData.SecondaryPostalcode
      : "",
    secondaryAddressLine1: tokensData.SecondaryAddressLine1
      ? tokensData.SecondaryAddressLine1
      : "",
    secondaryAddressLine2: tokensData.SecondaryAddressLine2
      ? tokensData.SecondaryAddressLine2
      : "",
    secondaryAddressLine3: tokensData.SecondaryAddressLine3
      ? tokensData.SecondaryAddressLine3
      : "",
    secondaryDialingcode: tokensData.SecondaryDialingcode
      ? tokensData.SecondaryDialingcode
      : "",
    secondaryPhoneNumber: tokensData.SecondaryPhoneNumber
      ? tokensData.SecondaryPhoneNumber
      : "",
    secondaryEmail: tokensData.SecondaryEmail ? tokensData.SecondaryEmail : "",
    secondaryDIDKeycode: tokensData.SecondaryDIDKeycode
      ? tokensData.SecondaryDIDKeycode
      : "",
    secondaryDID: tokensData.SecondaryDID ? tokensData.SecondaryDID : "",
    secondaryEntity: tokensData.SecondaryEntity
      ? tokensData.SecondaryEntity
      : "",
    secondaryRegistrationNumber: tokensData.SecondaryRegistrationNumber
      ? tokensData.SecondaryRegistrationNumber
      : "",
    tertiaryCountrycode: tokensData.TertiaryCountrycode
      ? tokensData.TertiaryCountrycode
      : "",
    tertiaryStateProvince: tokensData.TertiaryStateProvince
      ? tokensData.TertiaryStateProvince
      : "",
    tertiaryCityTown: tokensData.TertiaryCityTown
      ? tokensData.TertiaryCityTown
      : "",
    tertiaryPostalcode: tokensData.TertiaryPostalcode
      ? tokensData.TertiaryPostalcode
      : "",
    tertiaryAddressLine1: tokensData.TertiaryAddressLine1
      ? tokensData.TertiaryAddressLine1
      : "",
    tertiaryAddressLine2: tokensData.TertiaryAddressLine2
      ? tokensData.TertiaryAddressLine2
      : "",
    tertiaryAddressLine3: tokensData.TertiaryAddressLine3
      ? tokensData.TertiaryAddressLine3
      : "",
    tertiaryDialingcode: tokensData.TertiaryDialingcode
      ? tokensData.TertiaryDialingcode
      : "",
    tertiaryPhoneNumber: tokensData.TertiaryPhoneNumber
      ? tokensData.TertiaryPhoneNumber
      : "",
    tertiaryEmail: tokensData.TertiaryEmail ? tokensData.TertiaryEmail : "",
    tertiaryDIDKeycode: tokensData.TertiaryDIDKeycode
      ? tokensData.TertiaryDIDKeycode
      : "",
    tertiaryDID: tokensData.TertiaryDID ? tokensData.TertiaryDID : "",
    tertiaryEntity: tokensData.TertiaryEntity ? tokensData.TertiaryEntity : "",
    tertiaryRegistrationNumber: tokensData.TertiaryRegistrationNumber
      ? tokensData.TertiaryRegistrationNumber
      : "",
  };
  // const initialValues1 = {
  //   nativeID: "",
  //   firstname: "",
  //   secondFirstname: "",
  //   lastname: "",
  //   secondLastname: "",
  //   dob: "",
  //   birthCountrycode: "",
  //   birthGovernmentNumber: "",
  //   passportCountrycode: "",
  //   passportNumber: "",
  //   driversLicenseCountrycode: "",
  //   driversLicenseNumber: "",
  //   governmentIDCountrycode: "",
  //   governmentIDNumber: "",
  //   otherIDCountrycode: "",
  //   otherIDNumber: "",
  //   primaryCountrycode: "",
  //   primaryStateProvince: "",
  //   primaryCityTown: "",
  //   primaryPostalcode: "",
  //   primaryAddressLine1: "",
  //   primaryAddressLine2: "",
  //   primaryAddressLine3: "",
  //   primaryDialingcode: "",
  //   primaryPhoneNumber: "",
  //   primaryEmail: "",
  //   primaryDIDKeycode: "",
  //   primaryDID: "",
  //   primaryEntity: "",
  //   primaryRegistrationNumber: "",
  //   secondaryCountrycode: "",
  //   secondaryStateProvince: "",
  //   secondaryCityTown: "",
  //   secondaryPostalcode: "",
  //   secondaryAddressLine1: "",
  //   secondaryAddressLine2: "",
  //   secondaryAddressLine3: "",
  //   secondaryDialingcode: "",
  //   secondaryPhoneNumber: "",
  //   secondaryEmail: "",
  //   secondaryDIDKeycode: "",
  //   secondaryDID: "",
  //   secondaryEntity: "",
  //   secondaryRegistrationNumber: "",
  //   tertiaryCountrycode: "",
  //   tertiaryStateProvince: "",
  //   tertiaryCityTown: "",
  //   tertiaryPostalcode: "",
  //   tertiaryAddressLine1: "",
  //   tertiaryAddressLine2: "",
  //   tertiaryAddressLine3: "",
  //   tertiaryDialingcode: "",
  //   tertiaryPhoneNumber: "",
  //   tertiaryEmail: "",
  //   tertiaryDIDKeycode: "",
  //   tertiaryDID: "",
  //   tertiaryEntity: "",
  //   tertiaryRegistrationNumber: "",
  // };
  return (
    <>
      {isValidPublicAddress && (
        <div className="row">
          <div className="col-12 mt-5">
            <div className="originating-account link-wallet-box create-page ms-0">
              <Formik
                initialValues={initialValuesData}
                enableReinitialize={true}
                // validationSchema={validationSchema}
                onSubmit={(values) => {
                  setdata(values);
                  console.log(
                    "🚀 ~ file: Originator.js ~ line 107 ~ Originator ~ values",
                    values
                  );

                  nextstep();
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box  m-0">
                      <Form className="wallet-information-form">
                        <div className="row ">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Information</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  placeholder="First Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">First Name</label>
                                <div
                                  className={
                                    errors.firstname && touched.firstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="firstname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="secondFirstname"
                                  id="secondFirstname"
                                  placeholder="Middle  Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Second First Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  placeholder="Last Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Last Name</label>
                                <div
                                  className={
                                    errors.lastname && touched.lastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="lastname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="secondLastname"
                                  id="secondLastname"
                                  placeholder="Second Last  Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Second Last Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="TokenName"
                                  id="TokenName"
                                  placeholder="Business Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Business Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="TokenName"
                                  id="TokenName"
                                  placeholder="Industry"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Industry</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Home Address </h6>
                            <div className="mb-5">
                              <label>Birth Country Code</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="birthCountrycode"
                                  id="birthCountrycode"
                                  placeholder="Country"
                                  className="form-control form-select"
                                  onChange={(e) => {
                                    console.log(
                                      "🚀 ~ file: Originator.js ~ line 213 ~ Originator ~ e",
                                      e.target.value
                                    );
                                    setFieldValue(
                                      "birthCountrycode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {countryCode &&
                                    countryCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.countrycode}
                                            key={index}
                                          >
                                            {value.isoCountryname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="birthCountrycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryStateProvince"
                                  id="primaryStateProvince"
                                  placeholder="State"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">State</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryCityTown"
                                  id="primaryCityTown"
                                  placeholder="City"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">City</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="primaryAddressLine1"
                                  id="primaryAddressLine1"
                                  placeholder="Address Line 1"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Address Line 1{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryAddressLine2"
                                  id="primaryAddressLine2"
                                  placeholder="Address Line 2"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Address Line 2{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryPostalcode"
                                  id="primaryPostalcode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Postal Code{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Identification</h6>
                            <div className="mb-5">
                              <label>Date of Birth</label>
                              <div className="input-box">
                                <Field
                                  type="date"
                                  name="dob"
                                  id="dob"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.dob && touched.dob
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="dob"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="birthGovernmentNumber"
                                  id="birthGovernmentNumber"
                                  placeholder="Government Birth Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Government Birth Number
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="passportCountrycode"
                                  id="passportCountrycode"
                                  placeholder="Passport Country"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Passport Country
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="passportNumber"
                                  id="passportNumber"
                                  placeholder="Passport Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Passport Number{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="driversLicenseNumber"
                                  id="driversLicenseNumber"
                                  placeholder="Driver's License Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Driver's License Number{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="governmentIDNumber"
                                  id="governmentIDNumber"
                                  placeholder="Other Government ID Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Other Government ID Number{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Contact Information</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryEmail"
                                  id="primaryEmail"
                                  placeholder="Email Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Email Address
                                </label>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Mobile Dialing Country Code</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="primaryDialingcode"
                                  id="primaryDialingcode"
                                  placeholder="Mobile Dialing Country Code"
                                  className="form-control form-select"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "primaryDialingcode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {dailingCode &&
                                    dailingCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="primaryPhoneNumber"
                                  id="primaryPhoneNumber"
                                  placeholder="Mobile Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Mobile Number
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            Skip
                          </button>
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModifyOriginator;
