import { WEB_WALLET_URL } from "../Constatnts/config";
import http from "./httpService";
import { hmacencrypt } from "./walletKey";

export async function GetMilestoneAPI(publickey, privateKey) {
    let values = {
      sourcePublicAddress: publickey,
      // TokenSymbol:"USDA",
      // displayname:"Unicoin"
      // objectcode:"",
    };
    const token = await hmacencrypt(
      "POST",
      WEB_WALLET_URL + "/money/GetMilestones",
      values,
      publickey,
      privateKey
    );
    const response = await http.post(
      "/money/GetMilestones",
      values,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

export async function CancelMilestoneAPI(publickey, privateKey, data) {
    // let values = {
    //   sourcePublicAddress: publickey,
    // };
    const token = await hmacencrypt(
      "POST",
      WEB_WALLET_URL + "/money/CancelMilestone",
      data,
      // values,
      publickey,
      privateKey
    );
    const response = await http.post(
      "/money/CancelMilestone",
      data,
      // values,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }