import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-230448186-1";
ReactGA.initialize(TRACKING_ID);

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const menus = useSelector((state) => state.keyReducer);
  if (menus.menu) {
    let data = menus.menu;
    if (data.includes(location.pathname)) {
      return children;
    } else {
      return <Navigate to="/webwallet/gettransfers" />;
    }
  } else {
    return children;
    // return <Navigate to="/webwallet/getissuers" />;
  }
};
export default RequireAuth;
