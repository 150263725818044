import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";
import CentralBankSection from "./CentralBankSection";
import DigitalCurrency from "./DigitalCurrency";
import DLTSection from "./DLTSection";
import GlobalKyc from "./GlobalKyc";
import Gpi from "./Gpi";
import HeroBanner from "./HeroBanner";
import SmartWallets from "./SmartWallets";

function CentralBankDigitalCurrency() {
    return (
        <>
            {/*<div className="global network monetary-authorities crypto-exchanges">*/}
                {/*<div className="container-fluid p-0">*/}
                    {/*<div className="global-img">*/}
                        {/*<img src="../images/central-bank-digital-currency.png" className="img-fluid" alt="dl-beatsnoop" />*/}
                        {/*<div class="global-text">*/}
                            {/*<p>Central Bank Digital Currency</p>*/}
                            {/*<h1>We Offer Banks the <br className="d-none d-md-block" /> Most Leading CBDC   <br className="d-none d-md-block" /> Technology</h1>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*</div>*/}

            <Header />
            <HeroBanner />
            <CentralBankSection />
            <Gpi />
            <GlobalKyc />
            <DigitalCurrency />
            <SmartWallets />
            <DLTSection />
            <Footer />
        </>
    )
}

export default CentralBankDigitalCurrency;