import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
// import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  getCountryCodeDropDown,
  getDialingcodesAPI,
  getDropdownvalues,
} from "../../../../api/dropdown";

import { getEntity } from "../../../../api/Entity";
import { decrypytdata } from "../../../../api/walletKey";
import { useSearchParams } from "react-router-dom";

function ModifyOriginator({ nextstep, setdata }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [enityTypedropdown, setenityTypedropdown] = useState([]);
  const [worldcurrency, setWorldcurrency] = useState([]);
  const [GetDialingcode, setGetDialingcode] = useState([]);
  const [searchParams] = useSearchParams();
  const [tokensData, settokensData] = useState([]);
  console.log("🚀 ~ file: ModifyOriginator.js ~ line 23 ~ ModifyOriginator ~ tokensData", tokensData)

  const publicKeys = useSelector((state) => state.keyReducer);

  const fetchdropdwon = async () => {
    let LKEntityClass = await getDropdownvalues("LKEntityType");
    setenityTypedropdown(LKEntityClass);
    let LKCountryCode = await getCountryCodeDropDown("GetWorldCurrency");
    setWorldcurrency(LKCountryCode);
    let LKDailingCodes = await getDialingcodesAPI("GetDialingcodes");
    setGetDialingcode(LKDailingCodes);
  };

 
  const initialValues = {
    nativeID: "",
    EntityTypecode:tokensData.EntityTypecode ? tokensData.EntityTypecode : 0,
    Entity: tokensData.Entity ? tokensData.Entity :"",
    displayname:tokensData.Displayname ? tokensData.Displayname : "",
    registrationNumber: tokensData.RegistrationNumber ? tokensData.RegistrationNumber :"",
    governmentTaxID:tokensData.GovernmentTaxID ? tokensData.GovernmentTaxID : "",
    usA_EIN:tokensData.USA_EIN ? tokensData.USA_EIN : "",
    isO_LEI:tokensData.ISO_LEI ? tokensData.ISO_LEI : "",
    registration_Countrycode:tokensData.Registration_Countrycode ? tokensData.Registration_Countrycode : "",
    registration_StateProvince:tokensData.Registration_StateProvince ? tokensData.Registration_StateProvince : "",
    registration_CityTown:tokensData.Registration_CityTown ? tokensData.Registration_CityTown : "",
    registration_Postalcode:tokensData.Registration_Postalcode ? tokensData.Registration_Postalcode : "",
    registration_AddressLine1:tokensData.Registration_AddressLine1 ? tokensData.Registration_AddressLine1 : "",
    registration_AddressLine2:tokensData.Registration_AddressLine2 ? tokensData.Registration_AddressLine2 : "",
    registration_AddressLine3:tokensData.Registration_AddressLine3 ? tokensData.Registration_AddressLine3 : "",
    countrycode:tokensData.Countrycode ? tokensData.Countrycode : "",
    stateProvince:tokensData.Registration_StateProvince ? tokensData.Registration_StateProvince : "",
    cityTown:tokensData.Registration_CityTown ? tokensData.Registration_CityTown : "",
    postalcode:tokensData.Registration_Postalcode ? tokensData.Registration_Postalcode : "",
    addressLine1:tokensData.AddressLine1 ? tokensData.AddressLine1 : "",
    addressLine2:tokensData.AddressLine2 ? tokensData.AddressLine2 : "",
    addressLine3:tokensData.AddressLine3 ? tokensData.AddressLine3 : "",
    dialingcode:tokensData.Dialingcode ? tokensData.Dialingcode : "",
    phoneNumber:tokensData.PhoneNumber ? tokensData.PhoneNumber : "",
    website:tokensData.Website ? tokensData.Website : "",
  };
  // const validationSchema = Yup.object({
  //   Entity: Yup.string().required("Enitity is required!"),
  //   displayname: Yup.string().required("Display Name is required!"),
  //   GovernmentTaxID: Yup.string().required("Tax ID  is required!"),
  //   registration_Countrycode: Yup.string().required(
  //     "Country Code is required!"
  //   ),
  // });

  useEffect(() => {
    let APPID = "";
    let APIKEY = "";
    const fetchdata = async () => {
        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }

        if (APPID && APIKEY) {
            // const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
            const encryptedLedger = await getEntity(APPID, APIKEY, searchParams.get("id"));
            const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
            console.log("🚀 ~ file: OriginatorModify.js ~ line 40 ~ fetchdata ~ tokens", tokens)
            // setRows(JSON.parse(tokens));
            let data = JSON.parse(JSON.parse(tokens).CommandResponse)
            if (data) {
                let dataArr = []
                data?.forEach((dataMain, index) => {
                    // multiple data
                    dataArr.push({ ...dataMain, rowid: index })
                })
                const dataTokens = (Object.assign({}, ...dataArr))
                settokensData(dataTokens)
            }
        }
    };
    if (publicKeys?.publicKey?.publicAddress) {
          setisValidPublicAddress(true);
          fetchdropdwon();
        }
        fetchdata();
}, [publicKeys, searchParams]);

 
  return (
    <>
      {isValidPublicAddress && (
        <div className="row">
          <div className="col-12 mt-5">
            <div className="originating-account link-wallet-box create-page ms-0">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                // validationSchema={validationSchema}
                onSubmit={(values) => {
                  setdata(values);
                  console.log(
                    "🚀 ~ file: Originator.js ~ line 53 ~ Originator ~ values",
                    values
                  );
                  //   console.log(values);
                  // debugger;
                  nextstep();
                  //   nextstep();
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box  m-0">
                      <Form className="wallet-information-form">
                        <div className="row mb-xl-5">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Business Information fgdfsg </h6>
                            <div className="mb-5">
                              <label>Business Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="TokenClass"
                                  id="TokenClass"
                                  placeholder="Business Name"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Alias or Display Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="displayname"
                                  id="displayname"
                                  placeholder="Alias or Display Name"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.displayname && touched.displayname
                                      ? "input-error"
                                      : null
                                  }
                                ></div>
                                <i className="bi bi-check-lg"></i>
                                <ErrorMessage
                                  name="displayname"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Entity Type</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  id="Entity"
                                  name="Entity"
                                  className="form-select form-control"
                                  onChange={(e) => {
                                    setFieldValue("Entity", e.target.value);
                                    console.log(
                                      "🚀 ~ file: Originator.js ~ line 117 ~ Originator ~ e.target.value",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.Entity}
                                >
                                  {enityTypedropdown &&
                                    enityTypedropdown.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayName}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div
                                  className={
                                    errors.Entity && touched.Entity
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="Entity"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/*  <div className="mb-5">
                              <label>Industry</label>
                              <Field
                                as="select"
                                id="decimalPlaces"
                                name="decimalPlaces"
                                className="form-select form-control"
                              >
                                <option defaultValue>--Select--</option>
                                <option value="1">Two</option>
                                <option value="2">Three</option>
                                <option value="3">Four</option>
                                <option value="4">Five</option>
                                <option value="5">six</option>
                              </Field>
                            </div>  */}
                            <div className="mb-5">
                              <label>Website</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="website"
                                  id="website"
                                  placeholder="Website"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Registration Information </h6>

                            <div className="mb-5">
                              <label>Registration Country</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  id="registration_Countrycode"
                                  name="registration_Countrycode"
                                  className="form-select form-control"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "registration_Countrycode",
                                      e.target.value
                                    );
                                    console.log(
                                      "🚀 ~ file: Originator.js ~ line 197 ~ Originator ~ e.target.value",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.registration_Countrycode}
                                >
                                  {worldcurrency &&
                                    worldcurrency.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.countrycode}
                                            key={index}
                                          >
                                            {value.isoCountryname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div
                                  className={
                                    errors.registration_Countrycode &&
                                    touched.registration_Countrycode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="registration_Countrycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Dailing Codes</label>
                              <Field
                                as="select"
                                id="dialingcode"
                                name="dialingcode"
                                className="form-select form-control"
                                onChange={(e) => {
                                  setFieldValue("dialingcode", e.target.value);
                                  console.log(
                                    "🚀 ~ file: Originator.js ~ line 248 ~ Originator ~ e.target.value",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.dialingcode}
                              >
                                {GetDialingcode &&
                                  GetDialingcode.map((value, index) => {
                                    return (
                                      <>
                                        <option
                                          value={value.keycode}
                                          key={index}
                                        >
                                          {value.displayname}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Field>
                            </div>
                            <div className="mb-5">
                              <label>Registration Number</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="registrationNumber"
                                  id="registrationNumber"
                                  placeholder="Registration Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Tax ID Number</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="GovernmentTaxID"
                                  id="GovernmentTaxID"
                                  placeholder="Alias or Display Name"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.GovernmentTaxID &&
                                    touched.GovernmentTaxID
                                      ? "input-error"
                                      : null
                                  }
                                ></div>
                                <i className="bi bi-check-lg"></i>
                                <ErrorMessage
                                  name="GovernmentTaxID"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>USA EIN Number </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="usA_EIN"
                                  id="usA_EIN"
                                  placeholder="USA EIN Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Registration Address</h6>
                            <div className="mb-5">
                              <label>Address Line 1</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="registration_AddressLine1"
                                  id="registration_AddressLine1"
                                  placeholder="Address Line 1"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Address Line 2</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="registration_AddressLine2"
                                  id="registration_AddressLine2"
                                  placeholder="Address Line 2"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>City </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="registration_CityTown"
                                  id="registration_CityTown"
                                  placeholder="City"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>State </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="registration_StateProvince"
                                  id="registration_StateProvince"
                                  placeholder="State"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Postal Code </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="registration_Postalcode"
                                  id="registration_Postalcode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Business Address</h6>
                            <div className="mb-5">
                              <label>Address Line 1</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="addressLine1"
                                  id="addressLine1"
                                  placeholder="Address Line 1"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Address Line 2</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="addressLine2"
                                  id="addressLine2"
                                  placeholder="Address Line 2"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>City </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="cityTown"
                                  id="cityTown"
                                  placeholder="City"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>State </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="stateProvince"
                                  id="stateProvince"
                                  placeholder="State"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Postal Code </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="postalcode"
                                  id="postalcode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            PREVIOUS
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                            // onClick={nextstep}
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModifyOriginator;
