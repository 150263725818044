import React from "react";

function GeoSanctionsSuspend() {
   
    return (
        <>
           
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row ms-lg-4 ms-xl-5 m-0">
                    <div className="col-12">
                        <div className="main-heading">
                            <div className="main-heading">
                                <h3>Geo Sanction</h3>
                                <h6> Suspend Geo Sanction</h6>
                                <p>
                                    Enables the suspension of a geo location sanction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GeoSanctionsSuspend;
