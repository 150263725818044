import { ADMIN_URL } from "../Constatnts/config";
import axios from "axios";

export async function getDropdownvalues(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetLookupTable", {
    LookupTable: type,
  });
  console.log(
    "🚀 ~ file: dropdown.js ~ line 8 ~ getDropdownvalues ~ dataDictionary",
    dataDictionary
  );

  return dataDictionary.data;
}

export async function getLedgerCodes(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetLedgercodes", {
    sourcePublicWallet: type,
  });
  return dataDictionary.data;
}

export async function getCountryCodeDropDown(type) {
  let dataDictionary = await axios.post(
    ADMIN_URL + "/common/GetWorldCurrency",
    {
      LookupTable: type,
    }
  );
  return dataDictionary.data;
}

export async function getDialingcodesAPI(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetDialingcodes", {
    LookupTable: type,
  });
  return dataDictionary.data;
}

export async function getCurrencycodesAPI(type) {
  let dataDictionary = await axios.post(
    ADMIN_URL + "/common/GetCurrencycodes",
    {
      LookupTable: type,
    }
  );
  return dataDictionary.data;
}

export async function getDailingDropDown(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetDialingcodes", {
    LookupTable: type,
  });
  return dataDictionary.data;
}
export async function GetGeoCodes(type) {
  let dataDictionary = await axios.post(ADMIN_URL + "/common/GetGeo", {
    LookupTable: type,
  });
  return dataDictionary.data;
}
// export async function getCurrencycodesAPI(type) {
//   let dataDictionary = await axios.post(ADMIN_URL + "/common/GetCurrencycodes", {
//     LookupTable: type,
//   });
//   return dataDictionary.data;
// }

// export async function getDialingcodesAPI(type) {
//   let dataDictionary = await axios.post(ADMIN_URL + "/common/GetDialingcodes", {
//     LookupTable: type,
//   });
//   return dataDictionary.data;
// }
