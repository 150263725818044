import React, { useEffect, useState } from "react";
import Originator from "./Create/Originator";
import Review from "./Create/Review";

function CreateEntitySanction() {
    const [step, setstep] = useState(1);
    const [entityData, setEntityData] = useState(1);

    useEffect(() => {
        setstep(1);
    }, []);

    const setdata = (data) => {
        setEntityData(data);
    };
    const cleandata = () => {
        setEntityData(1);
    };

    const nextstep = () => {
        if (step < 3) {
            setstep(step + 1);
        }
    };
    const previous = () => {
        if (step > 1) {
            setstep(step - 1);
        }
    };
    const renderstep = (step) => {
        switch (step) {
            case 1:
                return (
                    <Originator
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        cleandata={cleandata}
                    />
                );
            case 2:
                return (
                    <Review
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        cleandata={cleandata}
                        entityData={entityData}
                    /> 
                );
            default:
                return false;
        }
    };

    return (
        <>
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0 ">
                    <div className="col-12">
                        <div className="main-heading ms-lg-4 ms-xl-5 ">
                            <h3>
                            Entity Sanction
                            </h3>
                            <h6>
                                Create Entity Sanction
                                </h6>
                            <p>
                                This function enables the creation of an entity sanction.
                            </p>
                        </div>
                        <div className="deposit-funds-tabs ms-0 ms-lg-4 ms-xl-5 ">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li
                                    className={
                                        step > 1
                                            ? "nav-item originator-mr-w disableElement"
                                            : "nav-item originator-mr-w "
                                    }
                                >
                                    <button
                                        className={step === 1 ? "nav-link active" : "nav-link"}
                                        id="home-tab"
                                        type="button"
                                    >
                                        <div className="originator">
                                            <h3>1</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Entity</h6>
                                    </button>
                                </li>
                                <li
                                    className={
                                        step > 2
                                            ? "nav-item money-mr-w disableElement"
                                            : "nav-item money-mr-w"
                                    }
                                >
                                    <button
                                        className={step === 2 ? "nav-link active" : "nav-link"}
                                        id="profile-tab"
                                        type="button"
                                    >
                                        <div className="review">
                                            <h3>2</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Review</h6>
                                    </button>
                                </li>

                                {/* <li
                                    className={step > 3 ? "nav-item disableElement" : "nav-item"}
                                >
                                    <button
                                        className={step === 3 ? "nav-link active" : "nav-link"}
                                        id="cot-tab"
                                        type="button"
                                    >
                                        <div className="review">
                                            <h3>3</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Review</h6>
                                    </button>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home">
                                    {renderstep(step)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CreateEntitySanction;
