import React, { useState } from "react";
import Beneficiary from "../CreatePayeePlus/Create/Beneficiary";
import Originator from "../CreatePayeePlus/Create/Originator";
import Review from "../CreatePayeePlus/Create/Review";

function CreatePayeePlus() {
  const [step, setstep] = useState(1);
  const [newData, setNewdata] = useState(1);

  const [walletData, setwalletData] = useState("");
  const [ResponseData, setResponseData] = useState();

  const nextstep = () => {
    if (step < 4) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 1) {
      console.log(
        "🚀 ~ file: CreatePayeePlus.js ~ line 20 ~ previous ~ step",
        step
      );

      setstep(step - 1);
    }
  };
  const setReview = (data) => {
    setNewdata(data);
  };

  const setVdata = (data) => {
    console.log(
      "🚀 ~ file: CreatePayeePlus.js ~ line 33 ~ setWal ~ data",
      data
    );

    setwalletData(data);
  };
  const setResponse = (data) => {
    setResponseData(data);
  };

  // const cleandata = () => {
  //   setWallet(1);
  // };

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <Originator
            nextstep={nextstep}
            previous={previous}
            setReview={setReview}
          />
        );
      case 2:
        return (
          <Beneficiary
            nextstep={nextstep}
            previous={previous}
            setVdata={setVdata}
            setResponse={setResponse}
          />
        );
      case 3:
        return (
          <Review
            nextstep={nextstep}
            previous={previous}
            setWallet={setVdata}
            walletData={walletData}
            newData={newData}
            ResponseData={ResponseData}
          />
        );
      default:
        return false;
    }
  };

  return (
    <>
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="container-fluid p-0 ">
          <div className="row ms-lg-4 ms-xl-5">
            <div className="col-12">
              <div className="main-heading mt-4">
                <h3>Payee</h3>
                <h6>Create Payee</h6>
                <p>
                  This function creates a payee (beneficiary) for sending money.
                </p>
              </div>
              <div className="deposit-funds-tabs m-0">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    className={
                      step > 1
                        ? "nav-item originator-mr-w disableElement"
                        : "nav-item originator-mr-w "
                    }
                  >
                    <button
                      className={step === 1 ? "nav-link active" : "nav-link"}
                      id="home-tab"
                      type="button"
                    >
                      <div className="originator">
                        <h3>1</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Payee</h6>
                    </button>
                  </li>
                  <li
                    className={
                      step > 2
                        ? "nav-item money-mr-w disableElement"
                        : "nav-item money-mr-w"
                    }
                  >
                    <button
                      className={step === 2 ? "nav-link active" : "nav-link"}
                      id="profile-tab"
                      type="button"
                    >
                      <div className="beneficiary">
                        <h3>2</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Wallet</h6>
                    </button>
                  </li>
                  <li
                    className={
                      step > 3 ? "nav-item disableElement" : "nav-item"
                    }
                  >
                    <button
                      className={step === 3 ? "nav-link active" : "nav-link"}
                      id="cot-tab"
                      type="button"
                    >
                      <div className="review">
                        <h3>3</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Review</h6>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home">
                    {renderstep(step)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CreatePayeePlus;

// import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
// import Beneficiary from "../CreatWallets/Create/Beneficiary";
// import Money from "../CreatWallets/Create/Money";
// import Originator from "../CreatWallets/Create/Originator";
// import Review from "../CreatWallets/Create/Review";

// function CreateWallet() {
//   const [step, setstep] = useState(1);
//   const [wallet, setWallet] = useState(1);
//   const [people] = useState();
//   let { hash } = useLocation();

//   const nextstep = () => {
//     // debugger;
//     if (step < 4) {
//       setstep(step + 1);
//     }
//   };
//   const cleandata = () => {
//     setWallet(1);
//   };
//   const setdata = (data) => {
//     setWallet(data);
//   };
//   const renderstep = (step) => {
//     switch (step) {
//       case 1:
//         return (
//           <Originator
//             hashname={hash}
//             nextstep={nextstep}
//             previous={previous}
//             setdata={setdata}
//             cleandata={cleandata}
//             wallet={wallet}
//           />
//         );
//       case 2:
//         return (
//           <Beneficiary
//             hashname={hash}
//             nextstep={nextstep}
//             previous={previous}
//             setdata={setdata}
//             cleandata={cleandata}
//             wallet={wallet}
//             people={people}
//           />
//         );
//       case 3:
//         return (
//           <Money
//             hashname={hash}
//             nextstep={nextstep}
//             previous={previous}
//             setdata={setdata}
//             cleandata={cleandata}
//             wallet={wallet}
//           />
//         );
//       case 4:
//         return (
//           <Review
//             nextstep={nextstep}
//             previous={previous}
//             setdata={setdata}
//             cleandata={cleandata}
//             wallet={wallet}
//           />
//         );
//       default:
//         return false;
//     }
//   };
//   const previous = () => {
//     if (step > 1) {
//       setstep(step - 1);
//     }
//   };

//   return (
//     <>
//       {/* <Header /> */}

//       <div className="col-md-9 col-lg-9 col-xxl-10">
//         <div className="container-fluid p-0 ">
//           {/* <div className="row m-0"> */}
//           {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
//                             <Sidebar />
//                         </div> */}
//           <div className="col-12">
//             <div className="sub-heading mt-4">
//               <h3>Create Wallet</h3>
//               <p>
//                 This function creates a multi-currency cryptographic wallet on
//                 the Unicoin network.
//               </p>
//             </div>
//             <div className="deposit-funds-tabs">
//               <ul className="nav nav-tabs" id="myTab" role="tablist">
//                 <li
//                   className={
//                     step > 1
//                       ? "nav-item originator-mr-w disableElement"
//                       : "nav-item originator-mr-w "
//                   }
//                 >
//                   <button
//                     className={step === 1 ? "nav-link active" : "nav-link"}
//                     id="home-tab"
//                     type="button"
//                   >
//                     <div className="originator">
//                       <h3>1</h3>
//                       <i className="bi bi-check-lg"></i>
//                     </div>
//                     <h6>Entity</h6>
//                   </button>
//                 </li>
//                 <li
//                   className={
//                     step > 2
//                       ? "nav-item money-mr-w disableElement"
//                       : "nav-item money-mr-w"
//                   }
//                 >
//                   <button
//                     className={step === 2 ? "nav-link active" : "nav-link"}
//                     id="profile-tab"
//                     type="button"
//                   >
//                     <div className="beneficiary">
//                       <h3>2</h3>
//                       <i className="bi bi-check-lg"></i>
//                     </div>
//                     <h6>Owner</h6>
//                   </button>
//                 </li>
//                 <li
//                   className={
//                     step > 3
//                       ? "nav-item money-mr-w disableElement"
//                       : "nav-item money-mr-w "
//                   }
//                 >
//                   <button
//                     className={step === 3 ? "nav-link active" : "nav-link"}
//                     id="contact-tab"
//                     type="button"
//                   >
//                     <div className="money">
//                       <h3>3</h3>
//                       <i className="bi bi-check-lg"></i>
//                     </div>
//                     <h6>Wallet</h6>
//                   </button>
//                 </li>
//                 <li
//                   className={step > 4 ? "nav-item disableElement" : "nav-item"}
//                 >
//                   <button
//                     className={step === 4 ? "nav-link active" : "nav-link"}
//                     id="cot-tab"
//                     type="button"
//                   >
//                     <div className="review">
//                       <h3>4</h3>
//                       <i className="bi bi-check-lg"></i>
//                     </div>
//                     <h6>Review</h6>
//                   </button>
//                 </li>
//               </ul>
//               <div className="tab-content" id="myTabContent">
//                 <div className="tab-pane fade show active" id="home">
//                   {/* <Originator /> */}
//                   {renderstep(step)}
//                 </div>
//               </div>
//             </div>

//             {/* <div className="col-xxl-11">
//                             <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//                                 <button
//                                     type="button"
//                                     className="btn cancel-btn mb-3 mb-md-0 me-4"
//                                     onClick={previous}
//                                     {step === 1 ? 'Skip' : 'Previous'}
//                                 </button>
//                                 <button
//                                     type="button"
//                                     className="btn cancel-btn mb-3 mb-md-0 me-4"
//                                 >
//                                     CANCEL
//                                 </button>
//                                 <button
//                                     type="button"
//                                     className="btn next-btn mb-3 mb-md-0"
//                                     onClick={nextstep}
//                                 >
//                                     {step === 4 ? 'Submit' : 'Next'}
//                                 </button>
//                             </div>
//                         </div> */}
//           </div>
//         </div>
//       </div>
//       {/* </div> */}
//     </>
//   );
// }
// export default CreateWallet;
