import React from "react";
import { useLocation } from "react-router-dom";

import Email from "../LinkWallet/Email";
import Phone from "../LinkWallet/Phone";
import BankAccount from "../LinkWallet/Bank Acoount";
import Username from "../LinkWallet/Username";
import CryptoCurrency from "../LinkWallet/CryptoCurrency";
import PaymentDevice from "../LinkWallet/Payment Device";
function LinkWallet({ nextstep, previous }) {
  // const [option, setOption] = useState("Email");
  let { pathname } = useLocation();
  const next = () => {
    nextstep();
  };
  // const chnageOption = (e) => {
  //     setOption(e.target.textContent);
  // };
  // const renderSwitch = () => {
  //     switch (option) {
  //         case "Email":
  //             return <Email />;
  //         case "Phone":
  //             return <Phone />;
  //         case "Bank Account":
  //             return <BankAccount />;
  //         case "UserName":
  //             return <Username />;
  //         case "CryptoCurrency":
  //             return <CryptoCurrency />;
  //         case "Payment Device":
  //             return <PaymentDevice />;
  //         default:
  //             return false;
  //     }
  // };
  return (
    <>
      {/* <Header /> */}
      {/* <div className="container-fluid p-0">
        <div className="row m-0"> */}
      {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                        <Sidebar />
                    </div> */}

      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="main-heading">
          <i className="bi bi-arrow-left"></i>
          <h3>
                      Wallets
          </h3>
        </div>
        <div className="link-wallet-box">
                  <h4>{pathname === "/webwallet/modifywallet"
                      ? "Modify Wallet"
                      : "Attach Wallet"}</h4>
          <p>
            To mask your public wallet address you can link your wallet to any
            of the following payment addresses and receive money using that
            payment address.
          </p>
          <div className="link-wallet-tabs">
            <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-email-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-email"
                  type="button"
                  role="tab"
                  aria-controls="pills-email"
                  aria-selected="true"
                >
                  <img
                    src="../icons/link-email.png"
                    className="link-email-img none"
                    alt="link-email-img"
                  ></img>
                  <img
                    src="../icons/link-email-light.png"
                    className="link-email-img show"
                    alt="link-email-img"
                  ></img>
                  Email
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-phone-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-phone"
                  type="button"
                  role="tab"
                  aria-controls="pills-phone"
                  aria-selected="false"
                >
                  <img
                    src="../icons/link-phone.png"
                    className="link-phone-img none"
                    alt="link-phone-img"
                  ></img>
                  <img
                    src="../icons/link-phone-light.png"
                    className="link-phone-img show"
                    alt="link-phone-img"
                  ></img>
                  Phone
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-account-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-account"
                  type="button"
                  role="tab"
                  aria-controls="pills-account"
                  aria-selected="false"
                >
                  <img
                    src="../icons/link-bank.png"
                    className="link-bank-img none"
                    alt="link-bank-img"
                  ></img>
                  <img
                    src="../icons/link-bank-light.png"
                    className="link-bank-img show"
                    alt="link-bank-img"
                  ></img>
                  Bank Account
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-username-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-username"
                  type="button"
                  role="tab"
                  aria-controls="pills-username"
                  aria-selected="false"
                >
                  <img
                    src="../icons/link-username.png"
                    className="link-username-img none"
                    alt="link-username-img"
                  ></img>
                  <img
                    src="../icons/link-username-light.png"
                    className="link-username-img show"
                    alt="link-username-img"
                  ></img>
                  Username
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-cryptocurrency-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-cryptocurrency"
                  type="button"
                  role="tab"
                  aria-controls="pills-cryptocurrency"
                  aria-selected="false"
                >
                  <img
                    src="../icons/link-cryptocurrency.png"
                    className="link-cryptocurrency-img none"
                    alt="link-cryptocurrency-img"
                  ></img>
                  <img
                    src="../icons/link-cryptocurrency-light.png"
                    className="link-cryptocurrency-img show"
                    alt="link-cryptocurrency-img"
                  ></img>
                  Cryptocurrency
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link me-0"
                  id="pills-payment-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-payment"
                  type="button"
                  role="tab"
                  aria-controls="pills-payment"
                  aria-selected="false"
                >
                  <img
                    src="../icons/link-payment.png"
                    className="link-payment-img none"
                    alt="link-payment-img"
                  ></img>
                  <img
                    src="../icons/link-payment-light.png"
                    className="link-payment-img show"
                    alt="link-payment-img"
                  ></img>
                  Payment Device
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-email"
                role="tabpanel"
                aria-labelledby="pills-email-tab"
              >
                <Email />
              </div>
              <div
                className="tab-pane fade"
                id="pills-phone"
                role="tabpanel"
                aria-labelledby="pills-phone-tab"
              >
                <Phone />
              </div>
              <div
                className="tab-pane fade"
                id="pills-account"
                role="tabpanel"
                aria-labelledby="pills-account-tab"
              >
                <BankAccount />
              </div>
              <div
                className="tab-pane fade"
                id="pills-username"
                role="tabpanel"
                aria-labelledby="pills-username-tab"
              >
                <Username />
              </div>
              <div
                className="tab-pane fade"
                id="pills-cryptocurrency"
                role="tabpanel"
                aria-labelledby="pills-cryptocurrency-tab"
              >
                <CryptoCurrency />
              </div>
              <div
                className="tab-pane fade"
                id="pills-payment"
                role="tabpanel"
                aria-labelledby="pills-payment-tab"
              >
                <PaymentDevice next={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
}

export default LinkWallet;
