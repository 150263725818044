import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import React from 'react'

const DigitalCurrencies = () => {

    return (
        <>
            <Header />
            <div className="digital-currencies">
                <div className="container-fluid p-0">
                    <div className="currencies-img">
                        <img src="../images/currencies.jpg" className="img-fluid" alt="currencies" />
                        <div className="currencies-text">
                            <h3>Unicoin leads the<br />
                            Digital Currency Economy</h3>
                        </div>
                    </div>
                </div>
                <div className="our-digital">
                    <div className="container">
                        <div className="our-digital-head">
                            <h3>Our Digital Currency Innovations</h3>
                            <p>National, Continental, and Global Digital Currency Solutions</p>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-4">
                                <img src="../images/coins/our-digital-img.png" className="img-fluid mb-4 mb-md-0" alt="our-digital-img" />
                            </div>
                            <div className="col-md-auto">
                                <div className="our-digital-list mb-5">
                                    <div className="our-digital-number-width">
                                        <div className="our-digital-number">
                                            <h3>1</h3>
                                        </div>
                                    </div>
                                    <div className="our-digital-text">
                                        <h3>Composite Digital Currency (CDC)</h3>
                                        <ul>
                                            <li>Global World Currencies</li>
                                            <li>Single Continental Currencies</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="our-digital-list mb-5 ms-100">
                                    <div className="our-digital-number-width">
                                        <div className="our-digital-number">
                                            <h3>2</h3>
                                        </div>
                                    </div>
                                    <div className="our-digital-text">
                                        <h3>Neutral Monetary Unit (NMU)</h3>
                                        <ul>
                                            <li>Implementing FX Forwards in Digital Currency</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="our-digital-list align-items-center mb-5 ms-100">
                                    <div className="our-digital-number-width">
                                        <div className="our-digital-number">
                                            <h3>3</h3>
                                        </div>
                                    </div>
                                    <div className="our-digital-text">
                                        <h3 className="mb-0">Central Bank Digital Currency (CBDC)</h3>
                                    </div>
                                </div>
                                <div className="our-digital-list">
                                    <div className="our-digital-number-width">
                                        <div className="our-digital-number">
                                            <h3>4</h3>
                                        </div>
                                    </div>
                                    <div className="our-digital-text">
                                        <h3>Cash-backed Digital Assets (CBDAs)</h3>
                                        <ul>
                                            <li>Yield Model vs Fractional Reserve Business Model</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="currency-innovations">
                        <div className="container">
                            <div className="currency-innovations-img">
                                <img src="../images/digital-img-bg.png" className="img-fluid mb-4" alt="digital-img" />
                                <div className="currency-innovations-text">
                                    <h3>Digital Currency Innovations</h3>
                                    <p>Unicoin introduces a tokenization framework and protocol for issuing national (CBDC), continental, and global digital currencies.</p>
                                    <p>Purchasing neutral monetary units (NMUs) preserves its value for the life of its purchases mitigating any exposure to local currency depreciation.</p>
                                    <p>Unicoin only issues digital currencies governed and minted by regulated banks and licensed FinTech companies.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                                    <div className="coin-box">
                                        <img src="../images/coins/SOV (1).png" alt="zara-coin" />
                                        <h3>Universal <br /> Monetary Unit</h3>
                                        <h6>UNICOIN</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                                    <div className="coin-box">
                                        <img src="../images/coins/cbdas.png" alt="zara-coin" />
                                        <h3>Cash-backed<br /> Digital Assets</h3>
                                        <h6>CBDAs</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                                    <div className="coin-box">
                                        <img src="../images/coins/G7 color.png" alt="zara-coin" />
                                        <h3>Composite<br />
                                        Digital Currencies</h3>
                                        <h6>CDCs</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                                    <div className="coin-box">
                                        <img src="../images/coins/NMUS.png" alt="zara-coin" />
                                        <h3>Neutral<br /> Monetary Units</h3>
                                        <h6>NMUs</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="composite-digital">
                    <div className="container-fluid p-0">
                        <div className="composite-digital-img">
                            <img src="../images/composite-digital.png" className="img-fluid" alt="composite-digital" />
                            <div className="composite-digital-main">
                                <div className="container">
                                    <div className="row align-items-end">
                                        <div className="col-md-8">
                                            <div className="composite-digital-text">
                                                <h3>Composite Digital Currencies minimizes FX volatility from forex markets.</h3>
                                                <p>Composite Digital Currencies is a composite index of a basket of national currencies.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <img src="../images/coins/composite-digital-1.png" className="img-fluid" alt="composite-digital-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="the-benefit-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl">
                                <div className="the-benefit-img">
                                    <img src="../images/the-benefit-img.png" className="img-fluid" alt="the-benefit-img" />
                                </div>
                            </div>
                            <div className="col-xl-10">
                                <div className="the-benefit-text">
                                    <h3>The Benefit of a Single Global Trade Currency </h3>
                                    <div className="the-benefit">
                                        <div className="icons-top">
                                            <div className="list-icon">
                                                <i class="bi bi-check2"></i>
                                            </div>
                                        </div>
                                        <p>Accumulate and Hold Balances</p>
                                    </div>
                                    <div className="the-benefit">
                                        <div className="icons-top">
                                            <div className="list-icon">
                                                <i class="bi bi-check2"></i>
                                            </div>
                                        </div>
                                        <p>Emerging markets stop FX losses on local currency</p>
                                    </div>
                                    <div className="the-benefit">
                                        <div className="icons-top">
                                            <div className="list-icon">
                                                <i class="bi bi-check2"></i>
                                            </div>
                                        </div>
                                        <p>Eliminate FX Conversion Fees</p>
                                    </div>
                                    <div className="the-benefit">
                                        <div className="icons-top">
                                            <div className="list-icon">
                                                <i class="bi bi-check2"></i>
                                            </div>
                                        </div>
                                        <p>Earn Interest on Balances</p>
                                    </div>
                                    <div className="the-benefit">
                                        <div className="icons-top">
                                            <div className="list-icon">
                                                <i class="bi bi-check2"></i>
                                            </div>
                                        </div>
                                        <p>Enchances Fraud Prevention Technology</p>
                                    </div>
                                    <div className="the-benefit">
                                        <div className="icons-top">
                                            <div className="list-icon">
                                                <i class="bi bi-check2"></i>
                                            </div>
                                        </div>
                                        <p>Streamline all Trade Finance Payment Models</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="composite-digital">
                    <div className="container-fluid p-0">
                        <div className="composite-digital-img">
                            <img src="../images/neutral-monetary-img.png" className="img-fluid" alt="neutral-monetary-img" />
                            <div className="composite-digital-main">
                                <div className="container">
                                    <div className="row align-items-end">
                                        <div className="col-md-8">
                                            <div className="composite-digital-text">
                                                <h3>Neutral Monetary Units eliminate FX depreciation from forex markets.</h3>
                                                <p>Neutral Monetary Units lock in FX rates upon purchase preventing exposure to any potential FX depreciation of the local currency.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <img src="../images/coins/composite-digital-1.png" className="img-fluid" alt="composite-digital-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="prevention-protocol">
                    <div className="container-fluid">
                        <div className="prevention-protocol-head">
                            <h3>Unicoin Digital Currency Fraud Prevention Protocol</h3>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 col-xxl-2 px-1 mb-4 mb-xl-0">
                                <div className="prevention-protocol-box">
                                    <div className="prevention-protocol-head-box">
                                        <img src="../icons/vet-parties.png" alt="vet-parties" />
                                        <h3>Vet Parties</h3>
                                    </div>
                                    <div className="prevention-protocol-text">
                                        <p>Banks consume weeks to months vetting trading partners. </p>
                                        <p>However, hackers and criminals fake out virtually all pre-vetting due diligence processes.</p>
                                        <p>Many a times the buy side criminals are working with inside bankers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xxl-2 px-1 mb-4 mb-xl-0">
                                <div className="prevention-protocol-box color-box-2">
                                    <div className="prevention-protocol-head-box">
                                        <img src="../icons/proof-funds.png" alt="vet-parties" />
                                        <h3>Proof of Funds</h3>
                                    </div>
                                    <div className="prevention-protocol-text">
                                        <p>Buyers must show proof of funds of a cash balance or with a Standby Letter of Credit (SBLC). </p>
                                        <p>With Unicoin, funds must be secured in their wallet in order to transact.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xxl-2 px-1 mb-4 mb-xl-0">
                                <div className="prevention-protocol-box  color-box-3">
                                    <div className="prevention-protocol-head-box">
                                        <img src="../icons/inspect-product.png" alt="vet-parties" />
                                        <h3>Inspect Product</h3>
                                    </div>
                                    <div className="prevention-protocol-text">
                                        <p>The Buyer may inspect the product prior to shipment.  </p>
                                        <p>They buyer can inspect or use an insured inspector.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xxl-2 px-1 mb-4 mb-lg-0">
                                <div className="prevention-protocol-box  color-box-4">
                                    <div className="prevention-protocol-head-box">
                                        <img src="../icons/pay-seller.png" alt="vet-parties" />
                                        <h3>Pay Seller</h3>
                                    </div>
                                    <div className="prevention-protocol-text">
                                        <p>Once the product passes inspection the Seller may receive a partial CBDC payment.  </p>
                                        <p>Sometimes the full purchase amount is paid upon a satisfied inspection.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xxl-2 px-1 mb-4 mb-md-0">
                                <div className="prevention-protocol-box color-box-5">
                                    <div className="prevention-protocol-head-box  ">
                                        <img src="../icons/deliver-product.png" alt="vet-parties" />
                                        <h3>Deliver Product</h3>
                                    </div>
                                    <div className="prevention-protocol-text">
                                        <p>The Seller ships the product according to the freight forward terms.  </p>
                                        <p>Theft routinely happens during the logistics and transporting process.</p>
                                        <p>The delivered product may not be the same as the product shipped.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xxl-2 px-1 mb-4 mb-md-0">
                                <div className="prevention-protocol-box color-box-6 ">
                                    <div className="box-arrow">
                                        <div className="prevention-protocol-arrow"></div>
                                        <div className="prevention-protocol-head-box">
                                            <img src="../icons/post-validation.png" alt="vet-parties" />
                                            <h3>Post-Validation</h3>
                                        </div>
                                    </div>
                                    <div className="prevention-protocol-text">
                                        <p>With Unicoin, the Seller balance is paid upon delivery of the product.  </p>
                                        <p>However, the Seller cannot transfer the CBDC or liquidate the CBDC to their bank account until the product passes a final inspection.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cash-backed">
                    <div className="container-fluid p-0">
                        <div className="cash-backed-img">
                            <img src="../images/cash-backed-img.jpg" className="img-fluid" alt="cash-backed-img" />
                            <div className="cash-backed-head">
                                <h3>Cash-backed Digital Assets (CBDA)</h3>
                                <p>CBDAs are fiat-backed digital assets issued and governed by the banking industry.</p>
                                <img src="../images/cash-backed-img-2.png" alt="cash-backed-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cross-cbdc">
                    <div className="container">
                        <div className="cross-cbdc-head">
                            <h3>Cross-CBDC Transfers are easily enabled via Cash-Backed Digital Assets (CBDA).</h3>
                            <img src="../images/cross-cbdc-img.png" alt="cross-cbdc-img" />
                        </div>
                    </div>
                    <div className="cross-cbdc-content">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-5">
                                    <div className="cross-cbdc-text text-end">
                                        <p>A CBDA Wallet can provide a common payment gateway service to all CBDCs worldwide without the complexities and costs of each bank and FinTech maintaining a node for each CBDC.</p>
                                    </div>
                                </div>
                                <div className="col-md-2 text-center">
                                    <img src="../images/cross-cbdc-arrow.png" alt="cross-cbdc-arrow" />
                                </div>
                                <div className="col-md-5">
                                    <div className="cross-cbdc-text">
                                        <p>Each bank and FinTech can establish their own rate structure for transacting domestic and international money transfer services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cross-cbdc-table table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colspan="7">Standardized CBDC Money as a Service Payment Gateway</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h3>Nigeria</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                            <td>
                                                <h3>Ghana</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                            <td>
                                                <h3>Tunisia</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                            <td>
                                                <h3>Ethiopia</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                            <td>
                                                <h3>S. Africa</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                            <td>
                                                <h3>Kenya</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                            <td>
                                                <h3>...</h3>
                                                <h6>CBDC</h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="about">
                    <div className="container">
                        <div className="row pt-100">
                            <div className="col-md-6">
                                <div className="about-img">
                                    <img src="../images/about-img.png" className="img-fluid" alt="about-img" />
                                    <div className="client-name">
                                        <h6>darrell@dcma.io</h6>
                                        <h3>Darrell Hubbard</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-text">
                                    <h3>About our Founder, Darrell Hubbard</h3>
                                    <p>Darrell Hubbard has an exceptional record of achievement in Computer Science and Information Technology. He received his master's in computer science at the age of 19 from North Carolina State University (NCSU) and started his career as a Chief Architect at AT&T Bell Laboratories.
                                    He served as a member of the National Institute of Science and Technology (NIST) during its release of TCP/IP, known as the Internet. He was appointed as a US Expert in the American National Standards Institute (ANSI) and elected as a Chairperson within the International Standards Organization (ISO).
                                    Darrell has over 25 years of high-technology and financial technology experience serving as the Head of Financial Management Architect at AT&T and consulting for leading corporations in America. His clients include Amgen, Warner Bros., Toyota, Sony Pictures, Fannie Mae, TD Ameritrade, Screen Actors Guild (SAG), Philips Healthcare, McKesson, Kiewit, Ansys, Ferguson, Oracle, HP, Dell, and many others.
                                    Over the past several years he has dedicated his talents to Distributed Ledger Technology (DLT) and digital currencies. Darrell has collaborated with or completed projects for the European Union and other central banks around the world.
                                    His work is featured by the European Commission, the United Nations, and the World Economic Forum. In 2018, Darrell was inducted into the Computer Science Alumni Hall of Fame at NCSU.
                                Darrell graduated with an MBA from Harvard University.</p>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <div className="dcma-logo">
                                    <img src="../images/dcma-logo.png" className="img-fluid" alt="dcma-logo" />
                                    <h5>info@dcma.io</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <Footer />
            </div>
        </>
    )
}

export default DigitalCurrencies;