import React from "react";

function ModifyEntitySanction() {

    return (
        <>
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="ledger-information create-issuer-information">
                    <div className="row ms-lg-4 ms-xl-5 m-0">
                        <div className="col-12">
                            <div className="main-heading">
                                <h3>Entity Sanction</h3>
                                <h6>Modify Entity Sanction</h6>
                                <p>
                                    This function enables the modification of an entity sanction.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModifyEntitySanction;
