import Box from "@mui/material/Box";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
      display="flex"
      justifyContent="flex-end"
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );
}
export default QuickSearchToolbar;
