import React from "react";
import Header from "../../Components/Header";

const Crypto2 = () => {
    return (
        <>
            <Header />
            <div className="monetary-policy community crypto2-page">
                <div className="container-fluid p-0">
                    <div className="monetary-policy-slider">
                        <img src="../images/Crypto2.png" className="img-fluid" alt="community-img" />
                        <div className="monetary-policy-slider-text text-center" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} >
                            <img src="../images/coins/SOV (1).png" style={{ width: "30%" }} alt="U-1" />
                            <h1 className="mt-2" style={{ width: "100%", fontWeight: "700" }}>Crypto 2.0</h1>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <div className="monetary-policy-question community-question pb-5">
                            <h2><span>01</span>CRYPTO 2.0</h2>
                            <p>Crypto 2.0 refers to cryptographic solutions that comply with regulated banking and financial services and are designed to operate completely on digital currency cryptography with minimal to no reliance on the traditional banking and financial services systems.
                                The term was coined by Darrell Hubbard <span className="text-danger">[1]</span> in 2020 to distinguish Unicoin, the first Crypto 2.0 platform, from Bitcoin and the other alt coins in the market.</p>
                            <p>Crypto 2.0 embraces the values of both decentralized and centralized governance and adopts the best of both principles in its solutions. Core features of Crypto 2.0 include:</p>
                            <ul>
                                <li>
                                    A trusted consensus protocol for financial transaction commits
                                    <ul className="mt-3">
                                        <li>Ledger-based electronic cash</li>
                                        <li>Private hand-to-hand physical cash (digital cash)</li>
                                    </ul>
                                </li>
                                <li>
                                    Cryptographic implementations of legal forms of societal cash.
                                    <ul className="mt-3">
                                        <li>Privacy and Data Protection</li>
                                        <li>Know Your Customer (KYC) Onboarding</li>
                                        <li>Money Transfer Limits and Controls</li>
                                        <li>Anti-Money Laundering (AML) Protections</li>
                                        <li>Suspicious Activity Reporting (SARS)</li>
                                        <li>Sanctions Enforcement</li>
                                    </ul>
                                </li>
                                <li>
                                    Compliance with all applicable consumer protections and financial services regulations.
                                </li>
                            </ul>
                            <div className="monetary-policy-question-two community-question-text my-5">
                                <h2><span>1.1</span>Trusted Consensus Protocols</h2>
                                <p>Monetary Authorities are the trusted centralized governing body over a country's monetary system. They are responsible for the issuance of legal tender, establishing governance and monetary policy over legal tender, and for providing a safe and secure payment system for the exchange of its legal tender.</p>
                                <p>As Monetary Authorities design and issue central bank digital currency (CBDC) and other digital assets in their economy, a new wave of trusted consensus protocols must be designed because the first generation of trustless consensus protocols are inefficient and are not scalable for high volume transaction processing.</p>
                                <p>A hybrid consensus protocol or a semi-decentralized consensus protocol is required to achieve the scale needed for a high-volume financial transaction environment. The trusted central authority should have the rights to instantaneously process and write transactions accurately to the ledger while giving other validating nodes the right to review and contest any written transactions by the central authority.</p>
                                <p>In an article published in March 2022, "Not Bitcoin, Here's Where The Next $30T In Crypto Could Go To," [4] Alkesh Shah, the head of the cryptocurrency and digital asset strategy at BofA Securities, discusses the next wave of crypto protocols referred to herein as Crypto 2.0.</p>
                                <p>According to Shah, "The next phase of growth in the cryptocurrency space will be driven by protocols less decentralized than Bitcoin... Ultimately, we want some governance and some level of trust... and that is really what makes an ecosystem real, and that's where we're going."</p>
                                <p>The Staked Proof of Trust (SPOT) protocol is a semi-decentralized cryptocurrency protocol innovated for the Unicoin network and is keenly optimized for trusted monetary authorities.</p>
                                <p>The SPOT protocol works opposite of untrusted protocol consensus algorithms. With SPOT, monetary authority nodes, meganodes, are trusted nodes and processing and writes incoming transactions in real-time to the ledger.</p>
                                <p>The decentralized validating nodes, mesanodes, only rejects transactions they disagree with. If the majority of validating nodes rejects a meganode transaction, the transaction does not transition from pending to active state. Instead, it transitions from pending to suspended state.</p>
                                <p>SPOT is scientifically the fastest possible consensus algorithm, as no network traffic or confirmation of messages are needed to confirm a transaction. Instead, only reject messages are sent over the network.</p>
                                <p>Embracing a trusted consensus model is the foundational layer of Crypto 2.0. Other important building blocks include the proper cryptographic implementation of the two forms of legal societal cash, regulated electronic cash and physical hand-to-hand cash.</p>
                                <p>All Crypto 2.0 apps may not implement both forms of legal societal cash. However, whichever version of cash implemented, it should follow the banking regulatory framework for that form of societal cash.</p>
                            </div>
                            <div className="monetary-policy-question-two community-question-text mb-5">
                                <h2><span>1.2</span>Legal Cryptographic Cash</h2>
                                <h4>Physical Cash</h4>
                                <p>Physical cash can be transacted outside the electronic cash system. In this scenario, the exchange of value between two parties is private, anonymous, and is not recorded on any intermediary ledger.</p>
                                <p>Even if using encryption and other cryptographic technology, the mere use of a decentralized or centralized ledger that is transparent and visible to others would violate the properties of physical cash.</p>
                                <p>When Bitcoin emerged, the cryptographic addresses were considered anonymous. Today, many network monitoring technologies have been developed to monitor, track, and trace Bitcoin addresses.</p>
                                <p>Many times, when large Bitcoin transfers are transacted it becomes global news with Bitcoin addresses being publicized. Cybersecurity experts have classified Bitcoin addresses by major industry classifications such as crypto exchanges, mixers, and other known entities.</p>
                                <p>So, although Bitcoin operates outside of financial institutions, it does not operate as physical cash. The primary characteristics of physical cash are hand-to-hand or point-to-point, private, anonymous, and self-governance.</p>
                                <p>When physical cash is implemented using cryptographic technology it is considered digital cash. Unicoin is the world's first physical cash (digital cash) cryptocurrency.</p>
                                <h4>Electronic Cash</h4>
                                <p>Regulated electronic cash transactions are facilitated by intermediary financial institutions responsible for complying with all applicable consumer protections, banking, and financial services laws in their jurisdiction.</p>
                                <p>The laws may vary by jurisdiction. However, common regulations include know your customer (KYC) regulations, anti-money laundering (AML) procedures, and suspicious activity reporting (SARS).</p>
                                <p>Electronic cash requires transactions to be private, safe, and secure when recorded on financial ledgers while also being traceable with personal identification information by financial crimes enforcement agencies.</p>
                                <p>Crypto 2.0 platforms embrace financial integrity and implement security features to prevent and monitor illicit activity within its financial system.</p>
                                <p>The Unicoin network is reserved exclusively for governments, banks, and licensed financial technology (FinTech) companies that operate under a banking or financial services regulatory framework.</p>
                            </div>
                            <div className="monetary-policy-question-two community-question-text mb-5">
                                <h2><span>1.3</span>Consumer Protections and Regulatory Compliance</h2>
                                <p>Every jurisdiction has their own set of legal, regulatory, and compliance rules. Crypto 2.0 digital financial services platforms seek to comply and enforce the existing framework of banking and financial services regulations as applied to the banking industry when no specific laws are specified for cryptocurrency and digital assets in their region.</p>
                                <p>Adopting a global localization framework for regulatory compliance is ideal for platforms with a global reach and elect to comply with regional regulations. Global localization technology requires architecting a general design that can be configurable for each regulatory region.</p>
                            </div>
                            <h2><span>02</span>History</h2>
                            <div className="monetary-policy-question-two community-question-text mb-5">
                                <h2><span>2.1</span>Crypto 1.0</h2>
                                <p>The very first sentence in the Bitcoin Whitepaper [2] states, "A purely peer-to-peer version of electronic cash would allow online payments to be sent directly from one party to another without going through a financial institution."</p>
                                <p>The global banking industry recognizes two forms of societal cash: regulated electronic cash and private hand-to-hand physical cash. Bitcoin's version of peer-to-peer electronic cash does not cryptographically implement either of these forms of legal societal cash.</p>
                                <p>Because Bitcoin is designed to circumvent intermediary financial institutions, there are no KYC procedures to identify transacting parties in the case of money laundering or fraudulent transactions.</p>
                                <p>Bitcoin was originally touted as a store of value digital commodity suitable for medium of exchange transactions. According to research, as the investment community has embraced Bitcoin, it has been become 26 times more volatile than the S&P 500 [3].</p>
                                <p>The extreme volatility of Bitcoin has attracted millions of traders around the world. As a result, Bitcoin has morphed more into a speculative alternative investment than a true store of value digital asset.</p>
                                <p>Although Bitcoin has enjoyed significant traction by capital markets, because of the reasons cited above, Bitcoin has been challenged to receive any adoption in retail and commercial banking operations.</p>
                                <p>Nonetheless, the rapid adoption of cryptocurrency and digital assets pose a fundamental threat to the survival of the banking industry as billions of dollars outflow from the traditional banking system into cryptocurrency and other digital assets.</p>
                                <p>This trend has prompted the global banking industry to research and develop their own digital currency solutions to compete with the growth in private sector cryptocurrencies.</p>
                            </div>
                            <div className="monetary-policy-question-two community-question-text mb-5">
                                <h2><span>2.2</span>Crypto 2.0</h2>
                                <p>Bitcoin and the thousands of other alt coins were designed to circumvent financial institutions and are not engineered to meet the regulatory requirements of banks and financial institutions.</p>
                                <p>A new wave of cryptographic ledger technology has evolved to meet the regulatory, compliance, and digital transformation needs of banking and financial services.</p>
                                <p>The phrase Crypto 2.0 was inspired by the innovations of Unicoin, a global decentralized digital financial services network engineered for governments and central banks.</p>
                                <p>Crypto 2.0 is not a Bitcoin fork. It is a crypto fork.</p>
                                <p>Crypto 2.0 embraces three fundamental guiding principles -</p>
                                <p>1.	Trusted governance and semi-decentralized consensus protocols</p>
                                <p>2.	Cryptographic solutions for regulated electronic cash and physical cash</p>
                                <p>3.	Adoption of consumer protection and other banking and financial services regulatory compliance</p>
                                <p>The Unicoin network received the Innovation of the Year Award in 2020 during Hong Kong Blockchain Week in conjunction with another digital asset that implemented an income producing Stablecoin as a store of value digital currency on the Unicoin network.</p>
                                <p>Since the emergence of central bank digital currency (CBDC), central banks are currently researching the issuance of CBDC on the Unicoin network.</p>
                            </div>
                            <div className="monetary-policy-question community-question mb-5">
                                <h2><span>03</span>UNICOIN</h2>
                                <p>Unicoin is a global decentralized digital financial services network for governments and central banks. The same way you need an Internet Services Provider (ISP) to access the Internet, you need Financial Services Provider (FSP) to access the Unicoin network.</p>
                                <p>An FSP could be a regulated bank, a licensed financial technology (Fintech) company, or a cryptocurrency exchange.</p>
                                <p>The Unicoin network supports all three (3) classes of Store of Value digital assets:</p>
                                <ul>
                                    <li>
                                        Fixed Store of Value, such as Cash-back Digital Assets (CBDAs)
                                    </li>
                                    <li>
                                        Income Producing Store of Value, such as DeFi digital assets.
                                    </li>
                                    <li>
                                        Cryptocurrency Store of Value, such as Unicoin.
                                    </li>
                                </ul>
                            </div>
                            <div className="monetary-policy-question community-question mb-5">
                                <h2><span>04</span>REFERENCES</h2>
                                <p>Hubbard, D. 2018 Hall of Fame Inductee. From North Carolina State University. <a href="/">North Carolina State University (touchpros.com)</a></p>
                                <p>Nakamoto, S. Bitcoin: A Peer-to-Peer Electronic Cash System. <a href="/">bitcoin.pdf</a></p>
                                <p>Fama, M, Andrea Fumagalli, Stefano Lucarelli Cryptocurrencies, Monetary Policy, and New Forms of Monetary Sovereignty<a href="/"> Cryptocurrencies, Monetary Policy, and New Forms of Monetary Sovereignty: International Journal of Political Economy: Vol 48, No 2 (tandfonline.com)</a></p>
                                <p>Sriram, S, Benzinga Not Bitcoin, Here's Where The Next $30T In Crypto Could Go To <a href="/">Not Bitcoin, Here's Where The Next $30T In Crypt Could Go To, According To BofA Analyst | Markets Insider (businessinsider.com)</a></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Crypto2;
