import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ValidateWalletLink } from "../../../../../api/PayeesApi";
import { decrypytdata } from "../../../../../api/walletKey";

function Username({ setVdata, nextstep }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  let APPID = "";
  let APIKEY = "";
  const [data, setData] = useState("");
  console.log("🚀 ~ file: Username.js ~ line 17 ~ Username ~ data", data);

  const initialValues = {
    walletLinkTypecode: "USERNAME",
    username: data.username ? data.username : "",
  };

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await ValidateWalletLink(
        APPID,
        APIKEY,
        initialValues
      );
      console.log(
        "🚀 ~ file: Username.js ~ line 30 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Username.js ~ line 33 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      setVdata({
        accountClasscode: "USERNAME",
        username: data.username ? data.username : "",
      });
      nextstep();
      // dispatch(setPrivateId(""));
      // setData("");
      // debugger;
      // let jsonData;
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // } else {
      //   dispatch(setPrivateId(""));
      //   // dispatch(setResult(jsonData))
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
      // debugger;
      //   nextstep();
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {}}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <div className="">
                  <Form>
                    <div className="row align-items-center">
                      <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        {/* <label>Email</label> */}
                        <div className="">
                          <div className="input-box mb-5 email-floting  form-floating">
                            <Field
                              type="text"
                              name="username"
                              id="username"
                              className="form-control email-floting-input"
                              onChange={(e) => {
                                // setVdata((prev) => {
                                //   return {
                                //     ...prev,
                                //     [e.target.name]: e.target.value,
                                //   };
                                // });
                                setData((prev) => {
                                  return {
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  };
                                });
                              }}
                              value={formik.username}
                            />
                            <label className="ps-0 pb-0">Username</label>
                            <div
                              className={
                                errors.username && touched.username
                                  ? "input-error"
                                  : null
                              }
                            >
                              <ErrorMessage
                                name="username"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                        <div className="mb-5">
                          <button
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="btn next-btn"
                          >
                            Validate
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default Username;
