import React from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
function Transactions() {
    return (
        <>
            <Header />
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9 col-lg-9 col-xxl-10">
            <div className="balance-page">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-10 mb-5">
                            <div className="balance-head">
                                <h3>Transactions</h3>
                                <p>Creates a new store of value digital asset for savings, commerce and trade.    </p>
                            </div>
                        </div>
                        <div className="col-md-10 mb-5">
                            <div className="transactions-table">
                                <h3>Recent</h3>
                                <div className="transactions-table-box mb-4">
                                    <div className="row align-items-center justify-content-around">
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                                        <img src="../images/coins/U-1-small.png" className="" alt="U-1-img"></img>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-date">
                                                <h4>12 February</h4>
                                                <p>PENDING</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>To</h4>
                                                <p>AKJLKJ:DFJ</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>From</h4>
                                                <p>KJS:GKJLKJGL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-units">
                                                <h4>Units</h4>
                                                <p>1500</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-6 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>TX Hash</h4>
                                                <p>HUBDLKJ*$*#H#LJ</p>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                            <div className="transactions-print">
                                                <img src="../icons/printer.png" className="" alt="printer-img"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="transactions-table-box successfull-boder mb-4">
                                    <div className="row align-items-center justify-content-around">
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                                        <img src="../images/coins/U-1-small.png" className="" alt="U-1-img"></img>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-date successfull">
                                                <h4>12 February</h4>
                                                <p>SUCCESSFULL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>To</h4>
                                                <p>AKJLKJ:DFJ</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>From</h4>
                                                <p>KJS:GKJLKJGL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-units">
                                                <h4>Units</h4>
                                                <p>1500</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-6 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>TX Hash</h4>
                                                <p>HUBDLKJ*$*#H#LJ</p>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                            <div className="transactions-print">
                                                <img src="../icons/printer.png" className="" alt="printer-img"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="transactions-table-box cancelled-boder mb-4">
                                    <div className="row align-items-center justify-content-around">
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                                        <img src="../images/coins/U-1-small.png" className="" alt="U-1-img"></img>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-date cancelled">
                                                <h4>12 February</h4>
                                                <p>CANCELLED</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>To</h4>
                                                <p>AKJLKJ:DFJ</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>From</h4>
                                                <p>KJS:GKJLKJGL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-units">
                                                <h4>Units</h4>
                                                <p>1500</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-6 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>TX Hash</h4>
                                                <p>HUBDLKJ*$*#H#LJ</p>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                            <div className="transactions-print">
                                                <img src="../icons/printer.png" className="" alt="printer-img"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-10 mb-5">
                            <div className="transactions-table">
                                <h3>January 2022</h3>
                                <div className="transactions-table-box mb-4">
                                    <div className="row align-items-center justify-content-around">
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                                        <img src="../images/coins/U-1-small.png" className="" alt="U-1-img"></img>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-date">
                                                <h4>12 February</h4>
                                                <p>PENDING</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>To</h4>
                                                <p>AKJLKJ:DFJ</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>From</h4>
                                                <p>KJS:GKJLKJGL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-units">
                                                <h4>Units</h4>
                                                <p>1500</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-6 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>TX Hash</h4>
                                                <p>HUBDLKJ*$*#H#LJ</p>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                            <div className="transactions-print">
                                                <img src="../icons/printer.png" className="" alt="printer-img"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="transactions-table-box successfull-boder mb-4">
                                    <div className="row align-items-center justify-content-around">
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                                        <img src="../images/coins/U-1-small.png" className="" alt="U-1-img"></img>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-date successfull">
                                                <h4>12 February</h4>
                                                <p>SUCCESSFULL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>To</h4>
                                                <p>AKJLKJ:DFJ</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>From</h4>
                                                <p>KJS:GKJLKJGL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-units">
                                                <h4>Units</h4>
                                                <p>1500</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-6 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>TX Hash</h4>
                                                <p>HUBDLKJ*$*#H#LJ</p>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                            <div className="transactions-print">
                                                <img src="../icons/printer.png" className="" alt="printer-img"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="transactions-table-box cancelled-boder mb-4">
                                    <div className="row align-items-center justify-content-around">
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                                        <img src="../images/coins/U-1-small.png" className="" alt="U-1-img"></img>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-date cancelled">
                                                <h4>12 February</h4>
                                                <p>CANCELLED</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>To</h4>
                                                <p>AKJLKJ:DFJ</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>From</h4>
                                                <p>KJS:GKJLKJGL</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-units">
                                                <h4>Units</h4>
                                                <p>1500</p>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-4 col-md-6 col-lg-2 mb-4 mb-lg-0">
                                            <div className="transactions-to">
                                                <h4>TX Hash</h4>
                                                <p>HUBDLKJ*$*#H#LJ</p>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4 col-md-3 col-lg-auto mb-4 mb-lg-0">
                                            <div className="transactions-print">
                                                <img src="../icons/printer.png" className="" alt="printer-img"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div></div></div>
        </>
    );
}

export default Transactions;
