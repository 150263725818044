import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import { GetTokenSymbols } from "../../../api/LedgerAPI";
import { getTokenBySymbol, TokenSwapAPI } from "../../../api/Token";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";

function TokenSwap() {
  const publicKeys = useSelector((state) => state.keyReducer);
  //   const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");
  const [TokenSymbol, setTokenSymbol] = useState([]);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  // const [tokenPublicAddress, setTokenPublicAddress] = useState();
  const [tokensData, settokensData] = useState([]);
  const [targetToken, setTargetToken] = useState();
  console.log(
    "🚀 ~ file: TokenSwap.js ~ line 21 ~ TokenSwap ~ targetToken",
    targetToken
  );
  const [searchParams] = useSearchParams();

  const fetchdropdwon = async () => {
    let tokenSymboldropdownvalues = await GetTokenSymbols("GetTokenSymbols");
    setTokenSymbol(tokenSymboldropdownvalues);
  };

  const initialValues = {
    sourceTokenSymbol: tokensData.TokenSymbol ? tokensData.TokenSymbol : "",
    sourcePublicAddress: tokensData.PublicAddress
      ? tokensData.PublicAddress
      : "",
    amount: 0,
    units: 0,
    targetTokenSymbol: "",
    targetPublicAddress: targetToken ? targetToken : "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let APPID = "";
  let APIKEY = "";

  const TokenSwapData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedToken = await TokenSwapAPI(APPID, APIKEY, data);
      dispatch(showLoader(false));
      const devryptedToken = await decrypytdata(encryptedToken.data, APIKEY);

      let jsonData;
      if (devryptedToken) {
        jsonData = JSON.parse(devryptedToken);
        console.log("JSONDATA", jsonData);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedToken));
        navigate("/result");
      }
      if (jsonData.ReturnMessage) {
        success(jsonData.ReturnMessage);
      }
      dispatch(setPrivateId(""));
    }
    // setPrivateModal(false);
  };

  useEffect(() => {
    let APPID = "";
    let APIKEY = "";
    const fetchdata = async () => {
      if (publicKeys.publicKey) {
        APPID = publicKeys.publicKey.publicAddress;
      }
      if (publicKeys.privateKey) {
        APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      }

      if (APPID && APIKEY) {
        // const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
        const encryptedLedger = await getTokenBySymbol(
          APPID,
          APIKEY,
          searchParams.get("id")
        );
        const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
        // setRows(JSON.parse(tokens));
        let data = JSON.parse(JSON.parse(tokens).CommandResponse);
        if (data) {
          let dataArr = [];
          data?.forEach((dataMain, index) => {
            // multiple data
            dataArr.push({ ...dataMain, rowid: index });
          });
          const dataTokens = Object.assign({}, ...dataArr);
          settokensData(dataTokens);
        }
      }
    };
    fetchdata();
    fetchdropdwon();
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
  }, [publicKeys, searchParams]);

  return (
    <>
      {/* {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={SwapData} />
            )} */}
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5">
            <div className="col-12">
              <div className="main-heading">
                <h3>Token</h3>
                <h6>Swap</h6>
                <p>
                  This function converts one digital currency to another digital
                  currency at the current market value exchange rate.
                </p>
              </div>
              <div className="m-0 wallet-information-box ">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      setdata(values);
                      console.log(
                        "🚀 ~ file: TokenMint.js ~ line 71 ~ TokenMint ~ values",
                        values
                      );
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, setFieldValue } = formik;
                      return (
                        <Form className="wallet-information-form">
                          <div className="suspend-text wallet-information-form">
                            <div class="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-6 mb-3">
                                <div className="mb-3">
                                  <label>Source Token Name</label>
                                  <Field
                                    as="select"
                                    className="form-select form-control"
                                    id="sourceTokenSymbol"
                                    disabled={true}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "sourceTokenSymbol",
                                        e.target.value
                                      );
                                      console.log(
                                        "🚀 ~ file: TokenMint.js ~ line 105 ~ TokenMint ~ e.target.value",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tokenSymbol}
                                  >
                                    {TokenSymbol &&
                                      TokenSymbol.map((value, index) => {
                                        return (
                                          <>
                                            <option
                                              value={value.tokenSymbol}
                                              key={index}
                                            >
                                              {value.displayname}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </Field>
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage
                                      name="sourceTokenSymbol"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-6 mb-3">
                                <div className="mb-3">
                                  <label>Target Token Name</label>
                                  <Field
                                    as="select"
                                    className="form-select form-control"
                                    id="targetTokenSymbol"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "targetTokenSymbol",
                                        e.target.value
                                      );
                                      setTargetToken(e.target.value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tokenSymbol}
                                  >
                                    {TokenSymbol &&
                                      TokenSymbol.map((value, index) => {
                                        return (
                                          <>
                                            <option
                                              value={value.publicAddress}
                                              key={index}
                                            >
                                              {value.displayname}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </Field>
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage
                                      name="targetTokenSymbol"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-6 mb-3">
                                <div class="mb-3">
                                  <div class="input-box form-floating">
                                    <Field
                                      type="number"
                                      name="units"
                                      placeholder="units"
                                      id="units"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">Units</label>
                                    <div
                                      className={
                                        errors.units && touched.units
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="units"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-6 mb-3">
                                <div class="mb-4">
                                  <div class="input-box form-floating">
                                    <Field
                                      type="number"
                                      name="amount"
                                      placeholder="amount"
                                      id="amount"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">Amount</label>
                                    <div
                                      className={
                                        errors.amount && touched.amount
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="amount"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-6 mb-3">
                                <div class="mb-4">
                                  <div class="input-box form-floating">
                                    <Field
                                      type="text"
                                      name="sourcePublicAddress"
                                      placeholder="Source Public Address"
                                      id="sourcePublicAddress"
                                      className="form-control"
                                      disabled={true}
                                    />
                                    <label className="ps-0 pb-0">
                                      Source Public Address
                                    </label>
                                    <div
                                      className={
                                        errors.sourcePublicAddress &&
                                        touched.sourcePublicAddress
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="SourcePublicAddress"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-6 mb-3">
                                <div class="mb-5">
                                  <div class="input-box form-floating">
                                    <Field
                                      type="text"
                                      name="targetPublicAddress"
                                      placeholder="Target Public Address"
                                      id="targetPublicAddress"
                                      className="form-control"
                                      value={targetToken}
                                    />
                                    <label className="ps-0 pb-0">
                                      Target Public Address
                                    </label>
                                    <div
                                      className={
                                        errors.LedgerPublicAddress &&
                                        touched.LedgerPublicAddress
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="targetPublicAddress"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6 ">
                                <div className="d-flex me-0 me-md-4 me-xxl-5 justify-content-end">
                                  <button
                                    type="button"
                                    class="btn cancel-btn mb-3 mb-md-0 me-4"
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    type="submit"
                                    class="btn next-btn mb-3 mb-md-0"
                                    onClick={() => {
                                      // setPrivateModal(true);
                                      TokenSwapData();
                                    }}
                                  >
                                    SUBMIT
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TokenSwap;
