import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrivateId } from "../../../../action";
import { decrypytdata } from "../../../../api/walletKey";
import { AttacheWallet } from "../../../../api/Wallets";
import { success } from "../../../../Components/alert";
import PrivateModal from "../../../../Components/PrivateModal";

function CryptoCurrency() {
  const [data, setData] = useState();
  const publicKeys = useSelector((state) => state.keyReducer);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [privateModal, setPrivateModal] = useState(false);

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
      console.log("🚀 ~ file: Email.js ~ line 22 ~ closeModal ~ APPID", APPID);
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: Email.js ~ line 26 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await AttacheWallet(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      dispatch(setPrivateId(""));
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
      console.log(
        "🚀 ~ file: Beneficiary.js ~ line 39 ~ closeModal ~ jsonData",
        jsonData
      );
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // debugger;
      //   nextstep();
    }
    setPrivateModal(false);
  };
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
      //   fetchdropdwon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const initialValues = {};

  return (
    <>
      {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log("values", values);
              // debugger;
              setData(values);
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <div className="">
                  <Form className="wallet-information-form">
                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Cryptocurrency Type</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="cryptocurrencyTypecode"
                          id="cryptocurrencyTypecode"
                          placeholder="Enter Cryptocurrency Type"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.cryptocurrencyTypecode &&
                            touched.cryptocurrencyTypecode
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="cryptocurrencyTypecode"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Public Wallet Address</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="publicAddress"
                          id="publicAddress"
                          placeholder="Enter Public Wallet Address"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.publicAddress && touched.publicAddress
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="publicAddress"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="d-flex me-0 me-md-4 me-xxl-5">
                      <button
                        type="button"
                        class="btn cancel-btn mb-3 mb-md-0 me-4"
                      >
                        CANCEL
                      </button>
                      <button class="btn next-btn mb-3 mb-md-0" type="submit">
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default CryptoCurrency;
