import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action";
import {  modifyEntityAPI } from "../../../../api/Entity";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
// import PrivateModal from "../../../../Components/PrivateModal";
function ModifyReview({ entityData, previous }) {
  console.log(
    "🚀 ~ file: Review.js ~ line 8 ~ Review ~ entityData",
    entityData
  );
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let APPID = "";
  let APIKEY = "";

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY && entityData) {
      dispatch(showLoader(true));
      const encryptedIssuer = await modifyEntityAPI(APPID, APIKEY, entityData);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      console.log(
        "🚀 ~ file: Review.js ~ line 24 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // const jsonData = JSON.parse(devryptedIssuer);
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        console.log(
          "🚀 ~ file: Review.js ~ line 40 ~ closeModal ~ jsonData",
          jsonData
        );
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
    }
    
    // setPrivateModal(false);
  };
  // useEffect(() => {
  //   closeModal();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [publicKeys]);

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      <div className="row">
        <div className="col-xxl-11">
          <div className="review-wallet review-new-token">
            <div className="container-fluid p-0 ">
              <div className="row m-0">
                <div className="col-md-12 mt-5">
                  <div className="review-wallet-box ms-0">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="review-wallet-info-box mb-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="heading">
                                <h3>Wallet Information</h3>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <hr className="mb-4" />
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Business Information</h6>
                              {/* <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>First Name</h4>
                                  <p>Thomas</p>
                                </div>
                              </div> */}
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Display Name</h4>
                                  <p>{entityData.displayname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Entity Type</h4>
                                  <p>{entityData.entity}</p>
                                </div>
                              </div>
                              {/* <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Industry</h4>
                                  <p>Token</p>
                                </div>
                              </div> */}
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Website</h4>
                                  <p>{entityData.website}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Registration Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Registration Country</h4>
                                  <p>{entityData.registration_Countrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Registration Number</h4>
                                  <p>{entityData.registrationNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Tax ID Number</h4>
                                  <p>{entityData.governmentTaxID}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>USA EIN Number</h4>
                                  <p>{entityData.usA_EIN}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Dialing Country Code</h4>
                                  <p>{entityData.dialingcode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Number</h4>
                                  <p>{entityData.phoneNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Registration Address</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 1</h4>
                                  <p>{entityData.registration_AddressLine1}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 2</h4>
                                  <p>{entityData.registration_AddressLine2}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 2</h4>
                                  <p>{entityData.registration_AddressLine3}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>City</h4>
                                  <p>{entityData.registration_CityTown}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>State</h4>
                                  <p>{entityData.registration_StateProvince}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Postal Code</h4>
                                  <p>{entityData.registration_Postalcode}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Business Address</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 1</h4>
                                  <p>{entityData.addressLine1}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 2</h4>
                                  <p>{entityData.addressLine2}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 3</h4>
                                  <p>{entityData.addressLine3}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>City</h4>
                                  <p>{entityData.cityTown}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>State</h4>
                                  <p>{entityData.stateProvince}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Postal Code</h4>
                                  <p>{entityData.postalcode}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wallet-information-box m-0">
          <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
            <button
              type="button"
              className="btn cancel-btn mb-3 mb-md-0 me-4"
              onClick={previous}
            >
              PREVIOUS
            </button>
            <button
              type="submit"
              className="btn next-btn mb-3 mb-md-0"
              // onClick={nextstep}
              onClick={() => {
                // setPrivateModal(true);
                closeModal();
                console.log("hello");
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModifyReview;
