import React from "react";
import SanctionsInfo from "../Sanctions/Create/SanctionsInfo";

function CreateSanctions() {
  
    return (
        <>
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="ledger-information transfer-rates-page ms-lg-4 ms-xl-5">
                    <div className="container-fluid p-0">
                        <div className="main-heading mb-4">
                            <h3>Compliance</h3>
                            <h6>Create Money Transfer Limits</h6>
                            <p>This function enables limits on how much a person or entity can send or receive in a specified time period.</p>
                        </div>

                        <div className="wallet-information-box transfer-rates m-0">
                            <div className="row">
                                <div className="col-xl-10">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                <div className="domestic">
                                                    <i class="bi bi-person-fill"></i>
                                                </div>
                                                <h3>Single Data Entry</h3>
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                                <div className="domestic">
                                                    <i class="bi bi-people-fill"></i>
                                                </div>
                                                <h3>Batch Upload</h3>

                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <SanctionsInfo />
                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className=" wallet-information-box m-0">
                                                <div className=" wallet-information-form mt-4">
                                                    <h6>Batch Upload Sanctions</h6>
                                                    <div className="review-wallet-box  m-0">
                                                        <div className="row">
                                                            <div className="col-sm-6 col-xl-4">
                                                                <div className="mb-5">
                                                                    <label className="mb-2">Attach Documents (optional)</label>
                                                                    <div class="input-box">
                                                                        <div class="input-group custom-file-button">
                                                                            <input type="file" class="form-control" id="inputGroupFile" />
                                                                            <label class="input-group-text" for="inputGroupFile">BROWSE</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-11">
                                                                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                                                    <button
                                                                        type="button"
                                                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                                    >
                                                                        CANCEL
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn next-btn mb-3 mb-md-0"
                                                                    >
                                                                        SUBMIT
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateSanctions;