import React from "react";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../action/index";
// import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "./../../../../Components/alert";
import { insertBranch } from "../../../../api/branches";
import { useNavigate } from "react-router-dom";

function ReviewBranch({ branchData, previous }) {
  // let { pathname } = useLocation();

  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await insertBranch(APPID, APIKEY, branchData);
      console.log(
        "🚀 ~ file: ReviewBranch.js ~ line 31 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: ReviewBranch.js ~ line 32 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      console.log(
        "🚀 ~ file: ReviewBranch.js ~ line 33 ~ closeModal ~ devryptedIssuer",
        JSON.parse(devryptedIssuer)
      );
      // debugger;
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
      dispatch(setPrivateId(""));
      // debugger;
    }
    dispatch(showLoader(false));
  };

  return (
    <>
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="container-fluid p-0 ">
          <div className="row ms-lg-4 ms-xl-5 ">
            {/* <div className="row m-0"> */}
            {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            </div> */}

            <div className="col-12">
              <div className="main-heading">
                <h3>Branch</h3>
                <h6>Create Branch</h6>
                <p>This function creates a Branch for an Issuer.</p>
              </div>
              <div className="sub-heading"></div>
              <div className="review-wallet-box m-0">
                <div className="row">
                  <div className="col-xxl-11">
                    <div className="review-wallet-info-box mb-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            <h3>Branches Information</h3>
                            <i
                              className="bi bi-pencil-square"
                              // onClick={() => {
                              //   previous();
                              // }}
                            ></i>
                          </div>
                          <hr className="mb-4" />
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Basic Information</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Branches</h4>
                              <p>{branchData?.Branches}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Display Name</h4>
                              <p>{branchData?.displayname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Branch Type</h4>
                              <p>{branchData?.issuerTypecode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Branch Code</h4>
                              <p>{branchData?.issuercode}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Institution </h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Institution Typecode</h4>
                              <p>{branchData?.institutionTypecode}</p>
                            </div>
                          </div>
                          {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Department Typecode</h4>
                            <p>{branchData?.departmentTypecode}</p>
                          </div>
                        </div> */}
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Office Typecode</h4>
                              <p>{branchData?.officeTypecode}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Identifier</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Location Identifier</h4>
                              <p>{branchData?.accuityID}</p>
                            </div>
                          </div>
                          {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Native Identifier</h4>
                            <p>{branchData?.NativeIdentifier}</p>
                          </div>
                        </div> */}
                          {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>UUID</h4>
                            <p>{branchData?.uuid}</p>
                          </div>
                        </div> */}
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Additional Informaiton</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Incorporation Number</h4>
                              <p>{branchData?.incorporationNumber}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Federal Tax ID</h4>
                              <p>{branchData?.federal_TaxID}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>ISO LEI</h4>
                              <p>{branchData?.isO_LEI}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Basic Address Information</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Country Code</h4>
                              <p>{branchData?.countrycode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>State Province</h4>
                              <p>{branchData?.stateProvince}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>City Town</h4>
                              <p>{branchData?.cityTown}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Address</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Postalcode</h4>
                              <p>{branchData?.postalCode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Address Line 1</h4>
                              <p>{branchData?.addressLine1}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Address Line 2</h4>
                              <p>{branchData?.addressLine2}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="review-wallet-info-box mb-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            <h3>Primary Contact Information</h3>
                            <i className="bi bi-pencil-square"></i>
                          </div>
                          <hr className="mb-4" />
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Primary Contact</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>First Name</h4>
                              <p>{branchData?.firstname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Middle Name</h4>
                              <p>{branchData?.secondFirstname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Last Name</h4>
                              <p>{branchData?.lastname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Second Last Name</h4>
                              <p>{branchData?.secondLastname}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Contact Information </h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Dialing Code</h4>
                              <p>{branchData?.mobileDialingcode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Phone Number</h4>
                              <p>{branchData?.mobilePhone}</p>
                            </div>
                          </div>
                          {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Extension</h4>
                            <p>{branchData?.extension}</p>
                          </div>
                        </div> */}
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Email Address</h4>
                              <p>{branchData?.email}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Primary Contact Account Information</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Job Title</h4>
                              <p>{branchData?.jobTitle}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Username</h4>
                              <p>{branchData?.username}</p>
                            </div>
                          </div>
                          {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Password</h4>
                            <p>Password</p>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-11 p-0">
                  <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                    <button
                      type="button"
                      className="btn cancel-btn mb-3 mb-md-0 me-4"
                      onClick={() => {
                        previous();
                      }}
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      className="btn next-btn mb-3 mb-md-0"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default ReviewBranch;

// import React, { useState, useEffect } from "react";
// import { setPrivateId } from "../../../../action/index";
// import { useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// // import { insertBranch } from "../../../../api/branches";
// // import { decrypytdata } from "../../../../api/walletKey";
// import PrivateModal from "./../../../../Components/PrivateModal";

// // import { success } from "./../../../../Components/alert";

// function ReviewBranch({ branchData, previous, cleandata }) {
//   let { pathname } = useLocation();
//   const [privateModal, setPrivateModal] = useState(false);
//   const publicKeys = useSelector((state) => state.keyReducer);
//   let APPID = "";
//   let APIKEY = "";
//   const dispatch = useDispatch();
//   const closeModal = async () => {
//     if (publicKeys.publicKey) {
//       APPID = publicKeys.publicKey.publicAddress;
//     }
//     if (publicKeys.privateKey) {
//       APIKEY = publicKeys.privateKey.PrivateWalletAddress;
//     }
//     if (APPID && APIKEY) {
//       // const encryptedIssuer = await insertBranch(APPID, APIKEY, branchData);
//       // const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
//       // const jsonData = JSON.parse(devryptedIssuer);
//       // if (jsonData.returnMessage) {
//       //   success(jsonData.returnMessage);
//       // }
//       // dispatch(setPrivateId(""));
//       // debugger;
//     }
//     setPrivateModal(false);
//   };
//   useEffect(() => {
//     closeModal();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [publicKeys]);
//   return (
//     <>
//       <>
//         {privateModal && (
//           <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
//         )}
//         <div className="col-md-9 col-lg-9 col-xxl-10">
//           <div className="container-fluid p-0 ">
//             {/* <div className="row m-0"> */}
//             {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
//             </div> */}

//             <div className="col-12">
//               <div className="main-heading">
//                 <i className="bi bi-arrow-left"></i>
//                 <h3>Branches</h3>
//               </div>
//               <div className="sub-heading">
//                 <h3>
//                   {pathname === "/webwallet/modifyissuer"
//                     ? "Modify Issuer"
//                     : "Create Issuer"}
//                 </h3>
//                 <p>
//                   This function establishes a financial institution on the
//                   Unicoin network capable of creating customer wallets.
//                 </p>
//               </div>
//               <div className="review-wallet-box">
//                 <div className="row">
//                   <div className="col-xxl-11">
//                     <div className="review-wallet-info-box mb-5">
//                       <div className="row">
//                         <div className="col-12">
//                           <div className="heading">
//                             <h3>Branches Information</h3>
//                             <i
//                               className="bi bi-pencil-square"
//                               onClick={() => {
//                                 previous();
//                                 dispatch(setPrivateId(""));
//                               }}
//                             ></i>
//                           </div>
//                           <hr className="mb-4" />
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Basic Information</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Branches</h4>
//                               <p>{branchData.Branches}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Display Name</h4>
//                               <p>{branchData.displayname}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Branch Type</h4>
//                               <p>{branchData.BranchTypeCode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Branch Code</h4>
//                               <p>{branchData.brachescode}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Institution </h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Institution Typecode</h4>
//                               <p>{branchData.institutionTypecode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Department Typecode</h4>
//                               <p>{branchData.departmentTypecode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Office Typecode</h4>
//                               <p>{branchData.officeTypecode}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Identifier</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Location Identifier</h4>
//                               <p>{branchData.LocationIdentifier}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Native Identifier</h4>
//                               <p>{branchData.NativeIdentifier}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>UUID</h4>
//                               <p>{branchData.uuid}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Additional Informaiton</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Incorporation Number</h4>
//                               <p>{branchData.incorporationNumber}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Federal Tax ID</h4>
//                               <p>{branchData.federal_TaxID}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>ISO LEI</h4>
//                               <p>{branchData.isO_LEI}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Basic Address Information</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Country Code</h4>
//                               <p>{branchData.countrycode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>State Province</h4>
//                               <p>{branchData.stateProvince}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>City Town</h4>
//                               <p>{branchData.cityTown}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Address</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Postalcode</h4>
//                               <p>{branchData.postalCode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Address Line 1</h4>
//                               <p>{branchData.addressLine1}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Address Line 2</h4>
//                               <p>{branchData.addressLine2}</p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="review-wallet-info-box mb-5">
//                       <div className="row">
//                         <div className="col-12">
//                           <div className="heading">
//                             <h3>Primary Contact Information</h3>
//                             <i className="bi bi-pencil-square"></i>
//                           </div>
//                           <hr className="mb-4" />
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Primary Contact</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>First Name</h4>
//                               <p>{branchData.firstName}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Middle Name</h4>
//                               <p>{branchData.middlename}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Last Name</h4>
//                               <p>{branchData.lastName}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Second Last Name</h4>
//                               <p>{branchData.secondLastname}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Contact Information </h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Dialing Code</h4>
//                               <p>{branchData.mobileDialingcode}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Phone Number</h4>
//                               <p>{branchData.mobilePhone}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Extension</h4>
//                               <p>{branchData.extension}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Email Address</h4>
//                               <p>{branchData.email}</p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-sm-6 col-lg-4 col-xl-3">
//                           <h6>Primary Contact Account Information</h6>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Job Title</h4>
//                               <p>{branchData.jobTitle}</p>
//                             </div>
//                           </div>
//                           <div className="wallet-list">
//                             <i className="bi bi-check"></i>
//                             <div>
//                               <h4>Username</h4>
//                               <p>{branchData.username}</p>
//                             </div>
//                           </div>
//                           {/* <div className="wallet-list">
//                           <i className="bi bi-check"></i>
//                           <div>
//                             <h4>Password</h4>
//                             <p>Password</p>
//                           </div>
//                         </div> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-md-11 p-0">
//                   <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//                     <button
//                       type="button"
//                       className="btn cancel-btn mb-3 mb-md-0 me-4"
//                       onClick={() => {
//                         cleandata();
//                         dispatch(setPrivateId(""));
//                         previous();
//                       }}
//                     >
//                       CANCEL
//                     </button>
//                     <button
//                       type="button"
//                       className="btn next-btn mb-3 mb-md-0"
//                       onClick={() => {
//                         setPrivateModal(true);
//                       }}
//                     >
//                       SUBMIT
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* </div> */}
//       </>
//       {/* </div> */}
//     </>
//   );
// }

// export default ReviewBranch;
