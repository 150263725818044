import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";

// import * as Yup from "yup";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { getCountryCodeDropDown, getDailingDropDown, getDropdownvalues } from "../../../../api/dropdown";


function ModifyOriginator({ nextstep, setdata }) {
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const [enityTypedropdown, setenityTypedropdown] = useState([]);
    const [worldcurrency, setWorldcurrency] = useState([]);
    const [GetDialingcode, setGetDialingcode] = useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);

    const fetchdropdwon = async () => {
        let LKEntityClass = await getDropdownvalues("LKEntityType");
        setenityTypedropdown(LKEntityClass);
        let LKCountryCode = await getCountryCodeDropDown("GetWorldCurrency");
        setWorldcurrency(LKCountryCode);
        let LKDailingCodes = await getDailingDropDown("GetDialingcodes");
        setGetDialingcode(LKDailingCodes);
    };

    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);
    const initialValues = {
        nativeID: "",
        EntityTypecode: 1,
        Entity: "",
        displayname: "",
        registrationNumber: "",
        governmentTaxID: "",
        usA_EIN: "",
        isO_LEI: "",
        registration_Countrycode: "",
        registration_StateProvince: "",
        registration_CityTown: "",
        registration_Postalcode: "",
        registration_AddressLine1: "",
        registration_AddressLine2: "",
        registration_AddressLine3: "",
        countrycode: "",
        stateProvince: "",
        cityTown: "",
        postalcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        dialingcode: "",
        phoneNumber: "",
        website: "",
    };
    const validationSchema = Yup.object({
        Entity: Yup.string().required("Enitity is required!"),
        displayname: Yup.string().required("Display Name is required!"),
        governmentTaxID: Yup.string().required("Tax ID  is required!"),
        registration_Countrycode: Yup.string().required(
            "Country Code is required!"
        ),
    });
    return (
        <>
            {isValidPublicAddress && (
                <div className="row">
                    <div className="col-12 mt-5">
                        <div className="originating-account link-wallet-box create-page ms-0">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    setdata(values);
                                    console.log(
                                        "🚀 ~ file: Originator.js ~ line 53 ~ Originator ~ values",
                                        values
                                    );
                                    // debugger;
                                    nextstep();
                                }}
                            >
                                {(formik) => {
                                    const { errors, touched, setFieldValue } = formik;
                                    return (
                                        <div className="wallet-information-box  m-0">
                                            <Form className="wallet-information-form">
                                                <div className="row mb-xl-5">
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Business Information</h6>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="TokenClass"
                                                                    id="TokenClass"
                                                                    placeholder="Business Name"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Business Name</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="displayname"
                                                                    id="displayname"
                                                                    placeholder="Alias or Display Name"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Alias or Display Name</label>
                                                                <div
                                                                    className={
                                                                        errors.displayname && touched.displayname
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                ></div>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="displayname"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Entity Type</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    as="select"
                                                                    id="Entity"
                                                                    name="Entity"
                                                                    className="form-select form-control"
                                                                    onChange={(e) => {
                                                                        setFieldValue("Entity", e.target.value);
                                                                        console.log(
                                                                            "🚀 ~ file: Originator.js ~ line 117 ~ Originator ~ e.target.value",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.Entity}
                                                                >
                                                                    {enityTypedropdown &&
                                                                        enityTypedropdown.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.keycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.displayName}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div
                                                                    className={
                                                                        errors.Entity && touched.Entity
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="Entity"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-5">
                                                            <label>Industry</label>
                                                            <Field
                                                                as="select"
                                                                id="decimalPlaces"
                                                                name="decimalPlaces"
                                                                className="form-select form-control"
                                                            >
                                                                <option defaultValue>--Select--</option>
                                                                <option value="1">Two</option>
                                                                <option value="2">Three</option>
                                                                <option value="3">Four</option>
                                                                <option value="4">Five</option>
                                                                <option value="5">six</option>
                                                            </Field>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="website"
                                                                    id="website"
                                                                    placeholder="Website"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Website</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Registration Information </h6>

                                                        <div className="mb-5">
                                                            <label>Registration Country</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    as="select"
                                                                    id="registration_Countrycode"
                                                                    name="registration_Countrycode"
                                                                    className="form-select form-control"
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "registration_Countrycode",
                                                                            e.target.value
                                                                        );
                                                                        console.log(
                                                                            "🚀 ~ file: Originator.js ~ line 197 ~ Originator ~ e.target.value",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.registration_Countrycode}
                                                                >
                                                                    {worldcurrency &&
                                                                        worldcurrency.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.countrycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.isoCountryname}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div
                                                                    className={
                                                                        errors.registration_Countrycode &&
                                                                            touched.registration_Countrycode
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="registration_Countrycode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Dailing Codes</label>
                                                            <Field
                                                                as="select"
                                                                id="dialingcode"
                                                                name="dialingcode"
                                                                className="form-select form-control"
                                                                onChange={(e) => {
                                                                    setFieldValue("dialingcode", e.target.value);
                                                                    console.log(
                                                                        "🚀 ~ file: Originator.js ~ line 248 ~ Originator ~ e.target.value",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.registration_Countrycode}
                                                            >
                                                                {GetDialingcode &&
                                                                    GetDialingcode.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayname}
                                                                                </option>
                                                                            </>
                                                                        );
                                                                    })}
                                                            </Field>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registrationNumber"
                                                                    id="registrationNumber"
                                                                    placeholder="Registration Number"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Registration Number</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="governmentTaxID"
                                                                    id="governmentTaxID"
                                                                    placeholder="Alias or Display Name"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Alias or Display Name</label>
                                                                <div
                                                                    className={
                                                                        errors.governmentTaxID &&
                                                                            touched.governmentTaxID
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                ></div>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="governmentTaxID"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="usA_EIN"
                                                                    id="usA_EIN"
                                                                    placeholder="USA EIN Number"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">USA EIN Number </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Registration Address</h6>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_AddressLine1"
                                                                    id="registration_AddressLine1"
                                                                    placeholder="Address Line 1"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 1</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_AddressLine2"
                                                                    id="registration_AddressLine2"
                                                                    placeholder="Address Line 2"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 2</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_CityTown"
                                                                    id="registration_CityTown"
                                                                    placeholder="City"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">City </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_StateProvince"
                                                                    id="registration_StateProvince"
                                                                    placeholder="State"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">State </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_Postalcode"
                                                                    id="registration_Postalcode"
                                                                    placeholder="Postal Code"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Postal Code </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Business Address</h6>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="addressLine1"
                                                                    id="addressLine1"
                                                                    placeholder="Address Line 1"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 1</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="addressLine2"
                                                                    id="addressLine2"
                                                                    placeholder="Address Line 2"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 2</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="cityTown"
                                                                    id="cityTown"
                                                                    placeholder="City"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">City </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box  form-floating    ">
                                                                <Field
                                                                    type="text"
                                                                    name="stateProvince"
                                                                    id="stateProvince"
                                                                    placeholder="State"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">State </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="postalcode"
                                                                    id="postalcode"
                                                                    placeholder="Postal Code"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Postal Code </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                    <button
                                                        type="button"
                                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                        onClick={() => nextstep()}
                                                    >
                                                        Skip
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                    >
                                                        CANCEL
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn next-btn mb-3 mb-md-0"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModifyOriginator;
