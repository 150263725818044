
import React from 'react'

const Download = () => {
    return (
        <>
            <div className="download-page pb-5">
                <div className="containe-fluid">
                    <img src="../images/download-img.jpg" className="img-fluid" alt="download-img" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="download-head">
                                <h3>Downloads</h3>
                                <h6>Web Wallet Downloads</h6>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="download">
                                <div className="windows-box">
                                    <div className="windows">
                                        <div>
                                            <img src="../icons/windows-icon.png" alt="windows-icon" />
                                        </div>
                                        <div>
                                            <h3>Windows</h3>
                                            <p>Windows</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="windows-box">
                                    <div className="windows">
                                        <div>
                                            <img src="../icons/apple-icon.png" alt="apple-icon" />
                                        </div>
                                        <div>
                                            <h3>Mac</h3>
                                            <p>OS</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="windows-box">
                                    <div className="windows align-items-start me-md-0">
                                        <div>
                                            <img src="../icons/linux-icon.png" alt="Linux-icon" />
                                        </div>
                                        <div className="mt-4">
                                            <h3>Linux</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Download