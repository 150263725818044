import React from 'react';
function DifferenceTable() {
    return (
        <>
            <div className="difference">
                <div className="container">
                    <div className="difference-table">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="difference-table-box">
                                    <div className="difference-table-icon">
                                        <img src="icons/Physical Cash icon.png" alt="" />
                                        <h3>Physical Cash</h3>
                                    </div>
                                    <h4>Point to Point</h4>
                                    <p>All transactions between two parties are considered peer-to-peer. However, a point-to-point payment is the direct exchange of value between cold or hardware wallets without recording on a network ledger. </p>
                                
                                    <h4>Private</h4>
                                    <p>Only the two parties involved in the exchange of value should know about the financial transaction.</p>

                                    <h4>Anonymous</h4>
                                    <p>Point-to-Point transactions are not recorded on any intermediary ledger and are fully private and anonymous. Peer-to-Peer protocols, such as Bitcoin, are recorded on public ledgers and are not anonymous.</p>

                                    <h4>Self Governance</h4>
                                    <p>Just like physical cash, only the person or entity in possession of the cash has the responsibility to protect and secure their funds. If the device is lost or stolen, the digital currency cannot be recovered.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="difference-table-box">
                                    <div className="difference-table-icon ">
                                        <img src="icons/Electronic Cash icon.png" alt="" />
                                        <h3>Electronic Cash</h3>
                                    </div>
                                    <div className="border">
                                    <h4>KYC Enabled</h4>
                                    <p>Financial institutions should know if their customers have the proper licenses to operate the business they are supporting through their financial systems. And more importantly, in any instance of fraud, theft, or money laundering, the financial institution should be able to identify their transacting party associated with such transactions</p>

                                    <h4>Traceability</h4>
                                    <p>In order to maintain a sovereign monetary system, financial institutions, governments, central banks, and law enforcement must be able to identify, monitor, and trace suspicious and criminal transactions.</p>

                                    <h4>Private / Anonymous</h4>
                                    <p>Financial institutions are required to protect their customer data and to ensure their transactions are conducted with privacy and anonymity from other customers and financial partners.</p>

                                    <h4>Intermediary Governance</h4>
                                    <p>Financial institutions are required to provide a governance framework over their operations to ensure their financial systems are transacted with financial integrity, data protection and security. Intermediaries also provide research and dispute resolution services in the event of potential fraud.</p>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="difference-table-box">
                                    <div className="difference-table-icon">
                                        <img src="icons/Store of Value icon.png" alt="" />
                                        <h3>Store of Value</h3>
                                    </div>
                                    <h4>Fixed Store Of Value</h4>
                                    <p>The Unicoin network offers a portfolio of cash-based digital assets (CBDAs) for each national currency. As more banks join the Unicoin network a CBDA could be launched for every country worldwide.</p>

                                    <h4>Income-Producing Store Of Value</h4>
                                    <p>Income-producing store of value are digitalized money market products yielding an annual return with no risk to principle such as savings accounts, certificates of deposit, and investment grade bonds.</p>
                                    <p>Central banks and banks can issue income-producing store of value tokens on the Unicoin network.</p>

                                    <h4>Digital Cash Store of Value</h4>
                                    <p>Digital Cash which is are designed as a monetary system and are expected to preserve its value over time is considered a cryptocurrency store of value digital asset.</p>
                                    <p>A digital cash store of value digital asset is considered a money commodity and may experience pricing volatility like all commodities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DifferenceTable;