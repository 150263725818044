import React from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";

function Balances() {
  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            <Sidebar />
          </div>
          <div className="col-md-9 col-lg-9 col-xxl-10">
            <div className="balance-page">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-9 mb-5">
                    <div className="balance-head">
                      <h3>Balance</h3>
                      <p>
                        Creates a new store of value digital asset for savings,
                        commerce and trade.
                      </p>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-auto mb-4">
                        <div className="balance-box">
                          <div className="account">
                            <h6>Cash Account</h6>
                          </div>
                          <img
                                                      src="../images/coins/U-1.png"
                            className=""
                            alt="U-1-img"
                          ></img>
                          <h5>UNC</h5>
                          <h3>Ü 5900.000000</h3>
                          <div className="account-details mb-3">
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/deposit.png"
                                  className=""
                                  alt="deposit-img"
                                ></img>
                              </div>
                              <p>Deposit</p>
                            </div>
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/withdraw.png"
                                  className=""
                                  alt="withdraw-img"
                                ></img>
                              </div>
                              <p>Withdraw</p>
                            </div>
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/transfer.png"
                                  className=""
                                  alt="transfer-img"
                                ></img>
                              </div>
                              <p>Transfer</p>
                            </div>
                          </div>
                          <div className="account-details">
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/ctivity.png"
                                  className=""
                                  alt="ctivity-img"
                                ></img>
                              </div>
                              <p>Activity</p>
                            </div>
                            <div className="account-details-round light">
                              <div className="round">
                                <img
                                  src="../icons/FX.png"
                                  className=""
                                  alt="FX-img"
                                ></img>
                              </div>
                              <p>FX Rates</p>
                            </div>
                            <div className="account-details-round light">
                              <div className="round">
                                <img
                                  src="../icons/update.png"
                                  className=""
                                  alt="update-img"
                                ></img>
                              </div>
                              <p>Update</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto  mb-4">
                        <div className="balance-box">
                          <div className="account red">
                            <h6>Yield Account</h6>
                          </div>
                          <img
                                                      src="../images/coins/U-1.png"
                            className=""
                            alt="U-1-img"
                          ></img>
                          <h5>UNC</h5>
                          <h3>Ü 5900.000000</h3>
                          <div className="account-details mb-3">
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/deposit.png"
                                  className=""
                                  alt="deposit-img"
                                ></img>
                              </div>
                              <p>Deposit</p>
                            </div>
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/withdraw.png"
                                  className=""
                                  alt="withdraw-img"
                                ></img>
                              </div>
                              <p>Withdraw</p>
                            </div>
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/transfer.png"
                                  className=""
                                  alt="transfer-img"
                                ></img>
                              </div>
                              <p>Transfer</p>
                            </div>
                          </div>
                          <div className="account-details">
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/ctivity.png"
                                  className=""
                                  alt="ctivity-img"
                                ></img>
                              </div>
                              <p>Activity</p>
                            </div>
                            <div className="account-details-round light">
                              <div className="round">
                                <img
                                  src="../icons/FX.png"
                                  className=""
                                  alt="FX-img"
                                ></img>
                              </div>
                              <p>FX Rates</p>
                            </div>
                            <div className="account-details-round light">
                              <div className="round">
                                <img
                                  src="../icons/update.png"
                                  className=""
                                  alt="update-img"
                                ></img>
                              </div>
                              <p>Update</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto mb-4">
                        <div className="balance-box ">
                          <div className="account yellow">
                            <h6>Savings Account</h6>
                          </div>
                          <img
                                                      src="../images/coins/U-1.png"
                            className=""
                            alt="U-1-img"
                          ></img>
                          <h5>UNC</h5>
                          <h3>Ü 5900.000000</h3>
                          <div className="account-details mb-3">
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/deposit.png"
                                  className=""
                                  alt="deposit-img"
                                ></img>
                              </div>
                              <p>Deposit</p>
                            </div>
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/withdraw.png"
                                  className=""
                                  alt="withdraw-img"
                                ></img>
                              </div>
                              <p>Withdraw</p>
                            </div>
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/transfer.png"
                                  className=""
                                  alt="transfer-img"
                                ></img>
                              </div>
                              <p>Transfer</p>
                            </div>
                          </div>
                          <div className="account-details">
                            <div className="account-details-round">
                              <div className="round">
                                <img
                                  src="../icons/ctivity.png"
                                  className=""
                                  alt="ctivity-img"
                                ></img>
                              </div>
                              <p>Activity</p>
                            </div>
                            <div className="account-details-round light">
                              <div className="round">
                                <img
                                  src="../icons/FX.png"
                                  className=""
                                  alt="FX-img"
                                ></img>
                              </div>
                              <p>FX Rates</p>
                            </div>
                            <div className="account-details-round light">
                              <div className="round">
                                <img
                                  src="../icons/update.png"
                                  className=""
                                  alt="update-img"
                                ></img>
                              </div>
                              <p>Update</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Balances;
