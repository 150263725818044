//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function IssuerServices() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/issuer-services.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Issuer Services</p>
                            <h1>Admininstrative <br className="d-none d-md-block" /> Controls for Full   <br className="d-none d-md-block" /> Service Issuer <br className="d-none d-md-block" />Management</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IssuerServices;