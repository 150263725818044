/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../api/Login";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email().required("Email required"),
});

const ForgetPassword = () => {
    let navigate = useNavigate();

  return (
    <>
      <div className="container-fluid">
        <div className="login-page">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const response = await forgotPassword(values);
              if (response === "true") {
                navigate("/newCreadential", { state: values });
              }
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <div className="row align-items-center">
                  <div className="col-md-7 col-lg-8 p-0">
                    <div className="login-img">
                      <img
                        src="../images/dl.beatsnoop2.png"
                        className="img-fluid"
                        alt="login-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4">
                    <div className="login-box">
                      <div className="login-text">
                                  <img src="../images/coins/U-1.png" alt="logo" />
                        <h3>Forgot Password</h3>
                        <Form className="login-form">
                          <div className="mb-3 mb-md-5">
                            <label className="form-label">
                              Email Address
                            </label>
                            <div className="password-eye">
                              <Field
                                type="email"
                                name="email"
                                className="form-control"
                                id="email"
                              />
                            </div>
                            <div
                              className={
                                errors.email && touched.email
                                  ? "input-error"
                                  : null
                              }
                            >
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <button type="submit" id="btn">
                            Forgot Password
                          </button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
