import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import Failure from "./Failure";
import Success from "./Success";
function Result() {
  const select = useSelector((state) => state);

  const temp = () => {
    console.log(select.resultReducer, "result");
  };
  useEffect(() => {
    temp();
    // eslint-disable-next-line
  }, [select]);

  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            <Sidebar />
          </div>
          {select.EncryptDataReducer.data.status === 200 && (
            <Success resultDatas={select} />
          )}
          {(select.EncryptDataReducer.data.status === 500 ||
            select.EncryptDataReducer.data.status === 400) && (
            <Failure resultDatas={select} />
          )}
        </div>
      </div>
    </>
  );
}

export default Result;

// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import Header from "../../Components/Header";
// import Sidebar from "../../Components/Sidebar";

// function Result() {
//   const select = useSelector((state) => state);

//   const [resultData, setResultData] = useState([]);
//   const [encryptData, setencryptData] = useState([]);
//   const [selectData, setselectData] = useState([]);

//   useEffect(() => {
//     if (select.resultReducer.data) {
//       setResultData(JSON.parse(select.resultReducer.data.commandResponse));
//       setencryptData(select.EncryptDataReducer);
//       setselectData(select.resultReducer)
//     }
//   }, [select]);
//   return (
//     <>
//       <Header />
//       <div className="container-fluid p-0">
//         <div className="row m-0">
//           <div className="col-md-3 col-lg-3 col-xxl-2">
//             <Sidebar />
//           </div>
//           {select.EncryptDataReducer.data.status === 200 ? (
//             <>
//             {/* {console.log("status-SUCCESS====",EncryptDataReducer.data.status)} */}
//               <div className="col-md-9 col-lg-9 col-xxl-10 d-flex">
//                 <div className="review-wallet-box deposit-funds-tabs ">
//                   <h6 className="my-5">Transaction Result</h6>
//                   <div className="row">
//                     <div className="col-xxl-11">
//                       <div className="review-wallet-info-box mb-5">
//                         <div className="row">
//                           <div className="col-12">
//                             <div className="heading">
//                               <div className="money-heading">
//                                 <h3>
//                                   Transaction
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].TxHash}
//                                   </p>
//                                 </h3>
//                               </div>
//                               <i className="bi bi-pencil-square"></i>
//                             </div>
//                             <hr className="mb-4" />
//                           </div>
//                           <div className="col-12">
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Command
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className=" col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {" "}
//                                     {resultData.length > 0 &&
//                                       resultData[0].Command}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>
//                                     Command Type
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <h6>
//                                     <span></span>
//                                     {resultData.length > 0 &&
//                                       resultData[0].lk1[0].CommandType}
//                                   </h6>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Object Code
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].lk1[0].o[0].Objectcode}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Object Name
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].lk1[0].o[0].Objectname}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Node
//                                     Network
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       encryptData.data.config.baseURL}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>End Point
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       encryptData.data.config.url}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Network
//                                     Response Code
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       encryptData.data.status}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Network
//                                     Response Message
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       selectData.data.returnMessage}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>

//                             <hr className="mb-4" />
//                           </div>
//                           <div className="col-12">
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Public
//                                     Address
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       selectData.data.publicAddress}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Private Key
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       selectData.data.privateKey}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Return
//                                     Message
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       selectData.data.returnMessage}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Return
//                                     Keycode
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       selectData.data.returnKeycode}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Return Code
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       selectData.data.returncode}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <hr className="mb-4" />
//                           </div>
//                           <div className="col-12">
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>TxHash
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].TxHash}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>
//                                     Status
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].lk1[0].o[0].lk2[0].Status}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Block
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].BlockID}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Time Stamp
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {resultData.length > 0 &&
//                                       resultData[0].Datetimestamp}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i> Network
//                                     Fee
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     Ü
//                                     {resultData.length > 0 &&
//                                       resultData[0].NetworkFee}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <hr className="mb-4" />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <>
//               <div className="col-md-9 col-lg-9 col-xxl-10 d-flex">
//                 <div className="review-wallet-box deposit-funds-tabs ">
//                   <h6 className="my-5">Transaction Result</h6>
//                   <div className="row">
//                     <div className="col-xxl-11">
//                       <div className="review-wallet-info-box mb-5">
//                         <div className="row">
//                           <div className="col-12">
//                             <div className="heading">
//                               <div className="money-heading">
//                                 <h3>
//                                   Transaction
//                                   <p>
//                                     {/* {resultData.length > 0 && resultData[0].TxHash} */}
//                                   </p>
//                                 </h3>
//                               </div>
//                               <i className="bi bi-pencil-square"></i>
//                             </div>
//                             <hr className="mb-4" />
//                           </div>
//                           <div className="col-12">
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Command
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className=" col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].Command} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>
//                                     Command Type
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <h6>
//                                     <span></span>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].lk1[0].CommandType} */}
//                                   </h6>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Object Code
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].lk1[0].o[0].Objectcode} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Object Name
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].lk1[0].o[0].Objectname} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Node
//                                     Network
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       encryptData.data.config.baseURL} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>End Point
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       encryptData.data.config.url} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Network
//                                     Response Code
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       encryptData.data.status} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Network
//                                     Response Message
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       selectData.data.returnMessage} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>

//                             <hr className="mb-4" />
//                           </div>
//                           <div className="col-12">
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Public
//                                     Address
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       selectData.data.publicAddress} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Private Key
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       selectData.data.privateKey} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Return
//                                     Message
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {selectData.length > 0 &&
//                                       selectData.data.returnMessage}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Return
//                                     Keycode
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       selectData.data.returnKeycode} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Return Code
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       selectData.data.returncode} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <hr className="mb-4" />
//                           </div>
//                           <div className="col-12">
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>TxHash
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].TxHash} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>
//                                     Status
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].lk1[0].o[0].lk2[0].Status} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Block
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].BlockID} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i>Time Stamp
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].Datetimestamp} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="row mb-4">
//                               <div className="col-md-4 mb-3 mb-md-0">
//                                 <div className="money-transaction-icon">
//                                   <h3>
//                                     <i class="bi bi-question-lg"></i> Network
//                                     Fee
//                                   </h3>
//                                 </div>
//                               </div>
//                               <div className="col-md">
//                                 <div className="money-transaction-text">
//                                   <p>
//                                     Ü
//                                     {/* {resultData.length > 0 &&
//                                       resultData[0].NetworkFee} */}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <hr className="mb-4" />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Result;
