import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function GetPeople(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // uuid: "",
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/GetPeople",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/GetPeople", values, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}

export async function CreatePeople(publickey, privateKey, people) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/CreatePeople",
    people,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/CreatePeople", people, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function ModifyPeople(publickey, privateKey, people) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/ModifyPeople",
    people,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/ModifyPeople", people, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function peopleSuspend(publickey, privateKey, SuspendData) {
  // const modifyIssusersDetails = {
  //   sourcePublicAddress: publickey,
  //   peoplecode: SuspendData.peoplecode,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/SuspendPeople",
    SuspendData,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/SuspendPeople", SuspendData, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}
