
const DLTSection = () => {
  return (
    <section className="section section-5">
      <div className="container text-center">
        <h1 className="large-heading">
          Multi-Dimensional <span>Distributed Ledger Technology</span>
        </h1>
        <p>
          Our multi-dimensional DLT is engineered to meet the robust ledger
          needs for banking and financial services.
        </p>
      </div>
      <div className="container-fluid">
        <img
                  src="../images/Investments-Ledger1.png"
          alt="img"
          className="img-fluid img-fix desktop-img"
        />
        <img
                  src="../images/Investments-Ledger-V.jpg"
          alt="img"
          className="img-fluid mobile-img"
        />
      </div>
    </section>
  );
};
export default DLTSection;
