import Header from "../../Components/Header";
import React from 'react';

function Summit() {
    return (
        <>
            <Header />
            <div className="umbrella summit-page global network digital-page">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/hero-section.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div className="global-text">
                            <h3>1st Annual<br className="d-none d-md-block" />
                                Global Digital Banking Summit
                            </h3>
                            <h5>
                                New Delhi, India
                            </h5>
                            <h6>July 28, 2022</h6>
                            <p>Special Appearance by The Minister of Finance, Nirmala Sitharaman</p>
                        </div>
                    </div>
                </div>
                <div className="summit monetary-policy bg-white py-5">
                        <div className="container">
                            <div className="monetary-policy-question">
                                <h2><span>01</span>1st Annual Global Digital Banking Summit</h2>
                                <p>Cryptocurrencies, such as Bitcoin, are designed to circumvent regulatory governance and to operate outside the control of financial institutions. These first-generation cryptocurrencies pose a serious threat to the central banking monetary system.</p>
                                <p>Despite the inherent risks, private citizens are attracted to cryptocurrency because of its potential wealth creation opportunity. Many citizens in emerging markets are also seeking a store of value asset that preserves value better than their national depreciating currency.</p>
                                <p>Central banks around the world are researching, developing, and piloting the use of central bank digital currency (CBDC) and cash-based digital assets (CBDA) in their monetary systems.</p>
                                <p>It will be challenging for central banks to gain widespread adoption of a CBDC that is decoupled from the wealth creation opportunity and payment efficiencies offered by cryptocurrencies.</p>
                                <p>The Digital Currency Monetary Authority (DCMA) is the world leader in the innovation of digital currency solutions for governments and central banks.</p>
                                <p>The DCMA introduces Crypto 2.0 which incorporates the best practices of both decentralized and centralized governance, regulatory compliance, and cryptocurrency monetary policy controls.</p>
                                <p>The purpose of the 1st Annual Global Digital Banking Summit is to bring Finance Ministry and Central Bank officials from around the world to discuss how to achieve monetary sovereignty with private sector cryptocurrencies and digital assets infiltrating the global banking system.</p>
                                <div className="monetary-policy-question-two my-5">
                                    <h2><span>1.1</span>Session 1 Discussion</h2>
                                    <p>Can central bank independence be preserved with both political and central bank leaders vying for legislative and regulatory influence over cryptocurrencies and digital currencies?</p>
                                    <p>What are the banking implications between a Central Bank Digital Currency (CBDC) and a Cash-backed Digital Asset (CBDA), which is pegged 1-to-1 to a national legal tender?</p>
                                    <p>Is there a role for continental and regional trade zone digital currencies within the global banking system? Trade zone currencies could neutralize FX rates volatility between trading partners.</p>
                                    <p>What are the regulatory, governance, and technological best practices for sustaining a safe and secure coexistence of cryptocurrency and CBDC within the global banking system?</p>
                                    <p>Is there a monetary policy framework that would impact open market trading of cryptocurrency and enable them to qualify as a central bank and member bank cash reserve currency?</p>
                                    
                                </div>
                                <div className="monetary-policy-question-two mb-5">
                                    <h2><span>1.2</span>Session 2 Discussion</h2>
                                    <p>The global banking industry recognizes regulated electronic cash and hand-to-hand physical cash as the two legal forms of societal cash. What are the leading cryptographic solutions for realizing both forms of societal cash for the global digital banking economy?</p>
                                    <p>Can full-service retail and commercial banking operate fully on digital currency rails without the need for real-time integration with traditional fiat banking systems?</p>
                                    <p>What are the leading challenges and solutions for achieving cross-CBDC transfers for facilitating and accelerating global digital trade and digital payments?</p>
                                </div>
                                <div className="monetary-policy-question-two mb-5">
                                    <h2><span>1.3</span>Session 3 Unicoin Presentation</h2>
                                    <p>A presentation and demonstration of the Unicoin global digital financial services network for governments and central banks.</p>
                                    <ul>
                                        <li>
                                            Onboarding Central Banks, Member Banks, and Customers.
                                        </li>
                                        <li>
                                            Multi-Functional Digital Ledger Technology for Full-Service Digital Banking.
                                        </li>
                                        <li>
                                            Enhanced KYC and Sanctions Enforcement
                                        </li>
                                        <li>
                                            Tokenization Framework
                                        </li>
                                        <li>
                                            Smart Wallets Technology.
                                        </li>
                                        <li>
                                            Cross-CBDC Transactions.
                                        </li>
                                        <li>
                                            Digital Trade Escrow and Milestone Payments.
                                        </li>
                                        <li>
                                            Network Monitoring.
                                        </li>
                                        <li>
                                            Privacy and Security.
                                        </li>
                                    </ul>
                                </div>
                                <div className="monetary-policy-question-two">
                                    <h2><span>1.4</span>Depository Interest Rates</h2>
                                    <p>Central banks offer interest rate yields to entice customer deposits and foreign investment in their national currency. With Unicoin, the UMPC will establish yield payout rates for wallet holders to stake Unicoin in the Staked Proof of Trust (SPOT) consensus protocol.</p>
                                    <p>With no risk to principal, SPOT transaction validators could earn up to twenty percent (20%) annually. </p>
                                    <p>The UMPC will establish SPOT yield rates based on the competitive decentralized finance (DeFi) market environment and based on the need to stimulate continuous demand for Unicoin.</p>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/Authority.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text summit-text">
                            <div class="row g-0 justify-content-center">
                        <div class="col-sm-10">
                                    <h2>Hosted by the<br className="d-none d-md-block" />
                                        Digital Currency Monetary Authority</h2>
                                    <p>In cooperation with the Finance Ministry</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a href="https://dcma.io" target="_blank" rel="noreferrer"><img src="../images/dcma_logo 1.png" alt="" /></a>
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summit;