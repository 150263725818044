import React, { useState } from "react";
import Beneficiary from "../Demo/ledgertowallettransfer/Beneficiary";
import Money from "../Demo/ledgertowallettransfer/Money";
import Originator from "../Demo/ledgertowallettransfer/Originator";
import Review from "../Demo/ledgertowallettransfer/Review";

function MoneyTransfer() {
    const [step, setstep] = useState(1);
    const [transferData, setTransferData] = useState(1);
    console.log(
        "🚀 ~ file: Transfer.js ~ line 9 ~ Transfer ~ transferData",
        transferData
    );

    const setdata = (data) => {
        setTransferData(data);
    };

    const nextstep = () => {
        if (step < 4) {
            setstep(step + 1);
        }
    };
    const renderstep = (step) => {
        switch (step) {
            case 1:
                return (
                    <Originator
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                    />
                );
            case 2:
                return (
                    <Beneficiary
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        transferData={transferData}
                    />
                );
            case 3:
                return (
                    <Money
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        transferData={transferData}
                    />
                );
            case 4:
                return (
                    <Review
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        transferData={transferData}
                    />
                );
            default:
                return false;
        }
    };
    const previous = () => {
        if (step > 1) {
            setstep(step - 1);
        }
    };

    return (
        <>
            {/* <Header /> */}

            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0 ">
                    <div className="row ms-lg-4 ms-xl-5 m-0">
                        {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}
                        <div className="col-12">
                            <div className="main-heading">
                                <h3> Money Services</h3>
                                <h6>Money Transfer </h6>
                                <p>Full-Service Digital Banking Money Transfer Service.</p>
                            </div>
                            <div className="deposit-funds-tabs m-0">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li
                                        className={
                                            step > 1
                                                ? "nav-item originator-m-t disableElement"
                                                : "nav-item originator-m-t "
                                        }
                                    >
                                        <button
                                            className={step === 1 ? "nav-link active" : "nav-link"}
                                            id="home-tab"
                                            type="button"
                                        >
                                            <div className="originator">
                                                <h3>1</h3>
                                                <i className="bi bi-check-lg"></i>
                                            </div>
                                            <h6>Payment</h6>
                                        </button>
                                    </li>
                                    <li
                                        className={
                                            step > 2
                                                ? "nav-item beneficiary-m-t disableElement"
                                                : "nav-item beneficiary-m-t"
                                        }
                                    >
                                        <button
                                            className={step === 2 ? "nav-link active" : "nav-link"}
                                            id="profile-tab"
                                            type="button"
                                        >
                                            <div className="beneficiary">
                                                <h3>2</h3>
                                                <i className="bi bi-check-lg"></i>
                                            </div>
                                            <h6>Source</h6>
                                        </button>
                                    </li>
                                    <li
                                        className={
                                            step > 3
                                                ? "nav-item money-m disableElement"
                                                : "nav-item money-m "
                                        }
                                    >
                                        <button
                                            className={step === 3 ? "nav-link active" : "nav-link"}
                                            id="contact-tab"
                                            type="button"
                                        >
                                            <div className="money">
                                                <h3>3</h3>
                                                <i className="bi bi-check-lg"></i>
                                            </div>
                                            <h6>Target</h6>
                                        </button>
                                    </li>
                                    <li
                                        className={
                                            step > 4
                                                ? "nav-item money-m-t disableElement"
                                                : "nav-item money-m-t "
                                        }
                                    >
                                        <button
                                            className={step === 4 ? "nav-link active" : "nav-link"}
                                            id="contact-tab"
                                            type="button"
                                        >
                                            <div className="review">
                                                <h3>4</h3>
                                                <i className="bi bi-check-lg"></i>
                                            </div>
                                            <h6>Review</h6>
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home">
                                        {/* <Originator /> */}
                                        {renderstep(step)}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}
export default MoneyTransfer;
