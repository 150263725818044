import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../../action";

import { GetFXRatesApi } from "../../../api/FxRatesApi";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";
import BoxListing from "./BoxListing";
import ListType from "./ListType";
const FXRatesPrice = () => {
  //   const [privateModal, setPrivateModal] = useState(false);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [row, setRow] = useState();

  const [grid, setGrid] = useState("Box Format");

  let APPID = "";
  let APIKEY = "";

    const dispatch = useDispatch();
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await GetFXRatesApi(APPID, APIKEY);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log("🚀 ~ file: FXRatesPrice.js ~ line 33 ~ fetchdata ~ devryptedIssuer", JSON.parse(devryptedIssuer))

      const jsonData = JSON.parse(JSON.parse(devryptedIssuer).CommandResponse);
      console.log("🚀 ~ file: FXRatesPrice.js ~ line 36 ~ fetchdata ~ jsonData", jsonData)

      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
      if (jsonData) {
        let dataArr = [];
        jsonData?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({
            ...dataMain,
            rowid: dataMain.RowID,
          });
        });

        setRow(dataArr);
      }
      //   dispatch(setPrivateId(""));
    }
    
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const gridOption = (e) => {
    setGrid(e.target.value);
  };

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10 fxrates-price-page">
          <div className="row justify-content-center ms-lg-4 ms-xl-5">
            <div className="col-md-9 col-lg-9 col-xxl-12">
              <div className="fxrates-price m-0">
                <div className="fxrates-price-logo">
                  <h2>
                    UNICOIN<span>TM</span>
                  </h2>
                  <h6>A Universal Monetary Unit</h6>
                  <img
                    src="../images/coins/fx-u-logo.png"
                    className="mt-4"
                    alt="fx-u-logo"
                  />
                </div>
                <div className="best-price-fx-rates">
                  <h3>Best-Price FX Rates</h3>
                  <p>
                    Using the <span>Unipay function</span>, This function
                    displays the foreign exchange rates for each digital
                    currency to the United States Dollar (USD) and to Unicoin
                    (UMU).
                  </p>
                  <select
                    class="form-select"
                    role="tablist"
                    onChange={gridOption}
                  >
                    <option selected>Box Format</option>
                    <option>Listing Format</option>
                  </select>
                </div>

                <div className="row mt-4">
                  {grid === "Box Format" ? (
                    row &&
                    row.map((ndata) => {
                      return (
                        <BoxListing
                          QuoteImageFN={ndata.QuoteImageFN}
                          QuoteCountryname={ndata.QuoteCountryname}
                          BaseCountryname={ndata.BaseCountryname}
                          BaseImageFN={ndata.BaseImageFN}
                          BaseCurrencycode={ndata.BaseCurrencycode}
                          BasePrice={ndata.BasePrice}
                          CoinCountryname={ndata.CoinCountryname}
                          CoinImageFN={ndata.CoinImageFN}
                          CoinCurrencycode={ndata.CoinCurrencycode}
                          CoinPrice={ndata.CoinPrice}
                        />
                      );
                    })
                  ) : (
                    <>
                      <div className="wallet-balances-box">
                        <div className="table-responsive p-3 p-md-4">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Country</th>
                                <th>USD FX Rate</th>
                                <th>Unicoin FX Rate</th>
                                <th>Payout C urrency</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row &&
                                row.map((data) => {
                                  return (
                                    <ListType
                                      BaseImageFN={data.BaseImageFN}
                                      BaseCountryname={data.BaseCountryname}
                                      BasePrice={data.BasePrice}
                                      CoinImageFN={data.CoinImageFN}
                                      CoinPrice={data.CoinPrice}
                                      CoinCurrencycode={data.CoinCurrencycode}
                                      PayoutImageFN={data.PayoutImageFN}
                                      PayoutCurrencycode={
                                        data.PayoutCurrencycode
                                      }
                                      QuoteImageFN={data.QuoteImageFN}
                          QuoteCountryname={data.QuoteCountryname}
                                    />
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FXRatesPrice;
