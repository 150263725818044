import React from 'react';
import Header from "../../../Components/Header";
import Sidebar from "../../../Components/Sidebar";
function FXRates() {
    

    return (<>
        <Header />
        <div className="balance-page">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9 col-lg-9 col-xxl-10">
                        <div className="main-heading">
                            <i className="bi bi-arrow-left"></i>
                            <h3>FX Rates</h3>
                        </div>
                        <div className="wallet-information-box">
                            <h4>Unicoin FX Rates</h4>
                            <div className="row">
                                <div className="col-auto  mb-4">
                                    <div className="balance-box fx-rates-box">
                                        <div className="account yellow">
                                            <h6>UNICOIN</h6>
                                        </div>
                                        <img src="../images/coins/U-1.png" className="" alt="U-1-img"></img>
                                        <h5>UNC</h5>
                                        <h3>U 5900.000000</h3>
                                        <hr />
                                        <div className="fx-country">
                                            <div className="fx-country-box me-3 mb-3">
                                                <div className="fx-country-card">
                                                    <div className="fx-country-icon">
                                                        <img src="../icons/flag-box-icon.png" className="" alt="flag-box-icon-img"></img>
                                                    </div>
                                                    <div className="fx-country-flag">
                                                        <div>
                                                            <img src="../icons/flags/us-flag.png" className="" alt="us-flag-img"></img>
                                                            <h6>USD</h6>
                                                        </div>
                                                    </div>
                                                    <div className="text-start ms-2">
                                                        <h5>U.S Dollar</h5>
                                                        <h3>$ 120.0998</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fx-country-box mb-3">
                                                <div className="fx-country-card">
                                                    <div className="fx-country-icon">
                                                        <img src="../icons/flag-box-icon.png" className="" alt="flag-box-icon-img"></img>
                                                    </div>
                                                    <div className="fx-country-flag">
                                                        <div>
                                                            <img src="../icons/flags/ca-flag.png" className="" alt="us-flag-img"></img>
                                                            <h6>CND</h6>
                                                        </div>
                                                    </div>
                                                    <div className="text-start ms-2">
                                                        <h5>Canada</h5>
                                                        <h3>$ 143.00</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fx-country">
                                            <div className="fx-country-box me-3 mb-3 mb-md-0">
                                                <div className="fx-country-card">
                                                    <div className="fx-country-icon">
                                                        <img src="../icons/flag-box-icon.png" className="" alt="flag-box-icon-img"></img>
                                                    </div>
                                                    <div className="fx-country-flag">
                                                        <div>
                                                            <img src="../icons/flags/jk_32.png" className="" alt="us-flag-img"></img>
                                                            <h6>JMD</h6>
                                                        </div>
                                                    </div>
                                                    <div className="text-start ms-2">
                                                        <h5>Jamaica</h5>
                                                        <h3>$ 120.0998</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fx-country-box">
                                                <div className="fx-country-card">
                                                    <div className="fx-country-icon">
                                                        <img src="../icons/flag-box-icon.png" className="" alt="flag-box-icon-img"></img>
                                                    </div>
                                                    <div className="fx-country-flag">
                                                        <div>
                                                            <img src="../icons/flags/be-flag.png" className="" alt="us-flag-img"></img>
                                                            <h6>EUR</h6>
                                                        </div>
                                                    </div>
                                                    <div className="text-start ms-2">
                                                        <h5>Belgium</h5>
                                                        <h3>$ 120.0998</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default FXRates;