import React from 'react';
function Footer() {
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-md-2">
                            <a href="https://dcma.io" target="_blank" rel="noreferrer"><img src="../images/dcma_logo 1.png" alt="" /></a>
                        </div>
                        <div className="col-md-10 text-md-end mt-4 mt-md-0">
                            <div className="footer-text">
                                <h5>Unicoin<span>TM</span> is an innovation of Digital Currency Monetary Authority.</h5>
                                <a href="/">unicoin.dcma.io</a>
                                <p>&copy; 2021 All Right Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;