
import {  GridRow } from "@mui/x-data-grid";
import clsx from "clsx";

function GridStyle(props) {
    const { className, index, ...other } = props;
  
    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }
  export default GridStyle;