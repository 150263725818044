import React, { useEffect } from "react";
import { useState } from "react";
import CreateLedger from "./CreateLedgerModify";
import ReviewLedger from "./ReviewLedgerModify";

function CreateLedgers() {
  const [step, setstep] = useState(1);
  const [LedgerData, setLedgerData] = useState(1);
  useEffect(() => {
    setstep(1);
  }, []);
  const setdata = (data) => {
    setLedgerData(data);
  };
  const cleandata = () => {
    setLedgerData(1);
  };

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <CreateLedger
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            LedgerData={LedgerData}
            cleandata={cleandata}
          />
        );
      case 2:
        return (
          <ReviewLedger
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            LedgerData={LedgerData}
            cleandata={cleandata}
          />
        );
      default:
        return false;
    }
  };
  const nextstep = () => {
    if (step < 2) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };
  return <>{renderstep(step)}</>;
}

export default CreateLedgers;
