import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import { decrypytdata } from "../../../api/walletKey";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { unPauseWallet } from "../../../api/Wallets";
import { useLocation, useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult } from "../../../action";

function WalletUnpause() {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  //   const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");

  const location = useLocation();

  const initialValues = {
    sourcePublicAddress: location.search.replace("?id=", ""),
  };

  let APPID = "";
  let APIKEY = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const unPauseWalletData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY && data) {
      // debugger;
      const encryptedIssuer = await unPauseWallet(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      // debugger;
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        unPauseWalletData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
            <div className="col-12">
              <div className="main-heading">
                <h3> Wallet</h3>
                <h6>Unpause</h6>
                <p>
                  This function reactivates a wallet to conduct transactions.
                </p>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <div className="row">
                    <div className="suspend-text wallet-information-form">
                      <Formik
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        onSubmit={(values) => {
                          console.log("values", values);
                          // debugger;
                          setdata(values);
                          unPauseWalletData();
                        }}
                      >
                        {(formik) => {
                          const { errors, touched } = formik;
                          return (
                            <div className="">
                              <Form className="wallet-information-form">
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <div className="input-box  form-floating mb-5">
                                    <Field
                                      type="text"
                                      name="sourcePublicAddress"
                                      id="sourcePublicAddress"
                                      placeholder="Public Wallet Address"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Public Wallet Address
                                    </label>
                                    <div
                                      className={
                                        errors.sourcePublicAddress &&
                                        touched.sourcePublicAddress
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="sourcePublicAddress"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex me-0 me-md-4 me-xxl-5">
                                  <button
                                    type="button"
                                    class="btn cancel-btn mb-3 mb-md-0 me-4"
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    type="submit"
                                    class="btn next-btn mb-3 mb-md-0"
                                  >
                                    SUBMIT
                                  </button>
                                </div>
                              </Form>
                            </div>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>

                  {/* <div class="row">
                  <div class="col-xxl-6 mb-4">
                    <div class="mb-5">
                      <div class="input-box">
                        <select className="form-select form-control">
                          <option defaultValue>Wallet Public Address</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex me-0 me-md-4 me-xxl-5 justify-content-end">
                      <button
                        type="button"
                        class="btn cancel-btn mb-3 mb-md-0 me-4"
                      >
                        CANCEL
                      </button>
                      <button type="submit" class="btn next-btn mb-3 mb-md-0">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WalletUnpause;
