import React from "react";

const MoneyService = () => {
  return (
    <>
      <div className="documentation-accordion">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="Issuer">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#issuerOne"
                aria-expanded="true"
                aria-controls="issuerOne"
              >
                Transfer
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="issuerOne"
              className="accordion-collapse collapse show"
              aria-labelledby="Issuer"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Transfer Enables the sending of digital currency from one wallet
                to another wallet. A wallet could be a token wallet or a ledger
                wallet.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="Modify">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#ModifyTwo"
                aria-expanded="false"
                aria-controls="ModifyTwo"
              >
                FX Tansfer
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="ModifyTwo"
              className="accordion-collapse collapse show"
              aria-labelledby="Modify"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                FX Transfer Enables the cross-border sending of digital currency
                from one wallet to another wallet at the current exchange market
                rate.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="Suspend">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#SuspendThree"
                aria-expanded="false"
                aria-controls="SuspendThree"
              >
                Create Escrow
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="SuspendThree"
              className="accordion-collapse collapse show"
              aria-labelledby="Suspend"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Create Escrow Establishes Proof of Funds with an originator and
                proposes an escrow transaction between two wallet parties.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="BranchOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#CreateOne"
                aria-expanded="false"
                aria-controls="CreateOne"
              >
                Accept Escrow
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="CreateOne"
              className="accordion-collapse collapse show"
              aria-labelledby="BranchOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Accept Escrow Enables the beneficiary of an escrow to accept the
                terms and conditions.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="BranchThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#CreateThree"
                aria-expanded="false"
                aria-controls="CreateThree"
              >
                Suspend Escrow
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="CreateThree"
              className="accordion-collapse collapse show"
              aria-labelledby="BranchThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Enables the suspending of an escrow transaction. An originator
                can only suspend before the beneficiary accepts. A beneficiary
                can suspend at any time.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="AgentOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#CreateAgentOne"
                aria-expanded="false"
                aria-controls="CreateAgentOne"
              >
                Create Milestone
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="CreateAgentOne"
              className="accordion-collapse collapse show"
              aria-labelledby="AgentOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Create Milestone Create a milestone payment within an existing
                escrow transaction.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="AgentTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#ModifyAgentOne"
                aria-expanded="false"
                aria-controls="ModifyAgentOne"
              >
                Suspend Milestone
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="ModifyAgentOne"
              className="accordion-collapse collapse show"
              aria-labelledby="AgentTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Suspend Milestone Suspends a milestone payment within an
                existing escrow transaction.
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion-item">
            <h2 className="accordion-header" id="AgentThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#SuspendAgentOne"
                aria-expanded="false"
                aria-controls="SuspendAgentOne"
              >
                Release Milestone
                <i className="bi bi-plus-lg none"></i>
                <i className="bi bi-dash-lg show"></i>
              </button>
            </h2>
            <div
              id="SuspendAgentOne"
              className="accordion-collapse collapse show"
              aria-labelledby="AgentThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Release Milestone Releases a milestone payment within an
                existing escrow transaction.
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default MoneyService;
