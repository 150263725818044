import React from "react";

function Governance() {
  return (
    <>
          <div className="documentation-accordion ms-0">
              <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Issuer">
                          <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#issuerOne"
                              aria-expanded="true"
                              aria-controls="issuerOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                Why not adopt a decentralized autonomous organization (DAO) as a governance model?
                                </div>
                              <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div
                          id="issuerOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="Issuer"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  {" "}
                                  With DAOs, clusters of nodes with the most voting power could control the decisions of the network. The nodes with voting control could also shift over time. There is no guarantee the controlling nodes would operate in the best interest of the network.{" "}
                              </p>

                              <p>
                                  {" "}
                                 DAOs are great as a first level governance model. However, DAOs cannot enforce integrity if unintended nefarious attacks occur. Having a tier-2 monetary policy framework enables intervention when a decentralized network runs awry of its intended mission and targets.
                                </p>

                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item ">
                      <h2 className="accordion-header" id="Modify">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ModifyTwo"
                              aria-expanded="false"
                              aria-controls="ModifyTwo"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                 Who are the members of the Unicoin Monetary Policy Committee?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="ModifyTwo"
                          className="accordion-collapse collapse show"
                          aria-labelledby="Modify"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  The UMPC will consist of up to 12 members and is in its early stages of being formed. We are currently in discussions with member of central banks in both advanced economies and emerging market economies, and members of the United Nations, World Banks, and International Monetary Fund.
                                </p>

                              <p>
                                  {" "}
                                 The UMPC will also consist of members outside of government and banking. To ensure all Unicoin constituents are represented, we may seek rotational representation from economists, technologies, and consumer and commerce advocacy organizations.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Suspend">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#SuspendThree"
                              aria-expanded="false"
                              aria-controls="SuspendThree"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Because UMPC policymakers intend to minimize aggressive volatility, doesn’t that also limit the upside potential of Unicoin?  
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="SuspendThree"
                          className="accordion-collapse collapse show"
                          aria-labelledby="Suspend"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>No. The monetary policy goal of the UMPC is to achieve a sustainable appreciating universal monetary unit without enduring unsustainable swings in upside price movement. </p>
                              <p>High volatility is attractive to cryptocurrency traders and short-term investors. However, it does not benefit the Unicoin ecosystem overall. It is more important to attract central banks, banks, merchants, and consumers to adopt and use Unicoin for store of value and exchange of value. </p>
                              <p>Minimizing highly volatile asset price swings increases worldwide adoption and should propel Unicoin to achieve the highest potential asset value. </p>
                              </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchOne">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#CreateOne"
                              aria-expanded="false"
                              aria-controls="CreateOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                             Does the UMPC govern other DCMA digital currency innovations other than Unicoin?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="CreateOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="BranchOne"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  No. Other digital currency innovations on the Unicoin network include cash-backed digital assets, composite digital currencies, and neutral monetary units. Each of these digital currency products have precise functional requirements and are not subject to monetary oversight.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchTwo">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#CreateTwo"
                              aria-expanded="false"
                              aria-controls="CreateTwo"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Will the UMPC policy decisions be published for public review?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="CreateTwo"
                          className="accordion-collapse collapse show"
                          aria-labelledby="BranchTwo"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  {" "}
                                Yes. Once Unicoin is listed on cryptocurrency exchanges, monetary policy decisions will be transparent to the public and published within two weeks after each UMPC meeting.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
              </div>
          </div>
    </>
  );
}

export default Governance;
