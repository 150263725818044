import React from "react";
function CurrencyTable() {
    return (
        <>
            <div className="unicoin">
                <div className="container-fluid">
                    <div className="row justify-content-around">
                        <div className="col-md-4 col-xl-3">
                            <div className="row g-0">
                                <div className="col-12">
                                    <div className="unicoin-box">
                                        <img src="images/tech.png" alt="alt" />
                                        <p>Unicoin introduces the next generation of cryptographic innovations in store of value, tokenization, and distributed ledger technology.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="unicoin-box">
                                        <img src="images/classes.png" alt="alt" />
                                        <p>The Unicoin network offers 3 classes of store of value digital assets for all investment needs.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="unicoin-box">
                                        <img src="images/coin.png" className="coine-img" alt="alt" />
                                        <p>The Unicoin tokenization protocol offers breakthrough digital assets for store of value, payments, and trade.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="unicoin-box">
                                        <img src="images/leap.png" alt="alt" />
                                        <p>Unicoin leapfrogs DLT with the advent of a multi-ledger DLT which supports not only cash transactions for all banking debt and credit ledgers.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="unicoin-box mb-0">
                                        <img src="images/fast.png" alt="alt" />
                                        <p>Unicoin is validated as the world’s fastest DLT consensus protocol with a Staked Proof of Trust (SPOT) protocol. With SPOT Mesonodes only sent reject messages to the network when they disagree with the Meganodes which records incoming transactions instantly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-7 mt-5 mt-md-0">
                            <div className="unicon-title">
                                <h4>Currency</h4>
                                <p>Universal Coin (Unicoin)</p>
                            </div>
                            <div className="unicon-title">
                                <h4>Cryptocurrency Type</h4>
                                <p>Staking Network Coin</p>
                            </div>
                            <div className="unicon-title">
                                <h4>Store of Value Innovation</h4>
                                <p>3D Store of Value Protocol</p>
                            </div>
                            <div className="unicon-title align-items-start">
                                <h4>Store of Value Protocols</h4>
                                <div>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Income-producing</p>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Fixed Value</p>
                                    <p><img src="icons/true.png" className="me-4" alt="alt" />Speculative Value</p>
                                </div>
                            </div>
                            <div className="unicon-title">
                                <h4>Cash Technologies</h4>
                                <p>Physical and Electronic Cash</p>
                            </div>
                            <div className="unicon-title">
                                <h4>Electronic Cash Consensus</h4>
                                <p>Staked Proof of Trust (SPOT)</p>
                            </div>
                            <div className="unicon-title align-items-start">
                                <h4>DLT Innovation</h4>
                                <div>
                                    <p className="mb-3">Multi-Ledger DLT</p>
                                    <p>(Supports Cash plus all Banking Ledger Types)</p>
                                </div>
                            </div>
                            <div className="unicon-title align-items-start">
                                <h4>Node Types</h4>
                                <div>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Meganodes</p>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Mesonodes</p>
                                    <p><img src="icons/true.png" className="me-4" alt="alt" />Micronodes</p>
                                </div>
                            </div>
                            <div className="unicon-title">
                                <h4>Network Speed</h4>
                                <p>100,000+ TPS</p>
                            </div>
                            <div className="unicon-title align-items-start">
                                <h4>Digital Assets Supported</h4>
                                <div>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Cryptocurrencies</p>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Cash-based Digital Assets (CBDA)</p>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Composite Digital Currency (CDC)</p>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Neutral Monetary Units (NMU)</p>
                                    <p className="mb-3"><img src="icons/true.png" className="me-4" alt="alt" />Central Bank Digital Currency (CBDC)</p>
                                    <p><img src="icons/true.png" className="me-4" alt="alt" />Continental and Global Currencies</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CurrencyTable;
