import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CancelMilestoneAPI } from "../../../api/Milestones";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import PrivateModal from "../../../Components/PrivateModal";
function CancelMilestone() {
  const publicKeys = useSelector((state) => state.keyReducer);
  const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState();

  const initialValues = {
    txhash: "",
    milestoneTypecode: "",
  };

  let APPID = "";
  let APIKEY = "";
  const CancelMilestoneData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await CancelMilestoneAPI(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      const jsonData = JSON.parse(devryptedIssuer);
      console.log("🚀 ~ file: CancelMilestone.js ~ line 34 ~ CancelMilestoneData ~ jsonData", jsonData)
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
    }
    setPrivateModal(false);
  };
  useEffect(() => {
    CancelMilestoneData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [publicKeys.privateKey.PrivateWalletAddress]);
  return (
    <>
      {privateModal && (
        <PrivateModal
          modalpriate={privateModal}
          closeModal={CancelMilestoneData}
        />
      )}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row  ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Money Services</h3>
              <h6>Suspend Milestone</h6>
              <p>
                This function suspends a milestone payment within an existing
                escrow transaction.
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log("🚀 ~ file: CancelMilestone.js ~ line 63 ~ CancelMilestone ~ values", values)
                setdata(values);
                setPrivateModal(true);
                
              }}
            >
              {(formik) => {
                return (
                  <Form className="wallet-information-box">
                    <div className="suspend-text wallet-information-form">
                      <div class="row">
                        <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                          <div class="mb-5">
                            <div class="input-box form-floating">
                              <Field
                                type="text"
                                id="txhash"
                                name="txhash"
                                placeholder="Public Milestone Address"
                                class="form-control"
                              />
                              <label className="ps-0 pb-0">
                                Public Milestone Address
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex me-0 me-md-4 me-xxl-5">
                        <button
                          type="button"
                          class="btn cancel-btn mb-3 mb-md-0 me-4"
                        >
                          CANCEL
                        </button>
                        <button
                          type="submit"
                          class="btn next-btn mb-3 mb-md-0"
                        //   onClick={() => {
                        //     setPrivateModal(true);
                        //   }}
                        >
                          SUSPEND
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelMilestone;
