import React from "react";
function Info() {
  return (
    <>
      <div className="unicoin-store">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="unicoin-store-text">
                <p>
                  Despite the genius innovation of Bitcoin, as a
                  first-generation cryptographic technology, it is a hybrid
                  between physical cash and electronic cash and is not optimally
                  suited for either societal use case.
                </p>
                <p>
                  Unicoin improves upon the foundation of Bitcoin and provides
                  two separate digital currency innovations for realizing both
                  physical cash using direct mobile to mobile payments and
                  electronic cash using a regulatory financial services
                  framework.
                </p>
                <p>
                  Unicoin is a cryptocurrency offering a network of banking
                  regulated tokens offering all three classes of store of value.
                  In essence, Unicoin combines the best of Bitcoin and Ethereum
                  in one digital currency network. The difference is only
                  regulated banks and licensed FinTech companies can issue and
                  mint coins on the Unicoin network.
                </p>
                {/*<p className="mb-0">*/}
                {/*  For more information on Unicoin, email{" "}*/}
                {/*  <a href="mailto:unicoin@dcma.io">unicoin@dcma.io</a>*/}
                {/*</p>*/}
              </div>
            </div>
            <div className="col-md-6 text-center">
              {/* <img src="../images/coins/bitcoin vs unicoin.png" className="img-fluid" alt="alt" /> */}
              <img
                src="../images/bitcoin vs unicoin.png"
                className="img-fluid"
                alt="alt"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Info;
