import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
// import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
} from "../../../../../api/dropdown";
function Originator({ nextstep, setdata, formData }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);

  // const initialValues = {
  //   globalPeopleTypeID: 0,
  //   nativeID: "NativeId",
  //   globalPeople: "GlobalPeople",
  //   displayname: "DisplayName",
  //   firstname: "FirstName",
  //   secondFirstname: "SecondFirstName",
  //   lastname: "LastName",
  //   secondLastname: "SecondLastName",
  //   dob: "",
  //   birthCountrycode: "",
  //   birthGovernmentNumber: "BirthGovernmentNumber",
  //   passportCountrycode: "PassportCountrycode",
  //   passportNumber: "PassportNumber",
  //   driversLicenseCountrycode: "DriversLicenseCountrycode",
  //   driversLicenseNumber: "driversLicenseNumber",
  //   governmentIDCountrycode: "governmentIDCountrycode",
  //   governmentIDNumber: "governmentIDNumber",
  //   otherIDCountrycode: "otherIDCountrycode",
  //   otherIDNumber: "otherIDNumber",
  //   primaryCountrycode: "primaryCountrycode",
  //   primaryStateProvince: "primaryStateProvince",
  //   primaryCityTown: "primaryCityTown",
  //   primaryPostalcode: "primaryPostalcode",
  //   primaryAddressLine1: "primaryAddressLine1",
  //   primaryAddressLine2: "primaryAddressLine2",
  //   primaryAddressLine3: "primaryAddressLine3",
  //   primaryDialingcode: "primaryDialingcode",
  //   primaryPhoneNumber: "primaryPhoneNumber",
  //   primaryEmail: "primaryEmail",
  //   primaryDIDKeycode: "primaryDIDKeycode",
  //   primaryDID: "primaryDID",
  //   primaryEntity: "primaryEntity",
  //   primaryRegistrationNumber: "primaryRegistrationNumber",
  //   secondaryCountrycode: "secondaryCountrycode",
  //   secondaryStateProvince: "secondaryStateProvince",
  //   secondaryCityTown: "secondaryCityTown",
  //   secondaryPostalcode: "secondaryPostalcode",
  //   secondaryAddressLine1: "secondaryAddressLine1",
  //   secondaryAddressLine2: "secondaryAddressLine2",
  //   secondaryAddressLine3: "secondaryAddressLine3",
  //   secondaryDialingcode: "secondaryDialingcode",
  //   secondaryPhoneNumber: "secondaryPhoneNumber",
  //   secondaryEmail: "secondaryEmail",
  //   secondaryDIDKeycode: "secondaryDIDKeycode",
  //   secondaryDID: "secondaryDID",
  //   secondaryEntity: "secondaryEntity",
  //   secondaryRegistrationNumber: "secondaryRegistrationNumber",
  //   tertiaryCountrycode: "tertiaryCountrycode",
  //   tertiaryStateProvince: "tertiaryStateProvince",
  //   tertiaryCityTown: "tertiaryCityTown",
  //   tertiaryPostalcode: "tertiaryPostalcode",
  //   tertiaryAddressLine1: "tertiaryAddressLine1",
  //   tertiaryAddressLine2: "tertiaryAddressLine2",
  //   tertiaryAddressLine3: "tertiaryAddressLine3",
  //   tertiaryDialingcode: "tertiaryDialingcode",
  //   tertiaryPhoneNumber: "tertiaryPhoneNumber",
  //   tertiaryEmail: "tertiaryEmail",
  //   tertiaryDIDKeycode: "tertiaryDIDKeycode",
  //   tertiaryDID: "tertiaryDID",
  //   tertiaryEntity: "tertiaryEntity",
  //   tertiaryRegistrationNumber: "tertiaryRegistrationNumber",
  // };
   

  const initialValues1 = {
    globalPeopleTypeID: 0,
    nativeID: "",
    globalPeople: "",
    displayname: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    dob: "",
    birthCountrycode: "",
    birthGovernmentNumber: "",
    passportCountrycode: "",
    passportNumber: "",
    driversLicenseCountrycode: "",
    driversLicenseNumber: "",
    governmentIDCountrycode: "",
    governmentIDNumber: "",
    otherIDCountrycode: "",
    otherIDNumber: "",
    primaryCountrycode: "",
    primaryStateProvince: "",
    primaryCityTown: "",
    primaryPostalcode: "",
    primaryAddressLine1: "",
    primaryAddressLine2: "",
    primaryAddressLine3: "",
    primaryDialingcode: "",
    primaryPhoneNumber: "",
    primaryEmail: "",
    primaryDIDKeycode: "",
    primaryDID: "",
    primaryEntity: "",
    primaryRegistrationNumber: "",
    secondaryCountrycode: "",
    secondaryStateProvince: "",
    secondaryCityTown: "",
    secondaryPostalcode: "",
    secondaryAddressLine1: "",
    secondaryAddressLine2: "",
    secondaryAddressLine3: "",
    secondaryDialingcode: "",
    secondaryPhoneNumber: "",
    secondaryEmail: "",
    secondaryDIDKeycode: "",
    secondaryDID: "",
    secondaryEntity: "",
    secondaryRegistrationNumber: "",
    tertiaryCountrycode: "",
    tertiaryStateProvince: "",
    tertiaryCityTown: "",
    tertiaryPostalcode: "",
    tertiaryAddressLine1: "",
    tertiaryAddressLine2: "",
    tertiaryAddressLine3: "",
    tertiaryDialingcode: "",
    tertiaryPhoneNumber: "",
    tertiaryEmail: "",
    tertiaryDIDKeycode: "",
    tertiaryDID: "",
    tertiaryEntity: "",
    tertiaryRegistrationNumber: "",
  };
  // const validationSchema = Yup.object({
  //   globalPeopleTypeID: Yup.number(),
  //   nativeID: Yup.string(),
  //   globalPeople: Yup.string(),
  //   displayname: Yup.string(),
  //   firstname: Yup.string().required("First name is required"),
  //   secondFirstname: Yup.string(),
  //   lastname: Yup.string().required("Last name is required"),
  //   secondLastname: Yup.string(),
  //   dob: Yup.string().required("Date of Birth is required"),
  //   birthCountrycode: Yup.string().required("Birth Country Code is required"),
  //   birthGovernmentNumber: Yup.string(),
  //   passportCountrycode: Yup.string(),
  //   passportNumber: Yup.string(),
  //   driversLicenseCountrycode: Yup.string(),
  //   driversLicenseNumber: Yup.string(),
  //   governmentIDCountrycode: Yup.string(),
  //   governmentIDNumber: Yup.string(),
  //   otherIDCountrycode: Yup.string(),
  //   otherIDNumber: Yup.string(),
  //   primaryCountrycode: Yup.string(),
  //   primaryStateProvince: Yup.string(),
  //   primaryCityTown: Yup.string(),
  //   primaryPostalcode: Yup.string(),
  //   primaryAddressLine1: Yup.string(),
  //   primaryAddressLine2: Yup.string(),
  //   primaryAddressLine3: Yup.string(),
  //   primaryDialingcode: Yup.string(),
  //   primaryPhoneNumber: Yup.string(),
  //   primaryEmail: Yup.string(),
  //   primaryDIDKeycode: Yup.string(),
  //   primaryDID: Yup.string(),
  //   primaryEntity: Yup.string(),
  //   primaryRegistrationNumber: Yup.string(),
  //   secondaryCountrycode: Yup.string(),
  //   secondaryStateProvince: Yup.string(),
  //   secondaryCityTown: Yup.string(),
  //   secondaryPostalcode: Yup.string(),
  //   secondaryAddressLine1: Yup.string(),
  //   secondaryAddressLine2: Yup.string(),
  //   secondaryAddressLine3: Yup.string(),
  //   secondaryDialingcode: Yup.string(),
  //   secondaryPhoneNumber: Yup.string(),
  //   secondaryEmail: Yup.string(),
  //   secondaryDIDKeycode: Yup.string(),
  //   secondaryDID: Yup.string(),
  //   secondaryEntity: Yup.string(),
  //   secondaryRegistrationNumber: Yup.string(),
  //   tertiaryCountrycode: Yup.string(),
  //   tertiaryStateProvince: Yup.string(),
  //   tertiaryCityTown: Yup.string(),
  //   tertiaryPostalcode: Yup.string(),
  //   tertiaryAddressLine1: Yup.string(),
  //   tertiaryAddressLine2: Yup.string(),
  //   tertiaryAddressLine3: Yup.string(),
  //   tertiaryDialingcode: Yup.string(),
  //   tertiaryPhoneNumber: Yup.string(),
  //   tertiaryEmail: Yup.string(),
  //   tertiaryDIDKeycode: Yup.string(),
  //   tertiaryDID: Yup.string(),
  //   tertiaryEntity: Yup.string(),
  //   tertiaryRegistrationNumber: Yup.string(),
  // });

  const fetchdropdwon = async () => {
    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {isValidPublicAddress && (
        <div className="row">
          <div className="col-12 mt-5">
            <div className="originating-account link-wallet-box create-page ms-0">
              <Formik
                initialValues={initialValues1}
                // validationSchema={validationSchema}
                onSubmit={(values) => {
                  setdata(values);
                  console.log(
                    "🚀 ~ file: Originator.js ~ line 107 ~ Originator ~ values",
                    values
                  );

                  nextstep();
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box  m-0">
                      <Form className="wallet-information-form">
                        <div className="row mb-xl-5">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Information</h6>
                            <div className="mb-5">
                              <label>First Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  placeholder="First Name"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.firstname && touched.firstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="firstname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Second First Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="secondFirstname"
                                  id="secondFirstname"
                                  placeholder="Middle  Name"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Last Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  placeholder="Last Name"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.lastname && touched.lastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="lastname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Second Last Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="secondLastname"
                                  id="secondLastname"
                                  placeholder="Second Last  Name"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Business Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="TokenName"
                                  id="TokenName"
                                  placeholder="Business Name"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Industry</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="TokenName"
                                  id="TokenName"
                                  placeholder="Industry"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div> 
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Home Address </h6>
                            {/*<div className="mb-5">*/}
                            {/*  <label>Birth Country Code</label>*/}
                            {/*  <div className="input-box">*/}
                            {/*    <Field*/}
                            {/*      as="select"*/}
                            {/*      name="birthCountrycode"*/}
                            {/*      id="birthCountrycode"*/}
                            {/*      placeholder="Country"*/}
                            {/*      className="form-control form-select"*/}
                            {/*      onChange={(e) => {*/}
                            {/*        console.log(*/}
                            {/*          "🚀 ~ file: Originator.js ~ line 213 ~ Originator ~ e",*/}
                            {/*          e.target.value*/}
                            {/*        );*/}
                            {/*        setFieldValue(*/}
                            {/*          "birthCountrycode",*/}
                            {/*          e.target.value*/}
                            {/*        );*/}
                            {/*      }}*/}
                            {/*      onBlur={formik.handleBlur}*/}
                            {/*    >*/}
                            {/*      {countryCode &&*/}
                            {/*        countryCode.map((value, index) => {*/}
                            {/*          return (*/}
                            {/*            <>*/}
                            {/*              <option*/}
                            {/*                value={value.countrycode}*/}
                            {/*                key={index}*/}
                            {/*              >*/}
                            {/*                {value.isoCountryname}*/}
                            {/*              </option>*/}
                            {/*            </>*/}
                            {/*          );*/}
                            {/*        })}*/}
                            {/*    </Field>*/}
                            {/*    <div>*/}
                            {/*      <ErrorMessage*/}
                            {/*        name="birthCountrycode"*/}
                            {/*        component="span"*/}
                            {/*        className="error"*/}
                            {/*        style={{ color: "red" }}*/}
                            {/*      />*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div className="mb-5">
                              <label>State</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryStateProvince"
                                  id="primaryStateProvince"
                                  placeholder="State"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>City</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryCityTown"
                                  id="primaryCityTown"
                                  placeholder="City"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Address Line 1 </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryAddressLine1"
                                  id="primaryAddressLine1"
                                  placeholder="Address Line 1"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Address Line 2 </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryAddressLine2"
                                  id="primaryAddressLine2"
                                  placeholder="Address Line 2"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Postal Code </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryPostalcode"
                                  id="primaryPostalcode"
                                  placeholder="Postal Code"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Identification</h6>
                            <div className="mb-5">
                              <label>Date of Birth</label>
                              <div className="input-box">
                                <Field
                                  type="date"
                                  name="dob"
                                  id="dob"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.dob && touched.dob
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  
                                  <ErrorMessage
                                    name="dob"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Government Birth Number</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="birthGovernmentNumber"
                                  id="birthGovernmentNumber"
                                  placeholder="Government Birth Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Passport Country</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="passportCountrycode"
                                  id="passportCountrycode"
                                  placeholder="Passport Country"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Passport Number </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="passportNumber"
                                  id="passportNumber"
                                  placeholder="Passport Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Driver's License Number </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="driversLicenseNumber"
                                  id="driversLicenseNumber"
                                  placeholder="Driver's License Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Other Government ID Number </label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="governmentIDNumber"
                                  id="governmentIDNumber"
                                  placeholder="Other Government ID Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Contact Information</h6>
                            <div className="mb-5">
                              <label>Email Address</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryEmail"
                                  id="primaryEmail"
                                  placeholder="Email Address"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Mobile Dialing Country Code</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="primaryDialingcode"
                                  id="primaryDialingcode"
                                  placeholder="Mobile Dialing Country Code"
                                  className="form-control form-select"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "primaryDialingcode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {dailingCode &&
                                    dailingCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Mobile Number</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="primaryPhoneNumber"
                                  id="primaryPhoneNumber"
                                  placeholder="Mobile Number"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            Skip
                          </button>
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Originator;
