import { Link } from "react-router-dom";
function Header() {
    const pathname = window.location.pathname.split("/");
    return (
        <>
            <div
                className="root header"
                style={{
                    backgroundColor: "#d9ae69",
                }}
            >
                <nav className="navbar navbar-expand-xl">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img
                                src="../images/coins/U-1.png"
                                className="U-1-img"
                                alt="U-1-img"
                            ></img>
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown"
                            aria-controls="navbarNavDropdown"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">

                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        to="/home"
                                        className={
                                            pathname[1] === "Home"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                    >
                                        Home
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link*/}
                                {/*        to="/digital-cash"*/}
                                {/*        className={*/}
                                {/*            pathname[1] === "Physicalcash"*/}
                                {/*                ? "nav-link active"*/}
                                {/*                : "nav-link"*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        Digital  Cash*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Stakeholders
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a class="dropdown-item" href="/monetary-authorities">Monetary Authorities</a></li>
                                        <li><a class="dropdown-item" href="/banks">Banks</a></li>
                                        <li><a class="dropdown-item" href="/fintechs">Fintechs</a></li>
                                        <li><a class="dropdown-item" href="/crypto-exchanges">Crypto Exchanges</a></li>
                                        <li><a class="dropdown-item" href="/merchants">Merchants</a></li>
                                        <li><a class="dropdown-item" href="/citizens-and-corporations">Private Citizens</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Technology
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a class="dropdown-item" href="/digital-cash">
                                                <Link 
                                                    to="/digital-cash"
                                                    className={
                                                        pathname[1] === "DigitalCurrencies"
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Digital Cash
                                                </Link>
                                                </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="/electronic-cash">
                                                <Link
                                                    to="/electronic-cash"
                                                    className={
                                                        pathname[1] === "ElectronicCash"
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Electronic Cash
                                                </Link>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="/smart-card">
                                                <Link
                                                    to="/smart-card"
                                                    className={
                                                        pathname[1] === "SmartCard"
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                >
                                                    Smart Card
                                                </Link>
                                            </a>
                                        </li>
                                        <li><a class="dropdown-item" href="/multi-functional-dlt">Multi-Functional DLT</a></li>
                                        <li><a class="dropdown-item" href="/global-e-kyc">Global e-KYC</a></li>
                                        <li><a class="dropdown-item" href="/cash-reserve-ledgers">Cash Reserve Ledgers</a></li>
                                        <li><a class="dropdown-item" href="/staked-proof-of-trust-protocol">SPOT Protocol</a></li>
                                        <li><a class="dropdown-item" href="/monetary-policy">Monetary Policy</a></li>
                                        <li><a class="dropdown-item" href="/digital-monetization-technology">Monetization</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Solutions
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a class="dropdown-item" href="/digital-currencies">Digital Currency</a></li>
                                        <li><a class="dropdown-item" href="/central-bank-digital-currency-CBDC">Central Bank Digital Currency (CBDC)</a></li>
                                        <li><a class="dropdown-item" href="/income-producing-store-of-value">Income-producing Store of Value</a></li>
                                        <li><a class="dropdown-item" href="/cryptocurrency-store-of-value">Cryptocurrency Store of Value</a></li>
                                        <li><a class="dropdown-item" href="/cash-backed-digital-assets">Cash-backed Digital Assets (CBDA)</a></li>
                                        <li><a class="dropdown-item" href="/composite-digital-currency">Composite Digital Currency (CDC)</a></li>
                                        <li><a class="dropdown-item" href="/neutral-monetary-unit">Neutral Monetary Unit (NMU)</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a class="dropdown-item" href="/issuer-services">Issuer Services</a></li>
                                        <li><a class="dropdown-item" href="/ledger-services">Ledger Services</a></li>
                                        <li><a class="dropdown-item" href="/token-services">Token Services</a></li>
                                        <li><a class="dropdown-item" href="/wallet-services">Wallet Services</a></li>
                                        <li><a class="dropdown-item" href="/money-services">Money Services</a></li>
                                        <li><a class="dropdown-item" href="/staking-services">Staking Services</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Documentation
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a class="dropdown-item" href="/webwallet/getbalances">
                                                <Link
                                                    to="/webwallet/getbalances"
                                                    //to=""
                                                    className={
                                                        pathname[1] === "modal" ? "nav-link active" : "nav-link"
                                                    }
                                                >
                                                    Web Wallet
                                                </Link>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="/faq">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/faq"
                                                        className={
                                                            pathname[1] === "FAQ" ? "nav-link active" : "nav-link"
                                                        }
                                                    >
                                                        FAQ
                                                    </Link>
                                                </li>
                                            </a>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <a class="dropdown-item" href="/">*/}
                                        {/*        <Link*/}
                                        {/*            to="/community"*/}
                                        {/*            className={*/}
                                        {/*                pathname[1] === "Community"*/}
                                        {/*                    ? "nav-link active"*/}
                                        {/*                    : "nav-link"*/}
                                        {/*            }*/}
                                        {/*        >*/}
                                        {/*            Community*/}
                                        {/*        </Link>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                    </ul>
                                </li>
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}

                                {/*<li className="nav-item">*/}
                                {/*    <Link*/}
                                {/*        to="/news"*/}
                                {/*        className={*/}
                                {/*            pathname[1] === "News" ? "nav-link active" : "nav-link"*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        News*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*                                 <div*/}
                                {/*  className={*/}
                                {/*    pathname[1] === "modal"*/}
                                {/*      ? "nav-link active"*/}
                                {/*      : "nav-link"*/}
                                {/*  }*/}
                                {/*> */}
                                {/*</div> */}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}
                                {/*<li className="nav-item">*/}

                                {/*</li>*/}
                                {/* <li className="nav-item">
                  <Link
                    to="/modal"
                    className={
                      pathname[1] === "modal" ? "nav-link active" : "nav-link"
                    }
                  >
                    Login
                  </Link>
                </li> */}
                                {/*<li class="nav-item dropdown">*/}
                                {/*    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                                {/*        Governance*/}
                                {/*    </a>*/}
                                {/*    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/}
                                {/*        <li>*/}
                                {/*            <a class="dropdown-item" href="/">*/}
                                {/*                <Link*/}
                                {/*                    to="/crypto-2"*/}
                                {/*                    className={*/}
                                {/*                        pathname[1] === "Crypto2"*/}
                                {/*                            ? "nav-link active"*/}
                                {/*                            : "nav-link"*/}
                                {/*                    }*/}
                                {/*                >*/}
                                {/*                    Crypto 2.0*/}
                                {/*                </Link>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <a class="dropdown-item" href="/">*/}
                                {/*                <Link*/}
                                {/*                    to="/documentation"*/}
                                {/*                    className={*/}
                                {/*                        pathname[1] === "Documentation"*/}
                                {/*                            ? "nav-link active"*/}
                                {/*                            : "nav-link"*/}
                                {/*                    }*/}
                                {/*                >*/}
                                {/*                    Unicoin Protocol*/}
                                {/*                </Link>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <a class="dropdown-item" href="/">*/}
                                {/*                <Link*/}
                                {/*                    to="/monetary-policy"*/}
                                {/*                    className={*/}
                                {/*                        pathname[1] === "MonetaryPolicy"*/}
                                {/*                            ? "nav-link active"*/}
                                {/*                            : "nav-link"*/}
                                {/*                    }*/}
                                {/*                >*/}
                                {/*                    Monetary Policy*/}
                                {/*                </Link>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</li>*/}
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Login
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a class="dropdown-item" rel="noreferrer" href="https://admin.unicoinnetwork.com/" target="_blank">Administration</a></li>
                                        <li>
                                            <a class="dropdown-item" href="/webwallet/getbalances" target="_blank">
                                                <Link
                                                    to="/webwallet/getbalances"
                                                    //to=""
                                                    className={
                                                        pathname[1] === "modal" ? "nav-link active" : "nav-link"
                                                    }
                                                >
                                                    Web Wallet
                                                </Link>
                                            </a>
                                        </li>
                                        <li><a class="dropdown-item" rel="noreferrer" href="https://office.unicoinnetwork.com" target="_blank">Communications</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Header;
