import React from 'react';
function Money() {
    return (
        <>
            <div className="row">
                <div className="col-xl-11 col-xxl-8">
                    <div className="deposit-funds-page">
                        <h3>Money
                            </h3>
                        <div className="row">
                            <div className="col-sm-6 col-xxl-5 col-xxxl-4">
                                <div className="money-deposit-box">
                                    <div className="deposit-amount-box">
                                        <div className="money-line">
                                            <img
                                                src="../icons/enter money.png"
                                                className="enter-money-img"
                                                alt="enter-money-img"
                                            ></img>
                                            <img
                                                src="../icons/get.png"
                                                className="get-img"
                                                alt="get-img"
                                            ></img>
                                        </div>
                                        <div className="your-balance-box">
                                            <div className="row g-0 ms-3 align-items-center mb-5">
                                                <div className="col">
                                                    <div className="your-balance">
                                                        <h6>Your Balance</h6>
                                                        <h2>USD 1,000</h2>
                                                        <div className="line"></div>

                                                        <h6>Enter Money Transfer Amount</h6>
                                                        <h2>451,000</h2>
                                                    </div>
                                                </div>
                                                <div className="col-auto text-center">
                                                    <img
                                                        src="../images/coins/U-1-small.png"
                                                        className="get-img ms-2"
                                                        alt="get-img"
                                                    ></img>
                                                </div>
                                            </div>
                                            <div className="service-fee">
                                                <div className="line"></div>
                                                <h4>$ 10.00</h4>
                                                <p>(Service fee)</p>
                                            </div>
                                            <div className="service-fee">
                                                <div className="line"></div>
                                                <h4>$ 1.00</h4>
                                                <p>(Network fee)</p>
                                            </div>
                                            <div className="service-fee">
                                                <div className="line"></div>
                                                <h4>$ 10.00</h4>
                                                <p>(FX Rates)</p>
                                            </div>
                                            <div className="row g-0 ms-3 align-items-center mt-5">
                                                <div className="col">
                                                    <div className="your-balance">
                                                        <h6>Your Balance</h6>
                                                        <h2>USD 1,000</h2>
                                                    </div>
                                                </div>
                                                <div className="col-auto text-center">
                                                    <img
                                                        src="../images/coins/U-1-small.png"
                                                        className="get-img ms-2"
                                                        alt="get-img"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Money;