//eslint-disable-next-line
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
} from "../../../../api/dropdown";
import { decrypytdata } from "../../../../api/walletKey";
// import PrivateModal from "../../../../Components/PrivateModal";
function Beneficiary() {
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  //eslint-disable-next-line
  const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  //eslint-disable-next-line
  const [Data, setData] = useState();
  const fetchdropdwon = async () => {
    // let dropdownvalues = await getDropdownvalues("LKPayeeType");
    // setDropdown(dropdownvalues);

    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };
  // const formik = useFormik({
  //     initialValues: {
  //         PublicWalletAddress: "",
  //         Email: "",
  //         CountryCode: "",
  //         DialingCountryCode: "",
  //         MobilePhone: "",
  //         AccountName: "",
  //         AccountClass: "",
  //         AccountType: "",
  //         SwiftCode: "",
  //         BankCodeType: "",
  //         BankCode: "",
  //         AccountNumber: "",
  //         UserName: "",
  //         CryptocurrencyType: "",
  //         PublicWalletAddress2: "",
  //     },
  //     validationSchema: Yup.object({
  //         PublicWalletAddress: Yup.string().required(
  //             "Public Wallet Adress is required!"
  //         ),
  //         Email: Yup.string().required("Email Adress is required!"),
  //         AccountName: Yup.string().required("Account Name is required!"),
  //         MobilePhone: Yup.string().required("Mobile Phone is required!"),
  //         CountryCode: Yup.string().required("Country Code is required!"),
  //         DialingCountryCode: Yup.string().required(
  //             "Dialing Country Code is required!"
  //         ),
  //         AccountClass: Yup.string().required("Account Class is required!"),
  //         AccountType: Yup.string().required("Account Type is required!"),
  //         SwiftCode: Yup.string().required("Swift Code is required!"),
  //         BankCodeType: Yup.string().required("Bank Code Type is required!"),
  //         BankCode: Yup.string().required("Bank Code is required!"),
  //         AccountNumber: Yup.string().required("Account Number is required!"),
  //         UserName: Yup.string().required("User Name is required!"),
  //         CryptocurrencyType: Yup.string().required(
  //             "Cryptocurrency Type is required!"
  //         ),
  //         PublicWalletAddress2: Yup.string().required(
  //             "Public Wallet Address is required!"
  //         ),
  //     }),
  //     // onSubmit: (values) => {
  //     //     nextstep()
  //     // }
  // });
  let APPID = "";
  let APIKEY = "";
  //eslint-disable-next-line
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }

    if (APPID && APIKEY) {
      const encryptedLedger = "";
      const Entity = await decrypytdata(encryptedLedger.data, APIKEY);

      // let data = JSON.parse(JSON.parse(Entity).CommandResponse);
      let data = JSON.parse(Entity);
      console.log(
        "🚀 ~ file: CreatePayee.js ~ line 171 ~ closeModal ~ data",
        data
      );
    }
    setPrivateModal(false);
  };
  const initialValues = {
    PublicWalletAddress: "",
    Email: "",
    CountryCode: "",
    DialingCountryCode: "",
    MobilePhone: "",
    AccountName: "",
    AccountClass: "",
    AccountType: "",
    SwiftCode: "",
    BankCodeType: "",
    BankCode: "",
    AccountNumber: "",
    UserName: "",
    CryptocurrencyType: "",
    PublicWalletAddress2: "",
  };
//eslint-disable-next-line
  const validationSchema = Yup.object({
    PublicWalletAddress: Yup.string().required(
      "Public Wallet Adress is required!"
    ),
    Email: Yup.string().required("Email Adress is required!"),
    AccountName: Yup.string().required("Account Name is required!"),
    MobilePhone: Yup.string().required("Mobile Phone is required!"),
    CountryCode: Yup.string().required("Country Code is required!"),
    DialingCountryCode: Yup.string().required(
      "Dialing Country Code is required!"
    ),
    AccountClass: Yup.string().required("Account Class is required!"),
    AccountType: Yup.string().required("Account Type is required!"),
    SwiftCode: Yup.string().required("Swift Code is required!"),
    BankCodeType: Yup.string().required("Bank Code Type is required!"),
    BankCode: Yup.string().required("Bank Code is required!"),
    AccountNumber: Yup.string().required("Account Number is required!"),
    UserName: Yup.string().required("User Name is required!"),
    CryptocurrencyType: Yup.string().required(
      "Cryptocurrency Type is required!"
    ),
    PublicWalletAddress2: Yup.string().required(
      "Public Wallet Address is required!"
    ),
  });
  useEffect(() => {
    // if (publicKeys?.publicKey?.publicAddress) {
    //   if (!publicKeys?.privateKey?.PrivateWalletAddress)
    // }
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys.privateKey.PrivateWalletAddress]);
  return (
    <>
      {/* {PrivateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      <div className="row">
        <div className="col-12">
          <div className="originating-account link-wallet-box ms-0">
            <h3>Beneficiary</h3>
            <h4>
              Select the wallet identification type and enter the related
              information
            </h4>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              // validationSchema={validationSchema}
              onSubmit={(values) => {
                setData(() => {});
                // setdata(gridData);
                // setPrivateModal(true);
              }}
            >
              {(formik) => {
                //eslint-disable-next-line
                const {setFieldValue } = formik;
                return (
                  <div className="link-wallet-tabs">
                    <ul
                      className="nav nav-pills mb-5"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-payment-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-payment"
                          type="button"
                          role="tab"
                          aria-controls="pills-payment"
                          aria-selected="false"
                        >
                          <img
                            src="../icons/Public Wallet-light.png"
                            className="link-payment-img none"
                            alt="link-payment-img"
                          ></img>
                          <img
                            src="../icons/Public Wallet.png"
                            className="link-payment-img show"
                            alt="link-payment-img"
                          ></img>
                          Public Wallet
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-email-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-email"
                          type="button"
                          role="tab"
                          aria-controls="pills-email"
                          aria-selected="true"
                        >
                          <img
                            src="../icons/link-email.png"
                            className="link-email-img none"
                            alt="link-email-img"
                          ></img>
                          <img
                            src="../icons/link-email-light.png"
                            className="link-email-img show"
                            alt="link-email-img"
                          ></img>
                          Email
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-phone-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-phone"
                          type="button"
                          role="tab"
                          aria-controls="pills-phone"
                          aria-selected="false"
                        >
                          <img
                            src="../icons/link-phone.png"
                            className="link-phone-img none"
                            alt="link-phone-img"
                          ></img>
                          <img
                            src="../icons/link-phone-light.png"
                            className="link-phone-img show"
                            alt="link-phone-img"
                          ></img>
                          Phone
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-account-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-account"
                          type="button"
                          role="tab"
                          aria-controls="pills-account"
                          aria-selected="false"
                        >
                          <img
                            src="../icons/link-bank.png"
                            className="link-bank-img none"
                            alt="link-bank-img"
                          ></img>
                          <img
                            src="../icons/link-bank-light.png"
                            className="link-bank-img show"
                            alt="link-bank-img"
                          ></img>
                          Bank Account
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-username-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-username"
                          type="button"
                          role="tab"
                          aria-controls="pills-username"
                          aria-selected="false"
                        >
                          <img
                            src="../icons/link-username.png"
                            className="link-username-img none"
                            alt="link-username-img"
                          ></img>
                          <img
                            src="../icons/link-username-light.png"
                            className="link-username-img show"
                            alt="link-username-img"
                          ></img>
                          Username
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link me-0"
                          id="pills-cryptocurrency-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-cryptocurrency"
                          type="button"
                          role="tab"
                          aria-controls="pills-cryptocurrency"
                          aria-selected="false"
                        >
                          <img
                            src="../icons/link-cryptocurrency.png"
                            className="link-cryptocurrency-img none"
                            alt="link-cryptocurrency-img"
                          ></img>
                          <img
                            src="../icons/link-cryptocurrency-light.png"
                            className="link-cryptocurrency-img show"
                            alt="link-cryptocurrency-img"
                          ></img>
                          Cryptocurrency
                        </button>
                      </li>
                    </ul>
                    <Form className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade wallet-information-box ms-0"
                        id="pills-payment"
                        role="tabpanel"
                        aria-labelledby="pills-payment-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Address"
                                  id="PublicWalletAddress"
                                  onChange={formik.handleChange}
                                  value={formik.values.PublicWalletAddress}
                                />
                                <label className="ps-0 pb-0">
                                  Public Wallet Address
                                </label>
                                {formik.errors.PublicWalletAddress ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.PublicWalletAddress}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade show active wallet-information-box ms-0"
                        id="pills-email"
                        role="tabpanel"
                        aria-labelledby="pills-email-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Email"
                                  id="Email"
                                  onChange={formik.handleChange}
                                  value={formik.values.Email}
                                />
                                <label className="ps-0 pb-0">Email</label>
                                {formik.errors.Email ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.Email}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade wallet-information-box ms-0"
                        id="pills-phone"
                        role="tabpanel"
                        aria-labelledby="pills-phone-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <label>Country Code</label>
                            <Field
                              as="select"
                              className="form-select form-control"
                              id="countrycode"
                              name="countrycode"
                              onChange={(e) => {
                                // console.log(e.target.value, "Country Code");
                                setFieldValue("countrycode", e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                            >
                              {countryCode &&
                                countryCode.map((value, index) => {
                                  return (
                                    <>
                                      <option
                                        value={value.countrycode}
                                        key={index}
                                      >
                                        {value.isoCountryname}
                                      </option>
                                    </>
                                  );
                                })}
                            </Field>
                            <div>
                              <ErrorMessage
                                name="countrycode"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <label>Dialing Country Code</label>
                            <Field
                              as="select"
                              className="form-select form-control"
                              id="mobileDialingcode"
                              name="mobileDialingcode"
                              onChange={(e) => {
                                // console.log(e.target.value, "Dialing Code");
                                setFieldValue(
                                  "mobileDialingcode",
                                  e.target.value
                                );
                              }}
                              onBlur={formik.handleBlur}
                            >
                              {dailingCode &&
                                dailingCode.map((value, index) => {
                                  return (
                                    <>
                                      <option value={value.keycode} key={index}>
                                        {value.displayname}
                                      </option>
                                    </>
                                  );
                                })}
                            </Field>
                            <div>
                              <ErrorMessage
                                name="mobileDialingcode"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Number"
                                  id="MobilePhone"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.MobilePhone}
                                />
                                <label className="ps-0 pb-0">
                                  Mobile Phone
                                </label>
                                {formik.errors.MobilePhone ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.MobilePhone}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade wallet-information-box ms-0"
                        id="pills-account"
                        role="tabpanel"
                        aria-labelledby="pills-account-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Account Name"
                                  id="AccountName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.AccountName}
                                />
                                <label className="ps-0 pb-0">
                                  Account Name
                                </label>
                                {formik.errors.AccountName ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.AccountName}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Account Class"
                                  id="AccountClass"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.AccountClass}
                                />
                                <label className="ps-0 pb-0">
                                  Account Class
                                </label>
                                {formik.errors.AccountClass ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.AccountClass}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="accountTypecode"
                                  id="accountTypecode"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.SwiftCode}
                                />
                                <label className="ps-0 pb-0">Account Type</label>
                                {formik.errors.SwiftCode ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.SwiftCode}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Swift Code"
                                  id="SwiftCode"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.SwiftCode}
                                />
                                <label className="ps-0 pb-0">Swift Code</label>
                                {formik.errors.SwiftCode ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.SwiftCode}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Code"
                                  id="BankCodeType"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.BankCodeType}
                                />
                                <label className="ps-0 pb-0">
                                  Bank Code Type
                                </label>
                                {formik.errors.BankCodeType ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.BankCodeType}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Code"
                                  id="BankCode"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.BankCode}
                                />
                                <label className="ps-0 pb-0">Bank Code</label>
                                {formik.errors.BankCode ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.BankCode}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Number"
                                  id="AccountNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.AccountNumber}
                                />
                                <label className="ps-0 pb-0">
                                  Account Number
                                </label>
                                {formik.errors.AccountNumber ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.AccountNumber}
                                  </div>
                                ) : null}
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-username"
                        role="tabpanel"
                        aria-labelledby="pills-username-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Username"
                                  id="UserName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.UserName}
                                />
                                <label className="ps-0 pb-0">Username</label>
                                {formik.errors.UserName ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.UserName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-cryptocurrency"
                        role="tabpanel"
                        aria-labelledby="pills-cryptocurrency-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <label>Cryptocurrency Type</label>
                            <Field
                              className="form-select form-control w-100"
                              id="CryptocurrencyType"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.CryptocurrencyType}
                            />
                              
                            
                            {formik.errors.CryptocurrencyType ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.CryptocurrencyType}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="mb-5 wallet-information-form">
                              <div className="Field-box  form-floating">
                                <Field
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Address"
                                  id="PublicWalletAddress2"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.PublicWalletAddress2}
                                />
                                <label className="ps-0 pb-0">
                                  Public Wallet Address
                                </label>
                                {formik.errors.PublicWalletAddress2 ? (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.PublicWalletAddress2}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default Beneficiary;
