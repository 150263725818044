import React from "react";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../../action/index";
import { useSelector, useDispatch } from "react-redux";

import { decrypytdata } from "../../../../../api/walletKey";
import { success } from "./../../../../../Components/alert";
import { useNavigate } from "react-router-dom";
import { CreatePayeeApi } from "../../../../../api/PayeesApi";

function Review({ previous, walletData, newData }) {
  console.log("🚀 ~ file: Review.js ~ line 19 ~ walletData", walletData);

  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
      console.log("🚀 ~ file: Review.js ~ line 23 ~ closeModal ~ APPID", APPID);
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: Review.js ~ line 27 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await CreatePayeeApi(APPID, APIKEY, {
        ...walletData,
        ...newData,
      });
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Review.js ~ line 30 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // debugger;
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      dispatch(setPrivateId(""));
      // debugger;
    }
    dispatch(showLoader(false));
    // setPrivateModal(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-11">
          <div className="review-wallet review-new-token">
            <div className="container-fluid p-0 ">
              <div className="row m-0">
                <div className="col-md-12 mt-5">
                  <div className="review-wallet-box ms-0">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="review-wallet-info-box mb-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="heading">
                                <h3>Payee Information</h3>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <hr className="mb-4" />
                            </div>
                            <h6>Beneficiary Information</h6>
                            {newData?.payeeTypecode && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Payee Type</h4>
                                    <p>{newData?.payeeTypecode}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {newData?.firstname && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Frist Name</h4>
                                    <p>{newData?.firstname}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {newData?.secondFirstname && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Second Frist Name</h4>
                                    <p>{newData?.secondFirstname}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {newData?.lastname && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Last Name</h4>
                                    <p>{newData?.lastname}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {newData?.secondLastname && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Second Last Name</h4>
                                    <p>{newData?.secondLastname}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {newData?.countrycode && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Country</h4>
                                    <p>{newData?.countrycode}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {newData?.statecode && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>State</h4>
                                    <p>{newData?.statecode}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="review-wallet-info-box mb-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="heading">
                                <h3>Wallet Information</h3>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <hr className="mb-4" />
                            </div>
                            {walletData?.PublicAddress && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <h6>Public Address</h6>
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Public Address</h4>
                                    <p>{walletData?.PublicAddress}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {walletData?.username && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <h6>User Name</h6>
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>User Name</h4>
                                    <p>{walletData?.username}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {walletData?.accountNumber && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <h6>Bank</h6>
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Account Type</h4>
                                    <p>{walletData?.accountTypecode}</p>
                                  </div>
                                </div>
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Account Number</h4>
                                    <p>{walletData?.accountNumber}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {walletData?.email && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <h6>Email</h6>
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>User Name</h4>
                                    <p>{walletData?.email}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {walletData?.mobilePhone && (
                              <div className="col-sm-6 col-lg-4 col-xl-3">
                                <h6>Mobile</h6>
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Mobile Phone</h4>
                                    <p>{walletData?.mobilePhone}</p>
                                  </div>
                                </div>
                                {/* <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h4>Mobile Dialingcode</h4>
                                    <p>{walletData?.mobileDialingcode}</p>
                                  </div>
                                </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 p-0">
          <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
            <button
              type="button"
              className="btn cancel-btn mb-3 mb-md-0 me-4"
              onClick={() => {
                // cleandata();
                previous();
              }}
            >
              Previous
            </button>
            <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">
              CANCEL
            </button>
            <button
              type="button"
              className="btn next-btn mb-3 mb-md-0"
              onClick={() => {
                closeModal();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review;
