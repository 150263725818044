import React from 'react'
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
const SmartCard = () => {
    return (
        <>
        <Header/>
            <div className="smart-card">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-xl-5">
                            <div className="smart-card-text">
                                <h6>Featuring point to point physical cash.</h6>
                                <h3>Introducing the World's First Cryptocurrency Smart Card.</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-7">
                            <img src="../images/smart-card-img.png" className="img-fluid" alt="smart-img" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="technologies">
                                <h2>Unicoin Smart Card Technologies</h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="technologies-box">
                                <img src="../images/Chip.png" alt="Chip-img" />
                                <h4>Contact</h4>
                                <p>Contact chips support cryptographic functions to prevent counterfeiting of cards and additional functions that make them more secure than traditional magnetic-stripe cards.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div className="technologies-box">
                                <img src="../images/Contactless.png" alt="Contactless-img" />
                                <h4>Contactless</h4>
                                <p>Contactless enable transactions using proximity NFC payment devices. These devices allow transactions to be made by waving or tapping on an Unicoin compatible enabled terminal. </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4 mb-md-0">
                            <div className="technologies-box">
                                <img src="../images/Mobile.png" alt="Mobile-img" />
                                <h4>Mobile</h4>
                                <p>Unicoin supports Bluetooth, NFC, and other mobile technologies enabling the mobile phone to operates a point of sale (POS) device and to transact remote commerce and mobile payments.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="technologies-box">
                                <img src="../images/Payment.png" alt="Payment-img" />
                                <h4>Payment Tokenisation</h4>
                                <p>Unicoin payment tokenization provides a framework for transacting more safe and secure digital commerce and payments preserving privacy and preventing fraud and counterfeiting.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
           
        </>
    )
}

export default SmartCard;