import { ADMIN_URL, WEB_WALLET, WEB_WALLET_URL } from "../Constatnts/config.js";
import http, { httpAdmin } from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getWallet(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // walletTypecode: "",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/GetWallets",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/wallet/GetWallets", values, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}

export async function createWallet(
  publickey,
  privateKey,
  walletData
  // peopleData
) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/CreateWallet",
    walletData,
    // peopleData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/wallet/CreateWallet",
    walletData,
    // peopleData,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function suspendWallet(publickey, privateKey, suspendWalletData) {
  const modifyIssusersDetails = {
    sourcePublicAddress: publickey,
    issuercode: "",
  };
  console.log(modifyIssusersDetails, "modifyIssusersDetails");
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/SuspendWallet",
    modifyIssusersDetails,
    publickey,
    privateKey
  );
  const response = await http.post("/wallet/SuspendWallet", suspendWalletData, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}

export async function pauseWallet(publickey, privateKey, pauseWalletData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/PauseWallet",
    pauseWalletData,
    publickey,
    privateKey
  );
  const response = await http.post("/wallet/PauseWallet", pauseWalletData, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}

export async function unPauseWallet(publickey, privateKey, unPauseWalletData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/UnpauseWallet",
    unPauseWalletData,
    publickey,
    privateKey
  );
  const response = await http.post("/wallet/UnpauseWallet", unPauseWalletData, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}

export async function AttacheWallet(publickey, privateKey, AttachWalletData) {
  // const value = {
  //   sourcePublicAddress: publickey,
  //   AttachWalletData
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/AttachWallet",
    // values,
    AttachWalletData,
    publickey,
    privateKey
  );

  const response = await http.post("/wallet/AttachWallet", AttachWalletData, {
    headers: {
      Authorization: token,
    },
  });

  return response;
}
export async function GetWalletQRCode(publickey, privateKey) {
  const value = {
    sourcePublicAddress: publickey,
  };
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/security/GetWalletQRCode",
    value,

    publickey,
    privateKey
  );

  const response = await httpAdmin.post(
    `/security/GetWalletQRCode?publicAddress=${publickey}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}
export async function CreateWalletLink(
  publickey,
  privateKey,
  AttachWalletData
) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/CreateWalletLink",
    // values,
    AttachWalletData,
    publickey,
    privateKey
  );

  const response = await http.post(
    "/wallet/CreateWalletLink",
    AttachWalletData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}

export async function AttachPhotoAPI(publickey, privateKey, data) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/AttachPhoto",
    data,
    publickey,
    privateKey
  );
  const response = await http.post("/money/AttachPhoto", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function uploadPhotoAPI(publickey, privateKey, data) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET + "/common/UploadFile",
    data,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/common/UploadFile",
    data,
    { PublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
