import React from "react";
function Award() {
    return (
        <>
            <div className="unicoin-store">
                <a
                    href="https://tadsawards.org/2020-winners/yubi-money/"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="container">
                        <div className="best-innovation-heading unicoin-store-heading text-center">
                            <h3>Best Innovation of the Year</h3>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src="images/trophy.png" className="trophy-img" alt="trophy-img" />
                                <h2 className="trophy-heading">2020 HONGKONG BLOCKCHAIN WEEK</h2>
                                <img src="images/tads.png" className="tads-img" alt="tads-img" />
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </>
    );
}

export default Award;
