const DigitalCurrency = () => {
  return (
    <section className="section section-3">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <h2 className="small-heading">
              Digital Currency
              <br />
              <span>Network Monitoring</span>
            </h2>
            <div className="small-divider" />
            <p>
              The Global KYC Registry provides a global unique identifier for
              all customers which are associated with all of their financial
              transactions.
            </p>
            <p>
              This enables comprehensive network monitoring of customer
              transactions across financial institutions and across country
              borders.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DigitalCurrency;
