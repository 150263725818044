import React from "react";
// import Ledger from "./TargetSubmenu/Ledger";
//import { Ledger } from "../../../../Constatnts/Sidebaritems";
import Bank from "../wallettowallettransfer/TargetSubmenu/Bank";
import Ledger from "../wallettowallettransfer/TargetSubmenu/Ledger";
import Entity from "../wallettowallettransfer/TargetSubmenu/Entity";
import People from "../wallettowallettransfer/TargetSubmenu/People";
import Exchange from "./TargetSubmenu/Exchange";
import { useEffect } from "react";

function Beneficiary({
  transferData,
  step,
  setTargetData,
  previous,
  nextstep,
}) {
  console.log(
    "🚀 ~ file: Beneficiary.js ~ line 5 ~ Beneficiary ~ setdata",
    transferData
  );

  useEffect(() => {
    // 👇️ scroll to top on page load
    if (step === 2) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [step]);

  return (
    <>
      <ul class="nav nav-pills mt-5 mb-4" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4 active"
            id="Bank-tab"
            data-bs-toggle="pill"
            data-bs-target="#Bank"
            type="button"
          >
            Bank
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4"
            id="Exchange-tab"
            data-bs-toggle="pill"
            data-bs-target="#Exchange"
            type="button"
          >
            Exchange
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link  py-2 px-4"
            id="Ledger-tab"
            data-bs-toggle="pill"
            data-bs-target="#Ledger"
            type="button"
          >
            Ledger
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4"
            id="Entity-tab"
            data-bs-toggle="pill"
            data-bs-target="#Entity"
            type="button"
          >
            Merchant
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="target-menu nav-link py-2 px-4"
            id="People-tab"
            data-bs-toggle="pill"
            data-bs-target="#People"
            type="button"
          >
            Person
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="Bank">
          <Bank setTargetData={setTargetData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="Ledger" role="tabpanel">
          <Ledger setTargetData={setTargetData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="Entity" role="tabpanel">
          <Entity setTargetData={setTargetData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="People" role="tabpanel">
          <People setTargetData={setTargetData} nextstep={nextstep} />
        </div>
        <div class="tab-pane fade" id="Exchange" role="tabpanel">
          <Exchange setTargetData={setTargetData} nextstep={nextstep} />
        </div>
      </div>
      <div className="col-xxl-11">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn cancel-btn mb-3 mb-md-0 me-4"
            onClick={previous}
          >
            PREVIOUS
          </button>
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
            onClick={nextstep}
            // onClick={() => {
            //   setPrivateModal(true);
            // }}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
}
export default Beneficiary;
