import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetBalance } from "../../../../api/Balance";
import * as Yup from "yup";
import { decrypytdata } from "../../../../api/walletKey";
import Pagination from "../../../Headermenu/NewPagination";
import { showLoader } from "../../../../action";
function Originator({ setsourceData, nextstep, setpaymentData, units }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [rows, setRows] = useState([]);
  const [checkedRows, setCheckedRows] = useState();
  const [maxRows, setMaxRows] = useState("");
  const [balance, setBalance] = useState("");
  // const [units, setUnits] = useState("");
  console.log("🚀 ~ file: Originator.js ~ line 8 ~ Originator ~ rows", rows);
  const publicKeys = useSelector((state) => state.keyReducer);

  //paginason
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postparPage, setPostparPage] = useState(25);

  const initialValues = {
    ledgerBalanceTypecode: "",
    ledgerBalancecode: "",
    ledgerTypecode: null,
    tokenSymbol: "",
  };
  const initialValues1 = {
    sourceUnits: " ",
  };
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedLedger = await GetBalance(APPID, APIKEY, initialValues);
      dispatch(showLoader(false));
      const Ledgers = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: GetBalances.js ~ line 31 ~ fetchdata ~ Ledgers",
        Ledgers
      );
      let data = JSON.parse(JSON.parse(Ledgers).CommandResponse);
      console.log(
        "🚀 ~ file: GetBalances.js ~ line 33 ~ fetchdata ~ data",
        data
      );
      // setRows(JSON.parse(Ledgers));
      if (data) {
        let dataArr = [];
        let temp = data.shift();

        if (temp) {
          setMaxRows(temp.MaxRows);
        }
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
          // console.log(dataMain, "dataMain")
        });
        setRows(dataArr);
      }
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const validationSchema = Yup.object({
    sourceUnits: Yup.number()
      .typeError("you must specify a number")
      .required("Insufficient funds")
      .max(balance, "Insufficient funds")
      .min(0),
  });

  return (
    <>
      {isValidPublicAddress && (
        <div className="row mt-0">
          <div className="col-12">
            <div className="originating-account link-wallet-box ms-0">
              <div className="link-wallet-tabs">
                <div className="row ">
                  <div className="col-xxl-11">
                    <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-end px-4">
                        <div className="">
                          <div className="line-input input-group mb-3">
                            <span className="input-group-text">
                              <i className="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="search"
                              onChange={(e) => {
                                // setBankSearch(e.target.value);
                                console.log("abdkjbadb", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" ">
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            border: 1,
                            borderColor: "#c6c6c6",
                          }}
                        >
                          <TableContainer
                            component={Paper}
                            sx={{
                              boxShadow: "none",
                              maxHeight: 500,
                              borderBottom: 1,
                              borderColor: "#c6c6c6",
                            }}
                          >
                            <Table
                              stickyHeader
                              className="table table-hover table-striped"
                              sx={{ minWidth: 650, mb: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead
                                className="sticky-top"
                                sx={{
                                  zIndex: "10",
                                  borderBottom: 1,
                                  borderColor: "#c6c6c6",
                                }}
                              >
                                <TableRow className="data-table-row">
                                  <TableCell className="data-table-head">
                                    <div className="lable-div">
                                      <span className="text-dark">Select</span>
                                    </div>
                                  </TableCell>

                                  <TableCell className="data-table-head">
                                    <div className="lable-div">
                                      <span className="text-dark">
                                        Digital Currency Account
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="data-table-head"
                                    sx={{ minWidth: 250 }}
                                  >
                                    <div className="lable-div">
                                      <span className="text-dark">
                                        Token Symbol
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="data-table-head"
                                    sx={{ minWidth: 150 }}
                                  >
                                    <div className="lable-div">
                                      <span className="text-dark">
                                        Currency Code
                                      </span>
                                    </div>
                                  </TableCell>

                                  <TableCell className="data-table-head">
                                    <div className="lable-div">
                                      <span className="text-dark">Balance</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.map((items, index) => {
                                  return (
                                    <>
                                      <TableRow
                                        className={
                                          " " + (checkedRows ? "rowselect" : "")
                                        }
                                        onDoubleClick={() => {
                                          console.log("double");
                                          setsourceData((prev) => {
                                            return { ...prev, ...items };
                                          });
                                          nextstep();
                                        }}
                                        // key={index}
                                        // onClick={() => setActive(items)}
                                        // id={index}
                                        // className={isActive === items ? "is-checked" : ""}
                                      >
                                        {/* <span>{index}</span> */}
                                        <TableCell>
                                          <div className=" table-form-check ">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="flexCheckDefault"
                                              name="flexCheckDefault"
                                              checked={
                                                checkedRows === items.rowid
                                              }
                                              onChange={() => {
                                                setCheckedRows(items.rowid);
                                                // setInChecked(true);
                                              }}
                                              onClick={() => {
                                                setsourceData((prev) => {
                                                  return { ...prev, ...items };
                                                });
                                                setBalance(items.Balance);
                                              }}
                                            />
                                          </div>
                                        </TableCell>
                                        <TableCell>
                                          {items.Displayname}
                                        </TableCell>
                                        <TableCell>
                                          {items.TokenSymbol}
                                        </TableCell>
                                        <TableCell>
                                          {items.Currencycode}
                                        </TableCell>
                                        <TableCell>
                                          {items.Balance.toFixed(6)}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Box sx={{ width: "100%" }}>
                            <Box className="row py-3 px-2">
                              <Box className="col-12 col-md-3 col-xl-2">
                                <div className="row-select">
                                  <select
                                    class="form-select pagination-select"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setPostparPage(e.target.value);
                                    }}

                                    // placeholder="Bank Type"
                                  >
                                    {/* <option selected>Select rows</option> */}

                                    <option value={10}>10 Rows</option>
                                    <option selected value={25}>
                                      25 Rows
                                    </option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={200}>200 Rows</option>
                                  </select>
                                </div>
                              </Box>

                              <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                                <Pagination
                                  postsperPage={postparPage}
                                  maxRows={maxRows}
                                  paginate={paginate}
                                  currentPage={currentPage}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wallet-information-box ms-0">
                  <div className="row mt-5">
                    <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                      <Formik
                        initialValues={initialValues1}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                          // setValue({
                          //   accuityID: values.accuityID,
                          //   issuercode: values.issuercode,
                          //   hqIssuercode: values.hqIssuercode,
                          //   issuerTypecode: values.issuerTypecode,
                          //   issuer: values.issuer,
                          //   displayname: values.displayname,
                          //   institutionTypecode: values.institutionTypecode,
                          //   officeTypecode: values.officeTypecode,
                          //   nativeKEY: values.nativeKEY,
                          //   issuerUUID: values.issuerUUID,
                          //   incorporationNumber: values.incorporationNumber,
                          //   federal_TaxID: values.federal_TaxID,
                          //   isO_LEI: values.isO_LEI,
                          //   addressLine1: values.addressLine1,
                          //   addressLine2: values.addressLine2,
                          //   addressLine3: values.addressLine3,
                          //   neighborhood: values.neighborhood,
                          //   cityTown: values.cityTown,
                          //   stateProvince: values.stateProvince,
                          //   postalCode: values.postalCode,
                          //   countrycode: values.countrycode,
                          //   currencycode: currencyCode.keycode,
                          //   timezoneID: values.timezoneID,
                          //   username: values.username,
                          //   userUUID: values.userUUID,
                          //   firstname: values.firstname,
                          //   secondFirstname: values.secondFirstname,
                          //   lastname: values.lastname,
                          //   secondLastname: values.secondLastname,
                          //   jobTitle: values.jobTitle,
                          //   email: values.email,
                          //   mobileDialingcode: values.mobileDialingcode,
                          //   mobilePhone: values.mobilePhone,
                          //   imageFN: values.imageFN,
                          // });
                          // nextstep();
                        }}
                      >
                        {(formik) => {
                          const { errors, touched, setFieldValue } = formik;
                          return (
                            <form className=" wallet-information-form">
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="number"
                                    className="form-control"
                                    placeholder="Send Amount"
                                    name="sourceUnits"
                                    id="sourceUnits"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "sourceUnits",
                                        e.target.value
                                      );
                                      setpaymentData(e.target.value);
                                    }}
                                    value={formik.sourceUnits}
                                  />
                                  <label className="ps-0 pb-0">
                                    SEND AMOUNT
                                  </label>
                                  <i className="bi bi-check-lg"></i>
                                  <div
                                    className={
                                      errors.sourceUnits && touched.sourceUnits
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="sourceUnits"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Reference Code (optional)"
                                  />
                                  <label className="ps-0 pb-0">
                                    {" "}
                                    REFERENCE CODE
                                  </label>
                                  <i className="bi bi-check-lg"></i>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Comments (optional)"
                                  />
                                  <label className="ps-0 pb-0">
                                    Comments (optional)
                                  </label>
                                  <i className="bi bi-check-lg"></i>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Attach Documents (optional)</label>
                                <div class="input-box">
                                  <div class="input-group custom-file-button">
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="inputGroupFile"
                                    />
                                    <label
                                      class="input-group-text"
                                      for="inputGroupFile"
                                    >
                                      BROWSE
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Originator;
