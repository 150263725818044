      import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../../Components/Header";
import Sidebar from "../../../../Components/Sidebar";
function ReviewNewToken() {
  let { pathname } = useLocation();
  return (
    <>
      <Header />
      <div className="review-wallet review-new-token">
        <div className="container-fluid p-0 ">
          <div className="row m-0">
            <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
              <Sidebar />
            </div>

            <div className="col-md-9 col-lg-9 col-xxl-10">
              <div className="main-heading">
                <i className="bi bi-arrow-left"></i>
                <h3>
                                  Tokens
              </h3>
                          </div>
                          <div className="sub-heading">
                              <h3>
                                  {pathname === "/webwallet/modifytoken"
                                      ? "Modify Token"
                                      : "Create Token"}
                              </h3>
                              <p>This function enables the creation of a digital currency on the Unicoin network.</p>
                          </div>

              <div className="review-wallet-box">
                <div className="row">
                  <div className="col-xxl-11">
                    <div className="review-wallet-info-box mb-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            <h3>Token Information</h3>
                            <i className="bi bi-pencil-square"></i>
                          </div>
                          <hr className="mb-4" />
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Basic Information</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Class</h4>
                              <p>Thomas</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Type</h4>
                              <p>User</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Symbol</h4>
                              <p>User</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Name</h4>
                              <p>Token</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Display Name (Alias)</h4>
                              <p>Tokenn</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Currency Code</h4>
                              <p>USD</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Supply</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Supply Type</h4>
                              <p>User</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Maximum Supply</h4>
                              <p>1,000,000</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Initial Supply</h4>
                              <p>10</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Decimal Price</h4>
                              <p>0,00</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-11">
                    <div className="review-wallet-info-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            <h3>Selected Currencies</h3>
                            <i className="bi bi-pencil-square"></i>
                          </div>
                          <hr className="mb-4" />
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>USD United States Dollar</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>BSD Bahamian dollar</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>SGD Singapore dollar</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>DKK Danish krone</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>EUR Euro</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>BDT Bangladeshi taka</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5> KYD Cayman Islands dollar</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>BSD Bahamian dollar</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>AZN Azerbaijani manat</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>BND Brunei dollar</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>CRC Costa Rican colon</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>BDT Bangladeshi taka</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-11 p-0">
                  <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                    <button
                      type="button"
                      className="btn cancel-btn mb-3 mb-md-0 me-4"
                    >
                      PREVIOUS
                    </button>
                    <button type="button" className="btn next-btn mb-3 mb-md-0">
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ReviewNewToken;
