import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getToken(publickey, privateKey, data) {
  let values = {
    sourcePublicAddress: publickey,
    tokenSymbol: data,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/GetTokens",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/GetTokens",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function getTokenBySymbol(publickey, privateKey, TokenSymbol) {
  let values = {
    sourcePublicAddress: publickey,
    TokenSymbol: TokenSymbol,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/GetTokens",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/GetTokens",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function CreateTokenApi(publickey, privateKey, tokendata) {
  // debugger;
  // let values = {
  //   ...tokendata,
  //   // sourcePublicAddress: publickey,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/CreateToken",
    tokendata,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/CreateToken",
    tokendata,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function ModifyTokenApi(publickey, privateKey, tokendata) {
  // debugger;
  // let values = {
  //   ...tokendata,
  //   citycode: null,
  // };
  // const inititalTokenDetails = {
  //   token: "Kryptonian",
  // displayname: "Kryptonian",
  // currencycodeList: "ARS",
  // decimalPlaces: 2,
  // }
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/ModifyToken",
    tokendata,
    // inititalTokenDetails,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/ModifyToken",
    tokendata,
    // inititalTokenDetails,
    // citycode: null,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}

export async function TokenSuspendAPI(publickey, privateKey, data) {
  console.log("🚀 ~ file: Token.js ~ line 118 ~ TokenSuspendAPI ~ data", data);
  let values = {
    sourcePublicAddress: publickey,
    tokenSymbol: data.tokenSymbol,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/SuspendToken",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/token/SuspendToken", values, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function TokenMintAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/Mint",
    data,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/Mint",
    data,
    // values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function TokenBurnAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,

  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/Burn",
    // values
    data,
    publickey,
    privateKey
  );
  const response = await http.post("/token/Burn", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function TokenPauseAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/Pause",
    data,
    // data,
    publickey,
    privateKey
  );
  const response = await http.post("/token/Pause", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function TokenUnPauseAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/Unpause",
    // values,
    data,
    publickey,
    privateKey
  );
  const response = await http.post("/token/Unpause", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function TokenRedeemAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/Redeem",
    data,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/Redeem",
    data,
    // values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function TokenSwapAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/Swap",
    data,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/Swap",
    data,
    // values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function TokenSupplyAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  //   data,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/TokenSupply",
    // values,
    data,
    publickey,
    privateKey
  );
  try {
    const response = await http.post(
      "/token/TokenSupply",
      data,
      // values,
      // data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err, "responseresponseresponse");
  }
}

export async function TokenPriceAPI(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/TokenPrice",
    data,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/TokenPrice",
    data,
    // values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function importTokenBatch(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/token/ImportTokenBatch",
    issuerData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/token/ImportTokenBatch",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
