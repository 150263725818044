import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDropdownvalues } from "../../../../api/dropdown";
import { FetchLedgers } from "../../../../api/MoneyServices";

function Money({ previous, nextstep, setLedgerData, transferData }) {
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    // const [ledgerTypedropdown, setLedgerTypedropdown] = useState([]);
    const [ledgerClass, setLedgerClass] = useState([]);
    // const [findLedgerType, setFindLedgerType] = useState([]);

    const [findLedgerClass, setFindLedgerClass] = useState([]);
    const [value, setValues] = useState("");

    const [selected, setSelected] = useState();
    const isSelected = (id) => (selected === id ? true : false);
    const [rows, setRows] = React.useState([]);

    const publicKeys = useSelector((state) => state.keyReducer);

    let APPID = "";
    let APIKEY = "";
    const fetchdata = async () => {
        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }
        if (APPID && APIKEY) {
            // let values;
            // if (findLedgerClass || findLedgerType) {
            //   values = {
            //     sourcePublicAddress: APPID,
            //     // ledgerClasscode: findLedgerClass,
            //     // ledgerTypecode: findLedgerType,
            //     ledgerClasscode: findLedgerClass,
            //     ledgerTypecode: findLedgerType,
            //   };
            // } else {
            //   values = {
            //     sourcePublicAddress: APPID,
            //   };
            // }

            const ndata = {
                ledgerClasscode: "RESERVED",
                // ledgerTypecode: findLedgerType,
            };
            // const grid = { itemcount: 10, pageId: 1 };
            const encryptedLedger = await FetchLedgers(APPID, APIKEY, ndata);
            // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);

            // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
            // const data = JSON.parse(tokens);
            let data = encryptedLedger.data;
            if (data) {
                let dataArr = [];
                data?.forEach((dataMain, index) => {
                    // multiple data
                    dataArr.push({ ...dataMain, rowid: index });
                });
                setRows(dataArr);
            }
        }
    };

    const fetchdropdwon = async () => {
        let lclass = await getDropdownvalues("LKLedgerClass");
        setLedgerClass(lclass);

        // let dropdownvalues = await getDropdownvalues("LKLedgerType");
        // setLedgerTypedropdown(dropdownvalues);
        // let dialing = await getDailingDropDown("GetDialingcodes");
        // setDailingCode(dialing);
    };
    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            if (publicKeys?.privateKey?.PrivateWalletAddress) {
                setisValidPublicAddress(true);
                fetchdropdwon();
                fetchdata();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);
    useEffect(() => {
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findLedgerClass]);

    // const moneycolumns = [
    //   {
    //     field: "displayname",
    //     renderHeader: () => (
    //       <Field
    //         as="select"
    //         className="form-select form-control"
    //         id="ledgerclass"
    //         name="ledgerclass"
    //         onChange={(e) => {

    //           setFindLedgerClass(e.target.value);
    //           console.log(
    //             "🚀 ~ file: CreateLedger.js ~ line 192 ~ CreateLedger ~ e.target.value",
    //             e.target.value
    //           );
    //         }}
    //         onBlur={Formik.handleBlur}
    //         // value={formik.values.ledgerTypeKeycode}
    //       >
    //         <option selected value={" "}>
    //           ledger Class
    //         </option>
    //         {ledgerClass &&
    //           ledgerClass.map((value, index) => {
    //             return (
    //               <>
    //                 <option value={value.displayName} key={index}>
    //                   {value.displayName}
    //                 </option>
    //               </>
    //             );
    //           })}
    //       </Field>
    //     ),
    //     type: "string",
    //     flex: 1,
    //     align: "center",
    //     headerAlign: "center",
    //   },
    //   {
    //     field: "ledgerClass",
    //     headerName: "Ledger Class",
    //     type: "string",
    //     flex: 1,
    //     align: "center",
    //     headerAlign: "center",
    //   },
    //   {
    //     field: "ledgerType",
    //     headerName: "Ledger Type",
    //     type: "string",
    //     flex: 1,
    //     align: "center",
    //     headerAlign: "center",
    //   },
    // ];
    const initialValues = {};
    return (
        <>
            {isValidPublicAddress && (
                <div>
                    <div className="row mt-5">
                        <div className="col-xxl-10">
                            <Formik
                                initialValues={initialValues}
                                // validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    console.log(
                                        "🚀 ~ file: CreateIssuer.js ~ line 174 ~ CreateIssuer ~ values",
                                        values
                                    );
                                    // debugger;
                                    // CreateIssuersApi(values);

                                }}
                            >
                                {(formik) => {
                                    const { setFieldValue } = formik;
                                    return (
                                        <div className="wallet-balances-box">
                                            <Form className="">
                                                <div className="d-md-flex justify-content-end px-4">
                                                    <div className="">
                                                        <div className="line-input input-group mb-3">
                                                            <span className="input-group-text">
                                                                <i className="bi bi-search"></i>
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="search"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive p-3 p-md-4">
                                                    <div className="table">
                                                        <TableContainer
                                                            component={Paper}
                                                            sx={{ boxShadow: "none" }}
                                                        >
                                                            {/* <table className="table table-striped"> */}
                                                            <Table
                                                                className="table table-hover table-striped"
                                                                sx={{ minWidth: 650 }}
                                                                aria-label="simple table"
                                                            >
                                                                <TableHead>
                                                                    <TableRow sx={{ height: "1px" }}>
                                                                        <TableCell sx={{ height: "inherit" }}>
                                                                            <Box sx={{ height: "100%" }}>
                                                                                <label className="table-lable">
                                                                                    Select
                                                                                </label>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell sx={{ height: "inherit" }}>
                                                                            <Box sx={{ height: "100%" }}>
                                                                                <label className="table-lable">
                                                                                    Ledger
                                                                                </label>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <label className="table-lable">
                                                                                Ledger Class
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                className="form-select form-control"
                                                                                id="ledgerclass"
                                                                                name="ledgerclass"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "ledgerclass",
                                                                                        e.target.value
                                                                                    );

                                                                                    setFindLedgerClass(e.target.value);
                                                                                    console.log(
                                                                                        "🚀 ~ file: CreateLedger.js ~ line 192 ~ CreateLedger ~ e.target.value",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                value={"RESERVED"}
                                                                            >
                                                                                <option selected value={" "}>
                                                                                    Ledger Class
                                                                                </option>
                                                                                {ledgerClass &&
                                                                                    ledgerClass.map((value, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option
                                                                                                    value={value.keycode}
                                                                                                    key={index}
                                                                                                >
                                                                                                    {value.displayName}
                                                                                                </option>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                            </Field>
                                                                            <div style={{ color: "red" }}>
                                                                                <ErrorMessage
                                                                                    name="ledgerclass"
                                                                                    component="span"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell sx={{ height: "inherit" }}>
                                                                            <Box sx={{ height: "100%" }}>
                                                                                <label className="table-lable">
                                                                                    Ledger Type
                                                                                </label>
                                                                            </Box>
                                                                            {/* <Field
                                      as="select"
                                      className="form-select form-control"
                                      id="ledgerTypeKeycode"
                                      name="ledgerTypeKeycode"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "ledgerTypeKeycode",
                                          e.target.value
                                        );

                                        setFindLedgerType(e.target.value);
                                        console.log(
                                          "🚀 ~ file: CreateLedger.js ~ line 192 ~ CreateLedger ~ e.target.value",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.ledgerTypeKeycode}
                                    >
                                      <option selected value={" "}>
                                        ledger Type
                                      </option>
                                      {ledgerTypedropdown &&
                                        ledgerTypedropdown.map(
                                          (value, index) => {
                                            return (
                                              <>
                                                <option
                                                  value={value.keycode}
                                                  key={index}
                                                >
                                                  {value.displayName}
                                                </option>
                                              </>
                                            );
                                          }
                                        )}
                                    </Field> */}
                                                                            <div style={{ color: "red" }}>
                                                                                <ErrorMessage
                                                                                    name="ledgerTypeKeycode"
                                                                                    component="span"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {/* {rows?.length > 0 &&
                                  rows?.filter((user) => {
                                      if (
                                        findLedgerType === "" &&
                                        findLedgerClass === ""
                                      ) {
                                        return user;
                                      } else if (
                                        user.ledgerClass
                                          .toLowerCase()
                                          .includes(
                                            findLedgerClass.toLowerCase()
                                          ) &&
                                        user.ledgerType
                                          .toLowerCase()
                                          .includes(
                                            findLedgerType.toLowerCase()
                                          )
                                      ) {
                                        return user;
                                      }
                                    }) */}
                                                                    {rows.map((value, index) => {
                                                                        const isItemSelected = isSelected(
                                                                            value.rowID
                                                                        );

                                                                        return (
                                                                            <>
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        <div className=" table-form-check ">
                                                                                            <input
                                                                                                selected={isItemSelected}
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                id="flexCheckDefault"
                                                                                                // label={}
                                                                                                onChange={(e) => {
                                                                                                    console.log(
                                                                                                        e.target.checked,
                                                                                                        "checked"
                                                                                                    );
                                                                                                    if (e.target.checked === true) {
                                                                                                        setValues(value);
                                                                                                        setSelected(value.rowID);
                                                                                                    }
                                                                                                }}
                                                                                                name="flexCheckDefault"
                                                                                            />
                                                                                        </div>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {value.displayname}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {value.ledgerClass}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {value.ledgerType}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>

                                                            {/* </table> */}
                                                            {/* <FilterGrid row={rows} coloums={moneycolumns} /> */}
                                                        </TableContainer>

                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>

                    <div className="col-xxl-10">
                        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                            <button
                                type="button"
                                className="btn cancel-btn mb-3 mb-md-0 me-4"
                                onClick={previous}
                            >
                                PREVIOUS
                            </button>
                            <button
                                disabled={!value}
                                type="button"
                                onClick={() => {
                                    setLedgerData(value)
                                    nextstep();
                                }}
                                className="btn next-btn mb-3 mb-md-0"
                            >
                                NEXT
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default Money;