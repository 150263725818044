import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../action";
import {
  CreateTrasferApi,
  DepositAPI,
  WithdrawAPI,
} from "../../../../api/MoneyServices";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
import { ADMIN_URL } from "../../../../Constatnts/config";

// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { FetchCallDataAPI } from "../../../../api/MoneyServices";
// import { decrypytdata } from "../../../../api/walletKey";
// import { success } from "../../../../Components/alert";
// import PrivateModal from "../../../../Components/PrivateModal";
function Money({
  previous,
  sourceData,
  targetData,
  paymentData,
  paymentReview,
  step,
}) {
  const [fetchData, setFetchData] = useState();
  console.log("🚀 ~ file: Review.js ~ line 64 ~ fetchData", targetData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const targUnitsData = paymentData * fetchData?.FXRate;
  const MainData = targUnitsData?.toFixed(6);
  const SourceAmountData = parseFloat(MainData);

  const initialValues = {
    sourcePublicAddress: fetchData?.SourcePublicAddress
      ? fetchData?.SourcePublicAddress
      : "",
    targetPublicAddress: fetchData?.TargetPublicAddress
      ? fetchData?.TargetPublicAddress
      : "",
    sourceTokenSymbol: fetchData?.SourceTokenSymbol
      ? fetchData?.SourceTokenSymbol
      : "",
    targetTokenSymbol: fetchData?.TargetTokenSymbol
      ? fetchData?.TargetTokenSymbol
      : "",
    sourceLedgercode: fetchData?.SourceLedgercode
      ? fetchData?.SourceLedgercode
      : "",
    targetLedgercode: fetchData?.TargetLedgercode
      ? fetchData?.TargetLedgercode
      : "",
    // sourceUnits: unitsdataValue ? unitsdataValue : 0,
    sourceUnits: SourceAmountData ? SourceAmountData : 0,
  };
  console.log(" initialValues", initialValues);

  const data = {
    tokenSymbol: "",
    sourceLedgerBalanceID: sourceData?.LedgerBalanceID
      ? sourceData?.LedgerBalanceID
      : sourceData?.Objectcode
      ? sourceData?.Objectcode
      : "",
    targetLedgerBalanceID: targetData?.LedgerBalanceID
      ? targetData?.LedgerBalanceID
      : targetData?.Objectcode
      ? targetData?.Objectcode
      : "",
  };

  const FetchCallDataAPI = async () => {
    let dataDictionary = await axios.post(
      ADMIN_URL + "/common/FetchCallData",
      data
    );

    setFetchData(Object.assign(dataDictionary.data[0]));
    return dataDictionary.data;
  };

  useEffect(() => {
    FetchCallDataAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let APPID = fetchData ? fetchData.SourcePublicAddress : 0;
  let APIKEY = fetchData ? fetchData.SourcePrivateKey : 0;
  // const publicKeys = useSelector((state) => state.keyReducer);
  console.log("🚀 ~ file: Money.js ~ line 87 ~ closeModal ~ APPID", APPID);
  console.log("🚀 ~ file: Money.js ~ line 89 ~ closeModal ~ APIKEY", APIKEY);

  const closeModal = async () => {
    // if (publicKeys.publicKey) {
    //   APPID = publicKeys.publicKey.publicAddress;
    // }
    // if (publicKeys.privateKey) {
    //   APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    // }

    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      let encryptedIssuer;

      console.log("paymentData.MoneyTransferCommand====", fetchData);
      // TransferAPI

      if (fetchData && fetchData?.MoneyTransferCommand === "Transfer") {
        // dispatch(showLoader(true));
        encryptedIssuer = await CreateTrasferApi(APPID, APIKEY, initialValues);
      }

      // DepositAPI

      if (fetchData && fetchData?.MoneyTransferCommand === "Deposit") {
        // dispatch(showLoader(true));
        encryptedIssuer = await DepositAPI(APPID, APIKEY, initialValues);
        console.log(
          "🚀 ~ file: Review.js ~ line 100 ~ closeModal ~ encryptedIssuer",
          encryptedIssuer
        );
      }

      // WithdrawAPI
      if (fetchData && fetchData?.MoneyTransferCommand === "Withdraw") {
        // dispatch(showLoader(true));
        encryptedIssuer = await WithdrawAPI(APPID, APIKEY, initialValues);
      }
      console.log("encryptedIssuer", encryptedIssuer);

      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      console.log(
        "🚀 ~ file: Money.js ~ line 143 ~ closeModal ~  devryptedIssuer",
        devryptedIssuer
      );
      // const jsonData = JSON.parse(devryptedIssuer);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        console.log("JSONDATA", jsonData);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.ReturnMessage) {
        success(jsonData.ReturnMessage);
      }
      dispatch(setPrivateId(""));
    }
    dispatch(showLoader(false));

    // dispatch(showLoader(false));
    // setTimeout(() => {
    //   dispatch(showLoader(false));
    // }, 5000);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    if (step === 3) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [step]);

  return (
    <>
      {fetchData && (
        <div className="row">
          <div className="col-12">
            <div className="row mt-5">
              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box ">
                  <h3>Originating Account</h3>
                  <label className="mb-0">Digital Currency Account</label>
                  <p>
                    {sourceData.DigitalCurrencyAccount
                      ? sourceData?.DigitalCurrencyAccount
                      : sourceData.Displayname}
                  </p>
                  <label className="mb-0">Public Address</label>
                  <p>{fetchData?.SourcePublicAddress}</p>
                  <label className="mb-0">Currency Code</label>
                  <p>{sourceData.Currencycode}</p>
                  <div className="d-flex position-relative">
                    <div>
                      <label className="mb-0">Token Symbol</label>
                      <p>{fetchData?.SourceTokenSymbol}</p>
                    </div>
                    <div className="Deposit-Review ms-5 position-absolute">
                      <img
                        src={`/images/coins/${fetchData?.SourceTokenSymbol}.png`}
                        // src="../images/coins/U-logo.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <label className="mb-0">Transfer Amount</label>
                  <p>{sourceData.Balance}</p>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box payment-box">
                  <h3>Beneficiary Account</h3>
                  <label className="mb-0">Digital Currency Account</label>
                  <p>
                    {targetData?.SourcePublicAddress
                      ? targetData?.SourcePublicAddress
                      : sourceData.Displayname}
                  </p>
                  <label className="mb-0">Public Address</label>
                  <p>{fetchData.TargetPublicAddress}</p>
                  <label className="mb-0">Currency Code</label>
                  <p>{targetData.Currencycode}</p>

                  <div className="d-flex position-relative">
                    <div>
                      <label className="mb-0">Token Symbol</label>
                      <p>{fetchData.TargetTokenSymbol}</p>
                    </div>
                    <div className="Deposit-Review ms-5 position-absolute">
                      <img
                        src={`/images/coins/${fetchData?.TargetTokenSymbol}.png`}
                        // src="../images/coins/U-logo.png"
                        alt="logo"
                      />
                    </div>
                  </div>

                  <label className="mb-0">Amount</label>
                  <p>{SourceAmountData?.toFixed(6)}</p>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box rates-box">
                  <h3>Rates & Fee</h3>
                  <label className="mb-0">Service Fee</label>
                  <p>0.00</p>
                  <label className="mb-0">FX Rate</label>
                  <p>{fetchData?.FXRate}</p>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
                <div className="beneficiary-box reference-box">
                  <h3>Reference</h3>
                  <label className="mb-0">Reference Code</label>
                  <p></p>
                  <label>Note</label>
                  <p></p>
                  <label className="mb-0">Attachment</label>
                  {/* <a href="/"></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="col-xxl-11">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn cancel-btn mb-3 mb-md-0 me-4"
            onClick={previous}
          >
            PREVIOUS
          </button>
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
            onClick={() => {
              closeModal();
            }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
}
export default Money;
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action";
// import { CreateTrasferApi } from "../../../../api/MoneyServices";
// import { decrypytdata } from "../../../../api/walletKey";
// import { success } from "../../../../Components/alert";
// function Review({ sourceData, targetData, originateData, previous ,units}) {
//   console.log("🚀 ~ file: Money.js ~ line 6 ~ Review ~ units", units)

//   console.log("🚀 ~ file: Money.js ~ line 6 ~ Review ~ sourceData", sourceData)

//   console.log("🚀 ~ file: Money.js ~ line 6 ~ Review ~ targetData", targetData)
//   const [isValidPublicAddress, setisValidPublicAddress] = useState(false);

//   let APPID = "";
//   let APIKEY = "";
//   const publicKeys = useSelector((state) => state.keyReducer);
//   let balance
//   if(units){
//     balance = parseFloat(units).toFixed(6)

//   }

//   const initialValues = {
//     sourcePublicAddress: sourceData.PublicAddress ? sourceData.PublicAddress : "",
//     sourceLedgercode: sourceData?.Ledgercode ? sourceData?.Ledgercode : "",
//     targetPublicAddress: targetData.PublicAddress ? targetData.PublicAddress : "",
//     sourceTokenSymbol:sourceData.TokenSymbol ? sourceData.TokenSymbol : "",
//     targetTokenSymbol: targetData?.TokenSymbol ? targetData?.TokenSymbol : "",
//     targetLedgercode: targetData?.Ledgercode ? targetData?.Ledgercode : "",
//     sourceUnits:   balance ? balance : "",
//   };
//   //eslint-disable-next-line
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const fetchdata = async () => {
//     if (publicKeys.publicKey) {
//       APPID = publicKeys.publicKey.publicAddress;
//     }
//     if (publicKeys.privateKey) {
//       APIKEY = publicKeys.privateKey.PrivateWalletAddress;
//     }
//     if (APPID && APIKEY) {
//       dispatch(showLoader(true));
//       const encryptedLedger = await CreateTrasferApi(
//         APPID,
//         APIKEY,
//         initialValues
//       );
//       dispatch(showLoader(false));
//       const Ledgers = await decrypytdata(encryptedLedger.data, APIKEY);
//       console.log(
//         "🚀 ~ file: GetBalances.js ~ line 31 ~ fetchdata ~ Ledgers",
//         Ledgers
//       );
//       // let data = JSON.parse(JSON.parse(Ledgers).CommandResponse);
//       // console.log(
//       //   "🚀 ~ file: GetBalances.js ~ line 33 ~ fetchdata ~ data",
//       //   data
//       // );
//       let jsonData;
//       if (Ledgers) {
//         jsonData = JSON.parse(Ledgers);
//         dispatch(setPrivateId(""));
//         dispatch(setResult(jsonData));
//         dispatch(setResponseAPI(Ledgers));
//         navigate("/result");
//       } else {
//         dispatch(setPrivateId(""));
//         // dispatch(setResult(jsonData))
//         dispatch(setResponseAPI(Ledgers));
//         navigate("/result");
//       }
//       if (jsonData.returnMessage) {
//         success(jsonData.returnMessage);
//       }
//       dispatch(setPrivateId(""));

//     }
//   };
//   useEffect(() => {
//     if (publicKeys?.publicKey?.publicAddress) {
//       if (publicKeys?.privateKey?.PrivateWalletAddress) {
//         setisValidPublicAddress(true);
//         // fetchdata();
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [publicKeys]);
//   return (
//     <>
//       {isValidPublicAddress &&(<div className="row">
//         <div className="col-12">
//           <div className="row mt-5">
//             <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
//               {sourceData && (
//                 <div className="beneficiary-box ">
//                   <h3>Originating Account</h3>
//                   <label className="mb-0">Digital Currency Account </label>
//                   <p>{sourceData.Displayname}</p>
//                   <label className="mb-0">Public Address</label>
//                   <p>{sourceData.PublicAddress}</p>
//                   <label className="mb-0">Currency Code</label>
//                   <p>{sourceData.Currencycode}</p>
//                   <div className="d-flex position-relative">
//                     <div>
//                       <label className="mb-0">Token Symbol</label>
//                       <p>{sourceData.TokenSymbol}</p>
//                     </div>
//                     <div className="Deposit-Review ms-5 position-absolute">
//                       <img
//                         src={`/images/coins/${sourceData?.TokenSymbol}.png`}
//                         // src="../images/coins/U-logo.png"
//                         alt="logo"
//                       />
//                     </div>
//                   </div>

//                   {sourceData.Balance && (
//                     <>
//                       <label className="mb-0">Amount</label>
//                       <p>{sourceData.Balance.toFixed(6)}</p>
//                     </>
//                   )}
//                 </div>
//               )}
//             </div>

//             <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
//               <div className="beneficiary-box payment-box">
//                 <h3>Beneficiary Account</h3>
//                 {targetData && (
//                   <>
//                     <label className="mb-0">Digital Currency Account</label>
//                     <p>{targetData?.DigitalCurrencyAccount}</p>
//                     <label className="mb-0">Public Address</label>
//                     <p>{targetData.PublicAddress}</p>
//                     <label className="mb-0">Currency Code</label>
//                     <p>{targetData.Currencycode}</p>
//                   </>
//                 )}
//                 {targetData && (
//                   <div className="d-flex position-relative">
//                     <div>
//                       <label className="mb-0">Token Symbol</label>
//                       <p>{targetData?.TokenSymbol}</p>
//                     </div>
//                     <div className="Deposit-Review ms-5 position-absolute">
//                       <img
//                         src={`/images/coins/${targetData?.TokenSymbol}.png`}
//                         // src="../images/coins/U-logo.png"
//                         alt="logo"
//                       />
//                     </div>
//                   </div>
//                 )}
//                 {initialValues.sourceUnits && (
//                   <>
//                     <label className="mb-0">Amount</label>
//                     <p>{initialValues.sourceUnits}</p>
//                   </>
//                 )}
//               </div>
//             </div>
//             <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
//               <div className="beneficiary-box rates-box">
//                 <h3>Rates & Fee</h3>
//                 <label className="mb-0">Service Fee</label>
//                 <p>0.00</p>
//                 <label className="mb-0">Exchange Rate</label>
//                 <p>$</p>
//               </div>
//             </div>
//             <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
//               <div className="beneficiary-box reference-box">
//                 <h3>Reference</h3>
//                 <label className="mb-0">Reference Code</label>
//                 <p></p>
//                 <label>Note</label>
//                 <p></p>
//                 <label className="mb-0">Attachment</label>
//                 {/* <a href="/"></a> */}
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-xxl-11">
//           <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//             <button
//               type="button"
//               className="btn cancel-btn mb-3 mb-md-0 me-4"
//               onClick={previous}
//             >
//               PREVIOUS
//             </button>
//             <button
//               type="button"
//               className="btn next-btn mb-3 mb-md-0"
//               onClick={() => {
//                 fetchdata()
//               }}
//             >
//               SUBMIT
//             </button>
//           </div>
//         </div>
//       </div>)}
//     </>
//   );
// }

// export default Review;
