import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";
import { getPeopleSanctions } from "../../../api/SactionPeople";
import { decrypytdata } from "../../../api/walletKey";
import Grid from "../../../Components/Grid";
import { SactionPeoplecolumns } from "../../../Components/GridHeaders";
function GetPeopleSanctions() {
  const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [rows, setRows] = useState([]);
  // const [row, setRow] = useState();

  const dispatch = useDispatch();

  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await getPeopleSanctions(APPID, APIKEY);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: PeopleBalances.js ~ line 35 ~ fetchdata ~ devryptedIssuer",
        devryptedIssuer
      );

      let jsonData = JSON.parse(devryptedIssuer).CommandResponse;
      console.log(
        "🚀 ~ file: PeopleBalances.js ~ line 38 ~ fetchdata ~ jsonData",
        jsonData
      );

      //   if (jsonData.returnMessage) {
      //     success(jsonData.returnMessage);
      //   }
      if (jsonData) {
        let dataArr = [];
        jsonData?.forEach((dataMain, index) => {
          dataArr.push({
            ...dataMain,
            rowid: dataMain.RowID,
          });
        });
        setRows(dataArr);
      }

      //   setPrivateModal(false);
    }
    
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}

      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="container-fluid p-0">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3> People Sanctions</h3>
                <h6>Get People Sanctions</h6>
                <p> Lists all of the registered individual sanctions.</p>
              </div>
              <div className="row">
                <div className="col-xxl-10">
                  <div className="wallet-balances-box">
                    <div className="d-md-flex justify-content-between ">
                      <div className="search-box d-md-flex">
                        <div className="input-group mb-3"></div>
                      </div>
                      <Link to={{ pathname: "/webwallet/createpeople" }}>
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{ marginBottom: "10px" }}
                        >
                          + ADD SactionPeople
                        </button>
                      </Link>
                    </div>
                    <div style={{ height: 800, width: "100%" }}>
                      <Grid row={rows} coloums={SactionPeoplecolumns} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetPeopleSanctions;
