import React from 'react';
function Roadmap() {
    return (
        <>
            <div className="unicoin-store roadmap-bg">
                <div className="container-fluid">
                    <div className="roadmap-heading unicoin-store-heading text-center">
                        <h3>Unicoin Physical Cash Software Development Roadmap</h3>
                        <p>Unicoin will launch three technological versions of physical cash. </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="roadmap-flow">
                                <div className="row align-items-center">
                                    <div className="col-md-6 order-2 order-md-1 ">
                                        <p>Version 1 is direct mobile-to-mobile cryptocurrency transfers over Bluetooth.</p>
                                    </div>
                                    <div className="col-md-3 text-center order-1 order-md-2">
                                        <h6>Version 1 Completed</h6>
                                    </div>
                                    <div className="col-md-3 mt-5 mt-md-0 order-3">
                                        <img src="images/ver 1.png" alt="alt" />
                                    </div>
                                </div>
                            </div>
                            <div className="second roadmap-flow">
                                <div className="row align-items-center">
                                    <div className="col-md-3 mt-5  mt-md-0 order-3  order-md-1" >
                                        <img src="images/ver 2.png" alt="alt" />
                                    </div>
                                    <div className="col-md-3 text-center order-1 order-md-2">
                                        <h6>Version 2 In Completed</h6>
                                    </div>
                                    <div className="col-md-6 order-2  order-md-3">
                                        <p>Version 2 will implement mobile-to-mobile payments using smart cards and near field communications (NFC) technology.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="third roadmap-flow">
                                <div className="row align-items-center">
                                    <div className="col-md-6 order-2  order-md-1">
                                        <p>Version 3 will integrate chat messaging with mobile-to-mobile payments over WiFi and cellular communications.</p>
                                    </div>
                                    <div className="col-md-3 order-1 order-md-2 text-center">
                                        <h6>Version 3 in Research</h6>
                                    </div>
                                    <div className="col-md-3 order-3 order-md-3 mt-5  mt-md-0">
                                        <img src="images/ver 3.png" alt="alt" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Roadmap;