import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ledgerRuleSuspendApi } from "../../../api/LedgerRulesApi";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import TokenConfirm from "../Token/TokenConfirm";

// const initialValues = {};
function SuspendLedgerRule() {
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  let APPID = "";
  let APIKEY = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await ledgerRuleSuspendApi(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // debugger;
    }
    dispatch(showLoader(false));
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        // fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  const initialValues = {
    ledgerRulecode: 0,
    sourcePublicAddress: "",
  };
  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>Ledger Rules</h3>
                <h6>Suspend Ledger Rule</h6>
                <p>This function suspends a ledger rule.</p>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log("values", values);
                      // nextstep();
                      // debugger;
                      // CreateIssuersApi(values);
                      setdata(values);
                      setPopUp(true);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched } = formik;
                      return (
                        <div className="">
                          {/* <h4>Issuer Information</h4> */}

                          <Form className="wallet-information-form">
                            <div className="row mb-xl-5">
                              <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                {/* {/ <label>Issuer Code</label> /} */}

                                <div className="input-box form-floating mb-5">
                                  <Field
                                    type="text"
                                    name="selectLedger"
                                    id="selectLedger"
                                    placeholder="Select Ledger"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Select Ledger
                                  </label>
                                  <div
                                    className={
                                      errors.selectLedger &&
                                      touched.selectLedger
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="selectLedger"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                                <div className="input-box form-floating mb-5">
                                  <Field
                                    type="text"
                                    name="minimumAmount"
                                    id="minimumAmount"
                                    placeholder="Minimum Amount"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Minimum Amount
                                  </label>
                                  <div
                                    className={
                                      errors.minimumAmount &&
                                      touched.minimumAmount
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="minimumAmount"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                <div className="input-box form-floating mb-5">
                                  <Field
                                    type="text"
                                    name="selectLedgerRuleType"
                                    id="selectLedgerRuleType"
                                    placeholder="Select Ledger Rules Type"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Select Ledger Rules Type
                                  </label>
                                  <div
                                    className={
                                      errors.selectLedgerRuleType &&
                                      touched.selectLedgerRuleType
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="selectLedgerRuleType"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                                <div className="input-box form-floating mb-5">
                                  <Field
                                    type="text"
                                    name="MaximumAmount"
                                    id="MaximumAmount"
                                    placeholder="Maximum Amount"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Maximum Amount
                                  </label>
                                  <div
                                    className={
                                      errors.MaximumAmount &&
                                      touched.MaximumAmount
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="MaximumAmount"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex me-0 me-md-4 me-xxl-5">
                              <button
                                type="button"
                                class="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button
                                type="submit"
                                class="btn next-btn mb-3 mb-md-0"
                              >
                                SUBMIT
                              </button>
                            </div>
                            {popUp && (
                              <TokenConfirm
                                heading={"Suspend Ledger Rule"}
                                body={data}
                                suspendData={suspendData}
                                setPopUp={setPopUp}
                              />
                            )}
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuspendLedgerRule;
