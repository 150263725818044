import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import { suspendPeopleSanction } from "../../../api/SactionPeople";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import TokenConfirm from "../Token/TokenConfirm";

function PeopleSanctionsSuspend() {
  const publicKeys = useSelector((state) => state.keyReducer);
  //eslint-disable-next-line
  const [popUp, setPopUp] = useState(false);
  const [data, setdata] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const initialValues = {
    peopleSanctioncode: location.search.replace("?id=", "")
      ? location.search.replace("?id=", "")
      : " ",
  };
  let APPID = "";
  let APIKEY = "";

  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY && data) {
      dispatch(showLoader(true));
      // debugger;
      const encryptedIssuer = await suspendPeopleSanction(APPID, APIKEY, data);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // debugger;
    }
    
  };
  // const suspendData=()=>{
  //     console.log("test");
  //     issuresSuspend(publickey, privateKey)
  // }
  useEffect(() => {
    suspendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <div className="main-heading">
                <h3>People Sanctions</h3>
                <h6> Suspend People Sanctions</h6>
                <p>Enables the suspension of an individual sanction.</p>
              </div>
              <div className="wallet-information-box ms-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log("values", values);
                      // nextstep();
                      // debugger;
                      // CreateIssuersApi(values);
                      setdata(values);
                      setPopUp(true);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched } = formik;
                      return (
                        <div className="">
                          <Form className="wallet-information-form">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              {/* <label>Issuer Code</label> */}
                              <div className="input-box mb-5">
                                <Field
                                  type="text"
                                  name="peopleSanctioncode"
                                  id="peopleSanctioncode"
                                  placeholder="Public Sanctions Address"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.peopleSanctioncode &&
                                    touched.peopleSanctioncode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="peopleSanctioncode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="d-flex me-0 me-md-4 me-xxl-5">
                              <button
                                type="button"
                                class="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button
                                // onClick={() => {
                                //   setPrivateModal(true);
                                //   suspendData();
                                // }}
                                type="submit"
                                class="btn next-btn mb-3 mb-md-0"
                              >
                                SUSPEND
                              </button>
                            </div>
                            {popUp && (
                              <TokenConfirm
                                heading={"Suspend People Sanction"}
                                body={data.peopleSanctioncode}
                                suspendData={suspendData}
                                setPopUp={setPopUp}
                              />
                            )}
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                  {/* <div class="row">
                                    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                        <div class="mb-5">
                                            <div class="input-box">
                                                <input type="text" placeholder="Public Wallet Address" class="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex me-0 me-md-4 me-xxl-5">
                                    <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                    <button onClick={() => { suspendData(); setPrivateModal(true); }} type="submit" class="btn next-btn mb-3 mb-md-0">SUSPEND</button>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSanctionsSuspend;
