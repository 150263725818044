import React from 'react'

const Authorizations = () => {
  return (
      <>
          <div className="documentation-accordion">
              <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Issuer">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#issuerOne" aria-expanded="true" aria-controls="issuerOne">
                              Create Authorization
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="issuerOne" className="accordion-collapse collapse show" aria-labelledby="Issuer" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Establishes a financial institution on the Unicoin network capable of creating customer wallets.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Modify">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyTwo" aria-expanded="false" aria-controls="ModifyTwo">
                              Modify Authorization
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="ModifyTwo" className="accordion-collapse collapse  show" aria-labelledby="Modify" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Monetary Authorities can permission which regulated banks and licensed FinTech companie can create user wallets and mint digital currencies on the Unicoin network. but you can also
                                                                    </div>
                      </div>
                  </div>
                  <hr />
              </div>
          </div>
      </>
  )
}

export default Authorizations