import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import React from 'react'

function About() {
    return (
        <>
            <Header />
            <div className="about">
                <div className="container">
                    <div className="row pt-100">
                        <div className="col-md-6">
                            <div className="about-img">
                                <img src="../images/about-img.png" className="img-fluid" alt="about-img" />
                                <div className="client-name">
                                    <h6>darrell@dcma.io</h6>
                                    <h3>Darrell Hubbard</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-text">
                                <h3>About our Founder, Darrell Hubbard</h3>
                                <p>Darrell Hubbard has an exceptional record of achievement in Computer Science and Information Technology. He received his master's in computer science at the age of 19 from North Carolina State University (NCSU) and started his career as a Chief Architect at AT&T Bell Laboratories.
                                He served as a member of the National Institute of Science and Technology (NIST) during its release of TCP/IP, known as the Internet. He was appointed as a US Expert in the American National Standards Institute (ANSI) and elected as a Chairperson within the International Standards Organization (ISO).
                                Darrell has over 25 years of high-technology and financial technology experience serving as the Head of Financial Management Architect at AT&T and consulting for leading corporations in America. His clients include Amgen, Warner Bros., Toyota, Sony Pictures, Fannie Mae, TD Ameritrade, Screen Actors Guild (SAG), Philips Healthcare, McKesson, Kiewit, Ansys, Ferguson, Oracle, HP, Dell, and many others.
                                Over the past several years he has dedicated his talents to Distributed Ledger Technology (DLT) and digital currencies. Darrell has collaborated with or completed projects for the European Union and other central banks around the world.
                                His work is featured by the European Commission, the United Nations, and the World Economic Forum. In 2018, Darrell was inducted into the Computer Science Alumni Hall of Fame at NCSU.
                                Darrell graduated with an MBA from Harvard University.</p>
                            </div>
                        </div>

                        <div className="col-12 text-center">
                            <div className="dcma-logo">
                                <img src="../images/dcma-logo.png" className="img-fluid" alt="dcma-logo" />
                                <h5>info@dcma.io</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </>
    )
}

export default About;