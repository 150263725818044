import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ModifyLedgerRuleApi } from "../../../api/LedgerRulesApi";
import { getLedgerCodes } from "../../../api/dropdown";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";

// const initialValues = {};
function ModifyLedgerRule() {
  const publicKeys = useSelector((state) => state.keyReducer);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  //   const [privateModal, setPrivateModal] = useState(false);
  //   const [data, setdata] = useState("");
  // eslint-disable-next-line
  const [ledgerRuleTypedropdown, setLedgerRuleTypedropdown] = useState([]);
  // eslint-disable-next-line
  const [transferTypeDropDown, settransferTypeDropDown] = useState([]);
  let APPID = "";
  let APIKEY = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suspendData = async (values) => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await ModifyLedgerRuleApi(APPID, APIKEY, values);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // debugger;
    }
    dispatch(showLoader(false));
  };
  const fetchdropdwon = async () => {
    let dropdownvalues = await getLedgerCodes("LKLedgerRuleType");

    console.log(
      "🚀 ~ file: CreateLedgerRule.js ~ line 44 ~ fetchdropdwon ~ dropdownvalues",
      dropdownvalues
    );
    setLedgerRuleTypedropdown(dropdownvalues);
    let tokendropdownvalues = await getLedgerCodes("LKTransferType");
    settransferTypeDropDown(tokendropdownvalues);
    // let interestratedropdownvalues = await getDropdownvalues("LKInterestType");
    // setInterestrateropdown(interestratedropdownvalues);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const initialValues = {
    ledgercode: "",
    ledgerRuleTypecode: "",
    transferTypecode: "",
    minUnits: 0,
    maxUnits: 0,
  };
  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>Ledger Rules</h3>
                <h6>Modify Ledger Rule</h6>
                <p>This function Modify a ledger money servicing rule.</p>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log("values", values);
                      // nextstep();
                      // debugger;
                      // CreateIssuersApi(values);
                      suspendData(values);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, setFieldValue } = formik;
                      return (
                        <div className="">
                          {/* <h4>Issuer Information</h4> */}

                          <Form className="wallet-information-form">
                            <div className="row mb-xl-5">
                              <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                <div className="input-box form-floating mb-5">
                                  <Field
                                    // as="select"
                                    type="text"
                                    name="ledgercode"
                                    id="ledgercode"
                                    placeholder="Select Ledger"
                                    className="form-control"
                                    value={"Select Ledger Rule"}
                                  />
                                  <label className="ps-0 pb-0">
                                    Select Ledger
                                  </label>

                                  <div
                                    className={
                                      errors.ledgercode && touched.ledgercode
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="ledgercode"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-xl-5">
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  {/* {/ <label>Issuer Code</label> /} */}
                                  <label className="ps-0 pb-0">
                                    Select Ledger Rules Type
                                  </label>
                                  <div className="input-box form-floating mb-5">
                                    <Field
                                      as="select"
                                      name="ledgerRuleTypecode"
                                      id="ledgerRuleTypecode"
                                      placeholder="Select Ledger Rules Type"
                                      className="form-select form-control"
                                      //   value={"Select Ledger Rule Type"}
                                      onChange={(e) => {
                                        // console.log(e.target.value, "Issuer Type");
                                        setFieldValue(
                                          "ledgerRuleTypecode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    >
                                      {ledgerRuleTypedropdown &&
                                        ledgerRuleTypedropdown.map(
                                          (value, index) => {
                                            return (
                                              <>
                                                <option
                                                  value={value.ledgercode}
                                                  key={index}
                                                >
                                                  {value.displayname}
                                                </option>
                                              </>
                                            );
                                          }
                                        )}
                                    </Field>
                                    <div
                                      className={
                                        errors.ledgerRuleTypecode &&
                                        touched.ledgerRuleTypecode
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="ledgerRuleTypecode"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box form-floating mb-5">
                                    <Field
                                      type="text"
                                      name="minUnits"
                                      id="minUnits"
                                      placeholder="Minimum Amount"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Minimum Amount
                                    </label>
                                    <div
                                      className={
                                        errors.minUnits && touched.minUnits
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="minUnits"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <label className="ps-0 pb-0">
                                    Select Transfer Type
                                  </label>
                                  <div className="input-box form-floating mb-5">
                                    <Field
                                      as="select"
                                      name="transferTypecode"
                                      id="transferTypecode"
                                      placeholder="Select Transfer Type"
                                      className="form-select form-control"
                                      //   value={"Select Transfer Type"}
                                      onChange={(e) => {
                                        // console.log(e.target.value, "Issuer Type");
                                        setFieldValue(
                                          "transferTypecode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    >
                                      {transferTypeDropDown &&
                                        transferTypeDropDown.map(
                                          (value, index) => {
                                            return (
                                              <>
                                                <option
                                                  value={value.ledgercode}
                                                  key={index}
                                                >
                                                  {value.displayname}
                                                </option>
                                              </>
                                            );
                                          }
                                        )}
                                    </Field>
                                    <div
                                      className={
                                        errors.transferTypecode &&
                                        touched.transferTypecode
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="transferTypecode"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-box form-floating mb-5">
                                    <Field
                                      type="text"
                                      name="maxUnits"
                                      id="maxUnits"
                                      placeholder="Maximum Amount"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Maximum Amount
                                    </label>
                                    <div
                                      className={
                                        errors.maxUnits && touched.maxUnits
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="maxUnits"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex me-0 me-md-4 me-xxl-5">
                              <button
                                type="button"
                                class="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button
                                type="submit"
                                class="btn next-btn mb-3 mb-md-0"
                              >
                                SUBMIT
                              </button>
                            </div>
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModifyLedgerRule;
