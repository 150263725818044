//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function TokenServices() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/token-service.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Token Services</p>
                            <h1>A Complete  <br className="d-none d-md-block" /> Tokenization  <br className="d-none d-md-block" /> Framework for Digital <br className="d-none d-md-block" />Currency</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TokenServices;