import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../action";
import { peopleSuspend } from "../../../api/PeopleApi";
import TokenConfirm from "../Token/TokenConfirm";

function PeopleSuspend() {
  const publicKeys = useSelector((state) => state.keyReducer);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  // const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");
  const [popUp, setPopUp] = useState(false);

  const location = useLocation();
  const initialValues = {
    peoplecode: location.search.replace("?id=", ""),
  };
  let APPID = "";
  let APIKEY = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await peopleSuspend(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
      // debugger;
    }
    dispatch(showLoader(false));
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5  m-0">
            {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
            <div className="col-12">
              <div className="main-heading">
                <h3>KYC People</h3>
                <h6>Suspend</h6>
                <p>
                  This function suspends an individual customer from transacting
                  on the Unicoin network.
                </p>
              </div>
              <div className="wallet-information-box ms-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      console.log("values", values);
                      // nextstep();
                      // debugger;
                      // CreateIssuersApi(values);
                      setdata(values);
                      setPopUp(true);
                      // suspendData(values);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched } = formik;
                      return (
                        <div className="">
                          <Form className="wallet-information-form">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              {/* <label>Issuer Code</label> */}
                              <div className="input-box  form-floating mb-5">
                                <Field
                                  type="text"
                                  name="peoplecode"
                                  id="peoplecode"
                                  placeholder="Public People Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Public People Address
                                </label>

                                <div
                                  className={
                                    errors.peoplecode && touched.peoplecode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="peoplecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="d-flex me-0 me-md-4 me-xxl-5">
                              <button
                                type="button"
                                class="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button
                                // onClick={() => {
                                //   // suspendData();
                                //   setPopUp(true);
                                // }}
                                type="submit"
                                className="btn next-btn mb-3 mb-md-0"
                              >
                                SUSPEND
                              </button>
                            </div>
                            {popUp && (
                              <TokenConfirm
                                heading={"Suspend People"}
                                body={data.peoplecode}
                                suspendData={suspendData}
                                setPopUp={setPopUp}
                              />
                            )}
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                  {/* <div class="row">
                                    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                        <div class="mb-5">
                                            <div class="input-box">
                                                <input type="text" placeholder="Public Wallet Address" class="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex me-0 me-md-4 me-xxl-5">
                                    <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                    <button onClick={() => { suspendData(); setPrivateModal(true); }} type="submit" class="btn next-btn mb-3 mb-md-0">SUSPEND</button>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PeopleSuspend;
