import React, { useEffect } from "react";
import { useState } from "react";
import CreateAgents from "./CreateAgents";
import ReviewAgents from './ReviewAgents';
function CreateBranchs() {
  const [step, setstep] = useState(1);
  const [AgentData, setAgentData] = useState(1);
  useEffect(() => {
    setstep(1)
  },[]);
  const setdata = (data) => {
    setAgentData(data)
  };
  const cleandata=()=>{
    setAgentData(1)
  }
 
    const renderstep = (step) => {
        switch (step) {
          case 1:
            return <CreateAgents nextstep={nextstep} previous={previous}  setdata={setdata} AgentData={AgentData} cleandata={cleandata}/>
          case 2:
            return <ReviewAgents nextstep={nextstep} previous={previous} setdata={setdata} AgentData={AgentData} cleandata={cleandata}/>
          default:
            return false;
        }
    }
    const nextstep = () => {
      if (step < 2) {
        setstep(step + 1);
      }
    };
    const previous = () => {
      if (step > 1) {
        setstep(step - 1);
      }
    }
return( <>
        {renderstep(step)}
    </>
  );
}

export default CreateBranchs;
