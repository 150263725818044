import axios from "axios";
import { ADMIN_URL, WEB_WALLET_URL } from "../Constatnts/config";
// import { error } from "../Components/alert";
const qs = require("qs");

const httpAdmin = axios.create({
  baseURL: ADMIN_URL,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

const http = axios.create({
  baseURL: WEB_WALLET_URL,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (errors) => {
    // if (errors?.response?.status === 511) {
    //   error("511 : AUTHENTICATION REQUEST FAILED");
    // }
    // if (errors?.response?.status === 401) {
    //   error("401 : AUTHORIZATION REQUEST FAILED ");
    // }
    // if (errors?.response?.status === 404) {
    //   error("404 :INVALID COMMAND, NOT FOUND");
    // }
    // if (errors?.response?.status === 400) {
    //   error("400 : DATA VALIDATION ERROR ");
    // }
    // if (errors?.response?.status === 500) {
    //   error("500 : UNKNOWN SYSTEM ERROR ");
    // }
    console.log("errors.response ====", errors);
    return errors.response;
  }
);

export default http;
export { httpAdmin };

// if (errors?.response?.status === 511) {
//   error("511 : AUTHENTICATION REQUEST FAILED");
// }
// if (errors?.response?.status === 401) {
//   error("401 : AUTHORIZATION REQUEST FAILED - ACCESS DENIED ");
// }
// if (errors?.response?.status === 404) {
//   error("404 : AUTHORIZATION REQUEST FAILED - COMMAND NOT FOUND");
// }
// if (errors?.response?.status === 400) {
//   error("400 : SP ERROR ");
// }
