import React from "react";
import Header from "../../../Components/Header";
import Sidebar from "../../../Components/Sidebar";
function TransferRates() {

    return (
        <>
            <Header />
            <div className="ledger-information transfer-rates-page">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div>
                        <div className="col-md-9 col-lg-9 col-xxl-10">
                            <div className="main-heading">
                                <i className="bi bi-arrow-left"></i>
                                <h3>Digital Currency Transfer Rates</h3>
                            </div>

                            <div className="wallet-information-box transfer-rates">
                                <p>Each Issuer can establish their own rate structure for providing digital currency transfer services on the Unicoin network. The rates are defined in USD.
                                If the money transfer is denominated in another currency, the value will be converted to USD before applying your rate structure.
                                </p>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                    <div className="domestic">
                                                        <img src="../icons/Domestic-icon.png" className="Domestic-icon-img show" alt="Domestic-icon-img" />
                                                        <img src="../icons/Domestic-icon-active.png" className="Domestic-icon-img none" alt="Domestic-icon-img" />
                                                    </div>
                                                    <h3>Domestic</h3>
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                                    <div className="domestic">
                                                        <img src="../icons/International-icon.png" className="International-icon-img show" alt="International-icon-img" />
                                                        <img src="../icons/International-icon-active.png" className="International-icon-img none" alt="International-icon-img" />
                                                    </div>
                                                    <h3>International</h3>

                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div className="mt-4">
                                                    <div className="row m-0">
                                                        <div className="col-12">
                                                            <div className="wallet-balances-box">
                                                                <div className="d-md-flex justify-content-between px-4">
                                                                    <div className="search-box d-md-flex">
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text"><i className="bi bi-search"></i></span>
                                                                            <input type="text" className="form-control" placeholder="Search"></input>
                                                                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">From</button>
                                                                            <ul className="dropdown-menu dropdown-menu-end">

                                                                            </ul>
                                                                        </div>
                                                                        <button type="button" className="btn btn-primary mb-3 mb-md-0">SEARCH</button>
                                                                    </div>
                                                                    <button type="button" className="btn btn-primary ">+ ADD RATE</button>
                                                                </div>
                                                                <div className="table-responsive p-3 p-md-4">

                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-end">From</th>
                                                                                <th className="text-end">To</th>
                                                                                <th className="text-end">Origination Fee</th>
                                                                                <th className="text-end">Transfer Rate</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-end">$ 0.00</td>
                                                                                <td className="text-end">$ 9.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">1.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 10.00</td>
                                                                                <td className="text-end">$ 29.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">2.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 10.00</td>
                                                                                <td className="text-end">$ 29.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">2.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 25.00</td>
                                                                                <td className="text-end">$ 49.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end" >3.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 100.00</td>
                                                                                <td className="text-end">$ 59.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">4.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 250.00</td>
                                                                                <td className="text-end">$ 69.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">5.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                <div className="mt-4">
                                                    <div className="row m-0">
                                                        <div className="col-12">
                                                            <div className="wallet-balances-box">
                                                                <div className="d-md-flex justify-content-between px-4">
                                                                    <div className="search-box d-md-flex">
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text"><i className="bi bi-search"></i></span>
                                                                            <input type="text" className="form-control" placeholder="Search"></input>
                                                                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">From</button>
                                                                            <ul className="dropdown-menu dropdown-menu-end">

                                                                            </ul>
                                                                        </div>
                                                                        <button type="button" className="btn btn-primary mb-3 mb-md-0">SEARCH</button>
                                                                    </div>
                                                                    <button type="button" className="btn btn-primary ">+ ADD RATE</button>
                                                                </div>
                                                                <div className="table-responsive p-3 p-md-4">

                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-end">From</th>
                                                                                <th className="text-end">To</th>
                                                                                <th className="text-end">Origination Fee</th>
                                                                                <th className="text-end">Transfer Rate</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-end">$ 0.00</td>
                                                                                <td className="text-end">$ 9.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">1.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 10.00</td>
                                                                                <td className="text-end">$ 29.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">2.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 10.00</td>
                                                                                <td className="text-end">$ 29.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">2.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 25.00</td>
                                                                                <td className="text-end">$ 49.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end" >3.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 100.00</td>
                                                                                <td className="text-end">$ 59.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">4.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-end">$ 250.00</td>
                                                                                <td className="text-end">$ 69.99</td>
                                                                                <td className="text-end">$ 1.00</td>
                                                                                <td className="text-end">5.00 %</td>
                                                                                <td>
                                                                                    <ul className="d-flex mb-0 p-0">
                                                                                        <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                                                        <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransferRates;
