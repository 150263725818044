import React from 'react';
function Review() {
    return (
        <>
            <div className="row">
                <div className="col-xxl-9">
                    <div className="review-page">
                        <h3>Review</h3>
                        <div className="row">
                            <div className="col-xxl-10">
                                <div className="review-box">
                                    <div className="review-box-head">
                                        <div className="transfer mb-3 mb-md-0">
                                            <div className="transfer-icon">
                                                <img
                                                    src="../icons/transfer-icon.png"
                                                    className="transfer-icon-img"
                                                    alt="transfer-icon-img"
                                                ></img>
                                            </div>
                                            <div className="ms-3">
                                                <p>You Transfer</p>
                                                <h2>USD 1,000</h2>
                                            </div>
                                        </div>
                                        <div className="transfer">
                                            <div className="transfer-icon">
                                                <img
                                                    src="../icons/account-used.png"
                                                    className="account-used-img"
                                                    alt="account-used-img"
                                                ></img>
                                            </div>
                                            <div className="ms-3">
                                                <p>Account Used</p>
                                                <h2>John Matthew</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-list">
                                        <div className="review-originator mb-4 mb-md-0">
                                            <h4>Originator</h4>
                                            <div className="originator-list">
                                                <h6>Originator Type</h6>
                                                <p>Bank Account</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Originator Country</h6>
                                                <p>USA</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Bank Account Number</h6>
                                                <p>0192849384</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Swift Code</h6>
                                                <p>USA</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Domestic Bank Routing System</h6>
                                                <p>098939837r</p>
                                            </div>
                                            <div className="originator-list mb-0">
                                                <h6>Domestic Bank Routing Number</h6>
                                                <p>0129013</p>
                                            </div>
                                        </div>
                                        <div className="review-originator">
                                            <h4>Beneficiary</h4>
                                            <div className="originator-list">
                                                <h6>Originator Type</h6>
                                                <p>Bank Account</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Originator Country</h6>
                                                <p>USA</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Bank Account Number</h6>
                                                <p>0192849384</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Swift Code</h6>
                                                <p>USA</p>
                                            </div>
                                            <div className="originator-list">
                                                <h6>Domestic Bank Routing System</h6>
                                                <p>098939837r</p>
                                            </div>
                                            <div className="originator-list mb-0">
                                                <h6>Domestic Bank Routing Number</h6>
                                                <p>0129013</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default Review;