import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
// import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
    getCountryCodeDropDown,
    getDialingcodesAPI,
    getDropdownvalues,
} from "../../../../api/dropdown";

import * as Yup from "yup";

function Originator({ nextstep, setdata }) {
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const [enityTypedropdown, setenityTypedropdown] = useState([]);
    const [worldcurrency, setWorldcurrency] = useState([]);
    const [worldcurrencyOne, setWorldcurrencyOne] = useState([]);
    const [GetDialingcode, setGetDialingcode] = useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);

    const fetchdropdwon = async () => {
        let LKEntityClass = await getDropdownvalues("LKEntityType");
        setenityTypedropdown(LKEntityClass);
        let LKCountryCode = await getCountryCodeDropDown("GetWorldCurrency");
        setWorldcurrency(LKCountryCode);
        let LKCountryCodeTwo = await getCountryCodeDropDown("GetWorldCurrency");
        setWorldcurrencyOne(LKCountryCodeTwo);
        let LKDailingCodes = await getDialingcodesAPI("GetDialingcodes");
        setGetDialingcode(LKDailingCodes);
    };

    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);
    const initialValues = {
        nativeID: "",
        entityTypecode: "",
        entity: "",
        displayname: "",
        governmentTaxID: "",
        registrationNumber: "",
        usA_EIN: "",
        isO_LEI: "",
        registration_Countrycode: "",
        registration_StateProvince: "",
        registration_CityTown: "",
        registration_Postalcode: "",
        registration_AddressLine1: "",
        registration_AddressLine2: "",
        registration_AddressLine3: "",
        industrycode: "",
        countrycode: "",
        stateProvince: "",
        cityTown: "",
        postalcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        dialingcode: "",
        phoneNumber: "",
        website: ""
    };
    const validationSchema = Yup.object({
        entity: Yup.string().required("Enitity is required!"),
        displayname: Yup.string().required("Display Name is required!"),
        governmentTaxID: Yup.string().required("Tax ID  is required!"),
        registration_Countrycode: Yup.string().required(
            "Country Code is required!"
        ),
    });
    return (
        <>
            {isValidPublicAddress && (
                <div className="row">
                    <div className="col-12 mt-5">
                        <div className="originating-account link-wallet-box create-page ms-0">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    setdata(values);
                                    console.log(
                                        "🚀 ~ file: Originator.js ~ line 53 ~ Originator ~ values",
                                        values
                                    );
                                    console.log(values);
                                    // debugger;
                                    nextstep();
                                    //   nextstep();
                                }}
                            >
                                {(formik) => {
                                    const { errors, touched, setFieldValue } = formik;
                                    return (
                                        <div className="wallet-information-box  m-0">
                                            <Form className="wallet-information-form">
                                                <div className="row mb-xl-5">
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Business Information</h6>
                                                        {/* <div className="mb-5">
                              <label>Business Name</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="entity"
                                  id="entity"
                                  placeholder="Business Name"
                                  className="form-control"
                                />
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div> */}
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="displayname"
                                                                    id="displayname"
                                                                    placeholder="Alias or Display Name"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Alias or Display Name</label>
                                                                <div
                                                                    className={
                                                                        errors.displayname && touched.displayname
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                ></div>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="displayname"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Entity Type</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    as="select"
                                                                    id="entity"
                                                                    name="entity"
                                                                    className="form-select form-control"
                                                                    onChange={(e) => {
                                                                        setFieldValue("entity", e.target.value);
                                                                        console.log(
                                                                            "🚀 ~ file: Originator.js ~ line 117 ~ Originator ~ e.target.value",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.entity}
                                                                >
                                                                    {enityTypedropdown &&
                                                                        enityTypedropdown.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.keycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.displayName}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div
                                                                    className={
                                                                        errors.entityTypecode && touched.entityTypecode
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="entityTypecode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Entity Type Code </label>
                                                            <div className="input-box">
                                                                <Field
                                                                    as="select"
                                                                    id="entityTypecode"
                                                                    name="entityTypecode"
                                                                    className="form-select form-control"
                                                                    onChange={(e) => {
                                                                        setFieldValue("entityTypecode", e.target.value);
                                                                        console.log(
                                                                            "🚀 ~ file: Originator.js ~ line 117 ~ Originator ~ e.target.value",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.entityTypecode}
                                                                >
                                                                    {enityTypedropdown &&
                                                                        enityTypedropdown.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.keycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.displayName}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div
                                                                    className={
                                                                        errors.entityTypecode && touched.entityTypecode
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="entityTypecode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="website"
                                                                    id="website"
                                                                    placeholder="Website"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Website</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Registration Information </h6>

                                                        <div className="mb-5">
                                                            <label>Registration Country</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    as="select"
                                                                    id="registration_Countrycode"
                                                                    name="registration_Countrycode"
                                                                    className="form-select form-control"
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "registration_Countrycode",
                                                                            e.target.value
                                                                        );
                                                                        console.log(
                                                                            "🚀 ~ file: Originator.js ~ line 197 ~ Originator ~ e.target.value",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.registration_Countrycode}
                                                                >
                                                                    {worldcurrency &&
                                                                        worldcurrency.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.countrycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.isoCountryname}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div
                                                                    className={
                                                                        errors.registration_Countrycode &&
                                                                            touched.registration_Countrycode
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="registration_Countrycode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Country Code</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    as="select"
                                                                    id="countrycode"
                                                                    name="countrycode"
                                                                    // disabled={true}
                                                                    className="form-select form-control"
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "countrycode",
                                                                            e.target.value
                                                                        );
                                                                        console.log(
                                                                            "🚀 ~ file: Originator.js ~ line 197 ~ Originator ~ e.target.value",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.countrycode}
                                                                >
                                                                    {worldcurrencyOne &&
                                                                        worldcurrencyOne.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.countrycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.isoCountryname}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div
                                                                    className={
                                                                        errors.countrycode &&
                                                                            touched.countrycode
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="countrycode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Dailing Codes</label>
                                                            <Field
                                                                as="select"
                                                                id="dialingcode"
                                                                name="dialingcode"
                                                                className="form-select form-control"
                                                                onChange={(e) => {
                                                                    setFieldValue("dialingcode", e.target.value);
                                                                    console.log(
                                                                        "🚀 ~ file: Originator.js ~ line 248 ~ Originator ~ e.target.value",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.dialingcode}
                                                            >
                                                                {GetDialingcode &&
                                                                  GetDialingcode.map((value, index) => {
                                                                      return (
                                                                          <>
                                                                              <option
                                                                                  value={value.keycode}
                                                                                  key={index}
                                                                              >
                                                                                  {value.displayname}
                                                                              </option>
                                                                          </>
                                                                      );
                                                                  })}
                                                            </Field>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Registration Number</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="number"
                                                                    name="registrationNumber"
                                                                    id="registrationNumber"
                                                                    placeholder="Registration Number"
                                                                    className="form-control"
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Tax ID Number</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="number"
                                                                    name="governmentTaxID"
                                                                    id="governmentTaxID"
                                                                    placeholder="Alias or Display Name"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.governmentTaxID &&
                                                                            touched.governmentTaxID
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                ></div>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="governmentTaxID"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>USA EIN Number </label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="number"
                                                                    name="usA_EIN"
                                                                    id="usA_EIN"
                                                                    placeholder="USA EIN Number"
                                                                    className="form-control"
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Registration Address</h6>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_AddressLine1"
                                                                    id="registration_AddressLine1"
                                                                    placeholder="Address Line 1"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 1</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_AddressLine2"
                                                                    id="registration_AddressLine2"
                                                                    placeholder="Address Line 2"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 2</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_AddressLine3"
                                                                    id="registration_AddressLine3"
                                                                    placeholder="Address Line 3"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 3</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_CityTown"
                                                                    id="registration_CityTown"
                                                                    placeholder="City"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">City </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="registration_StateProvince"
                                                                    id="registration_StateProvince"
                                                                    placeholder="State"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">State </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="number"
                                                                    name="registration_Postalcode"
                                                                    id="registration_Postalcode"
                                                                    placeholder="Postal Code"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Postal Code </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Business Address</h6>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="addressLine1"
                                                                    id="addressLine1"
                                                                    placeholder="Address Line 1"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 1</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="addressLine2"
                                                                    id="addressLine2"
                                                                    placeholder="Address Line 2"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 2</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="addressLine3"
                                                                    id="addressLine3"
                                                                    placeholder="Address Line 3"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Address Line 3</label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="cityTown"
                                                                    id="cityTown"
                                                                    placeholder="City"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">City </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="stateProvince"
                                                                    id="stateProvince"
                                                                    placeholder="State"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">State </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="number"
                                                                    name="postalcode"
                                                                    id="postalcode"
                                                                    placeholder="Postal Code"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Postal Code </label>
                                                                <i className="bi bi-check-lg"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                    <button
                                                        type="button"
                                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                    >
                                                        PREVIOUS
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn next-btn mb-3 mb-md-0"
                                                    // onClick={nextstep}
                                                    >
                                                        NEXT
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Originator;