import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import React from 'react'

function About2() {
    return (
        <>
            <Header />
            <div className="global network digital-page">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/hero-section.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div className="global-text">
                            <h3>Digital Financial Services</h3>
                            <p>Governments, Banks, and Fintechs</p>
                        </div>
                    </div>
                </div>
                <div className="container  pt-100">
                    <div className="row g-0 justify-content-center">
                        <div className="col-12">
                            <div className="technology-heading">
                                <h3>Multi-Functional Distributed Ledger Technology</h3>
                                <p>Unicoin is the only DLT that can support all banking debit and credit ledgers.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-1">
                                <img src="../icons/cash-ledger.png" alt="icons" />
                                <h3>Cash Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-2">
                                <img src="../icons/cash-ledger-2.png" alt="icons" />
                                <h3>Loan Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-3">
                                <img src="../icons/cash-ledger-3.png" alt="icons" />
                                <h3>Investment Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-4">
                                <img src="../icons/cash-ledger-4.png" alt="icons" />
                                <h3>Disbursement  Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-5">
                                <img src="../icons/cash-ledger-5.png" alt="icons" />
                                <h3>Escrow Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-6">
                                <img src="../icons/cash-ledger-6.png" alt="icons" />
                                <h3>Trust Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-7">
                                <img src="../icons/cash-ledger-7.png" alt="icons" />
                                <h3>Overdraft Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-8">
                                <img src="../icons/cash-ledger-8.png" alt="icons" />
                                <h3>Interest Ledger</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid p-0 ">
                    <div className="fingerprint-img pt-100">
                        <img src="../images/fingerprint.png" className="img-fluid" alt="fingerprint" />
                        <div className="global-left">
                            <h3>Global Person Identifier (GPI)</h3>
                            <hr />
                            <p>The Global Person Identifier (GPI) is a 20-character, alpha-numeric code based on the ISO 17442 standard developed by the International Organization for Standardization (ISO).</p>
                            <p>It connects to key reference information that enables clear and unique identification of individuals participating in financial transactions.</p>
                            <p>Each GPI contains information about a person's identification structure and thus answers the questions of 'who is who'.</p>
                            <p>Simply put, the publicly available GPI data pool can be regarded as a global directory, which greatly enhances transparency in the global marketplace.</p>
                        </div>
                        <div className="global-left global-right mt-5 mt-lg-0">
                            <h3>Global Entity Identifier (GEI)</h3>
                            <hr />
                            <p>For legal business entities, a Global Entity Identifier (GEI) is established with its identification structure.</p>
                            <p>A customer transacting across multiple financial institutions will have the same GPI or GEI.</p>
                            <p className="mb-0">Both GPI and GEI are enablers for reducing and preventing tax evasion, money laundering, and fraud in cryptocurrency and digital currency transactions</p>
                   </div>
                </div>
            </div>
            <div className="digital">
                <div className="container-fluid p-0">
                    <div className="digital-img-bg">
                        <img src="../images/digital-img.jpg" className="img-fluid" alt="digital-img" />
                        <div className="carousel-caption">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <img src="../images/card.png" className="img-fluid" alt="card" />
                                </div>
                                <div className="col-md-6">
                                    <div className="digital-text">
                                        <h3>Digital Currency Network Monitoring</h3>
                                        <p>The Global KYC Registry provides a global unique identifier for all customers which are associated with all of their financial transactions.</p>

                                        <p className="mb-0">This enables comprehensive network monitoring of customer transactions across financial institutions and across country borders.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}

export default About2;