import React from "react";
function Originator({nextstep }) {


  return (
    <>
          
          <div className="row mt-5">
              <div className="col-xxl-10">
                  <div className="wallet-balances-box">
                      <div className="d-md-flex justify-content-between px-4">
                          <div className="">
                              <div className="mb-3">
                                  <input type="text" className="form-control line-input" placeholder="Amount"></input>
                              </div>
                          </div>
                          <div className="">
                              <div className="line-input input-group mb-3">
                                  <span className="input-group-text"><i className="bi bi-search"></i></span>
                                  <input type="text" className="form-control" placeholder="search" />
                              </div>
                          </div>
                      </div>
                      <div className="table-responsive p-3 p-md-4">
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th>Digital Currency</th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>Currencycode</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>Token Class</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                      <th>
                                          <select class="form-select" aria-label="Default select example">
                                              <option selected>Token Type</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </select>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>John Matthew</td>
                                      <td>User</td>
                                      <td>8934802983</td>
                                      <td className="text-end">823.00 USD</td>
                                  </tr>
                                  <tr>
                                      <td>Teegan Wu</td>
                                      <td>Jacob</td>
                                      <td>8934802983</td>
                                      <td className="text-end">23.00 USD</td>
                                  </tr>
                                  <tr>
                                      <td>Rohan Storey</td>
                                      <td>User</td>
                                      <td>8934802983</td>
                                      <td className="text-end">222.00 USD</td>
                                  </tr>
                                  <tr>
                                      <td>Kaiya Squires</td>
                                      <td>User</td>
                                      <td>8934802983</td>
                                      <td className="text-end" >314.00 USD</td>
                                  </tr>
                                  <tr>
                                      <td>Owen Hendrix</td>
                                      <td>User</td>
                                      <td>8934802983</td>
                                      <td className="text-end">100.00 USD</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      <div className="col-xxl-10">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0 me-4"
            onClick={nextstep}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
}
export default Originator;
