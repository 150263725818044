import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";
import { GetPeople } from "../../../api/PeopleApi";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import Grid from "../../../Components/Grid";
import { Peoplecolumns } from "../../../Components/GridHeaders";

function PeopleBalances() {
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [rows, setRows] = React.useState([]);
  // const [row, setRow] = useState();

  const dispatch = useDispatch();

  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await GetPeople(APPID, APIKEY);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: PeopleBalances.js ~ line 32 ~ fetchdata ~ devryptedIssuer",
        devryptedIssuer
      );

      let jsonData = JSON.parse(JSON.parse(devryptedIssuer).CommandResponse);
      // let jsonData = JSON.parse(devryptedIssuer).CommandResponse;


      console.log("🚀 ~ file: PeopleBalances.js ~ line 38 ~ fetchdata ~ jsonData", jsonData)

      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      if (jsonData) {
        let dataArr = [];
        jsonData?.forEach((dataMain, index) => {
          dataArr.push({
            ...dataMain,
            rowid: dataMain.RowID,
          });
        });
        setRows(dataArr);
      }
    }
    
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5  m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>KYC People</h3>
                <h6>Get People</h6>
                <p>
                  This function lists all of the people registered with a wallet
                </p>
              </div>
              <div className="row">
                <div className="col-xxl-10">
                  <div className="wallet-balances-box">
                    <div className="d-md-flex justify-content-between ">
                      <div className="search-box d-md-flex">
                        <div className="input-group mb-3"></div>
                      </div>
                      <Link to={{ pathname: "/webwallet/createpeople" }}>
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{ marginBottom: "10px" }}
                        >
                          + ADD Person
                        </button>
                      </Link>
                    </div>
                    <div style={{ height: 800, width: "100%" }}>
                      <Grid row={rows} coloums={Peoplecolumns} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PeopleBalances;
