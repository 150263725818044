const SmartWallets = () => {
  return (
    <section className="section-4">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-8">
                      <img src="../images/img-4.png" alt="img" className="img-fluid" />
          </div>
          <div className="col-md-4 col-lg-4">
            <h2 className="small-heading">
              Smart <span>Wallets</span>
            </h2>
            <div className="small-divider" />
            <p>
              Our CBDC Wallets capture additional metadata for enhanced
              servicing and network security.
            </p>
            <p />
            <ul>
              <li>
                <p>Issuing Bank</p>
              </li>
              <li>
                <p>Issuing Bank Branch</p>
              </li>
              <li>
                <p>Originating Bank Officer</p>
              </li>
              <li>
                <p>Customer Industry Code</p>
              </li>
              <li>
                <p>Customer Geography Code</p>
              </li>
              <li>
                <p>Global KYC Identifier</p>
              </li>
              <li>
                <p>Overdraft Protection Limit</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SmartWallets;
