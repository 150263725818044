//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function merchants() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges merchants">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/merchants.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Merchants</p>
                            <h1>Increase <br className="d-none d-md-block" /> profitability with<br className="d-none d-md-block" /> digital trade and<br className="d-none d-md-block" /> digital payments</h1 >
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default merchants;