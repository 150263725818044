import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function GetFXRatesApi(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // currencyPaircode: "",
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/GetFXRates",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/GetFXRates",
    values,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  
  return response;
}
