import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";
export async function createEscrow(publickey, privateKey, issuerData) {
  const getToken = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/CreateEscrow",
    issuerData,
    // createIssusersDetails,
    publickey,
    privateKey
  );

  const response = await http.post(
    WEB_WALLET_URL + "/money/CreateEscrow",
    issuerData,
    // createIssusersDetails,
    {
      headers: { Authorization: getToken },
    }
  );

  return response;
}

export async function getEscrows(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // escrowcode: "",
    // escrowTypecode:""
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/GetEscrows",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/GetEscrows",
    values,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}

export async function acceptEscrow(publickey, privateKey, issuerData) {
  let values = {
    // sourcePublicAddress: publickey,
    // escrowcode: "",
    // escrowTypecode:""
    txhash: "GOOD",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/AcceptEscrow",
    values,

    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/AcceptEscrow",
    values,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function cancelEscrow(publickey, privateKey, issuresSuspendData) {
  // const modifyIssusersDetails = {
  //   sourcePublicAddress: publickey,
  //   txhash: "",
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/CancelEscrow",
    issuresSuspendData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/CancelEscrow",
    issuresSuspendData,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
