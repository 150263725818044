import React from "react";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function Reject() {
    return (
        <>
            {/* <Header /> */}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0">
                    <div className="row ms-lg-4 ms-xl-5 m-0">
                        {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}
                        <div className="col-12">
                            <div className="main-heading">
                                <h3> SPOT Protocol</h3>
                                <h6>
                                    Reject
                                </h6>
                                <p>
                                    This function enables a SPOT Protocol participant to reject a financial transaction submitted by the Meganodes to the Unicoin DLT.
                                </p>
                            </div>
                            <div className="reject-page m-0">
                                <div className="row">
                                    <div className="col-md-10 col-lg-8 col-xl-6 col-xxl-4">
                                        <div className="reject-box">
                                            <div className="reject-head">
                                                <div className="input-group">
                                                    <span className="input-group-text">
                                                        <i className="bi bi-search"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Transaction Hash"
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="reject-details">
                                                <div className="reject-list">
                                                    <h6>Source Wallet</h6>
                                                    <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
                                                </div>
                                                <div className="reject-list">
                                                    <h6>Target Wallet</h6>
                                                    <p>yyyyyyyyyyyyyyyyyyyyyyyyyyyyyy</p>
                                                </div>
                                                <div className="reject-list">
                                                    <h6>Currency Code</h6>
                                                    <p>USD</p>
                                                </div>
                                                <div className="reject-list">
                                                    <h6>Token Symbol</h6>
                                                    <p>USDA</p>
                                                </div>
                                                <div className="reject-list">
                                                    <h6>Amount</h6>
                                                    <p>$ 5,000</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-11">
                                        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                            <button
                                                type="button"
                                                className="btn cancel-btn mb-3 mb-md-0 me-4"
                                            >
                                                CANCEL
                                            </button>
                                            <button type="button" className="btn next-btn mb-3 mb-md-0">
                                                REJECT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default Reject;
