import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { getCurrencycodesAPI } from "../../../../../api/dropdown";
function Money({ nextstep, previous, setWallet, ResponseData }) {
  const [GetCurrencycode, setGetCurrencycode] = useState([]);

  const fetchdropdwon = async () => {
    let LKCurrencyCodes = await getCurrencycodesAPI("GetCurrencycodes");
    setGetCurrencycode(LKCurrencyCodes);
  };

  useEffect(() => {
    fetchdropdwon();
  }, []);
  const initialValues = {
    walletClassKeycode: "USER",
    walletTypeKeycode: "WALLET",
    wallet: "",
    displayname: "",
    countrycode: "",
    currencycodeList: "",
    overdraftProtection: false,
    overdraftLimit: 0,
    uuid: ResponseData?.uuid,
    issuercode: "",
    issuerBranchcode: "",
    issuerOfficercode: "",
    privateCode: "",
  };
  const validationSchema = Yup.object({
    currencycodeList: Yup.string().required("Primary currency is required!"),
    overdraftLimit: Yup.string().required(" Over draft is required!"),
  });
  return (
    <>
      <div className="row modify-token">
        <div className="col-xl-11 col-xxl-12">
          <div className="reject-page review-page ms-0 mt-5">
            <div className="wallet-information-box  m-0">
              <div className="wallet-information-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setWallet(values);
                    console.log(
                      "🚀 ~ file: Money.js ~ line 47 ~ Money ~ values",
                      values
                    );
                    // setWalletsData(values);
                    nextstep();
                  }}
                >
                  {(formik) => {
                    const { errors, touched, setFieldValue } = formik;
                    return (
                      <Form>
                        <div className="row mb-xl-5">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Wallet Information</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  disabled={true}
                                  type="text"
                                  name="walletClassKeycode"
                                  id="walletClassKeycode"
                                  placeholder="Overdraft Limit"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Wallet Class
                                </label>
                                <div
                                  className={
                                    errors.walletClassKeycode &&
                                    touched.walletClassKeycode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="walletClassKeycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  disabled={true}
                                  type="text"
                                  name="walletTypeKeycode"
                                  id="walletTypeKeycode"
                                  placeholder="Overdraft Limit"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Wallet Type</label>
                                <div
                                  className={
                                    errors.walletTypeKeycode &&
                                    touched.walletTypeKeycode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="walletTypeKeycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Primary Currency</label>
                              <Field
                                as="select"
                                id="currencycodeList"
                                name="currencycodeList"
                                className="form-select form-control"
                                onChange={(e) => {
                                  setFieldValue(
                                    "currencycodeList",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.currencycodeList}
                              >
                                {GetCurrencycode &&
                                  GetCurrencycode.map((value, index) => {
                                    return (
                                      <>
                                        <option
                                          value={value.keycode}
                                          key={index}
                                        >
                                          {value.displayname}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Field>
                              <div
                                className={
                                  errors.displayname && touched.displayname
                                    ? "input-error"
                                    : null
                                }
                              ></div>
                              <ErrorMessage
                                name="displayname"
                                component="span"
                                className="error"
                                style={{ color: "red" }}
                              />
                            </div>

                            <div className="mb-5">
                              <label>Overdraft Protection Enabled</label>
                              <div className="form-check form-switch">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="overdraftProtection"
                                  name="overdraftProtection"
                                />
                                <div
                                  className={
                                    errors.overdraftProtection &&
                                    touched.overdraftProtection
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="overdraftProtection"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="number"
                                  name="overdraftLimit"
                                  id="overdraftLimit"
                                  placeholder="Overdraft Limit"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Overdraft Limit
                                </label>
                                <div
                                  className={
                                    errors.overdraftProtection &&
                                    touched.overdraftProtection
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="overdraftProtection"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-11 p-0">
                            <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                              <button
                                type="button"
                                className="btn cancel-btn mb-3 mb-md-0 me-4"
                                onClick={previous}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                className="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button
                                type="submit"
                                className="btn next-btn mb-3 mb-md-0"
                              >
                                NEXT
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Money;

// import { Form, Formik } from "formik";
// import React from "react";
// function Money({ nextstep, setdata, wallet, previous }) {
//   console.log("🚀 ~ file: Money.js ~ line 4 ~ Money ~ wallet", wallet);
//   return (
//     <>
//       <div className="row modify-token">
//         <div className="col-xl-11 col-xxl-12">
//           <div className="reject-page review-page ms-0 mt-5">
//             <div className="wallet-information-box  m-0">
//               <div className="wallet-information-form">
//                 <Formik
//                   initialValues={{
//                     flexCheckDefault: [],
//                   }}
//                   onSubmit={(values) => {
//                     setdata(values);
//                     nextstep();
//                     // debugger;
//                     //   let data = [tokenData, values];
//                     // debugger;
//                     //   setdata(data[0]);
//                     //   setValue(values);
//                   }}
//                 >
//                   {(formik) => {
//                     return (
//                       <Form>
//                         <div className="row mb-xl-5">
//                           <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
//                             <h6>Wallet Information</h6>
//                             <div className="mb-5">
//                               <label>Primary Currency</label>
//                               <select
//                                 id="IndustryCode"
//                                 className="form-select form-control"
//                               >
//                                 <option defaultValue>--Select--</option>
//                                 <option value="1">One</option>
//                                 <option value="2">Two</option>
//                                 <option value="3">Three</option>
//                               </select>
//                             </div>
//                             <div className="mb-5">
//                               <label>Overdraft Protection Enabled</label>
//                               <div className="form-check form-switch">
//                                 <input
//                                   className="form-check-input"
//                                   type="checkbox"
//                                   id="flexSwitchCheckChecked"
//                                 />
//                               </div>
//                             </div>
//                             <div className="mb-5">
//                               <label>Overdraft Limit</label>
//                               <div className="input-box">
//                                 <input
//                                   type="text"
//                                   name="TokenClass"
//                                   id="TokenClass"
//                                   placeholder="Overdraft Limit"
//                                   className="form-control"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="row">
//                           <div className="col-md-11 p-0">
//                             <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//                               <button
//                                 type="button"
//                                 className="btn cancel-btn mb-3 mb-md-0 me-4"
//                                 onClick={previous}
//                               >
//                                 Previous
//                               </button>
//                               <button
//                                 type="button"
//                                 className="btn cancel-btn mb-3 mb-md-0 me-4"
//                               >
//                                 CANCEL
//                               </button>
//                               <button
//                                 type="submit"
//                                 className="btn next-btn mb-3 mb-md-0"
//                               >
//                                 NEXT
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </Form>
//                     );
//                   }}
//                 </Formik>
//                 {/* <div className="row mb-xl-5">
//                   <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
//                     <h6>Wallet Information</h6>
//                     <div className="mb-5">
//                       <label>Primary Currency</label>
//                       <select
//                         id="IndustryCode"
//                         className="form-select form-control"
//                       >
//                         <option defaultValue>--Select--</option>
//                         <option value="1">One</option>
//                         <option value="2">Two</option>
//                         <option value="3">Three</option>
//                       </select>
//                     </div>
//                     <div className="mb-5">
//                       <label>Overdraft Protection Enabled</label>
//                       <div className="form-check form-switch">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           id="flexSwitchCheckChecked"
//                         />
//                       </div>
//                     </div>
//                     <div className="mb-5">
//                       <label>Overdraft Limit</label>
//                       <div className="input-box">
//                         <input
//                           type="text"
//                           name="TokenClass"
//                           id="TokenClass"
//                           placeholder="Overdraft Limit"
//                           className="form-control"
//                         />
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-md-11 p-0">
//                         <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//                           <button
//                             type="button"
//                             className="btn cancel-btn mb-3 mb-md-0 me-4"
//                           >
//                             PREVIOUS
//                           </button>
//                           <button
//                             type="submit"
//                             className="btn next-btn mb-3 mb-md-0"
//                           >
//                             NEXT
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Money;
