import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FetchCallDataAPI } from "../../../../api/MoneyServices";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
import PrivateModal from "../../../../Components/PrivateModal";
function Money({ previous, transferData, banksData, ledgerData }) {
  const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const data = {
    transactionClasscode: "",
    tokenSymbol: transferData.tokenSymbol,
    sourcePeopleID: 0,
    sourceBankID: banksData.bankID,
    sourceLedgercode: "",
    targetPeopleID: 0,
    targetBankID: banksData.parentBankID,
    targetLedgercode: ledgerData.ledgercode,
  };
  let APPID = "";
  let APIKEY = "";

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await FetchCallDataAPI(APPID, APIKEY,data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      console.log(
        "🚀 ~ file: Review.js ~ line 23 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      const jsonData = JSON.parse(devryptedIssuer);
      console.log(
        "🚀 ~ file: Review.js ~ line 24 ~ closeModal~ jsonData",
        jsonData
      );

      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // nextstep();
    }
    setPrivateModal(false);
  };
  return (
    <>
      {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
      <div className="review-wallet-box mt-5 ms-0">
        <div className="row">
          <div className="col-xxl-11">
            <div className="review-wallet-info-box mb-5">
              <div className="row">
                <div className="col-12">
                  <div className="heading">
                    <h3>Payment</h3>
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <hr className="mb-4" />
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Digital Currency</h4>
                      <p>{transferData.displayname}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Currencycode</h4>
                      <p>{transferData.currencycodeList}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Token Class</h4>
                      <p>{transferData.tokenClass}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Token Type</h4>
                      <p>{transferData.tokenType}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-wallet-info-box mb-5">
              <div className="row">
                <div className="col-12">
                  <div className="heading">
                    <h3>Source</h3>
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <hr className="mb-4" />
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Bank</h4>
                      <p>{banksData.bankName}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Bank Class</h4>
                      <p>{banksData.bankclass}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Bank Type</h4>
                      <p>{banksData.bankType}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Country</h4>
                      <p>{banksData.countrycode}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>State</h4>
                      <p>{banksData.stateProvince}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>City</h4>
                      <p>{banksData.cityTown}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-wallet-info-box mb-5">
              <div className="row">
                <div className="col-12">
                  <div className="heading">
                    <h3>Target</h3>
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <hr className="mb-4" />
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Ledger</h4>
                      <p>{ledgerData.displayname}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Ledger Class</h4>
                      <p>{ledgerData.ledgerClass}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  <div className="wallet-list">
                    <i className="bi bi-check"></i>
                    <div>
                      <h4>Ledger Type</h4>
                      <p>{ledgerData.ledgerType}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xxl-11">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn cancel-btn mb-3 mb-md-0 me-4"
            onClick={previous}
          >
            PREVIOUS
          </button>
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
            onClick={() => {
              closeModal();
            }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
}
export default Money;
