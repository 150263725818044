import React, { useState } from "react";
import Header from "../../Components/Header";
import Wallets from "../Documentation/Wallets";
import Tokens from "../Documentation/Tokens";
import IssuerDocumention from "./IssuerDocumention";
import Footer from "../../Components/Footer";
import Ledgers from "../Documentation/Ledgers";
import Authorizations from "../Documentation/Authorizations";
import MoneyService from "../Documentation/MoneyService";
import FxRates from "../Documentation/FxRates";
import SpotStaking from "../Documentation/SpotStaking/SpotStaking";
// import SidebarDocumention from "../../Components/SidebarDocumention";
function Issuer() {
  const [selectedmenu, setSelectedMenu] = useState("Issuer");
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };
  const rendermenu = () => {
    switch (selectedmenu) {
      case "Issuer":
        return <IssuerDocumention />;
      case "Wallets":
        return <Wallets />;
      case "Tokens":
        return <Tokens />;
      case "Ledgers":
        return <Ledgers />;
      case "Authorizations":
        return <Authorizations />;
      case "Money Services":
        return <MoneyService />;
      case "FX Rates":
        return <FxRates />;
      case "Spot Staking":
        return <SpotStaking />;
      default:
        return <IssuerDocumention />;
    }
  };
  return (
    <>
      <div className="documentation">
        <Header />

        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
              <div className="sidebar sidebar-documention">
                <div className="sidebar-main">
                  <div className="issuers">
                    <h2
                      id="home"
                      className={
                        selectedmenu === "Issuer"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-Issuers-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/Issuers-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Issuer</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      id="profile"
                      className={
                        selectedmenu === "Wallets"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-Wallets-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/Wallets-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Wallets</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      className={
                        selectedmenu === "Tokens"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      id="tokens-documentation"
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-token-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/token-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Tokens</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      className={
                        selectedmenu === "Ledgers"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      id="ledgers-documentation"
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-Ledgers-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/Ledgers-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Ledgers</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      className={
                        selectedmenu === "Authorizations"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      id="authorizations-documentation"
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-Authorizations-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/Authorizations-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Authorizations</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      className={
                        selectedmenu === "Money Services"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      onClick={(e) => {
                        handleClick(e.target.innerHTML);
                      }}
                      id="money-documentation"
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-Money Services-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/Money Services-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Money Services</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      className={
                        selectedmenu === "FX Rates"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      onClick={(e) => {
                        handleClick(e.target.innerHTML);
                      }}
                      id="fx-documentation"
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-FX Rates-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/FX Rates-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>FX Rates</h3>
                        </div>
                      </button>
                    </h2>

                    <h2
                      className={
                        selectedmenu === "Spot Staking"
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      onClick={(e) => {
                        handleClick(e.target.innerHTML);
                      }}
                      id="spot-documentation"
                    >
                      <button
                        className="accordion-button"
                        onClick={(e) => {
                          handleClick(e.target.innerHTML);
                        }}
                      >
                        <div className="sidebar-main-label d-flex align-items-center">
                          <img
                            src="../icons/d-Spot-icon-active.png"
                            className="issuers-icon-img show"
                            alt="Issuers-icon-img"
                          ></img>
                          <img
                            src="../icons/Spot-icon.png"
                            className="issuers-icon-img none"
                            alt="issuers-icon-img"
                          ></img>
                          <h3>Spot Staking</h3>
                        </div>
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-9 col-xxl-10">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-11 col-xxxl-9">
                  <div className="main-heading">
                    <h3>Documentation</h3>
                  </div>
                  <div className="documentation-tabs">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <button
                        className={
                            selectedmenu === "Issuer"
                              ? "nav-link active"
                              : "nav-link"
                          }
                         
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Issuer
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                         className={
                            selectedmenu === "Wallets"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Wallets
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            selectedmenu === "Tokens"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tokens-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#tokens-documentation"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Tokens
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                           className={
                            selectedmenu === "Ledgers"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="ledgers-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#ledgers-documentation"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Ledgers
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                           className={
                            selectedmenu === "Authorizations"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="authorizations-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#authorizations-documentation"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Authorizations
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                         className={
                            selectedmenu === "Money Services"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="money-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#money-documentation"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Money Services
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                           className={
                            selectedmenu === "FX Rates"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="fx-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#fx-documentation"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          FX Rates
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                           className={
                            selectedmenu === "Spot Staking"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="spot-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#spot-documentation"
                          type="button"
                          onClick={(e) => {
                            handleClick(e.target.innerHTML);
                          }}
                        >
                          Spot Staking
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-md-12 p-md-0">
                      <div className="tab-content" id="myTabContent">
                        {rendermenu()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Issuer;
