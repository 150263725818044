import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateTrasferApi } from "../../../../api/MoneyServices";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
import PrivateModal from "../../../../Components/PrivateModal";
function Money({ previous, nextstep }) {
    const [privateModal, setPrivateModal] = useState(false);
    const publicKeys = useSelector((state) => state.keyReducer);
    let APPID = "";
    let APIKEY = "";

    const closeModal = async () => {
        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }
        if (APPID && APIKEY) {
            const encryptedIssuer = await CreateTrasferApi(APPID, APIKEY);
            const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
            const jsonData = JSON.parse(devryptedIssuer);

            if (jsonData.returnMessage) {
                success(jsonData.returnMessage);
            }
            // nextstep();
        }
        setPrivateModal(false);
    };
    return (
        <>
            {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
            )}
            <div className="review-wallet-box mt-5 ms-0">
                <div className="row">
                    <div className="col-xxl-11">
                        <div className="review-wallet-info-box mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="heading">
                                        <h3>Payment</h3>
                                        <i className="bi bi-pencil-square"></i>
                                    </div>
                                    <hr className="mb-4" />
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Digital Currency</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Currencycode</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Token Class</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Token Type</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="review-wallet-info-box mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="heading">
                                        <h3>Source</h3>
                                        <i className="bi bi-pencil-square"></i>
                                    </div>
                                    <hr className="mb-4" />
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Ledger</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Ledger Class</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Ledger Type</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="review-wallet-info-box mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="heading">
                                        <h3>Target</h3>
                                        <i className="bi bi-pencil-square"></i>
                                    </div>
                                    <hr className="mb-4" />
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Ledger</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Ledger Class</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <div className="wallet-list">
                                        <i className="bi bi-check"></i>
                                        <div>
                                            <h4>Ledger Type</h4>
                                            <p>xyz</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-11">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                    <button
                        type="button"
                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                        onClick={previous}
                    >
                        PREVIOUS
                    </button>
                    <button
                        type="button"
                        className="btn next-btn mb-3 mb-md-0"
                        onClick={nextstep}
                    >
                        SUBMIT  
                    </button>
                </div>
            </div>
        </>
    );
}
export default Money;
