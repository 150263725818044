import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import ModifyOriginator from "./Modify/ModifyOriginator";
import ModifyReview from "./Modify/ModifyReview";

function ModifyPeople() {
  const [step, setstep] = useState(1);
  const [people, setPeople] = useState(1);

  // let { hash } = useLocation();

  const nextstep = () => {
    // debugger;
    if (step < 2) {
      setstep(step + 1);
    }
    // formData();
  };
  const cleandata = () => {
    setPeople(1);
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };
  const setdata = (data) => {
    setPeople(data);
  };

  // const formData = (data) => {
  //   console.log("🚀 ~ file: CreatePeople.js ~ line 37 ~ formData ~ data", data);
  // };
  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <ModifyOriginator
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            cleandata={cleandata}
            people={people}
            // formData={formData}
          />
        );
      case 2:
        return (
          <ModifyReview
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            cleandata={cleandata}
            people={people}
          />
        );
      default:
        return false;
    }
  };

  return (
    <>
      {/* <Header /> */}

      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="container-fluid p-0 ">
           <div className="row ms-lg-4 ms-xl-5"> 
          {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                            <Sidebar />
                        </div> */}
          <div className="col-12">
            <div className="main-heading mt-4">
                          <h3>
                              KYC People
                          </h3>
                          <h6>Modify</h6>
                          <p>
                                  This function updates the KYC information for an individual customer.
                          </p>
            </div>
            <div className="deposit-funds-tabs m-0">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li
                  className={
                    step > 1
                      ? "nav-item money-mr-w disableElement"
                      : "nav-item money-mr-w"
                  }
                >
                  <button
                    className={step === 1 ? "nav-link active" : "nav-link"}
                    id="profile-tab"
                    type="button"
                  >
                    <div className="beneficiary">
                      <h3>1</h3>
                      <i className="bi bi-check-lg"></i>
                    </div>
                    <h6>Owner</h6>
                  </button>
                </li>

                <li
                  className={step > 2 ? "nav-item disableElement" : "nav-item"}
                >
                  <button
                    className={step === 2 ? "nav-link active" : "nav-link"}
                    id="cot-tab"
                    type="button"
                  >
                    <div className="review">
                      <h3>2</h3>
                      <i className="bi bi-check-lg"></i>
                    </div>
                    <h6>Review</h6>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home">
                  {/* <Originator /> */}
                  {renderstep(step)}
                </div>
              </div>
            </div>

            {/* <div className="col-xxl-11">
              <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                <button
                  type="button"
                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                  onClick={previous}
                >
                  {step === 1 ? "Skip" : "Previous"}
                </button>
                <button
                  type="button"
                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn next-btn mb-3 mb-md-0"
                  onClick={nextstep}
                >
                  {step === 2 ? "Submit" : "Next"}
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
       </div> 
    </>
  );
}
export default ModifyPeople;
