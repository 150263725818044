import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    getCountryCodeDropDown,
    getDailingDropDown,
} from "../../../../api/dropdown";
import { decrypytdata } from "../../../../api/walletKey";
import { useLocation } from "react-router-dom";
import { getPayee } from "../../../../api/PayeesApi";
import { showLoader } from "../../../../action";

function ModifyPayee({ nextstep, setdata }) {
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    //   const [dropdown, setDropdown] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [dailingCode, setDailingCode] = useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);
    const [initialValues, setInitialValues] = useState();
    const location = useLocation();
    const find = location.search.replace("?id=", "");
    //   const initialValues = {
    //     payeeTypeID: 0,
    //     payeecode: "",
    //     payee: "",
    //     displayname: "",
    //     firstname: "",
    //     secondFirstname: "",
    //     lastname: "",
    //     secondLastname: "",
    //     businessName: "",
    //     email: "",
    //     mobileDialingcode: "",
    //     mobilePhone: "",
    //     addressLine1: "",
    //     addressLine2: "",
    //     addressLine3: "",
    //     cityTown: "",
    //     stateProvince: "",
    //     postalCode: "",
    //     countrycode: "",
    //     statecode: "",
    //     citycode: "",
    //     username: "",
    //     swiftcode: "",
    //     bankAccuityID: 0,
    //     bankcodeType: "",
    //     bankcode: "",
    //     accountClasscode: "",
    //     accountTypecode: "",
    //     accountNumber: "",
    //   };
    //eslint-disable-next-line
    const initialValues1 = {
        accuityID: 0,
        issuercode: "EkDo",
        hqIssuercode: "EkDo",
        issuerTypecode: "",
        issuer: "EkDoCor",
        displayname: "EkDoCor",
        institutionTypecode: "",
        officeTypecode: "",
        nativeKEY: "BBDK",
        issuerUUID: "ISSUERuid8",
        incorporationNumber: "7777",
        federal_TaxID: "AB18",
        isO_LEI: "DAK",
        addressLine1: "260 SHWETA TOWN",
        addressLine2: "BH CHURCH Road",
        addressLine3: "Road no 4",
        neighborhood: "BEGU",
        cityTown: "SAURASTRA",
        stateProvince: "Gujarat",
        postalCode: "395052",
        countrycode: "",
        currencycode: "",
        timezoneID: "",
        username: "EkDo1",
        userUUID: "USERuid9",
        firstname: "EkDo2",
        secondFirstname: "EKD",
        lastname: "Corpo",
        secondLastname: "CO",
        jobTitle: "CA",
        email: "EkDo@GMAIL.COM",
        mobileDialingcode: "",
        mobilePhone: "9422816792",
        imageFN: "stk",
    };

    // const validationSchema = Yup.object({
    //   issuer: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Issuer required"),
    //   issuercode: Yup.string().required("IssuerCode is required!"),
    //   nativeKEY: Yup.string(),
    //   issuerUUID: Yup.string(),
    //   hqIssuercode: Yup.string(),
    //   displayname: Yup.string()
    //     .max(20, "Must be 20 characters or less")
    //     .required("Displayname Required"),
    //   issuerTypecode: Yup.string().required("IssuerType is required!"),
    //   accuityID: Yup.number(),
    //   institutionTypecode: Yup.string().required(
    //     "InstitutionTypecode is required!"
    //   ),
    //   countrycode: Yup.string().required("CountryCode is required!"),
    //   stateProvince: Yup.string(),
    //   cityTown: Yup.string(),
    //   postalCode: Yup.string(),
    //   officeTypecode: Yup.string().required("officeTypecode is required!"),
    //   firstName: Yup.string().required("firstName is required!"),
    //   secondFirstname: Yup.string(),
    //   lastName: Yup.string().required("lastName is required!"),
    //   secondLastname: Yup.string(),
    //   email: Yup.string().required("email is required!"),
    //   jobTitle: Yup.string().required("jobTitle is required!"),
    //   username: Yup.string().required("username is required!"),
    //   addressLine1: Yup.string(),
    //   addressLine2: Yup.string(),
    //   addressLine3: Yup.string(),
    //   neighborhood: Yup.string(),
    //   userUUID: Yup.string(),
    //   mobileDialingcode: Yup.string()
    //     .typeError("you must specify a number")
    //     .required("DialingCode is required!"),
    //   mobilePhone: Yup.number()
    //     .typeError("you must specify a number")
    //     .max(9999999999, "Must be 10 Digit")
    //     .min(1111111111, "Must be 10 Digit")
    //     .required("PhoneNumber is required!"),
    //   incorporationNumber: Yup.string(),
    //   federal_TaxID: Yup.string(),
    //   isO_LEI: Yup.string(),
    //   imageFN: Yup.string(),
    // });
    const fetchdropdwon = async () => {
        // let dropdownvalues = await getDropdownvalues("LKIssuerType");
        // setDropdown(dropdownvalues);

        let countryType = await getCountryCodeDropDown("GetWorldCurrency");
        setCountryCode(countryType);

        let dialing = await getDailingDropDown("GetDialingcodes");
        setDailingCode(dialing);
    };

    const dispatch = useDispatch();
    let APPID = "";
    let APIKEY = "";
    //eslint-disable-next-line
    const fetchdata = async () => {
        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }
        console.log(APPID, APIKEY, "APPIDAPIKEY");
        if (APPID && APIKEY) {
            dispatch(showLoader(true));
            const encryptedIssuer = await getPayee(APPID, APIKEY);
            dispatch(showLoader(false));
            const Issuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
            // console.log("🚀 ~ file: ModifyIssuer.js ~ line 70 ~ fetchdata ~ Issuer", Issuer)
            let data = JSON.parse(JSON.parse(Issuer).commandResponse);
            console.log(data, "datadata");
            data.forEach((dataMain) => {
                // console.log("🚀 ~ file: ModifyIssuer.js ~ line 75 ~ fetchdata ~ dataMain", dataMain.PostalCode)

                if (dataMain.RowID === Number(find)) {
                    return setInitialValues({
                        accuityID: dataMain.AccuityID,
                        postalcode: dataMain.PostalCode,
                        issuer: dataMain?.Issuer ? dataMain.Issuer : "",
                        nativeKEY: dataMain?.NativeKEY ? dataMain.NativeKEY : "",
                        displayname: dataMain?.Displayname ? dataMain?.Displayname : "",
                        issuerUUID: dataMain?.issuerUUID ? dataMain?.issuerUUID : "",
                        institutionTypecode: dataMain?.InstitutionTypecode
                            ? dataMain?.InstitutionTypecode
                            : "",
                        officeTypecode: dataMain?.OfficeTypecode
                            ? dataMain?.OfficeTypecode
                            : "",
                        countrycode: dataMain?.Countrycode ? dataMain?.Countrycode : "",
                        stateProvince: dataMain?.StateProvince
                            ? dataMain?.StateProvince
                            : "",
                        cityTown: dataMain?.CityTown ? dataMain?.CityTown : "",
                        addressLine1: dataMain?.AddressLine1 ? dataMain.AddressLine1 : "",
                        addressLine2: dataMain?.AddressLine2 ? dataMain.AddressLine2 : "",
                        addressLine3: dataMain?.AddressLine3 ? dataMain.AddressLine3 : "",
                        neighborhood: dataMain?.Neighborhood ? dataMain.Neighborhood : "",
                        incorporationNumber: dataMain?.IncorporationNumber
                            ? dataMain?.IncorporationNumber
                            : "",
                        federal_TaxID: dataMain?.Federal_TaxID
                            ? dataMain?.Federal_TaxID
                            : "",
                        isO_LEI: dataMain?.ISO_LEI ? dataMain?.ISO_LEI : "",
                        dialingcode: dataMain?.Dialingcode ? dataMain?.Dialingcode : "",
                        phoneNumber: dataMain?.PhoneNumber ? dataMain?.PhoneNumber : "",
                        issuercode: dataMain?.Issuercode ? dataMain?.Issuercode : "",
                        hqIssuercode: dataMain?.Issuercode ? dataMain?.Issuercode : "",
                    });
                }
            });
        }
        
    };

    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);

    return (
        <>
            {isValidPublicAddress && (
                <div className="col-md-9 col-lg-9 col-xxl-10">
                    <div className="ledger-information create-issuer-information">
                        <div className="row ms-lg-4 ms-xl-5 m-0">
                            <div className="col-12">
                                <div className="main-heading">
                                    <h3>Payee</h3>
                                    <h6>Modify Payee</h6>
                                    <p>
                                        This function enables modifying payee information.

                                    </p>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    // validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        setdata(values);
                                        nextstep();
                                    }}
                                >
                                    {(formik) => {
                                        const { errors, touched, setFieldValue } = formik;
                                        return (
                                            <div className="wallet-information-box m-0">
                                                <h4>Beneficiary Information</h4>

                                                <Form className="wallet-information-form">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Address</h6>
                                                            <div className="mb-5">
                                                                <label>Country</label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-select form-control"
                                                                    id="countrycode"
                                                                    name="countrycode"
                                                                    onChange={(e) => {
                                                                        // console.log(e.target.value, "Country Code");
                                                                        setFieldValue("countrycode", e.target.value);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    {countryCode &&
                                                                        countryCode.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.countrycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.isoCountryname}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div>
                                                                    <ErrorMessage
                                                                        name="countrycode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>State</label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-select form-control"
                                                                    id="state"
                                                                    name="state"
                                                                    onChange={(e) => {
                                                                        // console.log(e.target.value, "Country Code");
                                                                        setFieldValue("state", e.target.value);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    {/* {countryCode &&
                                  countryCode.map((value, index) => {
                                    return (
                                      <>
                                        <option
                                          value={value.countrycode}
                                          key={index}
                                        >
                                          {value.isoCountryname}
                                        </option>
                                      </>
                                    );
                                  })} */}
                                                                </Field>
                                                                <div>
                                                                    <ErrorMessage
                                                                        name="state"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Company Name </h6>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="businessname"
                                                                        id="businessname"
                                                                        placeholder="Business Name"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Business Name</label>
                                                                    <div
                                                                        className={
                                                                            errors.businessname && touched.businessname
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="businessname"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="aliasname"
                                                                        id="aliasname"
                                                                        placeholder="Alias Name"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Alias Name</label>
                                                                    <div
                                                                        className={
                                                                            errors.aliasname && touched.aliasname
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="aliasname"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Personal Name </h6>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="firstname"
                                                                        id="firstname"
                                                                        placeholder="First Name"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">First Name</label>
                                                                    <div
                                                                        className={
                                                                            errors.firstname && touched.firstname
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="firstName"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="secondFirstname"
                                                                        id="secondFirstname"
                                                                        placeholder="Middle Name"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Middle Name</label>
                                                                    <div
                                                                        className={
                                                                            errors.secondFirstname &&
                                                                                touched.secondFirstname
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="secondFirstname"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="lastname"
                                                                        id="lastname"
                                                                        placeholder="Last Name"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Last Name</label>
                                                                    <div
                                                                        className={
                                                                            errors.lastname && touched.lastname
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="lastname"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="secondLastname"
                                                                        id="secondLastname"
                                                                        placeholder="Second Last Name"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Second Last Name</label>
                                                                    <div
                                                                        className={
                                                                            errors.secondLastname &&
                                                                                touched.secondLastname
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="secondLastname"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Contact</h6>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="email"
                                                                        id="email"
                                                                        placeholder="Email Address"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Email Address </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <div
                                                                        className={
                                                                            errors.email && touched.email
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <ErrorMessage
                                                                            name="email"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>Dialing Code</label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-select form-control"
                                                                    id="mobileDialingcode"
                                                                    name="mobileDialingcode"
                                                                    onChange={(e) => {
                                                                        // console.log(e.target.value, "Dialing Code");
                                                                        setFieldValue(
                                                                            "mobileDialingcode",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    {dailingCode &&
                                                                        dailingCode.map((value, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option
                                                                                        value={value.keycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.displayname}
                                                                                    </option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div>
                                                                    <ErrorMessage
                                                                        name="mobileDialingcode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="mobilePhone"
                                                                        id="mobilePhone"
                                                                        placeholder="Phone Number"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Phone Number</label>
                                                                    <div
                                                                        className={
                                                                            errors.mobilePhone && touched.mobilePhone
                                                                                ? "input-error"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <i className="bi bi-check-lg"></i>
                                                                        <ErrorMessage
                                                                            name="mobilePhone"
                                                                            component="span"
                                                                            className="error"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <h4>Wallet Informations (optional)</h4>
                                                        <div className="row mb-xl-5">
                                                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                <div className="mb-5">
                                                                    <div className="input-box form-floating">
                                                                        <Field
                                                                            type="text"
                                                                            name="publicwalletaddress"
                                                                            id="publicwalletaddress"
                                                                            placeholder="Wallet Public Wallet"
                                                                            className="form-control"
                                                                        />
                                                                        <label className="ps-0 pb-0">Wallet Public Wallet </label>
                                                                        <div
                                                                            className={
                                                                                errors.publicwalletaddress &&
                                                                                    touched.publicwalletaddress
                                                                                    ? "input-error"
                                                                                    : null
                                                                            }
                                                                        >
                                                                            <i className="bi bi-check-lg"></i>
                                                                            <ErrorMessage
                                                                                name="publicwalletaddress"
                                                                                component="span"
                                                                                className="error"
                                                                                style={{ color: "red" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                <div className="mb-5">
                                                                    <div className="input-box form-floating">
                                                                        <Field
                                                                            type="text"
                                                                            name="username"
                                                                            id="username"
                                                                            placeholder="Wallet Username"
                                                                            className="form-control"
                                                                        />
                                                                        <label className="ps-0 pb-0">Wallet Username</label>
                                                                        <div
                                                                            className={
                                                                                errors.username && touched.username
                                                                                    ? "input-error"
                                                                                    : null
                                                                            }
                                                                        >
                                                                            <i className="bi bi-check-lg"></i>
                                                                            <ErrorMessage
                                                                                name="username"
                                                                                component="span"
                                                                                className="error"
                                                                                style={{ color: "red" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                        <button
                                                            type="button"
                                                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                        >
                                                            CANCEL
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn next-btn mb-3 mb-md-0"
                                                        >
                                                            NEXT
                                                        </button>
                                                    </div>
                                                </Form>
                                            </div>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModifyPayee;
