const CentralBankSection = () => {
  return (
    <section className="two-column-block 2 block1">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="large-heading">
              Central Bank Digital Currency
              <br />
              <span>CBDC</span>
            </h1>
            <div className="small-divider" />
          </div>
          <div className="col-md-5 offset-md-1 text-right plr-0">
            <p>
              The DCMA provides digital currency, digital trade, and digital
              payment solutions for government and central banks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CentralBankSection;
