import React from 'react';
function Milestone() {
    return (
        <>
                <div className="row mt-5">
                    <div className="col-xxl-10">
                        <div className="wallet-balances-box">
                            <div className="d-md-flex justify-content-between px-4">
                                <div className="search-box d-md-flex">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text"><i className="bi bi-search"></i></span>
                                        <input type="text" className="form-control" placeholder="Search"></input>
                                        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Wallet Name</button>
                                        <ul className="dropdown-menu dropdown-menu-end">

                                        </ul>
                                    </div>
                                    <button type="button" className="btn btn-primary mb-3 mb-md-0">SEARCH</button>

                                </div>

                                <button type="button" className="btn btn-primary ">+ ADD WALLET</button>
                            </div>
                            <div className="table-responsive p-3 p-md-4">

                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Wallet Name</th>
                                            <th>Token Symbol</th>
                                            <th>Public Address</th>
                                            <th className="text-end">Balance</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>John Matthew</td>
                                            <td>User</td>
                                            <td>8934802983</td>
                                            <td className="text-end">823.00 USD</td>
                                            <td>
                                                <ul className="d-flex mb-0 p-0">
                                                    <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Teegan Wu</td>
                                            <td>Jacob</td>
                                            <td>8934802983</td>
                                            <td className="text-end">23.00 USD</td>
                                            <td>
                                                <ul className="d-flex mb-0 p-0">
                                                    <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Rohan Storey</td>
                                            <td>User</td>
                                            <td>8934802983</td>
                                            <td className="text-end">222.00 USD</td>
                                            <td>
                                                <ul className="d-flex mb-0 p-0">
                                                    <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Kaiya Squires</td>
                                            <td>User</td>
                                            <td>8934802983</td>
                                            <td className="text-end" >314.00 USD</td>
                                            <td>
                                                <ul className="d-flex mb-0 p-0">
                                                    <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Owen Hendrix</td>
                                            <td>User</td>
                                            <td>8934802983</td>
                                            <td className="text-end">100.00 USD</td>
                                            <td>
                                                <ul className="d-flex mb-0 p-0">
                                                    <li> <img src="../icons/Actions-pin.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-user.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-edit.png" alt="Wallets-icon-img"></img></li>
                                                    <li className="ms-3"> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

        </>
    );
}

export default Milestone;