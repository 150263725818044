import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const MonetaryPolicy = () => {
    return (
        <>
            <Header />
            <div className="monetary-policy">
                <div className="container-fluid p-0">
                    <div className="monetary-policy-slider">
                        <img src="../images/monetary-policy-img.png" className="img-fluid" alt="monetary-policy-img" />
                        <div className="monetary-policy-slider-text">
                            <img src="../images/coins/U-1-small.png" alt="U-1-small" />
                            <p>Universal Monetary Unit</p>
                            <h1>Monetary Policy Framework</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="monetary-policy-question">
                            <h2><span>01</span>Monetary Policy Framework</h2>
                            <p>The Digital Currency Monetary Authority (DCMA), is responsible for establishing monetary policy governing the Unicoin cryptocurrency.</p>
                            <p>Unicoin is a cryptocurrency engineered as a universal monetary unit (UMU) for global, continental, and domestic trade and payments.</p>
                            <p>The DCMA is currently organizing a Unicoin monetary policy committee (UMPC) consisting of up to twelve leading economists and central banking leaders around the world.</p>
                            <p>The primary goals of the UMPC are to establish a monetary policy framework for realizing Unicoin as a continuous demand store of value and a medium of exchange cryptocurrency and to ensure Unicoin qualifies as a cash reserve deposit currency for central banks around the world.</p>
                            <p>The Unicoin monetary policy framework consists of four (4) parts: open market operations, cash reserve incentives, FX discount rates, and depository interest rates.</p>
                            <p>The UMPC currently meets on the first Monday of each month.</p>
                            <div className="monetary-policy-question-two my-5">
                                <h2><span>1.1</span>Open Market Operations</h2>
                                <p>The primary value of any commodity is its utility value. With money, its utility is the ability to pay for goods, services, and debts as well as its ability to preserve value over a long period of time.</p>
                                <p>Because money can fluctuate based on fundamental economic factors as well as supply and demand, investors generally view money as a safer investment than traditional investments that have no underlying utility or value.</p>
                                <p>Aggressive movement in a currency price increases market volatility and lessens confidence in its stability. Highly volatile cryptocurrencies are viewed as speculative investments and lessens its adoptability as a true store of value or medium of exchange.</p>
                                <p>If money appreciates too fast, market participants tend to hoard it for the appreciation value, and if money depreciates too fast, market participants tend to overly spend it to minimize their loss of value.</p>
                                <p>The use of open market operations will assist in minimizing price volatility of Unicoin over time and should prevent Unicoin from being traded in the same pattern of the broad cryptocurrency market.</p>
                                <ul>
                                    <li>
                                        Unicoin open market operations will utilize a collaborating network of open market traders to buy and sell Unicoin to stabilize any aggressive volatility that may arise in Unicoin in the open market.
                                    </li>
                                    <li>
                                        Unicoin open market operations will also be a strategic tool for achieving short-term and longer-term monetary policy targets and goals.
                                    </li>
                                    <li>
                                        The Unicoin open market operations will deploy artificial intelligence and machine learning trading bot to assist in achieving Unicoin monetary policy goals.
                                    </li>
                                </ul>
                            </div>
                            <div className="monetary-policy-question-two mb-5">
                                <h2><span>1.2</span>Cash Reserve Incentives</h2>
                                <p>To establish monetary value and confidence in their national currency, central bank monetary policies impose cash depository and asset value requirements. A central bank's cash reserves is an integral part of establishing their credit worthiness and ratings with global credit agencies.</p>
                                <p>In a trickledown effect, central banks also impose minimum cash reserve requirements for their member banks correlated with their total customer depository liability.</p>
                                <p>As an money commodity, Unicoin could help central banks and their member banks meet their minimum depository requirements with less national fiat cash than would be generally required.</p>
                                <p>The UMPC monetary policy will establish specific monetary incentives for central banks and retail and commercial banks to purchase and hold Unicoin in their cash reserves.</p>
                                <p>Unicoin has engineered cash reserve ledgers specifically for central banks and their member banks.</p>
                            </div>
                            <div className="monetary-policy-question-two mb-5">
                                <h2><span>1.3</span>FX Discount Rates</h2>
                                <p>To stimulate continuous demand for Unicoin, the Unicoin protocol will offer banks and wallet holders the best-priced cross-border FX rate market worldwide.</p>
                                <p>Central banks increase and decrease interest rates to stimulate and to contract their money supply. With Unicoin, offering the best FX rates for cross-border money transfer should also stimulate Unicoin demand.</p>
                                <p>Although the Unicoin network will absorb an immediate loss on subsidizing and offering discounted FX rates, these best-priced FX rates are only accessible through the purchase of Unicoin.</p>
                                <p>Policymakers intend to achieve a cause-and-effect relationship of stimulating continuous demand for Unicoin which would ultimately increase it asset value. Depending on the continuous demand, UMPC policymakers will determine the effective FX discount rates.</p>
                                <p>The discount rate will generally range up to 5% to current intrabank (wholesale) FX rates. </p>
                                <p>For example, if the US Dollar to Mexican Peso (USDMXN) is currently trading on FX markets at 20.0000, the Unicoin USDMXN rate may be 21.0000, a 5% payout above wholesale bank rates.</p>
                                <p>See the Unipay function to learn how Unicoin can be tendered to make trade payments and remittances in any currency worldwide. Worldwide FX rates are updated daily on Unicoin network and can be viewed by clicking the Get FX Rates function in the Unicoin wallet.</p>
                            </div>
                            <div className="monetary-policy-question-two">
                                <h2><span>1.4</span>Depository Interest Rates</h2>
                                <p>Central banks offer interest rate yields to entice customer deposits and foreign investment in their national currency. With Unicoin, the UMPC will establish yield payout rates for wallet holders to stake Unicoin in the Staked Proof of Trust (SPOT) consensus protocol.</p>
                                <p>With no risk to principal, SPOT transaction validators could earn up to twenty percent (20%) annually. </p>
                                <p>The UMPC will establish SPOT yield rates based on the competitive decentralized finance (DeFi) market environment and based on the need to stimulate continuous demand for Unicoin.</p>
                                </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default MonetaryPolicy;
