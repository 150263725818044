import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import { Formik, Form, Field } from "formik";
// import PrivateModal from "../../../Components/PrivateModal";
import { setPrivateId, setResponseAPI, setResult } from "../../../action";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AgentSuspendAPI } from "../../../api/Agents";
import TokenConfirm from "../Token/TokenConfirm";

function AgentSuspend() {
  const [searchParams] = useSearchParams();
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const Branchcode = searchParams.get("id");
  const objectcode = searchParams.get("code");
  console.log("Branchcode", Branchcode);
  console.log("objectcode", objectcode);

  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");
  const [popUp, setPopUp] = useState(false);

  const initialValues = {
    issuercode: Branchcode,
    username: objectcode,
    // agentTypecode: "",
    // agentcode: "",
  };
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await AgentSuspendAPI(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      // const jsonData = JSON.parse(devryptedIssuer);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
    }
    // setPrivateModal(false);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {/* {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={suspendData} />
            )} */}
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <div className="main-heading">
                  <h3>Agents</h3>
                  <h6> Suspend Agents</h6>
                  <p>
                    This function suspends an agent from the Unicoin network.
                  </p>
                </div>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setdata(values);
                      setPopUp(true);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form className="wallet-information-form">
                          <div className="suspend-text wallet-information-form">
                            <div className="row">
                              <div class="col-sm-6 col-xl-4 col-xxl-5">
                                <div class="mb-5">
                                  <div class="input-box form-floating">
                                    <Field
                                      type="text"
                                      name="issuercode"
                                      id="issuercode"
                                      placeholder="Issuer Code"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Issuer Code
                                    </label>
                                  </div>
                                </div>
                                <div class="mb-5">
                                  <div class="input-box form-floating">
                                    <Field
                                      type="text"
                                      name="username"
                                      id="username"
                                      placeholder="User Name"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      User Name
                                    </label>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                  <button
                                    type="button"
                                    class="btn cancel-btn mb-3 mb-md-0 me-4"
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    // onClick={() => {
                                    //   suspendData();
                                    //   //   setPrivateModal(true);
                                    // }}
                                    type="submit"
                                    class="btn next-btn mb-3 mb-md-0"
                                  >
                                    SUSPEND
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {popUp && (
                                  <TokenConfirm
                                    heading={"Suspend Agent"}
                                    body={objectcode}
                                    suspendData={suspendData}
                                    setPopUp={setPopUp}
                                  />
                                )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AgentSuspend;
