import { WEB_WALLET_URL } from "../Constatnts/config";
import http from "./httpService";
import { hmacencrypt } from "./walletKey";

export async function getLimit(publickey, privateKey,data) {
  let values = {
    sourcePublicAddress: publickey,
    limitcode:data
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/GetLimits",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/compliance/GetLimits",
    // { sourcePublicAddress: publickey,tokenSymbol: "UMU" },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function createLimitAPI(publickey, privateKey, data) {
  // let values = {
  //   ...data,
  //   // sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/CreateLimit",
    data,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/compliance/CreateLimit",
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function modifyLimitAPI(publickey, privateKey, data) {
  // let values = {
  //   ...data,
  //   // sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/ModifyLimit",
    data,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/compliance/ModifyLimit",
    data,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}


export async function LimitSuspendAPI(publickey, privateKey,data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/compliance/SuspendLimit",
    data,
    publickey,
    privateKey
  );
  const response = await http.post("/compliance/SuspendLimit", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}