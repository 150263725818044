//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function DigitalMonetizationTechnology() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/continuous-cash-demand-reserved-cryptocurrency.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Digital Monetization Technology</p>
                            <h1>Enhance Profitability <br className="d-none d-md-block" /> Monetizing Digital<br className="d-none d-md-block" /> Currency</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalMonetizationTechnology;