import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../action";
import { getBanks } from "../../../../api/PayeesApi";

import { decrypytdata, validateApiResponse } from "../../../../api/walletKey";
import { AttacheWallet } from "../../../../api/Wallets";
import { Paycolumn } from "../../../../Components/GridHeaders";
import NewGrid from "../../../../Components/NewGrid";

function BankAccount() {
  const [rows, setRows] = useState([]);
  const [grid, setGrid] = useState();
  console.log(
    "🚀 ~ file: Bank Acoount.js ~ line 18 ~ BankAccount ~ grid",
    grid
  );
  // const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  let APPID = "";
  let APIKEY = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeModal = async (values) => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: Email.js ~ line 26 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      console.log("Bank Account");
      const encryptedIssuer = await AttacheWallet(APPID, APIKEY, values);
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 42 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 44 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // debugger;

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
    }
    dispatch(showLoader(false));
  };

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedLedger = await getBanks(
        APPID,
        APIKEY,
        validateApiResponse.countrycode
      );
      const Entity = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 76 ~ fetchdata ~ Entity",
        Entity
      );

      let data = JSON.parse(JSON.parse(Entity).CommandResponse);

      if (data) {
        let dataArr = [];

        data?.forEach((dataMain, index) => {
          dataArr.push({
            ...dataMain,
            rowid: dataMain.RowID,
          });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const onRowsSelectionHandler = (data) => {
    setGrid(data);
  };
  const initialValues = {
    walletLinkTypecode: "Bank",
    accountName: "",
    accountClasscode: "",
    accountTypecode: "",
    swiftcode: "",
    bankcodeType: "",
    bankcode: "",
    accountNumber: "",
  };
  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log("values", values);

              // setData(() => {
              //   return {
              //     accountName: values.accountName ? values.accountName : "",
              //     AccountClasscode: values.AccountClasscode
              //       ? values.AccountClasscode
              //       : "",
              //     AccountTypecode: values.AccountTypecode
              //       ? values.AccountTypecode
              //       : "",
              //     SWIFTCODE: "",
              //     bankcodeType: "",
              //     bankcode: "",
              //     AccountNumber: values.AccountNumber
              //       ? values.AccountNumber
              //       : "",
              //   };
              // });
              closeModal({
                walletLinkTypecode: "BANK",
                accountName: values.accountName ? values.accountName : "",
                accountClasscode: values.accountClasscode
                  ? values.accountClasscode
                  : "",
                accountTypecode: values.accountTypecode
                  ? values.accountTypecode
                  : "",
                swiftcode: values.swiftcode ? values.swiftcode : "",
                bankcodeType: values.bankcodeType ? values.bankcodeType : "",
                bankcode: values.bankcode ? values.bankcode : "",
                accountNumber: values.accountNumber ? values.accountNumber : "",
              });
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form className="wallet-information-form">
                  <div style={{ height: 500, width: "100%" }}>
                    <NewGrid
                      row={rows}
                      coloums={Paycolumn}
                      onRowsSelectionHandler={onRowsSelectionHandler}
                    />
                  </div>
                  <div className="row  mb-xl-5">
                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Account Name</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="accountName"
                          id="accountName"
                          placeholder="Enter Account Name"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.accountName && touched.accountName
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="accountName"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Account Number</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="accountNumber"
                          id="accountNumber"
                          placeholder="Enter Account Number"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.accountNumber && touched.accountNumber
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="accountNumber"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Account Class</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="accountClasscode"
                          id="accountClasscode"
                          placeholder="Enter Account Class"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.accountClasscode && touched.accountClasscode
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="accountClasscode"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Account Type</label>
                      <div className="input-box mb-5">
                        <Field
                          type="text"
                          name="accountTypecode"
                          id="accountTypecode"
                          placeholder="Enter Account Type"
                          className="form-control"
                        />
                        <div
                          className={
                            errors.accountTypecode && touched.accountTypecode
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="accountTypecode"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-md-5">
                      <button
                        type="button"
                        class="btn cancel-btn mb-3 mb-md-0 me-4"
                      >
                        CANCEL
                      </button>
                      <button class="btn next-btn mb-3 mb-md-0" type="submit">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default BankAccount;
