const intialState = {
  publicKey: "",
  privateKey: "",
  menu: "",
};

const keyReducer = (state = intialState, action) => {
  switch (action.type) {
    case "SET_PUBLIC_ID":
      return { ...state, publicKey: action.payload };
    case "SET_PRIVATE_ID":
      return { ...state, privateKey: action.payload };
    case "SET_MENU":
      return { ...state, menu: action.payload };

    default:
      return state;
  }
};

export default keyReducer;

const value = {
  data: "",
};
export const resultReducer = (state = value, action) => {
  switch (action.type) {
    case "SET_RESULT_DATA":
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

const values = {
  data: "",
  loading: false,

};
export const EncryptDataReducer = (state = values, action) => {
  switch (action.type) {
    case "SET_RESPONSE_DATA":
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

export const ShowLoader = (state = values, action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return { ...state, loading:  action.payload };

    default:
      return state;
  }
};
