import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action";
import { getDropdownvalues } from "../../../../api/dropdown";
import { GetTokenSymbols } from "../../../../api/LedgerAPI";
import { getLimit, modifyLimitAPI } from "../../../../api/limit";
import { decrypytdata } from "../../../../api/walletKey";
import { success } from "../../../../Components/alert";
// import PrivateModal from "../../../../Components/PrivateModal";

function ModifyLimits() {
  const publicKeys = useSelector((state) => state.keyReducer);
  const [searchParams] = useSearchParams();
  // const [privateModal, setPrivateModal] = useState(false);
  const [LimitRuleClass, setLimitRuleClass] = useState([]);
  const [LimitRuleType, setLimitRuleType] = useState([]);
  const [TimePeriodType, setTimePeriodType] = useState([]);
  const [TokenSymbol, setTokenSymbol] = useState([]);
  const [data, setdata] = useState("");
  const [modifyData, setmodifyData] = useState("");
  console.log(
    "🚀 ~ file: ModifyLimits.js ~ line 23 ~ ModifyLimits ~ modifyData",
    modifyData
  );
  const fetchdropdwon = async () => {
    let LKRuleclass = await getDropdownvalues("LKRuleclass");
    setLimitRuleClass(LKRuleclass);
    let LKRuletype = await getDropdownvalues("LKRuletype");
    setLimitRuleType(LKRuletype);
    let LKPeriodtype = await getDropdownvalues("LKPeriodtype");
    setTimePeriodType(LKPeriodtype);
    let GetTokenSymbolsData = await GetTokenSymbols("GetTokenSymbols");
    setTokenSymbol(GetTokenSymbolsData);
  };

  const initialValues = {
    limitClasscode: modifyData.LimitClasscode ? modifyData.LimitClasscode : "",
    limitTypecode: modifyData.LimitTypecode ? modifyData.LimitTypecode : "",
    periodTypecode: modifyData.PeriodTypecode ? modifyData.PeriodTypecode : "",
    tokenSymbol: modifyData.TokenSymbol ? modifyData.TokenSymbol : "",
    units: modifyData.Units ? modifyData.Units : 0,
  };

  let APPID = "";
  let APIKEY = "";

  const SingleDataLimit = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedToken = await getLimit(
        APPID,
        APIKEY,
        searchParams.get("id")
      );
      dispatch(showLoader(false));
      const tokens = await decrypytdata(encryptedToken.data, APIKEY);
      const jsonData = JSON.parse(JSON.parse(tokens).CommandResponse);
      console.log(
        "🚀 ~ file: CreateLimits.js ~ line 53 ~ TokenPriceData ~ jsonData",
        jsonData
      );
      setmodifyData(jsonData);
      // if (jsonData.returnMessage) {
      //   success(jsonData.returnMessage);
      // }
    }
    
    // setPrivateModal(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const TokenPriceData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY && data) {
      const encryptedToken = await modifyLimitAPI(APPID, APIKEY, data);
      const tokens = await decrypytdata(encryptedToken.data, APIKEY);
      // const jsonData = JSON.parse(tokens);

      let jsonData;
      if (tokens) {
        jsonData = JSON.parse(tokens);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedToken));
        navigate("/result");
      }
      if (jsonData.ReturnMessage) {
        success(jsonData.ReturnMessage);
      }
    }
    // setPrivateModal(false);
  };

  useEffect(() => {
    fetchdropdwon();
    if (searchParams) {
      SingleDataLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={TokenPriceData} />
      )} */}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="ledger-information transfer-rates-page">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>Limits</h3>
                <h6>Modify Limits</h6>
                <p>
                  Enables the modification of a money transfer amount limit.
                </p>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              setdata(values);
              // TokenPriceData();
            }}
          >
            {(formik) => {
              const { setFieldValue } = formik;
              return (
                <Form className="wallet-information-box transfer-rates m-0">
                  <div className="row ms-lg-4 ms-xl-5 m-0">
                    <div className="col-xl-10">
                      <ul
                        className="nav nav-tabs justify-content-around"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            <div className="domestic">
                              <i class="bi bi-person-fill"></i>
                            </div>
                            <h3>Single Registration</h3>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            <div className="domestic">
                              <i class="bi bi-people-fill"></i>
                            </div>
                            <h3>Batch Registration</h3>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="batch-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#batch"
                            type="button"
                            role="tab"
                            aria-controls="batch"
                            aria-selected="false"
                          >
                            <div className="domestic">
                              <i class="bi bi-file-earmark-medical-fill"></i>
                            </div>
                            <h3>Batch File Format</h3>
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="wallet-information-box m-0">
                            <div className="wallet-information-form">
                              <div className="row mb-0 mt-4 justify-content-between">
                                <div className="col-sm-6 col-xl-4 col-xxl-3">
                                  <h6>Currency</h6>
                                  <div className="mb-5">
                                    <label>Digital Currency</label>
                                    <Field
                                      as="select"
                                      id="tokenSymbol"
                                      name="tokenSymbol"
                                      disabled={true}
                                      className="form-select form-control"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "tokenSymbol",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.tokenSymbol}
                                    >
                                      {TokenSymbol &&
                                        TokenSymbol.map((value, index) => {
                                          return (
                                            <>
                                              <option
                                                value={value.tokenSymbol}
                                                key={index}
                                              >
                                                {value.displayname}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage
                                        name="tokenSymbol"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6 col-xl-4 col-xxl-3">
                                  <h6>Limit Information </h6>

                                  <div className="mb-5">
                                    <label>Limit Rule Class</label>
                                    <Field
                                      as="select"
                                      id="limitClasscode"
                                      name="limitClasscode"
                                      disabled={true}
                                      className="form-select form-control"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "limitClasscode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.limitClasscode}
                                    >
                                      {LimitRuleClass &&
                                        LimitRuleClass.map((value, index) => {
                                          return (
                                            <>
                                              <option
                                                value={value.keycode}
                                                key={index}
                                              >
                                                {value.displayName}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage
                                        name="limitClasscode"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <label>Limit Rule Type</label>
                                    <Field
                                      as="select"
                                      id="limitTypecode"
                                      name="limitTypecode"
                                      disabled={true}
                                      className="form-select form-control"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "limitTypecode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.limitTypecode}
                                    >
                                      {LimitRuleType &&
                                        LimitRuleType.map((value, index) => {
                                          return (
                                            <>
                                              <option
                                                value={value.keycode}
                                                key={index}
                                              >
                                                {value.displayName}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage
                                        name="limitTypecode"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>

                                  <div className="mb-5">
                                    <label>Time Period Type</label>
                                    <Field
                                      as="select"
                                      id="periodTypecode"
                                      name="periodTypecode"
                                      disabled={true}
                                      className="form-select form-control"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "periodTypecode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.periodTypecode}
                                    >
                                      {TimePeriodType &&
                                        TimePeriodType.map((value, index) => {
                                          return (
                                            <>
                                              <option
                                                value={value.keycode}
                                                key={index}
                                              >
                                                {value.displayName}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage
                                        name="periodTypecode"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3">
                                  <h6>Limit Range </h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="number"
                                        id="units"
                                        name="units"
                                        className="form-control"
                                        placeholder="Limit Amount"
                                      />
                                      <label className="ps-0 pb-0">
                                        Maximum Send Units
                                      </label>
                                      <i className="bi bi-check-lg"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                <button
                                  type="button"
                                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn mb-3 mb-md-0"
                                  onClick={() => {
                                    // setPrivateModal(true);
                                    TokenPriceData();
                                  }}
                                >
                                  SUBMIT
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div className=" wallet-information-box m-0">
                            <div className=" wallet-information-form mt-4">
                              <div className="review-wallet-box  m-0">
                                <div className="row">
                                  <h5 className="mb-3">
                                    Upload a Limit Rules File in the proper file
                                    format for batched processing
                                  </h5>
                                  <div className="col-sm-6 col-xl-4">
                                    <div className="mb-5">
                                      <label className="mb-3">
                                        Batch Upload Limit Rules
                                      </label>
                                      <div class="input-box">
                                        <div class="input-group custom-file-button">
                                          <input
                                            type="file"
                                            class="form-control"
                                            id="inputGroupFile"
                                          />
                                          <label
                                            class="input-group-text"
                                            for="inputGroupFile"
                                          >
                                            BROWSE
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xxl-11">
                                    <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                      <button
                                        type="button"
                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                      >
                                        CANCEL
                                      </button>
                                      <button
                                        type="button"
                                        className="btn next-btn mb-3 mb-md-0"
                                      >
                                        SUBMIT
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="batch"
                          role="tabpanel"
                          aria-labelledby="batch-tab"
                        >
                          <div className=" wallet-information-box m-0">
                            <div className=" wallet-information-form mt-4">
                              <div className="review-wallet-box  m-0">
                                <div className="row">
                                  <h5 className="mb-3">
                                    The file format for batched limit rules is a
                                    comma separate file (CSV) with the following
                                    sequential field names:
                                  </h5>
                                  <div className="wallet-balances-box m-0">
                                    <div className="table-responsive p-3 p-md-4">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Field Name</th>
                                            <th>Field Description</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>First Name</td>
                                            <td>The first name of the user </td>
                                          </tr>
                                          <tr>
                                            <td>Middle Name</td>
                                            <td>
                                              The middle name of the user{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Email</td>
                                            <td>The email address </td>
                                          </tr>
                                          <tr>
                                            <td>Password</td>
                                            <td>The login password </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ModifyLimits;
