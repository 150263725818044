import React from 'react'

const Wallets = () => {
  return (
      <>
          <div className="documentation-accordion">
              <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Issuer">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#issuerOne" aria-expanded="true" aria-controls="issuerOne">
                              Create Wallet
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="issuerOne" className="accordion-collapse collapse show" aria-labelledby="Issuer" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Create Wallet	Creates a multi-currency cryptographic wallet on the Unicoin network.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Modify">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyTwo" aria-expanded="false" aria-controls="ModifyTwo">
                              Modify Wallet
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="ModifyTwo" className="accordion-collapse collapse show" aria-labelledby="Modify" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Modify Wallet	Allows the updating of profile information associated with a wallet.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Suspend">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#SuspendThree" aria-expanded="false" aria-controls="SuspendThree">
                              Suspend Wallet
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="SuspendThree" className="accordion-collapse collapse show" aria-labelledby="Suspend" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Suspend Wallet	Suspends a wallet from transacting on the Unicoin network.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateOne" aria-expanded="false" aria-controls="CreateOne">
                              Create Wallet Link
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateOne" className="accordion-collapse collapse show" aria-labelledby="BranchOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Create Wallet Link	Enables a user to attach or link their bank account, email, or mobile phone to their wallet for sending and receiving digital currency.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateTwo" aria-expanded="false" aria-controls="CreateTwo">
                              Wallet Balances
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateTwo" className="accordion-collapse collapse show" aria-labelledby="BranchTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Wallet Balance	Provides the digital currency balance in a specified ledger. If no ledger address is specified, all digital currency balances across all ledgers are provided.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateThree" aria-expanded="false" aria-controls="CreateThree">
                              Grant Authorization
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateThree" className="accordion-collapse collapse show" aria-labelledby="BranchThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Grant Authorization	Grant authority to another wallet to transfer digital currency on the wallet owner�s behalf.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateAgentOne" aria-expanded="false" aria-controls="CreateAgentOne">
                              Revoke Authorization
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateAgentOne" className="accordion-collapse collapse show" aria-labelledby="AgentOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Revoke Authorization	Revokes the authorization of a prior Grant Authorization.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
              </div>
          </div>
      </>
  )
}

export default Wallets