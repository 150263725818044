import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getEscrows } from "../../../api/Escrow";
import { decrypytdata } from "../../../api/walletKey";
import Grid from "../../../Components/Grid";
import { GetEscrowcolumns } from "../../../Components/GridHeaders";
import PrivateModal from "../../../Components/PrivateModal";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function GetEscrow() {
    const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const closeModal = () => {
    setPrivateModal(false);
  };
  //eslint-disable-next-line
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedLedger = await getEscrows(APPID, APIKEY);
      console.log("🚀 ~ file: GetEscrow.js ~ line 29 ~ fetchdata ~ encryptedLedger", encryptedLedger)
      const Ledgers = await decrypytdata(encryptedLedger.data, APIKEY);
      let data = JSON.parse(JSON.parse(Ledgers).commandResponse);
      // console.log(JSON.parse(Ledgers));
      // setRows(JSON.parse(Ledgers));
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
          // console.log(dataMain, "dataMain")
        });
        setRows(dataArr);
      }
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
    return (
        <>
         {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
            {/* <Header /> */}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0">
                     <div className="row ms-lg-4 ms-xl-5 m-0"> 
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                        <Sidebar />
                    </div> */}
                    <div className="col-12">
                        <div className="main-heading">
                            <h3> Money Services</h3>
                            <h6>Get Escrows</h6>
                            <p>
                                This function lists all the escrows associated with a wallet.
                            </p>
                        </div>
                        <div className="wallet-balances-box m-0">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary "
                      style={{ marginBottom: "10px" }}
                      
                    >
                    <Link
                    to="/webwallet/createescrow"
                    //to=""
                    className={
                       "nav-link"
                    }
                    style={{color:"white"}}
                  >
                    
                    + ADD ESCROW 
                    
                  </Link>
                      
                    </button>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={GetEscrowcolumns} />
                  </div>
                </div>
                    </div>
                </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default GetEscrow;
