/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
//import { useLocation } from "react-router-dom";
function TokenModify({ nextstep }) {
    //let { pathname } = useLocation();

    const formik = useFormik({
        initialValues: {
            TokenClass: "",
            TokenType: "",
            SupplyType: "",
            TokenSymbol: "",
            TokenName: "",
            DisplayName: "",
            CurrencyCode: "",
            MaximumSupply: "",
            InitialSupply: "",
            DecimalPrice: "",
        },
        // validationSchema: Yup.object({
        //   TokenClass: Yup.string().required("Token Class is required!"),
        //   TokenType: Yup.string().required("Token Type is required!"),
        //   SupplyType: Yup.string().required("Supply Type is required!"),
        //   TokenSymbol: Yup.string().required("Token Symbol is required!"),
        //   TokenName: Yup.string().required("Token Name is required!"),
        //   DisplayName: Yup.string().required("Display Name is required!"),
        //   CurrencyCode: Yup.string().required("Currency Code is required!"),
        //   MaximumSupply: Yup.string().required("Maximum Supply is required!"),
        //   InitialSupply: Yup.string().required("Initial Supply is required!"),
        //   DecimalPrice: Yup.string().required("Decimal Price is required!"),
        // }),
        // onSubmit: (values) => {
        //   nextstep();
        // },
    });

    const validationSchema = Yup.object({
        TokenClass: Yup.string().required("Token Class is required!"),
        TokenType: Yup.string().required("Token Type is required!"),
        SupplyType: Yup.string().required("Supply Type is required!"),
        TokenSymbol: Yup.string().required("Token Symbol is required!"),
        TokenName: Yup.string().required("Token Name is required!"),
        DisplayName: Yup.string().required("Display Name is required!"),
        CurrencyCode: Yup.string().required("Currency Code is required!"),
        MaximumSupply: Yup.string().required("Maximum Supply is required!"),
        InitialSupply: Yup.string().required("Initial Supply is required!"),
        DecimalPrice: Yup.string().required("Decimal Price is required!"),
    });

    return (
        <>
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="container-fluid p-0">
                    <div className="row ms-lg-4 ms-xl-5 m-0">
                        <div className="col-12">
                            <div className="main-heading">
                                <h3>Tokens</h3>
                                <h6>Modify Token</h6>
                                <p>
                                    This function enables the creation of a digital currency on the Unicoin network.
                                </p>
                            </div>

                            <Formik
                                initialValues={formik.initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    nextstep();
                                }}
                            >
                                {(formik) => {
                                    return (
                                        <div className="wallet-information-box m-0">
                                            <Form className="wallet-information-form">
                                                <div className="row mb-xl-5">
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Basic Information</h6>
                                                        <div className="mb-5">
                                                            <label>Token Class</label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="TokenClass"
                            className="form-control "
                            placeholder="Token Class"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.TokenClass}
                          />
                          {formik.errors.TokenClass ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.TokenClass}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="TokenClass"
                                                                    id="TokenClass"
                                                                    placeholder="Token Class"
                                                                    className="form-control"
                                                                    value={formik.values.TokenClass}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="TokenClass"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Token Type</label>
                                                            <select
                                                                id="TokenType"
                                                                className="form-select form-control"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.TokenType}
                                                            >
                                                                <option defaultValue>--Select--</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                            {/* {formik.errors.TokenType ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.TokenType}
                          </div>
                        ) : null} */}
                                                            <div style={{ color: "red" }}>
                                                                <ErrorMessage
                                                                    name="TokenType"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Token Symbol</label>
                                                            <select
                                                                id="TokenSymbol"
                                                                className="form-select form-control"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.TokenSymbol}
                                                            >
                                                                <option defaultValue>--Select--</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                            {/* {formik.errors.TokenSymbol ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.TokenSymbol}
                          </div>
                        ) : null} */}
                                                            <div style={{ color: "red" }}>
                                                                <ErrorMessage
                                                                    name="TokenSymbol"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Token Name</label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="TokenName"
                            className="form-control "
                            placeholder="Token Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.TokenName}
                          />
                          {formik.errors.TokenName ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.TokenName}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="TokenName"
                                                                    id="TokenName"
                                                                    placeholder="Token Name"
                                                                    className="form-control"
                                                                    value={formik.values.TokenName}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="TokenName"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Display Name (Alias)</label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="DisplayName"
                            className="form-control "
                            placeholder="Display Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DisplayName}
                          />
                          {formik.errors.DisplayName ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.DisplayName}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="DisplayName"
                                                                    id="DisplayName"
                                                                    placeholder="Display Name"
                                                                    className="form-control"
                                                                    value={formik.values.DisplayName}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="DisplayName"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Currency Code</label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="CurrencyCode"
                            className="form-control "
                            placeholder="Currency Code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CurrencyCode}
                          />
                          {formik.errors.CurrencyCode ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.CurrencyCode}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="CurrencyCode"
                                                                    id="CurrencyCode"
                                                                    placeholder="Currency Code"
                                                                    className="form-control"
                                                                    value={formik.values.CurrencyCode}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="CurrencyCode"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Supply </h6>
                                                        <div className="mb-5">
                                                            <label>Supply Type</label>
                                                            <select
                                                                id="SupplyType"
                                                                className="form-select form-control"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.SupplyType}
                                                            >
                                                                <option defaultValue>--Select--</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                            {/* {formik.errors.SupplyType ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.SupplyType}
                          </div>
                        ) : null} */}
                                                            <div style={{ color: "red" }}>
                                                                <ErrorMessage
                                                                    name="SupplyType"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Maximum Supply</label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="MaximumSupply"
                            className="form-control "
                            placeholder="Maximum Supply"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.MaximumSupply}
                          />
                          {formik.errors.MaximumSupply ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.MaximumSupply}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="MaximumSupply"
                                                                    id="MaximumSupply"
                                                                    placeholder="Maximum Supply"
                                                                    className="form-control"
                                                                    value={formik.values.MaximumSupply}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="MaximumSupply"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Initial Supply</label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="InitialSupply"
                            className="form-control "
                            placeholder="Initial Supply"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.InitialSupply}
                          />
                          {formik.errors.InitialSupply ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.InitialSupply}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="InitialSupply"
                                                                    id="InitialSupply"
                                                                    placeholder="Initial Supply"
                                                                    className="form-control"
                                                                    value={formik.values.InitialSupply}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="InitialSupply"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Decimal Price </label>
                                                            <div className="input-box">
                                                                {/* <input
                            type="text"
                            id="DecimalPrice"
                            className="form-control "
                            placeholder="Decimal Price "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DecimalPrice}
                          />
                          {formik.errors.DecimalPrice ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.DecimalPrice}
                            </div>
                          ) : null}
                          <i className="bi bi-check-lg"></i> */}
                                                                <Field
                                                                    type="text"
                                                                    name="DecimalPrice"
                                                                    id="DecimalPrice"
                                                                    placeholder="Decimal Price"
                                                                    className="form-control"
                                                                    value={formik.values.DecimalPrice}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="DecimalPrice"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                    <button
                                                        type="button"
                                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                    >
                                                        CANCEL
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn next-btn mb-3 mb-md-0"
                                                    // onClick={() =>
                                                    //   nextstep()
                                                    // }
                                                    >
                                                        NEXT
                                                    </button>
                                                </div>
                                            </Form>
                                            {/* </form> */}
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TokenModify;
