import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
  getDropdownvalues,
} from "../../../../api/dropdown";
import { decrypytdata } from "../../../../api/walletKey";
import { useLocation } from "react-router-dom";
import { getBranches } from "../../../../api/branches";

function ModifyBranch({ nextstep, setdata, IssuerData }) {
  // let { pathname } = useLocation();
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [institutionTypeCode, setInstitutionTypeCode] = useState([]);
  const [officeTypeCode, setOfficeTypeCode] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [initialValues, setInitialValues] = useState();
  //   const [privateModal, setPrivateModal] = useState(false);
  const location = useLocation();
  const find = location.search.replace("?id=", "");
  // const initialValues1 = {
  //   // groupcode:"DollarBoxCor",
  //   // nativeID:"DF",
  //   // group:"DollarBoxCorporation",
  //   displayname: "DollarBox",
  //   addressLine1: "21 Mahavir villa",
  //   addressLine2: "Mumbai",
  //   addressLine3: "Maharastra",
  //   neighborhood: "jain School",
  //   cityTown: "Mumbai",
  //   stateProvince: "MH",
  //   postalCode: "49007",
  //   countrycode: "IN",
  //   statecode: "MH",
  //   citycode: "MUM",
  //   issuerTypecode: "BANK",
  //   // issuercode: "DollarBox",
  //   uuid: "",
  //   username: "RajuBhai",
  //   firstname: "RAJU",
  //   secondFirstname: "",
  //   lastname: "singh",
  //   secondLastname: "",
  //   jobTitle: "Teacher",
  //   imageFN: "",
  //   email: "Raju@gmail.com",
  //   mobileDialingcode: "91",
  //   mobilePhone: "1111111111",
  // };
  // const initialValues = {
  //   groupcode: "",
  //   group: "",
  //   nativeID: "",
  //   displayname: "DollarBox",
  //   addressLine1: "21 Mahavir villa",
  //   addressLine2: "Mumbai",
  //   addressLine3: "Maharastra",
  //   neighborhood: "jain School",
  //   cityTown: "Mumbai",
  //   stateProvince: "MH",
  //   postalCode: "49007",
  //   countrycode: "IN",
  //   // statecode: "MH",
  //   // citycode: "MUM",
  //   // issuerTypecode: "BANK",
  //   // issuercode: "Unicoin",
  //   // uuid: "",
  //   // username: "RajuBhai",
  //   // firstname: "RAJU",
  //   // secondFirstname: "",
  //   // lastname: "sing",
  //   // secondLastname: "",
  //   // jobTitle: "Teacher",
  //   // imageFN: "",
  //   // email: "Raju@gmail.com",
  //   timezoneID:"",
  //   mobileDialingcode: "91",
  //   mobilePhone: "1111111111",
  // };
  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    console.log(APPID, APIKEY, "APPIDAPIKEY");
    if (APPID && APIKEY) {
      const encryptedIssuer = await getBranches(APPID, APIKEY);
      const Issuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // console.log("🚀 ~ file: ModifyIssuer.js ~ line 70 ~ fetchdata ~ Issuer", Issuer)
      let data = JSON.parse(JSON.parse(Issuer).CommandResponse);
      console.log(data, "datadata");
      data.forEach((dataMain) => {
        if (dataMain.Displayname === find) {
          console.log(
            "🚀 ~ file: ModifyIssuer.js ~ line 75 ~ fetchdata ~ dataMain",
            dataMain
          );
          return setInitialValues({
            displayname: dataMain?.Displayname ? dataMain?.Displayname : "",
            countrycode: dataMain?.Countrycode ? dataMain?.Countrycode : "",
            timezoneID: "",
          });
        }
      });
    }
  };
  // eslint-disable-next-line
  const validationSchema = Yup.object({
    issuer: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Issuer required"),
    issuercode: Yup.string().required("IssuerCode is required!"),
    nativeKEY: Yup.string(),
    issuerUUID: Yup.string(),
    hqIssuercode: Yup.string(),
    displayname: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Displayname Required"),
    issuerTypecode: Yup.string().required("IssuerType is required!"),
    accuityID: Yup.number(),
    institutionTypecode: Yup.string().required(
      "InstitutionTypecode is required!"
    ),
    countrycode: Yup.string().required("CountryCode is required!"),
    stateProvince: Yup.string(),
    cityTown: Yup.string(),
    postalCode: Yup.string(),
    officeTypecode: Yup.string().required("officeTypecode is required!"),
    firstName: Yup.string().required("firstName is required!"),
    secondFirstname: Yup.string(),
    lastName: Yup.string().required("lastName is required!"),
    secondLastname: Yup.string(),
    email: Yup.string().required("email is required!"),
    jobTitle: Yup.string().required("jobTitle is required!"),
    username: Yup.string().required("username is required!"),
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    neighborhood: Yup.string(),
    userUUID: Yup.string(),
    mobileDialingcode: Yup.string()
      .typeError("you must specify a number")
      .required("DialingCode is required!"),
    mobilePhone: Yup.number()
      .typeError("you must specify a number")
      .max(9999999999, "Must be 10 Digit")
      .min(1111111111, "Must be 10 Digit")
      .required("PhoneNumber is required!"),
    incorporationNumber: Yup.string(),
    federal_TaxID: Yup.string(),
    isO_LEI: Yup.string(),
    imageFN: Yup.string(),
  });
  const fetchdropdwon = async () => {
    let dropdownvalues = await getDropdownvalues("LKIssuerType");
    setDropdown(dropdownvalues);

    let insTypeCode = await getDropdownvalues("LKInstitutionType");
    setInstitutionTypeCode(insTypeCode);

    let OfficeTypeCode = await getDropdownvalues("LKOfficeType");
    setOfficeTypeCode(OfficeTypeCode);

    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
        fetchdata();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="ledger-information create-issuer-information">
            <div className="row ms-lg-4 ms-xl-5">
              <div className="col-12">
                <div className="main-heading">
                  <h3>Branches</h3>
                  <h6>Modify Branch</h6>
                  <p>
                    This function enables the modification of branch profile
                    information.
                  </p>
                </div>
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    setdata(values);
                    nextstep();
                    // debugger;
                    // CreateIssuersApi(values);
                    console.log(
                      "🚀 ~ file: CreateBranch.js ~ line 183 ~ CreateIssuer ~ values",
                      values
                    );
                  }}
                >
                  {(formik) => {
                    const { errors, touched, setFieldValue } = formik;
                    return (
                      <div className="wallet-information-box m-0">
                        <h4>Branches Information</h4>

                        <Form className="wallet-information-form m-0">
                          <div className="row">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Basic Information</h6>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="issuer"
                                    id="issuer"
                                    placeholder="issuer"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">Branches</label>
                                  <div
                                    className={
                                      errors.issuer && touched.issuer
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="issuer"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="displayname"
                                    id="displayname"
                                    placeholder="Display Name"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Display Name
                                  </label>
                                  <div
                                    className={
                                      errors.displayname && touched.displayname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="displayname"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Branch Type</label>
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="issuerTypecode"
                                  name="issuerTypecode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Issuer Type");
                                    setFieldValue(
                                      "issuerTypecode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {dropdown &&
                                    dropdown.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayName}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="issuerTypecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="issuercode"
                                    id="issuercode"
                                    placeholder="Branch Code"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Branch Code
                                  </label>
                                  <div
                                    className={
                                      errors.issuercode && touched.issuercode
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="issuercode"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Institution </h6>
                              <div className="mb-5">
                                <label>Institution Typecode</label>
                                <div className="input-box">
                                  <Field
                                    as="select"
                                    className="form-select form-control"
                                    id="institutionTypecode"
                                    name="institutionTypecode"
                                    onChange={(e) => {
                                      // console.log(
                                      //   e.target.value,
                                      //   "Institution Typecode"
                                      // );
                                      setFieldValue(
                                        "institutionTypecode",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                  >
                                    {institutionTypeCode &&
                                      institutionTypeCode.map(
                                        (value, index) => {
                                          return (
                                            <>
                                              <option
                                                value={value.keycode}
                                                key={index}
                                              >
                                                {value.displayName}
                                              </option>
                                            </>
                                          );
                                        }
                                      )}
                                  </Field>
                                  <div
                                    className={
                                      errors.institutionTypecode &&
                                      touched.institutionTypecode
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="institutionTypecode"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mb-5">
                                <label>Office Typecode</label>
                                <div className="input-box">
                                  <Field
                                    as="select"
                                    name="officeTypecode"
                                    id="officeTypecode"
                                    // placeholder="Office Typecode"
                                    className="form-select form-control"
                                    onChange={(e) => {
                                      // console.log(
                                      //   e.target.value,
                                      //   "Office Typecode"
                                      // );
                                      setFieldValue(
                                        "officeTypecode",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                  >
                                    {officeTypeCode &&
                                      officeTypeCode.map((value, index) => {
                                        return (
                                          <>
                                            <option
                                              value={value.keycode}
                                              key={index}
                                            >
                                              {value.displayName}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </Field>
                                  <div
                                    className={
                                      errors.officeTypecode &&
                                      touched.officeTypecode
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="officeTypecode"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Identifier</h6>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="accuityID"
                                    id="accuityID"
                                    placeholder="Location Identifier"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Location Identifier
                                  </label>
                                  <i className="bi bi-check-lg"></i>
                                  <div
                                    className={
                                      errors.accuityID && touched.accuityID
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="accuityID"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Additional Informaiton</h6>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="incorporationNumber"
                                    id="incorporationNumber"
                                    placeholder="Incorporation Number"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Incorporation Number{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.incorporationNumber &&
                                      touched.incorporationNumber
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="incorporationNumber"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="federal_TaxID"
                                    id="federal_TaxID"
                                    placeholder="Tax ID"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Federal TaxID{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.federal_TaxID &&
                                      touched.federal_TaxID
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="federal_TaxID"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="isO_LEI"
                                    id="isO_LEI"
                                    placeholder="ISO LEI"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">ISO LEI </label>
                                  <div
                                    className={
                                      errors.isO_LEI && touched.isO_LEI
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="isO_LEI"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Basic Address Information</h6>
                              <div className="mb-5">
                                <label>Country Code</label>
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="countrycode"
                                  name="countrycode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Country Code");
                                    setFieldValue(
                                      "countrycode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {countryCode &&
                                    countryCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.countrycode}
                                            key={index}
                                          >
                                            {value.isoCountryname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="countrycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="stateProvince"
                                    id="stateProvince"
                                    placeholder="State Province"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    State Province{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.stateProvince &&
                                      touched.stateProvince
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="stateProvince"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="cityTown"
                                    id="cityTown"
                                    placeholder="City Town"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    City Town{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.cityTown && touched.cityTown
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="cityTown"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Address</h6>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="postalCode"
                                    id="postalCode"
                                    placeholder="Postal code"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Postalcode{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.postalCode && touched.postalCode
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="postalCode"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="addressLine1"
                                    id="addressLine1"
                                    placeholder="Address Line1"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    AddressLine1{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.addressLine1 &&
                                      touched.addressLine1
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="addressLine1"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="addressLine2"
                                    id="addressLine2"
                                    placeholder="Address Line2"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    AddressLine2{" "}
                                  </label>
                                  <div
                                    className={
                                      errors.addressLine2 &&
                                      touched.addressLine2
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="addressLine2"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h4>Primary Contact Information</h4>
                          <div className="row mb-xl-5">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Primary Contact</h6>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="First Name"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    First Name
                                  </label>
                                  <div
                                    className={
                                      errors.firstname && touched.firstname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="firstName"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="secondFirstname"
                                    id="secondFirstname"
                                    placeholder="Second First name"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Second First Name
                                  </label>
                                  <div
                                    className={
                                      errors.secondFirstname &&
                                      touched.secondFirstname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="secondFirstname"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Last Name"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">Last Name</label>
                                  <div
                                    className={
                                      errors.lastname && touched.lastname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="lastName"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="secondLastname"
                                    id="secondLastname"
                                    placeholder="Second Last Name"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Second Last Name
                                  </label>
                                  <div
                                    className={
                                      errors.secondLastname &&
                                      touched.secondLastname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="secondLastname"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Contact Information</h6>
                              <div className="mb-5">
                                <label>Dialing Code</label>
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="mobileDialingcode"
                                  name="mobileDialingcode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Dialing Code");
                                    setFieldValue(
                                      "mobileDialingcode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {dailingCode &&
                                    dailingCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="mobileDialingcode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="mobilePhone"
                                    id="mobilePhone"
                                    placeholder="Phone Number"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Phone Number
                                  </label>
                                  <div
                                    className={
                                      errors.mobilePhone && touched.mobilePhone
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="mobilePhone"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">
                                    Email Address{" "}
                                  </label>
                                  <i className="bi bi-check-lg"></i>
                                  <div
                                    className={
                                      errors.email && touched.email
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="email"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Primary Contact Account Information</h6>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="jobTitle"
                                    id="jobTitle"
                                    placeholder="Job Title"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">Job Title</label>
                                  <div
                                    className={
                                      errors.jobTitle && touched.jobTitle
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="jobTitle"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="input-box form-floating">
                                  <Field
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Username"
                                    className="form-control"
                                  />
                                  <label className="ps-0 pb-0">Username</label>
                                  <div
                                    className={
                                      errors.username && touched.username
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="username"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                            <button
                              type="button"
                              className="btn cancel-btn mb-3 mb-md-0 me-4"
                            >
                              CANCEL
                            </button>
                            <button
                              type="submit"
                              className="btn next-btn mb-3 mb-md-0"
                            >
                              NEXT
                            </button>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModifyBranch;
