import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import React from 'react'

function About1 () {
    return (
        <>
            <Header />
            <div className="global">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/dl.beatsnoop.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div className="global-text">
                            <h3>A Global Financial Services Provider Network</h3>
                            <p>Regulatory Compliant <span>Electronic Cash</span></p>
                        </div>
                    </div>
                </div>
                <div className="container pt-300">
                    <div className="row g-0 justify-content-center">
                        <div className="col-12">
                            <div className="technology-heading">
                                <h3>Multi-Functional Distributed Ledger Technology</h3>
                                <p>Unicoin is the only DLT that can support all banking debit and credit ledgers.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-1">
                                <img src="../icons/cash-ledger.png" alt="icons" />
                                <h3>Cash Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-2">
                                <img src="../icons/cash-ledger-2.png" alt="icons" />
                                <h3>Loan Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-3">
                                <img src="../icons/cash-ledger-3.png" alt="icons" />
                                <h3>Investment Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-4">
                                <img src="../icons/cash-ledger-4.png" alt="icons" />
                                <h3>Disbursement  Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-5">
                                <img src="../icons/cash-ledger-5.png" alt="icons" />
                                <h3>Escrow Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-6">
                                <img src="../icons/cash-ledger-6.png" alt="icons" />
                                <h3>Trust Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-7">
                                <img src="../icons/cash-ledger-7.png" alt="icons" />
                                <h3>Overdraft Ledger</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="technology-box t-b-8">
                                <img src="../icons/cash-ledger-8.png" alt="icons" />
                                <h3>Interest Ledger</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default About1;