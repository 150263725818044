import React, { useEffect, useState } from "react";
import PrivateModal from "../../../Components/PrivateModal";
import { getLedgers } from "../../../api/LedgerAPI";
import { useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { Ledgerscolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { Link } from "react-router-dom";
function GetLedger() {
  const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const closeModal = () => {
    setPrivateModal(false);
  };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedLedger = await getLedgers(APPID, APIKEY);
      const Ledgers = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log("🚀 ~ file: GetLedger.js ~ line 28 ~ fetchdata ~ Ledgers", Ledgers)
      let data = JSON.parse(JSON.parse(Ledgers).CommandResponse);
      console.log("🚀 ~ file: GetLedger.js ~ line 29 ~ fetchdata ~ data", data)
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);
  return (
    <>
      {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="row">
              <div className="col-xxl-10">
                <div className="main-heading">
                  <h3> Ledgers</h3>
                  <h6>Get Ledgers</h6>
                  <p>
                    This function lists all the ledgers associated with a
                    wallet.
                  </p>
                </div>
                <div className="wallet-balances-box m-0">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>
                    <Link to={`/webwallet/createledger`}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginBottom: "10px" }}
                      >
                        + ADD Ledgers
                      </button>
                    </Link>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={Ledgerscolumns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default GetLedger;
