import http from "./httpService";
import {  WEB_WALLET_URL } from "../Constatnts/config.js";
import { hmacencrypt } from "./walletKey";



export async function CreateLedgerRuleApi(publickey, privateKey, ledgerdata) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/CreateLedgerRule",
    ledgerdata,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/CreateLedgerRule",

    ledgerdata,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function GetLedgerRulesApi(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // ledgerTypecode: 0,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/GetLedgerRules",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/GetLedgerRules",values,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}




export async function ModifyLedgerRuleApi(publickey, privateKey, LedgerData) {
  // let values = {
  //   ...ledgerdata,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/ModifyLedgerRule",
    LedgerData,
    // values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/ModifyLedgerRule",
    LedgerData,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}




export async function ledgerRuleSuspendApi(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // ledgerTypecode: "",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/ledger/SuspendLedgerRule",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/ledger/SuspendLedgerRule",
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}


