import React from "react";
function GetGeoSanctions() {
  

    return (
        <>
           
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row ms-lg-4 ms-xl-5 m-0">
                    <div className="col-12">
                        <div className="main-heading">
                            <h3> Geo Sanctions</h3>
                            <h6>Get Geo Sanctions</h6>
                            <p> Lists all of the registered geo location sanctions.</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default GetGeoSanctions;
