import React from 'react';
function RatingUnicoinAndBitcoin() {
    return (
        <>
            <div className="container-fluid container-md">
            <div className="ratings">
                    <div className="row">
                        <div className="col-12">
                            <div className="ratings-box mb-4">
                                <div className="row">
                                    <div className="col-md-3 col-xl-2 align-items-center">
                                        <div className="ratings-box-icon">
                                            <img src="icons/Physical Cash icon.png" alt="" />
                                            <h3>Physical Cash</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-xl-10">
                                        <div className="ratings-box-text">
                                            <h5>Point to Point</h5>
                                            <h5>Private</h5>
                                            <h5>Anonymous</h5>
                                            <h5 className="mb-0">Self Governance</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ratings-box mb-4">
                                <div className="row">
                                    <div className="col-md-3 col-xl-2 align-items-center">
                                        <div className="ratings-box-icon">
                                            <img src="icons/Electronic Cash icon.png" alt="" />
                                            <h3>Electronic Cash</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-xl-10">
                                        <div className="ratings-box-text">
                                            <h5>KYC Enabled</h5>
                                            <h5>Traceable</h5>
                                            <h5>Private / Anonymous</h5>
                                            <h5 className="mb-0">Intermediary Governance</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ratings-box">
                                <div className="row">
                                    <div className="col-md-3 col-xl-2 align-items-center">
                                        <div className="ratings-box-icon">
                                            <img src="icons/Store of Value icon.png" alt="" />
                                            <h3>Store of Value</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-xl-10">
                                        <div className="ratings-box-text">
                                            <h5>Fixed Store of Value</h5>
                                            <h5>Income-producing Store of Value</h5>
                                            <h5 className="mb-0">Digital Cash Store of Value</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="ratings-star">
                    <div className="d-sm-flex justify-content-end">
                        <div className="text-center me-md-4 mx-3 mx-md-0 my-4 my-md-0">
                            <div className="ratings-star-bg">
                                <div className="unicoin-rating">
                                    <h3>Unicoin</h3>
                                        <img src="../images/coins/U-1.png" alt="" />
                                </div>
                                <div className="retings-star-icon">
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                            <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon">
                                            <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                </div>
                                <div className="retings-star-icon-1">
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                            <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                </div>
                                <div className="retings-star-icon">
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                    <div className="star-icon">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                </div>
                               

                            </div>
                        </div>
                        <div className="text-center mt-0 mt-sm-4 mt-md-0 mx-3 mx-md-0">
                            <div className="ratings-star-bg bitcoin">
                                <div className="unicoin-rating">
                                    <h3>Bitcoin</h3>
                                        {/* <img src="../images/coins/b-1.png" alt="" /> */}
                                        <img src="../images/b-1.png" alt="" />
                                </div>
                                <div className="retings-star-icon">
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                            <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                            <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                            <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon">
                                            <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                </div>
                                <div className="retings-star-icon-1">
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3  mb-md-2 mb-lg-3">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                </div>
                                <div className="retings-star-icon">
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon mb-3 mb-md-2 mb-lg-3">
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/white-star.png" alt="" />
                                    </div>
                                    <div className="star-icon">
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3" alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" className="me-3 me-md-2 me-lg-3"  alt="" />
                                        <img src="icons/star.png" alt="" />
                                    </div>
                                </div>
                               

                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>
        </>
    );
}

export default RatingUnicoinAndBitcoin;