import axios from "axios";
import { success, error } from "../Components/alert.js";
import { WEB_WALLET_URL } from "../Constatnts/config.js";
import { resolve } from "./resolve.js";
import * as rdd from "react-device-detect";
import { browserName, isMobile } from "react-device-detect";

export async function login(userDetails) {
  const loginDetails = {
    email: userDetails.email,
    password: userDetails.password,
    ipAddress: await axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        return res.data.IPv4;
      }),
    userDeviceTypeID: 0,
    userDevice: "",
    displayname: "string",
    hostname: "",
    proxy: "",
    devicetype: isMobile ? "mobile" : "desktop",
    browser: browserName,
    useragent: "",
    os: rdd.osName,
    screensize: window.innerWidth + "x" + window.innerHeight,
    enableD_COOKIE: 0,
    enableD_JAVASCRIPT: 0,
  };

  const response = await resolve(
    axios
      .post(WEB_WALLET_URL + "/api/user/Login", loginDetails)
      .then((res) => res.data)
  ).then(async (response) => {
    if (response.error) {
      error(response.error.response.data.error);
    } else {
      await localStorage.setItem("accessToken", response.data.accessToken);
      await localStorage.setItem("allData", JSON.stringify(response));
      success("Successfully Login");
      return response.data;
    }
  });
  return response;
}

export async function forgotPassword(forgotDetails) {
  const email = forgotDetails.email;
  const response = await resolve(
    axios
      .post(WEB_WALLET_URL + "/api/user/ForgotPassword?email=" + email)
      .then((res) => res.data)
  ).then((response) => {
    if (response.error) {
      error(response.error.response.data.error);
      return "false";
    } else {
      success("Forgot Request Sent");
      return "true";
    }
  });
  return response;
}

export async function resetPassword(passwordDetails) {
  const passwordDetail = {
    email: passwordDetails.email,
    otp: passwordDetails.otp,
    password: passwordDetails.password,
  };
  let response = await resolve(
    axios
      .post(WEB_WALLET_URL + "/api/user/ResetPassword", passwordDetail)
      .then((res) => res.data)
  ).then((response) => {
    if (response.error) {
      error(response.error.response.data.error);
      return "error";
    } else {
      success("Password updated Successfully");
      return "success";
    }
  });
  return response;
}
