import React from "react";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { CreateLedgerApi } from "../../../../api/LedgerAPI";
import { decrypytdata } from "../../../../api/walletKey";
// import PrivateModal from "./../../../../Components/PrivateModal";
import { success } from "./../../../../Components/alert";
import { useNavigate } from "react-router-dom";
function ReviewLedger({ LedgerData, previous }) {
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await CreateLedgerApi(APPID, APIKEY, LedgerData);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      // const jsonData = JSON.parse(devryptedIssuer);
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } 
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      dispatch(setPrivateId(""));
    }
    
    // setPrivateModal(false);
  };
  
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5">
          <div className="col-xxl-11">
            <div className="main-heading">
              <h3>Ledger</h3>
              <h6>Create Ledger</h6>
              <p>
                This function enables the creation of any type of asset or
                liability ledger for transferring digital currencies.
              </p>
            </div>
            <div className="review-wallet-box m-0">
              <div className="review-wallet-info-box">
                <div className="row">
                  <div className="col-12">
                    <div className="heading">
                      <h3>Ledger Information</h3>
                      <i className="bi bi-pencil-square"></i>
                    </div>
                    <hr className="mb-4" />
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>Basic Information</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Ledger Name</h4>
                        <p>{LedgerData.ledger}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Display Name</h4>
                        <p>{LedgerData.displayname}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Ledger Type</h4>
                        <p>{LedgerData.ledgerTypeKeycode}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Ledger Code</h4>
                        <p>{LedgerData.ledgercode}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Token Symbol</h4>
                        <p>{LedgerData.tokenSymbol}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>Range</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Minimum Deposit</h4>
                        <p>{LedgerData.minimumDeposit}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Maximum Deposit</h4>
                        <p>{LedgerData.maximumDeposit}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Minimum Balance </h4>
                        <p>{LedgerData.minimumBalance}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Maximum Balance </h4>
                        <p>{LedgerData.maximumBalance}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>Interest Rate</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Annualized Interest Rate Type</h4>
                        <p>{LedgerData.interestTypecode}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Annualized Interest Rate</h4>
                        <p>{LedgerData.annualInterestRate}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Compound Interest Period in Days</h4>
                        <p>{LedgerData.compoundPeriodInDays}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Grace Days for Interest Accrual</h4>
                        <p>{LedgerData.graceDaysForDeployment}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>Subscription & Fee</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Minimum Subscription Days</h4>
                        <p>{LedgerData.minimumSubscriptionDays}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Monthly Account Fee</h4>
                        <p>{LedgerData.monthlyFee}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Minimum Balance Default Fee</h4>
                        <p>{LedgerData.belowMinimumDefaultFee}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>Overdraft</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Overdraft Protection Enabled</h4>
                        <p>{LedgerData.overdraftProtection ? "Yes" : "No"}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Maximum Overdraft Amount</h4>
                        <p>{LedgerData.overdraftMaximumAmount}</p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Overdraft Fee</h4>
                        <p>{LedgerData.overdraftFee}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>User Action Settings</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>User Direct Deposits Allowed</h4>
                        <p>
                          {LedgerData.userDirectDepositAllowed ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>User Direct Withdrawals Allowed</h4>
                        <p>
                          {LedgerData.userDirectWithdrawAllowed ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>User Transfer Deposits Allowed</h4>
                        <p>
                          {LedgerData.userTransferDepositAllowed ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>User Transfer Withdrawals Allowed</h4>
                        <p>
                          {LedgerData.userTransferWithdrawAllowed
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 col-xl-3">
                    <h6>Owner Action Settings</h6>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Owner Direct Deposits Allowed</h4>
                        <p>
                          {LedgerData.ownerDirectDepositAllowed ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Owner Direct Withdrawals Allowed</h4>
                        <p>
                          {LedgerData.ownerDirectWithdrawAllowed ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Owner Transfer Deposits Allowed</h4>
                        <p>
                          {LedgerData.ownerTransferDepositAllowed
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-list">
                      <i className="bi bi-check"></i>
                      <div>
                        <h4>Owner Transfer Withdrawals Allowed</h4>
                        <p>
                          {LedgerData.ownerTransferWithdrawAllowed
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
              <button
                type="button"
                className="btn cancel-btn mb-3 mb-md-0 me-4"
                onClick={() => {
                  previous()
                }}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn next-btn mb-3 mb-md-0"
                onClick={() => {
                  // setPrivateModal(true);
                  closeModal();
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewLedger;
