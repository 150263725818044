import React from "react";
import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import Footer from "../../../Components/Footer";
import TechnologyFAQ from "./TechnologyFAQ";
import Governance from "./Governance";

function BissnessFAQ() {
    return (
        <>
            <div className="documentation faq">
                <Header />

                <div className="container-fluid p-0">
                    <div className="row m-0">
                        {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0"> */}
                        {/* <Sidebar /> */}
                        {/* </div> */}
                        <div className="col-md-9 col-lg-9 col-xxl-10">
                            <div className="faq-list">
                                <h2>FAQ</h2>
                                <div className="d-md-flex align-items-start">
                                    <div className="nav flex-column nav-pills me-5" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                                        <h3>Table of Content</h3>
                                        <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" >
                                            Technology</button>
                                        <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" >
                                            Business</button>
                                        <button className="nav-link" id="v-pills-governance-tab" data-bs-toggle="pill" data-bs-target="#v-pills-governance" type="button" role="tab" aria-controls="v-pills-governance" aria-selected="false" >
                                            Governance</button>
                                    </div>
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <h3>To learn more about Unicoin, check out our FAQs</h3>
                                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" >
                                            <div className="documentation-accordion ms-0">
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="Issuer">
                                                            <button
                                                                className="accordion-button"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#issuerOne"
                                                                aria-expanded="true"
                                                                aria-controls="issuerOne"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  What are the most significant Unicoin innovations?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="issuerOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="Issuer"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p> The Unicoin innovation stack consists of four layers. </p>

                                                                <p>
                                                                    {" "}
                  The first and most significant layer of the Unicoin innovation
                  stack is its cryptographic solutions for both regulated
                  electronic cash and physical hand-to-hand cash as defined by
                  the global banking industry.
                </p>

                                                                <p>
                                                                    {" "}
                  The second innovation layer is a multi-functional DLT which
                  supports all asset and liability banking and financial
                  services transactions beyond just assets or cash as are
                  supported in all other DLTs.{" "}
                                                                </p>

                                                                <p>
                                                                    {" "}
                  With Unicoin, banks and FinTech companies can perform
                  full-service banking and financial services strictly running
                  over the Unicoin digital currency rails.
                </p>

                                                                <p>
                                                                    {" "}
                  The third innovation layer is Smart Wallets. Unicoin
                  multi-currency Smart Wallets can hold all three classes of
                  store of value digital currencies without the need to
                  liquidate across crypto networks.{" "}
                                                                </p>

                                                                <p>
                                                                    {" "}
                  The fourth foundational layer is the Unicoin Staked Proof of
                  Trust (SPOT) consensus protocol.{" "}
                                                                </p>

                                                                <p>
                                                                    {" "}
                  The SPOT consensus protocol is scientifically the world�s
                  fastest consensus protocol because trusted nodes write
                  transactions to the DLT in real-time upon arrival and the
                  network of decentralized validator nodes only reject
                  transactions they deem invalid.{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="Modify">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#ModifyTwo"
                                                                aria-expanded="false"
                                                                aria-controls="ModifyTwo"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  How would you compare Unicoin to Bitcoin?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="ModifyTwo"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="Modify"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Bitcoin will go down in history as one of the most genius
                                                                    financial technology innovations. Bitcoin was invented to
                                                                    operate in a public trustless network outside of financial
                  institutions.{" "}
                                                                </p>

                                                                <p>
                                                                    {" "}
                  Now that the banking and financial services industry have
                  decided to embrace digital assets and digital currencies, a
                  new wave of distributed ledger technology (DLT), network
                  consensus, and digital currencies must be evolved to fit the
                  needs of regulated and trusted financial institutions.
                </p>

                                                                <p>
                                                                    Unicoin has been engineered from the ground up to address this
                                                                    new wave.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="Suspend">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#SuspendThree"
                                                                aria-expanded="false"
                                                                aria-controls="SuspendThree"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  How is Unicoin's physical cash different from other private
                  cryptocurrencies like Monero and Z-Cash?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="SuspendThree"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="Suspend"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    {" "}
                  The banking industry recognizes regulated electronic cash and
                  physical cash as the two legal forms of money in society.
                  Electronic cash is facilitated by regulated financial
                  institutions and physical cash operates hand-to-hand outside
                  of the banking system.
                </p>

                                                                <p>
                                                                    {" "}
                  Bitcoin and the entire industry of Alt Coins, including Monero
                  and Z-Cash, are hybrid cryptographic versions of electronic
                  cash and physical cash and do not optimally solve either
                  category.
                </p>

                                                                <p>
                                                                    {" "}
                  Unicoin is forking the cryptocurrency industry and is
                  introducing a new wave of cryptography that meet the global
                  banking industry�s definition of both regulated electronic
                  cash and physical (digital) cash.
                </p>

                                                                <p>
                                                                    {" "}
                  Cryptographic physical cash is point-to-point and is not
                  recorded in any intermediary ledger. Unicoin has engineered
                  the world�s first physical cash cryptographic solution with
                  the ability to send cryptocurrency and digital currencies
                  mobile-to-mobile without going through any intermediary or
                  financial institution.{" "}
                                                                </p>

                                                                <p>
                                                                    {" "}
                  Just like physical cash, only with point-to-point transfers
                  can you achieve true private and anonymous transactions.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="BranchOne">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#CreateOne"
                                                                aria-expanded="false"
                                                                aria-controls="CreateOne"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  How can Unicoin electronic cash be both private and traceable?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="CreateOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="BranchOne"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Regulated electronic cash must be safe and secure and preserve
                                                                    privacy while being traceable if required due to any fraud or
                                                                    suspicious activity. Unicoin network transactions are private
                                                                    because no public wallet address or personal information is
                  disclosed in our DLT.{" "}
                                                                </p>

                                                                <p>
                                                                    However, because our network of financial services providers
                                                                    (FSPs) must conduct Know Your Customer (KYC) due diligence
                                                                    before onboarding customers on the Unicoin network, all
                                                                    electronic cash financial transactions are associated with KYC
                                                                    information although not published on the public DLT.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="BranchTwo">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#CreateTwo"
                                                                aria-expanded="false"
                                                                aria-controls="CreateTwo"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  How can Unicoin physical cash be reconciled by the issuing
                  financial institution if transactions are not recorded in the
                  DLT?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="CreateTwo"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="BranchTwo"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    There are two ways to receive physical (digital) cash in your
                                                                    mobile phone. Either you withdraw it from your FSP wallet to
                                                                    your mobile phone, or you receive it as a direct transfer
                                                                    mobile-to-mobile.
                </p>

                                                                <p>
                                                                    In the first scenario of withdrawing cash to your mobile
                                                                    device, the financial institution is only reconciling the
                                                                    withdrawal. Banks and financial institutions are not
                                                                    responsible for reconciling money you spend once it�s in your
                                                                    personal possession.
                </p>

                                                                <p>
                                                                    {" "}
                  Unicoin in your mobile device is no longer connected to the
                  electronic banking DLT and operates as physical cash. Meaning,
                  if your mobile phone is lost or stolen, there is no way to
                  recover the funds.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="BranchThree">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#CreateThree"
                                                                aria-expanded="false"
                                                                aria-controls="CreateThree"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  What makes Unicoin compliant with global banking regulations?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="CreateThree"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="BranchThree"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    {" "}
                  Unicoin is a global decentralized financial services network.
                  In the same way you need an Internet Service Provider (ISP) to
                  access the Internet, you need a Financial Services Provider
                  (FSP) to access the Unicoin network.
                </p>

                                                                <p>
                                                                    {" "}
                  Each FSP must be a bank or a FinTech company licensed by the
                  central bank of their jurisdiction. In the United States our
                  FSPs and the Unicoin network are compliant with Financial
                  Crimes Enforcement Network (FinCEN), Office of Foreign Asset
                  Control (OFAC), and the Financial Action Task Force (FATF).
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="AgentOne">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#CreateAgentOne"
                                                                aria-expanded="false"
                                                                aria-controls="CreateAgentOne"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Bitcoin is being �adopted� by banks. How is that different
                  from Unicoin?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="CreateAgentOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="AgentOne"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Bitcoin has been adopted primarily as an alternative
                                                                    investment class by the capital markets divisions of banks.
                                                                    Bitcoin remains challenged in gaining traction in the core
                                                                    retail and commercial operations of banking.
                </p>

                                                                <p>
                                                                    As noted in the first sentence of the Abstract in the Bitcoin
                                                                    Whitepaper, Bitcoin has its own version of electronic cash not
                                                                    intended for financial institutions. Unicoin has been
                                                                    engineered for the regulated banking industry and has
                                                                    implemented a governance model for banking and financial
                                                                    services.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="AgentTwo">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#ModifyAgentOne"
                                                                aria-expanded="false"
                                                                aria-controls="ModifyAgentOne"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  How does Unicoin compare to Bitcoin as a store of value
                  protocol?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="ModifyAgentOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="AgentTwo"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    There are 3 classes of store of value cryptocurrencies: Fixed
                                                                    Store of Value (Stablecoins), Income-producing Store of Value
                                                                    (DeFi), and Speculative Store of Value.
                </p>

                                                                <p>
                                                                    Unicoin and Bitcoin are both speculative store of value
                                                                    cryptocurrencies. However, the Unicoin network also offers
                                                                    Fixed and Income-producing Store of Value digital currencies.
                                                                    Offering all three classes of Store of Value in the same
                                                                    multi-currency wallet enable investors to move between
                                                                    different store of value assets with minimum friction and
                                                                    fees.
                </p>

                                                                <p>
                                                                    For example, many crypto investors move between Bitcoin and
                                                                    Tether during market uncertainty and volatility. These out of
                                                                    network liquidations and transfers can be expensive. With
                                                                    Unicoin moving between a speculative store of value
                                                                    cryptocurrency and a fixed or income-producing store of value
                                                                    cryptocurrency has minimal friction and cost because they are
                                                                    all on the same Unicoin network.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="AgentThree">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#SuspendAgentOne"
                                                                aria-expanded="false"
                                                                aria-controls="SuspendAgentOne"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  How would you compare Unicoin to Ethereum?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="SuspendAgentOne"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="AgentThree"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    {" "}
                  Unicoin is like Ethereum in that Ethereum has a network coin,
                  Ether, and has implemented a tokenized framework to support
                  other classes of tokens. The difference between Unicoin and
                  Ethereum is all the digital currencies on the Unicoin network
                  are governed, issued, and minted by the banking industry.{" "}
                                                                </p>
                                                                <p> We do not support any private enterprise tokens.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQOne">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQA"
                                                                aria-expanded="false"
                                                                aria-controls="FAQA"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Who holds and governs the money for digital assets purchased
                  on the Unicoin network?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQA"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQOne"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    {" "}
                  In a decentralized governance model, each bank and financial
                  institution maintain their own customer deposits and can issue
                  and mint digital currency on the Unicoin network as needed.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQTwo">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQB"
                                                                aria-expanded="false"
                                                                aria-controls="FAQB"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Which aspects of the Unicoin network are centralized vs
                  decentralized?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQB"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQTwo"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Like virtually all blockchain projects, the Digital Currency
                                                                    Monetary Authority (DCMA) is an umbrella organization that
                                                                    operates as a decentralized governance organization (DGO)
                                                                    where each bank and financial institution is autonomous and
                                                                    has voting rights into the operations of the Unicoin network.
                </p>

                                                                <p>
                                                                    The Unicoin network consists of three types of nodes:
                                                                    Meganodes, Mesonodes, and Micronodes. Each node level has a
                                                                    network of nodes that operate in an independent, decentralized
                                                                    manner.
                </p>

                                                                <p>
                                                                    All digital currency deposits or purchases are decentralized
                  across the global network of Unicoin FSPs.{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQThree">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQC"
                                                                aria-expanded="false"
                                                                aria-controls="FAQC"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Can the Unicoin network support smart contracts?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQC"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQThree"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    {" "}
                  The Unicoin innovation of multi-functional distributed ledger
                  technology (mDLT) enables full-service banking and financial
                  services without the risks associated with writing smart
                  contracts.
                </p>

                                                                <p>
                                                                    If every bank and financial institution had to write their own
                                                                    smart contracts to implement all financial transactions, the
                                                                    Unicoin network would not scale and would introduce
                                                                    inconsistencies and security vulnerabilities. The Unicoin mDLT
                                                                    is a highly scalable, safe, security, and regulatory complaint
                                                                    technology for customizing and implementing all banking and
                                                                    financial services transactions.
                </p>

                                                                <p>
                                                                    Much of the code that would be written in smart contacts is
                                                                    customizable in the Unicoin protocol functions and executes
                                                                    directly in the Unicoin DLT without the need for smart
                                                                    contracts.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQFour">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQD"
                                                                aria-expanded="false"
                                                                aria-controls="FAQD"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Can banks create their own ledgers and tokens on the Unicoin
                  network?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQD"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQFour"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Banks can create any asset or liability ledger needed to
                                                                    support their retail or commercial banking operations such as
                                                                    interest-bearing, money market, loans, and investment
                                                                    accounts.
                </p>

                                                                <p>
                                                                    The Unicoin network has a cash-backed digital asset (CBDA) for
                                                                    every national currency worldwide. As such, banks do not need
                                                                    to create their own digital currency to conduct money
                                                                    transfers on Unicoin.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQFive">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQE"
                                                                aria-expanded="false"
                                                                aria-controls="FAQE"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  What is the difference between a Stablecoin and a Unicoin
                  Cash-backed Digital Asset (CBDA)?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQE"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQFive"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Stablecoins are issued and minted by private enterprises. The
                                                                    Unicoin network only support cryptocurrencies and Stablecoins
                                                                    governed, issued, and minted by a bank or a licensed financial
                  institution.{" "}
                                                                </p>

                                                                <p>
                                                                    {" "}
                  Cash-backed Digital Assets (CBDAs) are the functional
                  equivalent of a Stablecoin but are governed, issued, and
                  minted by the banking and financial services industry in a
                  decentralized model.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQSix">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQF"
                                                                aria-expanded="false"
                                                                aria-controls="FAQF"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  What are the advantages of the Unicoin SPOT consensus protocol
                  to other DLT consensus protocols?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQF"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQSix"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    There are many types of DLT consensus protocols. Hence, it
                                                                    would be difficult to make a blanket comparison. However, the
                                                                    keen advantages of the SPOT protocol are:
                </p>

                                                                <p> Fast and efficient</p>
                                                                <p> Can process over 100,000 TPS</p>
                                                                <p> Energy-efficient. </p>
                                                                <p> Resilient against network attacks</p>
                                                                <p> Pay dividends to validating node participants</p>

                                                                <p>
                                                                    {" "}
                  With SPOT, the decentralized network of trusted authority
                  Meganodes process and commit each transaction to the DLT in
                  real-time upon arrival, and the validating Mesonodes only
                  reject transactions they deem invalid.
                </p>

                                                                <p>
                                                                    {" "}
                  A Meganode transaction can only be rejected if the majority of
                  Mesonodes reject the transaction. To discourage bad actors
                  from gaming or attacking the network, if a Mesonode rejects a
                  transaction and they are in the minority, the rejecting
                  node(s) will lose their stake.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQSeven">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQG"
                                                                aria-expanded="false"
                                                                aria-controls="FAQG"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Can I earn money staking Unicoin like with other DeFi tokens?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQG"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQSeven"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                Yes. You can earn up to 20% annually staking Unicoin.
              </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQEight">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQH"
                                                                aria-expanded="false"
                                                                aria-controls="FAQH"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Can I earn Unicoin being a network node transaction validator?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQH"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQEight"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                Yes. Any Unicoin stakeholder can become a Mesonode and earn
                                                                Unicoin as a transaction validator.
              </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQNine">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQI"
                                                                aria-expanded="false"
                                                                aria-controls="FAQI"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Is Unicoin the world's first global bank?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQI"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQNine"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                The Unicoin network provides access to full-service banking and
                                                                financial services over a global decentralized network. Yet,
                                                                Unicoin is not a bank. Unicoin provides the digital
                                                                infrastructure to support global banking and financial services.
              </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQTen">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQJ"
                                                                aria-expanded="false"
                                                                aria-controls="FAQJ"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Will Unicoin be listed on crypto exchanges?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQJ"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQTen"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">Yes.</div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQEleven">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQM"
                                                                aria-expanded="false"
                                                                aria-controls="FAQM"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  What are the Unicoin Tokenomics?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQM"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQNEleven"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    {" "}
                  Unicoin has a fixed supply of 128,128,128 coins. Over
                  100,000,000 Unicoins will be locked up and not all put in
                  circulation for a long-term multi-year period. 25,000,000
                  Unicoins will be released to the public for purchase and
                  through Mesonode transaction validator participation.
                </p>

                                                                <p>
                                                                    {" "}
                  It is estimated 500,000 to 1,000,000 new Unicoins may be
                  minted to the circulating public each year.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQTwelve">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQK"
                                                                aria-expanded="false"
                                                                aria-controls="FAQK"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Why is the DCMA called a monetary authority?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQK"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQNTwelve"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                <p>
                                                                    Central banks are the monetary authority of national fiat
                                                                    currencies. The DCMA is filling the void in the market for
                                                                    continental, and more specifically, global currencies, such as
                                                                    the G7 and G20 world currencies.
                </p>

                                                                <p>
                                                                    Think of central banks as vertical national monetary
                                                                    authorities and the DCMA as a horizontal monetary authority
                                                                    spanning across multiple national currencies.
                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FAQThirteen">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#FAQL"
                                                                aria-expanded="false"
                                                                aria-controls="FAQL"
                                                            >
                                                                <div className="d-flex align-items-start">
                                                                    <i className="bi bi-question-circle me-4"></i>
                  Who are the private investors in the DCMA?
                </div>
                                                                <i className="bi bi-plus-lg none"></i>
                                                                <i className="bi bi-dash-lg show"></i>
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="FAQL"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="FAQNThirteen"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body ms-5">
                                                                The DCMA is a membership organization funded by banks and
                                                                financial institutions and does not accept private investment.
                                                                The public can participate in the monetization of the Unicoin
                                                                network by purchasing Unicoin.
              </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" >
                                            <TechnologyFAQ />
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-governance" role="tabpanel" aria-labelledby="v-pills-governance-tab" >
                                            <Governance />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default BissnessFAQ;
