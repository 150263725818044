import { Link } from "react-router-dom";
import { webwalletnavbar } from "../Constatnts/Sidebaritems";
import { useEffect, useState } from "react";
import axios from "axios";
import { ADMIN_URL } from "../Constatnts/config";
import { Modal } from "react-responsive-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setPublicId, setPrivateId, setMenus } from "../action/index";
import { validatePublicKey, decrypytlocalStoragedata } from "../api/walletKey";
import { encryptionString } from "../Constatnts/config";
import * as Yup from "yup";
import { success, error } from "./alert";
var CryptoJS = require("crypto-js");

// import http from "../api/httpService";

function Sidebar() {
  let pathname = window.location.pathname;
  const [allMenu, allMenuset] = useState([]);
  const [publicAddress, setPublicAddress] = useState([]);
  const [menu, setMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const [PrivateIdlocal, setPrivateIdlocal] = useState("");
  const [checkedPrivate, setCheckedPrivate] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  // for the get data from redux
  // eslint-disable-next-line no-unused-vars
  const publicKeys = useSelector((state) => state);
  let dynamicClassId = "";
  // let navigate = useNavigate();

  // let initialValuesPublic = {
  //   publicAddress: "",
  // };

  const validationSchema = Yup.object({
    publicAddress: Yup.string().required("Public Wallet Address is required"),
  });
  const handlePrivateKey = async (privateKey) => {
    if (privateKey) {
      const addressArray = [{ privateKey: privateKey }];
      let encryptPrivateKey = CryptoJS.AES.encrypt(
        JSON.stringify(addressArray),
        encryptionString
      ).toString();
      if (encryptPrivateKey) {
        localStorage.setItem("privateKey", encryptPrivateKey);
        // success("PrivateKey is Sucessfully Match");
        // navigate("/webwallet/createwallet");
      } else {
        // error("Please enter Valid privateKey");
      }
    }
  };
  async function setmenu(walletClassKeycode) {
    let filtermenu = [];
    let urls = [];
    console.log("🚀 ~ file: Sidebar.js ~ line 62 ~ setmenu ~ urls", urls);

    // eslint-disable-next-line array-callback-return
    allMenu.map((value) => {
      // let wallettype = value.walletClassList.split(",");
      // if (wallettype.includes(walletClassKeycode)) {
      //   filtermenu.push(value);
      //   urls.push(value.webURL);
      // }
      if (value.walletClassList) {
        let wallettype = value.walletClassList.split(",");
        if (wallettype.includes(walletClassKeycode)) {
          filtermenu.push(value);
          urls.push(value.webURL);
        }
      }
    });
    setMenu(filtermenu);

    dispatch(setMenus(urls));
  }
  const handlePublicKey = async (PublicKey) => {
    if (PublicKey) {
      const addressArray = [{ publicKey: PublicKey }];
      let encryptPublicAddress = CryptoJS.AES.encrypt(
        JSON.stringify(addressArray),
        encryptionString
      ).toString();
      if (encryptPublicAddress) {
        localStorage.setItem("publicAddress", encryptPublicAddress);
        // success("publicAddress is Sucessfully Match");
        // navigate("/webwallet/privatemodel");
      } else {
        // error("Please enter Valid PublicKey");
      }
    }
  };

  const fetchData = async () => {
    dispatch(setPublicId(""));
    dispatch(setPrivateId(""));
    let PublicAddress = localStorage.getItem("publicAddress");
    let PrivateAddress = localStorage.getItem("privateKey");
    if (PrivateAddress) {
      var APIKEY = await decrypytlocalStoragedata(PrivateAddress);
      setPrivateIdlocal(APIKEY[0].privateKey.PrivateWalletAddress);
    }
    if (PublicAddress) {
      var APPId = await decrypytlocalStoragedata(PublicAddress);

      setPublicAddress(APPId[0].publicKey.publicAddress);
      onOpenModal();
    } else {
      dispatch(setPublicId(""));
      dispatch(setPrivateId(""));
      onOpenModal();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let response = await axios.get(ADMIN_URL + "/security/GetNavigation");

    allMenuset(response.data);

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="sidebar">
        {menu &&
          menu.map((menu, index) => {
            if (menu.itemLevel === 1) {
              dynamicClassId =
                menu.displayname.replace(/ /g, "") + menu.navbarID;
            }
            return (
              <div className="accordion sidebar-main" key={index} id="sd">
                <div className="issuers">
                  {menu.itemLevel === 1 && (
                    <h2
                      // className="accordion-header active"
                      className={
                        webwalletnavbar.includes(pathname)
                          ? "accordion-header active"
                          : "accordion-header"
                      }
                      id={menu.keycode}
                      onClick={fetchData}
                    >
                      <Link
                        to={{
                          pathname: menu.webURL,

                          // query: { backUrl: "/webwallet/createissuer" },
                          // search: `?backUrl=${menu.webURL}`,
                        }}
                        className={pathname === menu.webURL ? " active" : ""}
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#" + dynamicClassId}
                        >
                          <div className="sidebar-main-label d-flex align-items-center ">
                            {webwalletnavbar.includes(pathname) ? (
                              <img
                                src="../icons/Issuers-icon-active.png"
                                className="issuers-icon-img show"
                                alt="Issuers-icon-img"
                              ></img>
                            ) : (
                              <img
                                src="../icons/Issuers-icon.png"
                                className="issuers-icon-img none"
                                alt="issuers-icon-img"
                              ></img>
                            )}
                            <h3>{menu.displayname}</h3>
                          </div>
                        </button>
                      </Link>
                    </h2>
                  )}
                  {menu.itemLevel === 2 && (
                    <div
                      id={dynamicClassId}
                      // className={
                      //   menu.walletClassList === "NODE,MA,0,0" &&
                      //   menu.itemLevel === 2
                      //     ? "accordion-collapse collapse show d-block"
                      //     : "accordion-collapse collapse show d-none"
                      // }
                      className={
                        webwalletnavbar.includes(pathname)
                          ? "accordion-collapse collapse "
                          : "accordion-collapse collapse show"
                      }
                      aria-labelledby="issuers"
                      data-bs-parent="#sd"
                    >
                      <ul>
                        <li onClick={fetchData}>
                          <Link
                            state={{
                              publicAddress:
                                publicKeys?.publicKey?.publicAddress,
                            }}
                            to={{
                              pathname: menu.webURL,
                              // query: { backUrl: "/webwallet/createissuer" },
                              // search: `?backUrl=${menu.webURL}`,
                            }}
                            className={
                              pathname === menu.webURL ? " active" : ""
                            }
                          >
                            {menu.displayname}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div>
        <Modal open={open} onClose={onCloseModal} center position="unset">
          <Formik
            initialValues={{
              publicAddress: publicAddress,
              PrivateWalletAddress: PrivateIdlocal,
            }}
            validationSchema={validationSchema}
            onSubmit={async (publicKey) => {
              const validatekey = await validatePublicKey(
                publicKey.publicAddress
              );

              if (validatekey) {
                let walletClassKeycode = validatekey.walletClassKeycode;
                // eslint-disable-next-line no-unused-vars
                // eslint-disable-next-line array-callback-return
                setmenu(walletClassKeycode);
                dispatch(setPrivateId(""));
                success("Sucessfully Verified PublicKey");
                if (checkedPrivate) {
                  handlePrivateKey({
                    PrivateWalletAddress: publicKey.PrivateWalletAddress,
                  });
                  await dispatch(
                    setPrivateId({
                      PrivateWalletAddress: publicKey.PrivateWalletAddress,
                    })
                  );
                  // fetchData();
                } else {
                  await dispatch(
                    setPrivateId({
                      PrivateWalletAddress: publicKey.PrivateWalletAddress,
                    })
                  );
                  // fetchData();
                }
                if (checked) {
                  handlePublicKey({ publicAddress: publicKey.publicAddress });
                  dispatch(
                    setPublicId({ publicAddress: publicKey.publicAddress })
                  );
                  // onOpenModalPrivate();
                  onCloseModal();
                } else {
                  setOpen(false);
                  // onOpenModalPrivate();
                  dispatch(
                    setPublicId({ publicAddress: publicKey.publicAddress })
                  );
                }
              } else {
                dispatch(setPrivateId(""));
                error("Public Key is Invalid");
              }
            }}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form className="welcome">
                  <div className="welcome-bg-img">
                    <div className="wallet-icon">
                      <img
                        src="../images/dl.beatsnoop-1.png"
                        alt="dl.beatsnoop 1-img"
                      />
                    </div>
                    <div className="carousel-caption">
                      <img
                        src="../images/coins/U-1.png"
                        className="modal-logo"
                        alt="logo"
                      />
                      <h3>Welcome to the Unicoin Network</h3>
                      <div className="text-start">
                        <label>Public Wallet Address</label>
                        <div className="input-box">
                          <Field
                            type="text"
                            name="publicAddress"
                            id="publicAddress"
                            className="form-control"
                            placeholder="Public Wallet Address"
                          />
                          <div>
                            <img
                              src="../icons/Public Wallet-icon.png"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div
                          className={
                            errors.publicAddress && touched.publicAddress
                              ? "input-error mt-1"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="publicAddress"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                        <div className="form-check mt-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                          />
                          <label htmlFor="flexCheckDefault">
                            Encrypt and Remember Me
                          </label>
                        </div>
                      </div>
                      <div className="text-start">
                        <label>Private Key</label>
                        <div className="input-box">
                          <Field
                            type="text"
                            name="PrivateWalletAddress"
                            id="PrivateWalletAddress"
                            className="form-control"
                            placeholder="Private Wallet Address"
                          />
                          <div>
                            <img src="../icons/key.png" alt="icon" />
                          </div>
                        </div>
                        <div
                          className={
                            errors.PrivateWalletAddress &&
                            touched.PrivateWalletAddress
                              ? "input-error mt-2"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="PrivateWalletAddress"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                        <div className="form-check mt-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault2"
                            checked={checkedPrivate}
                            onChange={(e) =>
                              setCheckedPrivate(e.target.checked)
                            }
                          />
                          <label htmlFor="flexCheckDefault2">
                            Encrypt and Remember Me
                          </label>
                        </div>
                      </div>

                      <button type="submit" className="validate">
                        VALIDATE
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </>
  );
}

export default Sidebar;
