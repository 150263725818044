import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";

export async function getAuthorization(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/GetAuths",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/wallet/GetAuths",
    { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function grantAuthorization(
  publickey,
  privateKey,
  grantingAddress
) {
  // const data = {authorizationTypeKeycode:grantingAddress}
  // let values = {
  //   sourcePublicAddress: publickey,
  //   grantingAddress,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/GrantAuthorization",
    // values,
    grantingAddress,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/wallet/GrantAuthorization",
    // values,
    grantingAddress,
    // {
    //   sourcePublicAddress: publickey,
    //   authorizationTypeKeycode: grantingAddress,
    // },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  
  return response;
}

export async function revokeAuthorization(
  publickey,
  privateKey,
  grantingAddress
) {
  // const data = {authorizationTypeKeycode:grantingAddress}
  // let values = {
  //   sourcePublicAddress: publickey,
  //   grantingAddress,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/wallet/RevokeAuthorization",
    // values,
    grantingAddress,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/wallet/RevokeAuthorization",
    // values,
    grantingAddress,
    // {
    //   sourcePublicAddress: publickey,
    //   authorizationTypeKeycode: grantingAddress,
    // },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  
  return response;
}
