//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function monetaryauthorities() {
    return (
        <>
            <div className="global network monetary-authorities ">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/monetary-authorit.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Monetary Authorities</p>
                            <h1>Embrace the<br className="d-none d-md-block" /> industry's most<br className="d-none d-md-block" /> comprehensive CBDC <br className="d-none d-md-block" />platform</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default monetaryauthorities;