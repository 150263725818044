import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const Download2 = () => {
    return (
        <>
            <Header />
            <div className="web-wallet-downloads download-page">
                <div className="container pb-5">
                    <div className="download-head">
                        <h3>Downloads</h3>
                        <h6>Web Wallet Downloads</h6>
                    </div>
                    <div className="download d-block">
                        <div className="windows-box mb-4 mb-xl-5">
                            <div className="windows">
                                <div>
                                    <img src="../icons/windows-icon.png" alt="windows-icon" />
                                </div>
                                <div>
                                    <h3>Windows</h3>
                                    <p>Windows</p>
                                </div>
                            </div>
                        </div>
                        <div className="windows-box mb-4 mb-xl-5">
                            <div className="windows">
                                <div>
                                    <img src="../icons/apple-icon.png" alt="apple-icon" />
                                </div>
                                <div>
                                    <h3>Mac</h3>
                                    <p>OS</p>
                                </div>
                            </div>
                        </div>
                        <div className="windows-box">
                            <div className="windows align-items-start me-md-0">
                                <div>
                                    <img src="../icons/linux-icon.png" alt="Linux-icon" />
                                </div>
                                <div className="mt-4">
                                    <h3>Linux</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="unicoin-logo">
                        <img src="../images/coins/U-2-png 2.png" alt="U-2-png 2.png" />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Download2;
