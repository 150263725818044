import React from "react";
import Header from "../../../Components/Header";
import Sidebar from "../../../Components/Sidebar";
function Authorization() {
    return (
        <>
            <Header />

            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9 col-lg-9 col-xxl-10">
                        <div className="main-heading">
                            <i className="bi bi-arrow-left"></i>
                            <h3> Authorizations</h3>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xxl-10">
                                <div className="wallet-balances-box">
                                    <h4 className="px-4">xxxxxxxxxxxxxxxxxxxxxxxxxx Public Wallet Authorizations</h4>
                                    <div className="d-md-flex justify-content-end px-4">
                                        <button type="button" className="btn btn-primary ">+ ADD AUTHORIZATION</button>
                                    </div>
                                    <div className="table-responsive p-3 p-md-4">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Public Wallet</th>
                                                    <th>Authorization Type</th>
                                                    <th>Created on</th>
                                                    <th className="text-end">Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Xxxxxxxxxxxxxxxxxx</td>
                                                    <td>SA</td>
                                                    <td>12/01/22</td>
                                                    <td className="text-end">Active</td>
                                                    <td>
                                                        <ul className="d-flex mb-0 p-0">
                                                            <li> <img src="../icons/Actions-trash.png" alt="Wallets-icon-img"></img></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Authorization;