import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showLoader } from "../../../../action";
import { getPayee } from "../../../../api/payee";
import { decrypytdata } from "../../../../api/walletKey";
import Pagination from "../../../Headermenu/NewPagination";
function Originator({ setTargetData, nextstep }) {
  const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [checkedRows, setCheckedRows] = useState();
  const publicKeys = useSelector((state) => state.keyReducer);
  const dispatch = useDispatch();

  //paginason
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postparPage, setPostparPage] = useState(25);
  // const [balance, setBalance] = useState("");
  const [maxRows, setMaxRows] = useState("");

  let APPID = "";
  let APIKEY = "";
  // const onRowsSelectionHandler = (index) => {
  //   setTargetData(index);
  // };
  // const closeModal = () => {
  //   setPrivateModal(false);
  // };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedLedger = await getPayee(APPID, APIKEY);
      dispatch(showLoader(false));
      const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      console.log(
        "🚀 ~ file: Originator.js ~ line 32 ~ fetchdata ~ tokens",
        JSON.parse(tokens)
      );

      let data = JSON.parse(JSON.parse(tokens).CommandResponse);
      console.log(
        "🚀 ~ file: Originator.js ~ line 33 ~ fetchdata ~ data",
        data
      );
      if (data) {
        let dataArr = [];
        let temp = data.shift();

        if (temp) {
          setMaxRows(temp.MaxRows);
        }
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      <div className="row mt-5">
        <div className="col-12">
          <div className="originating-account link-wallet-box ms-0">
            {/* <div className="row">
              <div className="col-xxl-10">
                <div className="wallet-balances-box">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>
                    <Link to={`/webwallet/createpayee`}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginBottom: "10px" }}
                      >
                        + ADD Payee
                      </button>
                    </Link>
                  </div>
                  <div style={{ width: "100%" }}>
                    <NewGrid
                      row={rows}
                      coloums={Payeecolumns}
                      onRowsSelectionHandler={onRowsSelectionHandler}
                    />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row ">
              <div className="col-xxl-11">
                <div className="wallet-balances-box">
                  <div
                    className="d-md-flex align-items-center justify-content-end px-4"
                    style={{ gap: "30px" }}
                  >
                    <div className="">
                      <div className="d-md-flex justify-content-between">
                        <div className="search-box d-md-flex">
                          <div className="input-group mb-3"></div>
                        </div>
                      </div>
                      <div className="line-input input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="search"
                          onChange={(e) => {
                            // setBankSearch(e.target.value);
                            console.log("abdkjbadb", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <Link to={`/webwallet/createpayee`}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginTop: "10px" }}
                      >
                        + ADD Payee
                      </button>
                    </Link>
                  </div>
                  <div className=" ">
                    <Paper
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        border: 1,
                        borderColor: "#c6c6c6",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          boxShadow: "none",
                          maxHeight: 500,
                          borderBottom: 1,
                          borderColor: "#c6c6c6",
                        }}
                      >
                        <Table
                          stickyHeader
                          className="table table-hover table-striped"
                          sx={{ minWidth: 650, mb: 0 }}
                          aria-label="simple table"
                        >
                          <TableHead
                            className="sticky-top"
                            sx={{
                              zIndex: "10",
                              borderBottom: 1,
                              borderColor: "#c6c6c6",
                            }}
                          >
                            <TableRow className="data-table-row">
                              <TableCell className="data-table-head">
                                <div className="lable-div">
                                  <span className="text-dark">Select</span>
                                </div>
                              </TableCell>

                              <TableCell className="data-table-head">
                                <div className="lable-div">
                                  <span className="text-dark">
                                    Digital Currency Account
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell
                                className="data-table-head"
                                sx={{ minWidth: 250 }}
                              >
                                <div className="lable-div">
                                  <span className="text-dark">
                                    Token Symbol
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell
                                className="data-table-head"
                                sx={{ minWidth: 150 }}
                              >
                                <div className="lable-div">
                                  <span className="text-dark">
                                    Currency Code
                                  </span>
                                </div>
                              </TableCell>

                              <TableCell className="data-table-head">
                                <div className="lable-div">
                                  <span className="text-dark">Balance</span>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows?.map((items, index) => {
                              console.log("shhshs", items);
                              return (
                                <>
                                  <TableRow
                                    className={
                                      " " + (checkedRows ? "rowselect" : "")
                                    }
                                    onDoubleClick={() => {
                                      console.log("double");
                                      setTargetData((prev) => {
                                        return { ...prev, ...items };
                                      });
                                      nextstep();
                                    }}
                                    // key={index}
                                    // onClick={() => setActive(items)}
                                    // id={index}
                                    // className={isActive === items ? "is-checked" : ""}
                                  >
                                    {/* <span>{index}</span> */}
                                    <TableCell>
                                      <div className=" table-form-check ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="flexCheckDefault"
                                          name="flexCheckDefault"
                                          checked={checkedRows === items.rowid}
                                          onChange={() => {
                                            setCheckedRows(items.rowid);
                                            // setInChecked(true);
                                          }}
                                          onClick={() => {
                                            setTargetData((prev) => {
                                              return { ...prev, ...items };
                                            });
                                            // setBalance(items.Balance);
                                          }}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell>{items.Displayname}</TableCell>
                                    <TableCell>{items.TokenSymbol}</TableCell>
                                    <TableCell>{items.Currencycode}</TableCell>
                                    <TableCell>{items.Balance}</TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box sx={{ width: "100%" }}>
                        <Box className="row py-3 px-2">
                          <Box className="col-12 col-md-3 col-xl-2">
                            <div className="row-select">
                              <select
                                class="form-select pagination-select"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  setPostparPage(e.target.value);
                                }}

                                // placeholder="Bank Type"
                              >
                                {/* <option selected>Select rows</option> */}

                                <option value={10}>10 Rows</option>
                                <option selected value={25}>
                                  25 Rows
                                </option>
                                <option value={50}>50 Rows</option>
                                <option value={100}>100 Rows</option>
                                <option value={200}>200 Rows</option>
                              </select>
                            </div>
                          </Box>

                          <Box className="col-12 col-xl-10 col-md-9 d-flex justify-content-end align-items-center">
                            <Pagination
                              postsperPage={postparPage}
                              maxRows={maxRows}
                              paginate={paginate}
                              currentPage={currentPage}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-xxl-11">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">
            PREVIOUS
          </button>
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0"
            onClick={nextstep}
          >
            Next
          </button>
        </div>
      </div> */}
    </>
  );
}
export default Originator;
