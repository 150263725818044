import { WEB_WALLET_URL } from "../Constatnts/config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletKey";
export async function CreateIssuersApi(publickey, privateKey, issuerData) {
  const getToken = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/CreateIssuer",
    issuerData,
    // createIssusersDetails,
    publickey,
    privateKey
  );

  const response = await http.post(
    WEB_WALLET_URL + "/issuer/CreateIssuer",
    issuerData,
    // createIssusersDetails,
    {
      headers: { Authorization: getToken },
    }
  );

  return response;
}

export async function getIssuer(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/GetIssuers",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/GetIssuers",
    values,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}

export async function insertIssuer(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/CreateIssuer",
    issuerData,

    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/CreateIssuer",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function modifyIssuer(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ModifyIssuer",
    issuerData,

    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/ModifyIssuer",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function issuresSuspend(
  publickey,
  privateKey,
  issuresSuspendData
) {
  const modifyIssusersDetails = {
    // sourcePublicAddress: issuresSuspendData.sourcePublicAddress,
    issuercode: "",
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/SuspendIssuer",
    modifyIssusersDetails,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/SuspendIssuer",
    modifyIssusersDetails,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
export async function importIssuerBatch(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ImportIssuerBatch",
    issuerData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/ImportIssuerBatch",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
