import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  // getCurrencycodesAPI,
  getDropdownvalues,
} from "../../../../api/dropdown";
import { FetchTokens } from "../../../../api/MoneyServices";
// import Pagination from "../../../Headermenu/Pagination";
// import { decrypytdata } from "../../../../api/walletKey";
function Originator({ nextstep ,setdata }) {
  const [privateModal, setPrivateModal] = useState(false);
  const [tokenClassdropdown, setTokenClassdropdown] = useState([]);
  const [tokenTypedropdown, setTokenTypedropdown] = useState([]);
  // const [currencyCodesdropdown, setcurrencyCodesdropdown] = useState([]);
  const [rows, setRows] = React.useState([]);
  // const [currencycodeData, setcurrencycodeData] = useState("");
  const [tokenClassData, setTokenClassData] = useState("");
  const [tokenTypeData, setTokenTypeData] = useState("");
  const publicKeys = useSelector((state) => state.keyReducer);
  const [fetchToken, setFetchToken] = useState();
  console.log(" fetchTokenData======", fetchToken)
  // const [indexData, setindexData] = useState();
 
  const fetchdropdwon = async () => {
    let LKTokenClass = await getDropdownvalues("LKTokenClass");
    setTokenClassdropdown(LKTokenClass);
    let LKTokenType = await getDropdownvalues("LKTokenType");
    setTokenTypedropdown(LKTokenType);
    // let LKCurrencyCodes = await getCurrencycodesAPI("LKCurrencyCodes");
    // setcurrencyCodesdropdown(LKCurrencyCodes);
  };

  //paginason
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentPage] = useState(1);
  const [postparPage] = useState(100);
  const indexofLastpage = currentPage * postparPage;
  const indexofFirstpost = indexofLastpage - postparPage;

  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  const datass = {
    tokenTypecode: tokenTypeData,
    tokenClasscode: tokenClassData,
    // currencycode: currencycodeData,
  };

  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      let values;
      if (tokenTypeData && tokenClassData) {
        values = {
          sourcePublicAddress: APPID,
          // currencycode: currencycodeData,
          tokenClasscode: tokenClassData,
          tokenTypecode: tokenTypeData,
        };
      } else {
        values = {
          sourcePublicAddress: APPID,
        };
      }
      const encryptedLedger = await FetchTokens(APPID, APIKEY, values);
      // const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      const data = encryptedLedger.data;
      // let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datass.tokenTypecode, datass.tokenClasscode]);

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);

  return (
    <>
      <div className="row mt-5">
        <div className="col-xxl-10">
          <div className="wallet-balances-box">
            <div className="d-md-flex justify-content-between px-4">
              <div className="">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control line-input"
                    placeholder="Amount"
                  ></input>
                </div>
              </div>
              <div className="">
                              <div className="line-input input-group mb-3">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive p-3 p-md-4">
                          <table className="table table-hover table-striped">
                <thead>
                  <tr className="data-table-row">
                    <th className="data-table-head"><div className="lable-div"><span className="text-dark">Select</span></div> </th>
                    <th className="data-table-head"><div className="lable-div"><span className="text-dark">Digital Currency</span></div></th>
                    <th className="data-table-head"><div className="lable-div"><span className="text-dark">Currency Code</span></div></th>
                    {/* <th>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        // value={e.target.value}
                        onChange={(e) => setcurrencycodeData(e.target.value)}
                      >
                        <option selected>Currencycode</option>

                        {currencyCodesdropdown.map((index) => {
                          return (
                            <>
                              <option
                                value={index.keycode}
                                // onChange={(e) => setDropdownItem(e.target.value)}
                              >
                                {index.isoCountryname}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </th> */}
                    <th><span className="text-dark">Token Class</span>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setTokenClassData(e.target.value)}
                      >
                        <option selected>Select Token Class</option>
                        {tokenClassdropdown.map((index) => {
                          return (
                            <>
                              <option value={index.keycode}>
                                {index.displayName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </th>
                    <th><span className="text-dark">Token Type</span>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setTokenTypeData(e.target.value)}
                      >
                        <option selected>Select Token Type</option>
                        {/* <option value="All">All</option> */}
                        {tokenTypedropdown.map((index) => {
                          return (
                            <>
                              <option value={index.keycode}>
                                {index.displayName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </th>
                    <th className="data-table-head"><div className="lable-div"><span>Token Symbol</span></div></th>
                   
                  </tr>
                </thead>
                <tbody>
                  {/* {rows?.length > 0 &&
                    rows
                      ?.slice(indexofFirstpost, indexofLastpage)
                      ?.filter((user) => {
                        if (
                          tokenClassData === "" &&
                          currencycodeData === "" &&
                          tokenTypeData === ""
                        ) {
                          return user;
                        } else if (
                          user.tokenClass
                            .toLowerCase()
                            .includes(tokenClassData.toLowerCase()) &&
                          user.currencycodeList
                            .toLowerCase()
                            .includes(currencycodeData.toLowerCase()) &&
                          user.tokenType
                            .toLowerCase()
                            .includes(tokenTypeData.toLowerCase())
                        ) {
                          return user;
                        }
                      }) */}
                  {rows?.slice(indexofFirstpost, indexofLastpage)?.map((items, index) => {
                    return (
                      <>
                        <tr key={index} >
                          {/* <span>{index}</span> */}
                          <td>
                            <div className=" table-form-check ">
                              <input
                                onClick={()=>setFetchToken(items)}
                                className="form-check-input"
                                type="radio"
                                id="flexCheckDefault"
                                name="flexCheckDefault"
                              />
                            </div>
                          </td>
                          <td>{items.displayname}</td>
                          <td>{items.currencycodeList}</td>
                          <td>{items.tokenClass}</td>
                          <td>{items.tokenType}</td>
                          <td>{items.tokenSymbol}</td>

                          {/* <td>
                            <input type="button" value="press" />
                          </td> */}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <Pagination 
                  postsperPage={postparPage}
                  totalPost={rows?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                /> */}
      </div>
      <div className="col-xxl-10">
        <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
          <button
            type="button"
            className="btn next-btn mb-3 mb-md-0 me-4"
            onClick={() => {
              setdata(fetchToken)
              nextstep()}}
            disabled={!fetchToken}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
export default Originator;
