export const Issuers = [
  "/webwallet/createissuer",
  "/webwallet/post-rates",
  "/webwallet/review-issuer",
  "/webwallet/review-rate",
  "/webwallet/transfer-rates",
  "/webwallet/modifyissuer",
];
export const Authorizations = [
  "/webwallet/ledger-authorizations",
  "/webwallet/token-authorizations",
  "/webwallet/wallet-authorizations",
];
export const Token = [
  "/webwallet/create-token",
  "/webwallet/modify-token",
  "/webwallet/token-supply",
  "/webwallet/composite-currencies",
  "/webwallet/review-new-token",
];

export const Ledger = [
  "/webwallet/ledger-balances",
  "/webwallet/create-ledgers",
  "/webwallet/modify-ledgers",
  "/webwallet/review-ledger",
  "/webwallet/deposit",
  "/webwallet/withdraw",
];
export const Wallets = [
  "/webwallet/create-wallet",
  "/webwallet/modify-wallet",
  "/webwallet/review-wallet",
  "/webwallet/link-wallet",
  "/webwallet/balances",
];
export const FX_rate = ["/webwallet/get-fx-rates"];
export const Money = ["/webwallet/transfer", "/webwallet/create-escrow"];
export const Spot_Staking = [
  "/webwallet/reject",
  "/webwallet/stake",
  "/webwallet/cashout",
];
export const webwalletnavbar = [
  "/webWallet",
  "/webwallet/createissuer",
  "/demo/wallet2ledger",
    "/demo/wallet2wallet",
  "/demo/ledger2wallet",
  "/demo/ledger2ledger",
  "/webwallet/modifyissuer",
  "/webwallet/modifywallet",
  "/webwallet/createtoken",
  "/webwallet/createwallet",
  "/webwallet/createpayeeplus",
  "/webwallet/modifytoken",
  "/webwallet/walletbalance",
  "/webwallet/transfer",
  "/webwallet/modifyledger",
  "/webwallet/createledger",
  "/webwallet/createbranch",
  "/webwallet/withdraw",
  "/webwallet/deposit",
  "/webwallet/tokensupply",
  "/webwallet/postrates",
  "/webwallet/reject",
  "/webwallet/cashout",
  "/webwallet/stake",
  "/webwallet/createescrow",
  "/webwallet/unipay",
  "/webwallet/postrates",
  "/webwallet/createagent",
  "/webwallet/createcompliance",
  "/webwallet/createsanctions",
  "/webwallet/createrates",
  "/webwallet/ledgerbalance",
  "/webwallet/ledgerdeposit",
  "/webwallet/ledgerwithdraw",
  "/webwallet/issuresbalances",
  "/webwallet/agentsbalances",
  "/webwallet/branchesbalances",
  "/webwallet/tokensbalances",
  "/webwallet/unicoinfunction",
  "/webwallet/pause",
  "/webwallet/unpause",
  "/webwallet/mint",
  "/webwallet/redeem",
  "/webwallet/burn",
  "/webwallet/swap",
  "/webwallet/supply1token",
  "/webwallet/tokenprice",
  "/webwallet/tokenslist",
  "/webwallet/tokencreate",
  "/webwallet/tokenmodify",
  "/webwallet/pausewallet",
  "/webwallet/unpausewallet",
  "/webwallet/moneyservicesbalances",
  "/webwallet/fxratesbalances",
  "/webwallet/confirm",
  "/webwallet/ledgersconfirm",
  "/webwallet/tokenconfirm",
  "/webwallet/issuresconfirm",
  "/webwallet/branchesconfirm",
  "/webwallet/agentsconfirm",
  "/webwallet/spotbalances",
  "/webwallet/authorizationsbalances",
  "/webwallet/suspendagent",
  "/webwallet/suspendissuer",
  "/webwallet/suspendledger",
  "/webwallet/milestonesuspend",
  "/webwallet/suspendtoken",
  "/webwallet/suspendwallet",
  "/webwallet/suspendbranch",
  "/webwallet/grantauthorization",
  "/webwallet/revokeauthorization",
  "/webwallet/transaction",
  "/webwallet/milestoneconfirm",
  "/webwallet/releasemilestone",
  "/webwallet/escrowsuspend",
  "/webwallet/suspendpayee",
  "/webwallet/escrowconfirm",
  "/webwallet/createmilestone",
  "/webwallet/modifymilestone",
  "/webwallet/getmilestones",
  "/webwallet/getescrows",
  "/webwallet/getpayees",
  "/webwallet/createpayee",
  "/webwallet/modifybranch",
  "/webwallet/modifyagent",
  "/webwallet/cancelescrow",
  "/webwallet/acceptescrow",
  "/webwallet/releaseescrow",
  "/webwallet/cancelmilestone",
  "/webwallet/getissuers",
  "/webwallet/getbranches",
  "/webwallet/getagents",
  "/webwallet/getgeosanctions",
  "/webwallet/creategeosanction",
  "/webwallet/modifygeosanction",
  "/webwallet/suspendgeosanction",
  "/webwallet/modifyentitysanction",
  "/webwallet/modifypayee",
  "/webwallet/getledgers",
  "/webwallet/gettokens",
  "/webwallet/getwallets",
  "/webwallet/getcompliance",
  "/webwallet/getrates",
  "/webwallet/getsanctions",
  "/webwallet/gettransfers",
  "/webwallet/getfxrates",
  "/webwallet/getspot",
  "/webwallet/getauths",
  "/webwallet/attachwallet",
  "/webwallet/getentities",
  "/webwallet/getpeople",
  "/webwallet/createentity",
  "/webwallet/modifyentity",
  "/webwallet/suspendentity",
  "/webwallet/createperson",
  "/webwallet/modifypeople",
  "/webwallet/suspendperson",
  "/webwallet/getbalances",
  "/webwallet/getactivity",
  "/webwallet/getentities",
  "/webwallet/getpeople",
  "/webwallet/createentity",
  "/webwallet/suspendentity",
  "/webwallet/createperson",
  "/webwallet/suspendperson",
  "/webwallet/suspendsanctions",
  "/webwallet/getbalances",
  "/webwallet/getactivity",
  "/webwallet/getpeople",
  "/webwallet/createpeople",
  "/webwallet/suspendpeople",
  "/webwallet/suspendcompliance",
  "/webwallet/Result",
  "/webwallet/getauths",
  "/webwallet/getledgerrules",
  "/webwallet/createledgerrule",
  "/webwallet/modifyledgerrule",
  "/webwallet/suspendledgerrule",
  "/webwallet/attachphoto",
  "/webwallet/getentitysanctions",
  "/webwallet/createentitysanction",
  "/webwallet/suspendentitysanction",
  "/webwallet/getpeoplesanctions",
  "/webwallet/createpeoplesanction",
  "/webwallet/modifypeoplesanction",
  "/webwallet/suspendpeoplesanction",
  "/webwallet/getrates",
  "/webwallet/createrate",
  "/webwallet/modifyrate",
  "/webwallet/suspendrate",
  "/webwallet/getlimits",
  "/webwallet/createlimit",
  "/webwallet/modifylimit",
  "/webwallet/suspendlimit",
];