import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Beneficiary from "../Ledgers/DepositFunds/Beneficiary";
import Originator from "../Ledgers/DepositFunds/Originator";
import Review from "../Ledgers/DepositFunds/Review";

function DepositFunds() {
  const [step, setstep] = useState(1);
  let { hash /*, pathname */ } = useLocation();
  const [sourceData, setsourceData] = useState(1);
  console.log(
    "🚀 ~ file: wallettowallet.js ~ line 10 ~ MoneyTransfer ~ sourceData",
    sourceData
  );
  const [targetData, setTargetData] = useState(1);
  console.log(
    "🚀 ~ file: wallettowallet.js ~ line 12 ~ MoneyTransfer ~ targetData",
    targetData
  );
  const [paymentData, setPaymentData] = useState();
  console.log(
    "🚀 ~ file: DepositFunds.js ~ line 21 ~ DepositFunds ~ paymentData",
    paymentData
  );

  const nextstep = () => {
    if (step < 3) {
      setstep(step + 1);
    }
  };
  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <Originator
            step={step}
            hashname={hash}
            setsourceData={setsourceData}
            nextstep={nextstep}
            setPaymentData={setPaymentData}
          />
        );
      case 2:
        return (
          <Beneficiary
            hashname={hash}
            setTargetData={setTargetData}
            nextstep={nextstep}
            step={step}
          />
        );
      case 3:
        return (
          <Review
            sourceData={sourceData}
            targetData={targetData}
            step={step}
            paymentData={paymentData}
            previous={previous}
          />
        );
      default:
        return false;
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };

  return (
    <>
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Balances</h3>
              <h6>Deposit</h6>
              <p>
                This function enables a wallet to add funds to a ledger account
                from their cash account.
              </p>
            </div>
            <div className="deposit-funds-tabs m-0">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li
                  className={
                    step > 1
                      ? "nav-item originator-m disableElement"
                      : "nav-item originator-m "
                  }
                >
                  <button
                    className={step === 1 ? "nav-link active" : "nav-link"}
                    id="home-tab"
                    type="button"
                  >
                    <div className="originator">
                      <h3>1</h3>
                      <i className="bi bi-check-lg"></i>
                    </div>
                    <h6>From Account</h6>
                  </button>
                </li>
                <li
                  className={
                    step > 2
                      ? "nav-item beneficiary-m disableElement"
                      : "nav-item beneficiary-m"
                  }
                >
                  <button
                    className={step === 2 ? "nav-link active" : "nav-link"}
                    id="profile-tab"
                    type="button"
                  >
                    <div className="beneficiary">
                      <h3>2</h3>
                      <i className="bi bi-check-lg"></i>
                    </div>
                    <h6>To Account</h6>
                  </button>
                </li>
                <li
                  className={
                    step > 3
                      ? "nav-item money-m disableElement"
                      : "nav-item money-m "
                  }
                >
                  <button
                    className={step === 3 ? "nav-link active" : "nav-link"}
                    id="contact-tab"
                    type="button"
                  >
                    <div className="review">
                      <h3>3</h3>
                      <i className="bi bi-check-lg"></i>
                    </div>
                    <h6>Review</h6>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home">
                  {/* <Originator /> */}
                  {renderstep(step)}
                </div>
              </div>
            </div>
            {step !== 3 ? (
              <div className="col-xxl-11">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                  <button
                    type="button"
                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                    onClick={previous}
                  >
                    PREVIOUS
                  </button>
                  <button
                    disabled={sourceData?.Balance <= paymentData?.TargetAmount}
                    type="button"
                    className="btn next-btn mb-3 mb-md-0"
                    onClick={nextstep}
                  >
                    {step === 1 || step === 2 ? "NEXT" : "SUBMIT"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}

export default DepositFunds;
