import React from 'react';
function IssuerDocumention() {
    return ( <>
     <div className="tab-pane fade show active" id="home">
                          <div className="documentation-accordion">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="Issuer">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#issuerOne"
                                    aria-expanded="true"
                                    aria-controls="issuerOne"
                                  >
                                    Create Issuer
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="issuerOne"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="Issuer"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Establishes a financial institution on the
                                    Unicoin network capable of creating customer
                                    wallets.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="Modify">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#ModifyTwo"
                                    aria-expanded="false"
                                    aria-controls="ModifyTwo"
                                  >
                                    Modify Issuer
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="ModifyTwo"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="Modify"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Establishes a financial institution on the
                                    Unicoin network capable of creating customer
                                    wallets.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="Suspend">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#SuspendThree"
                                    aria-expanded="false"
                                    aria-controls="SuspendThree"
                                  >
                                    Suspend Issuer
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="SuspendThree"
                            className="accordion-collapse collapse  show"
                                  aria-labelledby="Suspend"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Suspend Issuer Suspends an issuer�s access
                                    to the Unicoin network.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="BranchOne">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#CreateOne"
                                    aria-expanded="false"
                                    aria-controls="CreateOne"
                                  >
                                    Create Branch
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="CreateOne"
                            className="accordion-collapse collapse show"
                                  aria-labelledby="BranchOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Monetary Authorities can permission which
                                    regulated banks and licensed FinTech
                                    companie can create user wallets and mint
                                    digital currencies on the Unicoin network.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="BranchTwo">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#CreateTwo"
                                    aria-expanded="false"
                                    aria-controls="CreateTwo"
                                  >
                                    Modify Branch
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="CreateTwo"
                            className="accordion-collapse collapse show"
                                  aria-labelledby="BranchTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Modify Branch Enables the modification of
                                    branch profile information.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="BranchThree"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#CreateThree"
                                    aria-expanded="false"
                                    aria-controls="CreateThree"
                                  >
                                    Suspend Branch
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="CreateThree"
                            className="accordion-collapse collapse show"
                                  aria-labelledby="BranchThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Suspend Branch Suspends a branch from the
                                    Unicoin network.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="AgentOne">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#CreateAgentOne"
                                    aria-expanded="false"
                                    aria-controls="CreateAgentOne"
                                  >
                                    Create Agent
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="CreateAgentOne"
                            className="accordion-collapse collapse show"
                                  aria-labelledby="AgentOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Monetary Authorities can permission which
                                    regulated banks and licensed FinTech
                                    companie can create user wallets and mint
                                    digital currencies on the Unicoin network.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="AgentTwo">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#ModifyAgentOne"
                                    aria-expanded="false"
                                    aria-controls="ModifyAgentOne"
                                  >
                                    Modify Agent
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="ModifyAgentOne"
                            className="accordion-collapse collapse show"
                                  aria-labelledby="AgentTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Modify Agent Modifies an agent�s profile
                                    information.
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="AgentThree"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#SuspendAgentOne"
                                    aria-expanded="false"
                                    aria-controls="SuspendAgentOne"
                                  >
                                    Suspend Agent
                                    <i className="bi bi-plus-lg none"></i>
                                    <i className="bi bi-dash-lg show"></i>
                                  </button>
                                </h2>
                                <div
                                  id="SuspendAgentOne"
                            className="accordion-collapse collapse show"
                                  aria-labelledby="AgentThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    Suspend Agent Suspends an agent from the
                                    Unicoin network.
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        </div>
    </> );
}

export default IssuerDocumention;