import React from "react";
import { Link } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const Issuercolumns = [
  {
    field: "Countrycode",
    headerName: "Country",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Objectcode",
    headerName: "Issuer Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "TransactionType",
    headerName: "Issuer Type",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Issuer",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-pin.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Pin"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Users"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifyissuer?id=${params.row.Issuercode}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,

      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/suspendissuer?id=${params.row.Issuercode}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Ratecolumns = [
  {
    field: "Token",
    headerName: "Token",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Min Units",
    headerName: "Min Units",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Max Units",
    headerName: "Max Units",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Fee",
    headerName: "Fee",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Basis Pts",
    headerName: "Basis Pts",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-pin.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Pin"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Users"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifyissuer?id=${params.row.Issuercode}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,

      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/suspendissuer?id=${params.row.Issuercode}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
    ],
  },
];
export const Paycolumn = [
  {
    field: "Bankcode",
    headerName: "Bankcode",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "City",
    headerName: "City",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Bank",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  // {
  //   field: "RowID",
  //   headerName: "RowID",
  //   type: "string",
  //   flex: 1,
  //   align: "right",
  //   headerAlign: "right",
  // },

  {
    field: "StateProvince",
    headerName: "State",
    flex: 1,
    type: "string",
    align: "center",
    headerAlign: "center",
  },
];

export const Branchescolumns = [
  {
    field: "Countrycode",
    headerName: "Country",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Objectcode",
    headerName: "Branch Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "TransactionTypecode",
    headerName: "Branch Type",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Branch",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifybranch?id=${params.row.Displayname}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/suspendbranch?id=${params.row.Objectcode}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            {/* <Link to={{ pathname: "/webwallet/suspendissuer" }}> */}
            <img
              src="../icons/actions-agent.png"
              alt="Wallets-icon-img"
              size={10}
            />
            {/* </Link> */}
          </div>
        }
        label="Users"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Agentscolumns = [
  {
    field: "Objectcode",
    headerName: "User ID",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionTypecode",
    headerName: "User Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Displayname",
    headerName: "User",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Countrycode",
    headerName: "Country",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifyagent?id=${params.row.Objectcode}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/suspendagent?id=${params.row.Objectcode}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
    ],
  },
];
export const Ledgerscolumns = [
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TokenSymbol",
    headerName: "Token Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionType",
    headerName: "Ledger Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Displayname",
    headerName: "Ledger",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifyledger?id=${params.row.Ledgercode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/suspendledger?id=${params.row.Ledgercode}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/ledgerbalance}`}>
              <img
                src="../icons/Actions-1.1.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const LedgerRulesscolumns = [
  {
    field: "Ledger",
    headerName: "Ledger",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Ledgerrule",
    headerName: "Ledger Rule",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Minamount",
    headerName: "Min Amount",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Maxamount",
    headerName: "Max Amount",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifyledger?id=${params.row.Ledgercode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/suspendledger?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.1.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.2.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const GetBalancecolumns = [
  {
    field: "Ledger",
    headerName: "Currency Code",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Ledgerrule",
    headerName: "Token Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Minamount",
    headerName: "Displayname",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Maxamount",
    headerName: "Balance",
    flex: 1,
    type: "string",
    align: "left",
    headerAlign: "left",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifyledger?id=${params.row.Ledgercode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/suspendledger?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.1.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.2.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const GetEscrowcolumns = [
  {
    field: "Ledger",
    headerName: "Escrow",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Ledgerrule",
    headerName: "Escrow Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Minamount",
    headerName: "Amount",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Maxamount",
    headerName: "Reference Code",
    flex: 1,
    type: "string",
    align: "left",
    headerAlign: "left",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifyledger?id=${params.row.Ledgercode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/suspendledger?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.1.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.2.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const GetActivitycolumns = [
  {
    field: "Display Name",
    headerName: "Transaction",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "PreBalance",
    headerName: "Pre Balance",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Amount",
    headerName: "Amount",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "PostBalance",
    headerName: "Post Balance",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifyledger?id=${params.row.Ledgercode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/suspendledger?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.1.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-1.2.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Tokenscolumns = [
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Currencycode",
    headerName: "Currency",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Objectcode",
    headerName: "Token Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionTypecode",
    headerName: "Token Type",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Token",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifytoken?id=${params.row.TokenSymbol}`}>
            {/* <Link to={`/webwallet/modifytoken`}> */}
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/suspendtoken?id=${params.row.TokenSymbol}`}>
            <div tooltip="Suspend" flow="down" className="icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/pause?id=${params.row.TokenSymbol}`}>
            <div tooltip="Pause" flow="down" className="icons">
              <img
                src="../icons/Actions-10.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/unpause?id=${params.row.TokenSymbol}`}>
            <div tooltip="Unpause" flow="down" className="icons">
              <img
                src="../icons/Action-18.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/mint?id=${params.row.TokenSymbol}`}>
            <div tooltip="Mint" flow="down" className="icons">
              <img
                src="../icons/Actions-4.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action3"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/burn?id=${params.row.TokenSymbol}`}>
            <div tooltip="Burn" flow="down" className="icons">
              <img
                src="../icons/Actions-5.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action4"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/redeem?id=${params.row.TokenSymbol}`}>
            <div tooltip="Redeem" flow="down" className="icons">
              <img
                src="../icons/Actions-6.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action5"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/swap?id=${params.row.TokenSymbol}`}>
            <div tooltip="Swap" flow="down" className="icons">
              <img
                src="../icons/Actions-7.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action6"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/tokensupply?id=${params.row.TokenSymbol}`}>
            <div tooltip="Supply" flow="down" className="icons">
              <img
                src="../icons/Action-15.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action7"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/tokenprice?id=${params.row.TokenSymbol}`}>
            <div tooltip="Price" flow="down" className="icons">
              <img
                src="../icons/Action-16.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action8"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Walletscolumns = [
  {
    field: "WalletTypecode",
    headerName: " Wallet Class",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "WalletType",
    headerName: " Wallet Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Countrycode",
    headerName: "Country",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifywallet?id=${params.row.RowID}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/suspendwallet?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/pausewallet?id=${params.row.PublicAddress}`}>
              <img
                src="../icons/Actions-10.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/unpausewallet?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Action-18.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/attachwallet`}>
              <img
                src="../icons/Actions-pin.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action3"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Authorizationcolumns = [
  {
    field: "TransactionType",
    headerName: "Transaction Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "PublicAddress",
    headerName: "Auth Wallet",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Objectcode",
    headerName: "Objectcode",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-pin.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Pin"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="User"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-edit.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-trash.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Peoplecolumns = [
  {
    field: "Countrycode",
    headerName: "Country Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Display Name",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "DOB",
    headerName: "DOB",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-pin.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Pin"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Users"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifypeople?id=${params.row.Objectcode}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/suspendpeople?id=${params.row.UUID}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      />,
    ],
  },
];

export const SactionPeoplecolumns = [
  {
    field: "Countrycode",
    headerName: "Country Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Display Name",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "DOB",
    headerName: "DOB",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-pin.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Pin"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <img
              src="../icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Users"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link to={`/webwallet/modifypeople?id=${params.row.UUID}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="icons">
            <Link
              to={`/webwallet/suspendperson?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      />,
    ],
  },
];

export const TokensSupplycolumns = [
  {
    field: "displayname",
    headerName: "Displayname",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "currencycode",
    headerName: "Currency Code",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },

  {
    field: "tokenSymbol",
    headerName: "Token Symbol",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "authorizedUnits",
    headerName: "Authorized Units",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifytoken`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/suspendtoken`}>
            <div className="icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/pause`}>
            <div className="icons">
              <img
                src="../icons/Actions-10.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/unpause`}>
            <div className="icons">
              <img
                src="../icons/Action-18.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/mint`}>
            <div className="icons">
              <img
                src="../icons/Actions-4.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action3"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/burn`}>
            <div className="icons">
              <img
                src="../icons/Actions-5.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action4"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/redeem`}>
            <div className="icons">
              <img
                src="../icons/Actions-6.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action5"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/swap`}>
            <div className="icons">
              <img
                src="../icons/Actions-7.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action6"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/tokensupply`}>
            <div className="icons">
              <img
                src="../icons/Action-15.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action7"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/tokenprice`}>
            <div className="icons">
              <img
                src="../icons/Action-16.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action8"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Entitycolumns = [
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Displayname",
    headerName: "Displayname",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionType",
    headerName: "Transaction Type",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Website",
    headerName: "Website",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifyentity?id=${params.row.Objectcode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/suspendentity?id=${params.row.Objectcode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Payeecolumns = [
  {
    field: "TransactionType",
    headerName: "Transaction Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Payee",
    headerName: "Payee",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Countryname",
    headerName: "Country",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionDate",
    headerName: "Last Transaction",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  // {
  //     field: "Displayname",
  //     headerName: "Token",
  //     flex: 1,
  //     type: "string",
  //     align: "right",
  //     headerAlign: "right",
  // },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifytoken?id=${params.row.TokenSymbol}`}>
            {/* <Link to={`/webwallet/modifytoken`}> */}
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/suspendtoken`}>
            <div tooltip="Suspend" flow="down" className="icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action1"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/pause?id=${params.row.TokenSymbol}`}>
            <div tooltip="Pause" flow="down" className="icons">
              <img
                src="../icons/Actions-10.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action2"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/unpause?id=${params.row.TokenSymbol}`}>
            <div tooltip="Unpause" flow="down" className="icons">
              <img
                src="../icons/Action-18.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/mint?id=${params.row.PublicAddress}`}>
            <div tooltip="Mint" flow="down" className="icons">
              <img
                src="../icons/Actions-4.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action3"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/burn?id=${params.row.PublicAddress}`}>
            <div tooltip="Burn" flow="down" className="icons">
              <img
                src="../icons/Actions-5.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action4"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link
            to={`/webwallet/redeem?id=${params.row.PublicAddress}&&code=${params.row.TokenSymbol}`}
          >
            <div tooltip="Redeem" flow="down" className="icons">
              <img
                src="../icons/Actions-6.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action5"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link
            to={`/webwallet/swap?id=${params.row.PublicAddress}&&code=${params.row.TokenSymbol}`}
          >
            <div tooltip="Swap" flow="down" className="icons">
              <img
                src="../icons/Actions-7.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action6"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link
            to={`/webwallet/tokensupply?id=${params.row.TokenSymbol}&&id=${params.row.PublicAddress}`}
          >
            <div tooltip="Supply" flow="down" className="icons">
              <img
                src="../icons/Action-15.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action7"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/tokenprice`}>
            <div tooltip="Price" flow="down" className="icons">
              <img
                src="../icons/Action-16.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action8"
        // onClick={alert("delete")}
      />,
    ],
  },
];

export const Limitscolumns = [
  {
    field: "Objectcode",
    headerName: "Object code",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TokenSymbol",
    headerName: "Token Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionTypecode",
    headerName: "Transaction Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Units",
    headerName: "Units",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/modifylimit?id=${params.row.Objectcode}`}>
            {/* <Link to={`/webwallet/modifytoken`}> */}
            <div className="icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
        // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/webwallet/suspendlimit?id=${params.row.Objectcode}`}>
            <div className="icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
        // onClick={alert("delete")}
      />,
    ],
  },
];

// export const Payeecolumns = [
//   {
//     field: "ImageFN",
//     headerName: "Photo",
//     type: "string",
//     flex: 1,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "Payee",
//     headerName: "Payee",
//     type: "string",
//     flex: 1,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "ISOCountryname",
//     headerName: "Country",
//     type: "string",
//     flex: 1,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "TransactionDate",
//     headerName: "Last Transaction",
//     flex: 1,
//     type: "string",
//     align: "right",
//     headerAlign: "right",
//   },
//   // {
//   //     field: "Displayname",
//   //     headerName: "Token",
//   //     flex: 1,
//   //     type: "string",
//   //     align: "right",
//   //     headerAlign: "right",
//   // },
//   {
//     field: "Actions",
//     type: "actions",
//     headerName: "Actions",
//     flex: 1,
//     getActions: (params) => [
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/modifytoken?id=${params.row.TokenSymbol}`}>
//             {/* <Link to={`/webwallet/modifytoken`}> */}
//             <div className="icons">
//               <img
//                 src="../icons/Actions-edit.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Delete"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/suspendtoken`}>
//             <div tooltip="Suspend" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-trash.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action1"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/pause?id=${params.row.TokenSymbol}`}>
//             <div tooltip="Pause" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-10.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action2"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/unpause?id=${params.row.TokenSymbol}`}>
//             <div tooltip="Unpause" flow="down" className="icons">
//               <img
//                 src="../icons/Action-18.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Edit"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/mint?id=${params.row.PublicAddress}`}>
//             <div tooltip="Mint" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-4.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action3"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/burn?id=${params.row.PublicAddress}`}>
//             <div tooltip="Burn" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-5.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action4"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link
//             to={`/webwallet/redeem?id=${params.row.PublicAddress}&&code=${params.row.TokenSymbol}`}
//           >
//             <div tooltip="Redeem" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-6.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action5"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link
//             to={`/webwallet/swap?id=${params.row.PublicAddress}&&code=${params.row.TokenSymbol}`}
//           >
//             <div tooltip="Swap" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-7.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action6"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link
//             to={`/webwallet/tokensupply?id=${params.row.TokenSymbol}&&id=${params.row.PublicAddress}`}
//           >
//             <div tooltip="Supply" flow="down" className="icons">
//               <img
//                 src="../icons/Action-15.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action7"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/tokenprice`}>
//             <div tooltip="Price" flow="down" className="icons">
//               <img
//                 src="../icons/Action-16.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action8"
//         // onClick={alert("delete")}
//       />,
//     ],
//   },
// ];

// export const Limitscolumns = [
//   {
//     field: "Objectcode",
//     headerName: "Object code",
//     type: "string",
//     flex: 1,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "TokenSymbol",
//     headerName: "Token Symbol",
//     type: "string",
//     flex: 1,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "TransactionType",
//     headerName: "Transaction Type",
//     type: "string",
//     flex: 1,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "Units",
//     headerName: "Units",
//     flex: 1,
//     type: "string",
//     align: "right",
//     headerAlign: "right",
//   },
//   // {
//   //   field: "Displayname",
//   //   headerName: "Token",
//   //   flex: 1,
//   //   type: "string",
//   //   align: "right",
//   //   headerAlign: "right",
//   // },
//   {
//     field: "Actions",
//     type: "actions",
//     headerName: "Actions",
//     flex: 1,
//     getActions: (params) => [
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/modifylimit?id=${params.row.Objectcode}`}>
//             {/* <Link to={`/webwallet/modifytoken`}> */}
//             <div className="icons">
//               <img
//                 src="../icons/Actions-edit.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Delete"
//         // onClick={alert("delete")}
//       />,
//       <GridActionsCellItem
//         icon={
//           <Link to={`/webwallet/suspendlimit?id=${params.row.Objectcode}`}>
//             <div tooltip="Suspend" flow="down" className="icons">
//               <img
//                 src="../icons/Actions-trash.png"
//                 alt="Wallets-icon-img"
//                 size={10}
//               />
//             </div>
//           </Link>
//         }
//         label="Action1"
//       />,
//     ],
//   },
// ];
