//eslint-disable-next-line
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../../../action";
import { GetWalletQRCode } from "../../../../api/Wallets";

// import { Buffer } from "buffer";

// @ts-ignore
// window.Buffer = Buffer;
function PaymentDevice() {
  //eslint-disable-next-line
  const [data, setData] = useState();
  //eslint-disable-next-line
  const [image, setImage] = useState();
  console.log(
    "🚀 ~ file: Payment Device.js ~ line 14 ~ PaymentDevice ~ image",
    image
  );
  const dispatch = useDispatch();
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  let APPID = "";
  let APIKEY = "";

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      console.log("paymentdevise");
      const encryptedIssuer = await GetWalletQRCode(APPID, APIKEY);
      console.log(
        "🚀 ~ file: Payment Device.js ~ line 37 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );

      setImage(encryptedIssuer.data);

      // const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // console.log(
      //   "🚀 ~ file: Payment Device.js ~ line 34 ~ closeModal ~ devryptedIssuer",
      //   devryptedIssuer
      // );
    }
    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        closeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const initialValues = {};

  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log("values", values);
              // debugger;
              setData(values);
            }}
          >
            {(formik) => {
              //eslint-disable-next-line
              const { errors, touched } = formik;
              return (
                <div className="row">
                  <Form className="wallet-information-form payment-form">
                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      {/* <label>Payment Device</label> */}
                      {/* <div className="input-box mb-5">
                      <Field
                        type="text"
                        name="EncryptedDeviceID"
                        id="EncryptedDeviceID"
                        placeholder="Payment Device"
                        className="form-control"
                      />
                      <div
                        className={
                          errors.EncryptedDeviceID && touched.EncryptedDeviceID
                            ? "input-error"
                            : null
                        }
                      >
                        <ErrorMessage
                          name="EncryptedDeviceID"
                          component="span"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                    </div> */}
                    </div>
                    <div className=" mx-auto login-qr ">
                      <div className="">
                        <h1 className="md-6 mb-4 text-primary ">
                          To Link Your Mobile Device
                        </h1>
                        <h4>1. Log into your Mobile Wallet</h4>
                        <h4>2. Navigate to Scan</h4>
                        <h4>3. Scan the displayed QR Code</h4>
                      </div>

                      <img src={image} alt="imagess" />
                    </div>
                    {/* <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5 mt-md-5">
                    <button
                      type="button"
                      class="btn cancel-btn mb-3 mb-md-0 me-4"
                    >
                      CANCEL
                    </button>
                    <button
                      class="btn next-btn mb-3 mb-md-0"
                      type="submit"
                      onClick={async () => {
                        setPrivateModal(true);
                      }}
                    >
                      SUBMIT
                    </button>
                  </div> */}
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default PaymentDevice;
