const GlobalKyc = () => {
  return (
    <section className="two-column-block 2 block1">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="large-heading">
              Global <span>KYC Registry</span>
            </h1>
            <div className="small-divider" />
          </div>
          <div className="col-md-6 text-right">
            <p>
              “Governments which regulate or enforce the Global KYC Registry
              will be able to identify originators of all digital currency
              transactions regardless of how private and anonymous the DLT,
              including Bitcoin.” <br />
              <span className="text-red">
                - U.S. Government, Cybersecurity Expert
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default GlobalKyc;
