import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCountryCodeDropDown,
  getDropdownvalues,
} from "../../../../../api/dropdown";
import { GetGeo } from "../../../../../api/PayeesApi";
import { decrypytdata } from "../../../../../api/walletKey";

// import * as Yup from "yup";

function Originator({ nextstep, setReview }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [countryCode, setCountryCode] = useState([]);
  const [payeeType, setPayeeType] = useState([]);
  const [countryName, setCountryName] = useState("");
  const publicKeys = useSelector((state) => state.keyReducer);

  const initialValues = {
    payeeTypecode: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    countrycode: "",
    statecode: "",
    // payeecode: "Raj1",
    // payee: "Raj1",
    // accountClasscode: "WALLET",
  };
  let APPID = "";
  let APIKEY = "";

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      let data = {
        parentGeocode: countryName,
        json: true,
      };
      const encryptedIssuer = await GetGeo(APPID, APIKEY, data);
      console.log(
        "🚀 ~ file: Email.js ~ line 27 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Email.js ~ line 29 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      // setVdata({
      //   accountClasscode: "EMAIL",
      //   email: data.emailAddress
      //     ? data.emailAddress
      //     : "",
      // });
      // nextstep();
      // debugger;

      // let jsonData;
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // } else {
      //   dispatch(setPrivateId(""));
      //   // dispatch(setResult(jsonData))
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
    }
  };
  const fetchdropdwon = async () => {
    // let dropdownvalues = await GetGeoCodes("");
    // setDropdown(dropdownvalues);

    let countryType = await getCountryCodeDropDown("LKCountry");
    setCountryCode(countryType);

    let dialing = await getDropdownvalues("LKPayeeType");
    setPayeeType(dialing);
  };
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryName]);
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {isValidPublicAddress && (
        <div className="row">
          <div className="col-12 mt-5">
            <div className="originating-account link-wallet-box create-page ms-0">
              <div className="wallet-information-box  m-0">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  // validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setReview({
                      payeeTypecode: values.payeeTypecode
                        ? values.payeeTypecode
                        : "",
                      firstname: values.firstname ? values.firstname : "",
                      secondFirstname: values.secondFirstname
                        ? values.secondFirstname
                        : "",
                      lastname: values.lastname ? values.lastname : "",
                      secondLastname: values.secondLastname
                        ? values.secondLastname
                        : "",
                      countrycode: values.countrycode ? values.countrycode : "",
                      statecode: values.statecode ? values.statecode : "",
                      payeecode: values.firstname ? values.firstname : "",
                      payee: values.firstname ? values.firstname : "",
                      // accountClasscode: "WALLET",
                    });
                    nextstep();
                  }}
                >
                  {(formik) => {
                    const { errors, touched, setFieldValue } = formik;
                    return (
                      <Form className="wallet-information-form">
                        <h6 className="text-black">Payee Information</h6>
                        <p>
                          You must provide basic payee identification
                          information.
                        </p>
                        <div className="row">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <label>Payee Type</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="payeeTypecode"
                                  name="payeeTypecode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Country Code");
                                    setFieldValue(
                                      "payeeTypecode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  <option selected>Select Payee Type</option>
                                  {payeeType &&
                                    payeeType.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayName}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="payeeTypecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  // placeholder="Enter Account Number"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.firstname && touched.firstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="firstname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                                <label className="ps-0 pb-0">First Name</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="secondFirstname"
                                  id="secondFirstname"
                                  placeholder="Enter Account Number"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.secondFirstname &&
                                    touched.secondFirstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="secondFirstname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                                <label className="ps-0 pb-0">
                                  Second First Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  // placeholder="Enter Account Number"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.lastname && touched.lastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="lastname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                                <label className="ps-0 pb-0">Last Name</label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="secondLastname"
                                  id="secondLastname"
                                  // placeholder="Enter Account Number"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.secondLastname &&
                                    touched.secondLastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="secondLastname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                                <label className="ps-0 pb-0">
                                  Second Last Name
                                </label>
                                <i className="bi bi-check-lg"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <label>Country</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="countrycode"
                                  name="countrycode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Country Code");
                                    setFieldValue(
                                      "countrycode",
                                      e.target.value
                                    );
                                    setCountryName(e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {countryCode &&
                                    countryCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.countrycode}
                                            key={index}
                                          >
                                            {value.isoCountryname}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="countrycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="mb-5">
                              <label>State</label>
                              <div className="input-box form-floating">
                                <Field
                                  // as="select"
                                  type="text"
                                  className="form-select form-control"
                                  id="statecode"
                                  name="statecode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Country Code");
                                    setFieldValue("statecode", e.target.value);
                                  }}
                                  // onBlur={formik.handleBlur}
                                >
                                  {/* {countryCode &&
                                  countryCode.map((value, index) => {
                                    return (
                                      <>
                                        <option
                                          value={value.countrycode}
                                          key={index}
                                        >
                                          {value.isoCountryname}
                                        </option>
                                      </>
                                    );
                                  })} */}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="statecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                            onClick={() => nextstep()}
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Originator;
