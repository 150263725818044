import React from "react";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";

const demolanding = () => {
    return (
        <>
            <Header />
            <div className="demo-landing umbrella ">
                <div className="container">
                    <div className="digital-financial">
                        <h4>Digital Currency Public Monetary System</h4>
                        <h3>Crypto 2.0</h3>
                        <h2>Electronic Cash . Digital Cash</h2>
                        <p>Crypto 2.0 implements both electronic cash and digital cash engineered for central banks to deploy a resilient public monetary system using digital currency cryptography.</p>
                        <div className="d-inline-block d-md-flex justify-content-center mt-5">
                            <Link to="/subscription"> <button type="button" className="btn subscribr-btn mb-4 mb-md-0" > Downloads</button></Link>
                            <a href="https://www.youtube.com/watch?v=6b85sycl3fU&t=290s" target="_blank" rel="noreferrer" className="btn next-btn-video" >
                                <div>
                                    <i class="bi bi-play-circle-fill"></i>
                                </div>
                                <div className="text-strat">
                                    <h3>Video overview </h3>
                                    <p>Learn the future of Crypto 2.0</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="componentized-architecture">
                        <div className="row justify-content-evenly">
                            <div className="col-xxl-10">
                                <div className="componentized-architecture-head">
                                    <h6>Componentized Architecture</h6>
                                    <h3>Digital Currency Public Monetary System</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xxl-3">
                                <div className="componentized-architecture-box">
                                    <div class="card">
                                        <img src="../images/digital-mobile-applications.png" class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5>Digital Mobile Applications</h5>
                                        </div>
                                        <div class="list-group">
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/digital-banking-icon.png" alt="..." />
                                                </div>
                                                <h3>Digital Banking</h3>
                                            </div>
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/digital-trade-icon.png" alt="..." />
                                                </div>
                                                <h3>Digital Banking</h3>
                                            </div>
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/digital-payments-icons.png" alt="..." />
                                                </div>
                                                <h3>Digital Payments</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xxl-3 my-4 my-md-0 ">
                                <div className="componentized-architecture-box">
                                    <div class="card">
                                        <img src="../images/digital-mobile-applications.png" class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5>Foundational Technology</h5>
                                        </div>
                                        <div class="list-group">
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/digital-cash-signatures.png" alt="..." />
                                                </div>
                                                <h3>Digital Cash Signatures</h3>
                                            </div>
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/encrypted-security.png" alt="..." />
                                                </div>
                                                <h3>Encrypted Security</h3>
                                            </div>
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/wireless-messaging.png" alt="..." />
                                                </div>
                                                <h3>Wireless <br />Messaging
                                                    Protocol</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xxl-3 mt-0 mt-md-4 mt-lg-0">
                                <div className="componentized-architecture-box">
                                    <div class="card">
                                        <img src="../images/digital-mobile-applications.png" class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5>Open Standards</h5>
                                        </div>
                                        <div class="list-group">
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/digital-currency-specification.png" alt="..." />
                                                </div>
                                                <h3>Digital Currency
                                                    Specification</h3>
                                            </div>
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/payment-messaging.png" alt="..." />
                                                </div>
                                                <h3>Payment Messaging
                                                    Protocol</h3>
                                            </div>
                                            <div class="list-group-box">
                                                <div className="box-bg">
                                                    <img src="../images/tokenized-registry.png" alt="..." />
                                                </div>
                                                <h3>Tokenized FSP Registry</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="componentized-architecture mobile-device">
                        <div className="row justify-content-evenly">
                            <div className="col-xxl-10">
                                <div className="componentized-architecture-head">
                                    <h3>Digital Cash Signature Authentication Framework</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xxl-3">
                                <div className="mobile-device-box">
                                    <h3>Mobile Device A</h3>
                                    <hr />
                                    <div className="payment p-3">
                                        <div>
                                            <img src="../images/payment-systemcode.png" alt="icon" />
                                        </div>
                                        <div className="ms-md-3 ms-lg-0 ms-xl-3">
                                            <h6>Payment Systemcode ='abc'</h6>
                                            <p>Digital Signature Generator</p>
                                        </div>
                                    </div>
                                    <div className="payment p-3">
                                        <div>
                                            <img src="../images/payment-systemcode.png" alt="icon" />
                                        </div>
                                        <div className="ms-md-3 ms-lg-0 ms-xl-3">
                                            <h6>Payment Systemcode ='abc'</h6>
                                            <p>Digital Signature Generator</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xxl-3 order-md-3 order-lg-2 my-4 my-md-0 mt-0 mt-md-4 mt-lg-0">
                                <div className="digital-identifier">
                                    <span><i class="bi bi-check-lg"></i> Digital Ink Identifier</span>
                                    <span><i class="bi bi-check-lg"></i> Wallet Identifier (optional)</span>
                                    <span><i class="bi bi-check-lg"></i> Device Identifier (optional)</span>
                                    <div className="text-center">
                                        <img src="../images/arrow-landing.png" className="arrow-landing mt-4 mb-5" alt="arrow" />
                                        <img src="../images/arrow-landing.png" className="arrow-landing" alt="arrow" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xxl-3 order-md-2 ">
                                <div className="mobile-device-box">
                                    <h3>Mobile Device B</h3>
                                    <hr />
                                    <div className="payment p-3">
                                        <div>
                                            <img src="../images/security-typecode.png" alt="icon" />
                                        </div>
                                        <div className="ms-md-3 ms-lg-0 ms-xl-3">
                                            <h6>Security Typecode ='abc'</h6>
                                            <p>Digital Signature Validator</p>
                                        </div>
                                    </div>
                                    <div className="payment p-3">
                                        <div>
                                            <img src="../images/security-typecode.png" alt="icon" />
                                        </div>
                                        <div className="ms-md-3 ms-lg-0 ms-xl-3">
                                            <h6>Security Typecode ='xyz'</h6>
                                            <p>Digital Signature Validator</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="componentized-architecture payment-messaging">
                        <div className="row justify-content-evenly">
                            <div className="col-10">
                                <div className="componentized-architecture-head">
                                    <h6>Payment Messaging Protocol</h6>
                                    <h3>A Digital Currency Public Monetary System</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="payment-messaging-line">
                                    <div className="payment-messaging-box even-1">
                                    <div className="payment-messaging-rouned"></div>
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/generatep-payment-message.png" alt="icon" />
                                        </div>
                                        <h3>Generate Payment <br />Message</h3>
                                    </div>
                                    <div className="payment-messaging-box old-1">
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/digital-cash-signatures.png" alt="icon" />
                                        </div>
                                        <h3>Add Digital Cash <br />Signature</h3>
                                        <div className="payment-messaging-rouned old"></div>
                                    </div>
                                    <div className="payment-messaging-box even-2">
                                    <div className="payment-messaging-rouned"></div>
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/encrypted-security.png" alt="icon" />
                                        </div>
                                        <h3>Encrypt Payment <br />Message</h3>
                                    </div>
                                    <div className="payment-messaging-box old-2">
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/wireless-messaging.png" alt="icon" />
                                        </div>
                                        <h3>Send Over Wireless<br /> Protocol</h3>
                                        <div className="payment-messaging-rouned old"></div>
                                    </div>
                                    <div className="payment-messaging-box even-3">
                                    <div className="payment-messaging-rouned"></div>
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/receive-payment-message.png" alt="icon" />
                                        </div>
                                        <h3>Receive Payment <br /> Message</h3>
                                    </div>
                                    <div className="payment-messaging-box old-3">
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/decrypt-payment-message.png" alt="icon" />
                                        </div>
                                        <h3>Decrypt Payment <br /> Message</h3>
                                        <div className="payment-messaging-rouned old"></div>
                                    </div>
                                    <div className="payment-messaging-box even-4">
                                    <div className="payment-messaging-rouned"></div>
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/validate-digital-cash-signature.png" alt="icon" />
                                        </div>
                                        <h3>Validate Digital Cash <br /> Signature</h3>
                                    </div>
                                    <div className="payment-messaging-box old-4">
                                        <div className="payment-messaging-box-1">
                                            <img src="../images/generatep-payment-message.png" alt="icon" />
                                        </div>
                                        <h3>Process Payment <br/> Message</h3>
                                        <div className="payment-messaging-rouned old"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>

        </>
    );
};

export default demolanding;
