import React, { useEffect, useState } from "react";

import Email from "./LinkWallet/Email";
import Phone from "./LinkWallet/Phone";
import BankAccount from "./LinkWallet/Bank Acoount";
import Username from "./LinkWallet/Username";
// import CryptoCurrency from "./LinkWallet/CryptoCurrency";
import PaymentDevice from "./LinkWallet/Payment Device";
import { useSelector } from "react-redux";
function Attach() {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);

  const [review_data, setReviewData] = useState({});
  const [step, setstep] = useState(1);

  const setData = (data) => {
    // setValue(data);
    if (data.email) {
      setReviewData((prev) => {
        return {
          ...prev,
          email: data.email,
        };
      });
    }
    if (data.mobilenumber) {
      setReviewData((prev) => {
        return {
          ...prev,
          mobilenumber: data.mobilenumber,
        };
      });
    }
    if (data.Countrycode) {
      setReviewData((prev) => {
        return {
          ...prev,
          Countrycode: data.Countrycode,
        };
      });
    }
    if (data.dailingCode) {
      setReviewData((prev) => {
        return {
          ...prev,
          dailingCode: data.dailingCode,
        };
      });
    }
    if (data.accountName) {
      setReviewData((prev) => {
        return {
          ...prev,
          accountName: data.accountName,
        };
      });
    }
    if (data.accountTypecode) {
      setReviewData((prev) => {
        return {
          ...prev,
          accountTypecode: data.accountTypecode,
        };
      });
    }
    if (data.swiftcode) {
      setReviewData((prev) => {
        return {
          ...prev,
          swiftcode: data.swiftcode,
        };
      });
    }
    if (data.bankcode) {
      setReviewData((prev) => {
        return {
          ...prev,
          bankcode: data.bankcode,
        };
      });
    }
    if (data.accountNumber) {
      setReviewData((prev) => {
        return {
          ...prev,
          accountNumber: data.accountNumber,
        };
      });
    }
    if (data.accountClasscode) {
      setReviewData((prev) => {
        return {
          ...prev,
          accountClasscode: data.accountClasscode,
        };
      });
    }
    if (data.bankcodeType) {
      setReviewData((prev) => {
        return {
          ...prev,
          bankcodeType: data.bankcodeType,
        };
      });
    }
    if (data.username) {
      setReviewData((prev) => {
        return {
          ...prev,
          username: data.username,
        };
      });
    }
    if (data.deviceTypecode) {
      setReviewData((prev) => {
        return {
          ...prev,
          deviceTypecode: data.deviceTypecode,
        };
      });
    }
    if (data.publicAddress) {
      setReviewData((prev) => {
        return {
          ...prev,
          publicAddress: data.publicAddress,
        };
      });
    }
    if (data.publicAddress) {
      setReviewData((prev) => {
        return {
          ...prev,
          publicAddress: data.publicAddress,
        };
      });
    }
  };

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-username"
            role="tabpanel"
            aria-labelledby="pills-username-tab"
          >
            <Username setData={setData} />
          </div>
        );
      case 3:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-email"
            role="tabpanel"
            aria-labelledby="pills-email-tab"
          >
            <Email />
          </div>
        );
      case 4:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-phone"
            role="tabpanel"
            aria-labelledby="pills-phone-tab"
          >
            <Phone setData={setData} review_data={review_data} />
          </div>
        );
      case 2:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-account"
            role="tabpanel"
            aria-labelledby="pills-account-tab"
          >
            <BankAccount setData={setData} review_data={review_data} />
          </div>
        );
      case 5:
        return (
          <div
            className="tab-pane fade show active"
            id="pills-payment"
            role="tabpanel"
            aria-labelledby="pills-payment-tab"
          >
            <PaymentDevice setData={setData} review_data={review_data} />
          </div>
        );
      default:
        return false;
    }
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="link-wallet-box ms-lg-4 ms-xl-5 m-0">
            <div className="main-heading">
              <h3>Wallets </h3>
              <h6>Attach Wallet </h6>
              <p>
                This function enables a user to attach or link their bank
                account, email, or mobile phone to their wallet for sending and
                receiving digital currency.
              </p>
            </div>
            <div className="link-wallet-tabs">
              <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-username-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-username"
                    type="button"
                    role="tab"
                    aria-controls="pills-username"
                    aria-selected="false"
                    onClick={() => {
                      setstep(1);
                    }}
                  >
                    <img
                      src="../icons/link-username.png"
                      className="link-username-img none"
                      alt="link-username-img"
                    ></img>
                    <img
                      src="../icons/link-username-light.png"
                      className="link-username-img show"
                      alt="link-username-img"
                    ></img>
                    USERNAME
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-account-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-account"
                    type="button"
                    role="tab"
                    aria-controls="pills-account"
                    aria-selected="false"
                    onClick={() => {
                      setstep(2);
                    }}
                  >
                    <img
                      src="../icons/link-bank.png"
                      className="link-bank-img none"
                      alt="link-bank-img"
                    ></img>
                    <img
                      src="../icons/link-bank-light.png"
                      className="link-bank-img show"
                      alt="link-bank-img"
                    ></img>
                    BANK
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-email-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-email"
                    type="button"
                    role="tab"
                    aria-controls="pills-email"
                    aria-selected="false"
                    onClick={() => {
                      setstep(3);
                    }}
                  >
                    <img
                      src="../icons/link-email.png"
                      className="link-email-img none"
                      alt="link-email-img"
                    ></img>
                    <img
                      src="../icons/link-email-light.png"
                      className="link-email-img show"
                      alt="link-email-img"
                    ></img>
                    EMAIL
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-phone-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-phone"
                    type="button"
                    role="tab"
                    aria-controls="pills-phone"
                    aria-selected="false"
                    onClick={() => {
                      setstep(4);
                    }}
                  >
                    <img
                      src="../icons/link-phone.png"
                      className="link-phone-img none"
                      alt="link-phone-img"
                    ></img>
                    <img
                      src="../icons/link-phone-light.png"
                      className="link-phone-img show"
                      alt="link-phone-img"
                    ></img>
                    MOBILE
                  </button>
                </li>

                {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-cryptocurrency-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-cryptocurrency"
                  type="button"
                  role="tab"
                  aria-controls="pills-cryptocurrency"
                  aria-selected="false"
                >
                  <img
                    src="../icons/link-cryptocurrency.png"
                    className="link-cryptocurrency-img none"
                    alt="link-cryptocurrency-img"
                  ></img>
                  <img
                    src="../icons/link-cryptocurrency-light.png"
                    className="link-cryptocurrency-img show"
                    alt="link-cryptocurrency-img"
                  ></img>
                  Cryptocurrency
                </button>
              </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link me-0"
                    id="pills-payment-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-payment"
                    type="button"
                    role="tab"
                    aria-controls="pills-payment"
                    aria-selected="false"
                    onClick={() => {
                      setstep(5);
                    }}
                  >
                    <img
                      src="../icons/link-payment.png"
                      className="link-payment-img none"
                      alt="link-payment-img"
                    ></img>
                    <img
                      src="../icons/link-payment-light.png"
                      className="link-payment-img show"
                      alt="link-payment-img"
                    ></img>
                    DEVICE
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="home">
                  {renderstep(step)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Attach;
