//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function NeutralMonetaryUnit() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/neutral-monetary-unit.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Neutral Monetary Unit (UMU)</p>
                            <h1>Mitigate FX Rate <br className="d-none d-md-block" /> Fluctuations with <br className="d-none d-md-block" /> NMUs</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NeutralMonetaryUnit;