import { Form, Formik } from "formik";
import React, {  useState } from "react";
import { useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { uploadPhotoAPI } from "../../../api/Wallets";
import { success } from "../../../Components/alert";
import PrivateModal from "../../../Components/PrivateModal";

function AttachPhoto() {
  const publicKeys = useSelector((state) => state.keyReducer);
  const [privateModal, setPrivateModal] = useState(false);
  const [file, setFiles] = useState();

  const initialValues = {
    File: file,
    // PublicAddress:"ee2d5999-460b-4db3-8e5b-855bf3d355be",
  };

  const fileSelectedHandler = (event) => {
    setFiles(event.target.files[0]);
  };

  let APPID = "";
  let APIKEY = "";
  const AttachPhotoData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const formData =new FormData()
      formData.append("File" ,file)
      // formData.append("PublicAddress" ,"ee2d5999-460b-4db3-8e5b-855bf3d355be")
      // const data  = {
      //   imageFN: "US/1110000/f696f7f7-6e2b-4ebd-8dd7-11fe87750d35_1651382.jpg",      }
      // const encryptedIssuer = await AttachPhotoAPI(APPID, APIKEY, data);
      const encryptedIssuer = await uploadPhotoAPI(APPID, APIKEY, formData);
      // const encryptedIssuer = await userUploadPhotoAPI(APPID, APIKEY, formData);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      const jsonData = JSON.parse(devryptedIssuer);
      console.log("🚀 ~ file: AttachPhoto.js ~ line 45 ~ AttachPhotoData ~ jsonData", jsonData)
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      setPrivateModal(false);
    }
  };
//   useEffect(() => {
// console.log("hello")    // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [publicKeys]);
  return (
    <>
      {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={AttachPhotoData} />
      )}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Wallet</h3>
              <h6>Attach Photo</h6>
              <p>
                This function enables a user to attach a photo to their wallet.
              </p>
            </div>
            <div className="wallet-information-box m-0">
              <div className="suspend-text wallet-information-form">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    setFiles(values.target.files[0]);
                    console.log(
                      "🚀 ~ file: AttachPhoto.js ~ line 28 ~ AttachPhoto ~ values",
                      values
                    );
                  }}
                >
                  {(formik) => {
                    return (
                      <Form className="row">
                        <div className="suspend-text">
                          <div className="">
                              <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                <div className="input-box mb-5">
                                  <div className="input-group custom-file-button">
                                    <input
                                      type="file"
                                      name="imageFN"
                                      className="form-control"
                                      id="imageFN"
                                      onChange={fileSelectedHandler}
                                    />
                                    <label
                                      className="input-group-text"
                                      // for="imageFN"
                                    >
                                      BROWSE
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex me-0 me-md-4 me-xxl-5">
                                <button
                                  type="button"
                                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn mb-3 mb-md-0"
                                  onClick={() => {
                                    setPrivateModal(true);
                                  }}
                                >
                                  SUBMIT
                                </button>
                              </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttachPhoto;
