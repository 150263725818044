import React from 'react'

const Tokens = () => {
  return (
      <>
          <div className="documentation-accordion">
              <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Issuer">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#issuerOne" aria-expanded="true" aria-controls="issuerOne">
                              Create Token
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="issuerOne" className="accordion-collapse collapse show" aria-labelledby="Issuer" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Create Token	Enables the creation of a digital currency on the Unicoin network.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Modify">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyTwo" aria-expanded="false" aria-controls="ModifyTwo">
                              Modify Token
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="ModifyTwo" className="accordion-collapse collapse  show" aria-labelledby="Modify" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Modify Token	Allows the updating of token descriptive information.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Suspend">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#SuspendThree" aria-expanded="false" aria-controls="SuspendThree">
                              Suspend Token
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="SuspendThree" className="accordion-collapse collapse show" aria-labelledby="Suspend" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Suspend Token	Suspends the sending and receiving of a digital currency.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateOne" aria-expanded="false" aria-controls="CreateOne">
                              Token Supply
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateOne" className="accordion-collapse collapse show" aria-labelledby="BranchOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Token Supply	Provides all the Tokenomics of a digital currency including the current market price.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateThree" aria-expanded="false" aria-controls="CreateThree">
                              Pause
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateThree" className="accordion-collapse collapse show" aria-labelledby="BranchThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Pause	Temporarily suspend the sending and receiving of a digital currency.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateAgentOne" aria-expanded="false" aria-controls="CreateAgentOne">
                              Unpause
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateAgentOne" className="accordion-collapse collapse show" aria-labelledby="AgentOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Unpause	Reactivates the sending and receiving of a digital currency.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyAgentOne" aria-expanded="false" aria-controls="ModifyAgentOne">
                              Mint
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="ModifyAgentOne" className="accordion-collapse collapse show" aria-labelledby="AgentTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Mint	Increases the token supply by issuing new digital currency into circulation.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#SuspendAgentOne" aria-expanded="false" aria-controls="SuspendAgentOne">
                              Burn
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="SuspendAgentOne" className="accordion-collapse collapse show" aria-labelledby="AgentThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Burn	Reduces the token supply by removing digital currency from circulation.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#SuspendAgentFour" aria-expanded="false" aria-controls="SuspendAgentFour">
                              Redeem
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="SuspendAgentFour" className="accordion-collapse collapse show" aria-labelledby="AgentFour" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Redeem	Liquidates a digital currency to its fiat equivalent monetary value.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CreateTwo" aria-expanded="false" aria-controls="CreateTwo">
                              Swap
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="CreateTwo" className="accordion-collapse collapse show" aria-labelledby="BranchTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Swap	Converts one digital currency to another digital currency at the current market value exchange rate.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
              </div>
          </div>
      </>
  )
}

export default Tokens