import React from "react";
function StoreValue() {
  return (
      <>
          <div className="unicoin-store">
              <div className="container">
                  <div className="unicoin-store-heading text-center">
                      <h3>Unicoin as Store of Value</h3>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-md-6">
                          <div className="unicoin-store-text">
                              <p>A store of value is an asset that maintains its value over time without depreciating. Gold and precious metals are considered store of value because they generally preserve their value over time.</p>
                              <p>When Bitcoin first emerged as a cryptocurrency, it was far less volatile than in today’s market and was considered a digital store of value. However, as advanced economies have adopted and regulated Bitcoin as an alternative investment className, it has morphed more into a speculative asset className.</p>
                              <p>In financial markets, there are three broad classes of store of value financial instruments: Fixed Store of Value, Income-producing Store of Value, and Speculative Store of Value.</p>
                              <p className="mb-0">Bitcoin is a speculative store of value cryptocurrency. The Unicoin network offers all three classes of store of value in one multi-currency wallet. Hence, trading between different store of value digital assets classes on the Unicoin network is faster, cheaper, and more efficient. </p>
                          </div>
                      </div>
                      <div className="col-md-6 text-center">
                          <img src="images/SOV.png" className="sov-img" alt="sov-img" />
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
}

export default StoreValue;
