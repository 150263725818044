import React, { useEffect } from "react";
import { useState } from "react";
import ModifyBranch from "./ModifyBranch";
import ReviewModifyBranch from "./ReviewModifyBranch";
function ModifyBranchs() {
  const [step, setstep] = useState(1);
  useEffect(() => {
    setstep(1);
  }, []);

  const [data,setdata] = useState();
  console.log("🚀 ~ file: index.js ~ line 12 ~ ModifyBranchs ~ data", data)

  

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return <ModifyBranch setdata={setdata} nextstep={nextstep} previous={previous} />;
      case 2:
        return <ReviewModifyBranch nextstep={nextstep} data={data} previous={previous} />;
      default:
        return false;
    }
  };
  const nextstep = () => {
    if (step < 2) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };
  return <>{renderstep(step)}</>;
}

export default ModifyBranchs;
