//import Footer from "../../Components/Footer";
//import Header from "../../Components/Header";
import React from 'react'
//import { Link } from "react-router-dom";

function globalekyc() {
    return (
        <>
            <div className="global network monetary-authorities crypto-exchanges">
                <div className="container-fluid p-0">
                    <div className="global-img">
                        <img src="../images/Global-E-KYC.png" className="img-fluid" alt="dl-beatsnoop" />
                        <div class="global-text">
                            <p>Global E-KYC</p>
                            <h1>Enhance your KYC with <br className="d-none d-md-block" /> Global Customer<br className="d-none d-md-block" /> Identifiers</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default globalekyc;