import React from "react";

function Beneficiary({ transferData, setdata, previous, nextstep }) {
    console.log(
        "🚀 ~ file: Beneficiary.js ~ line 5 ~ Beneficiary ~ setdata",
        transferData
    );

    return (
        <>
            <div className="row mt-5">
                <div className="col-xxl-10">
                    <div className="wallet-balances-box">
                        <div className="d-md-flex justify-content-end px-4">
                            <div className="">
                                <div className="line-input input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-search"></i></span>
                                    <input type="text" className="form-control" placeholder="search" />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive p-3 p-md-4">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Ledger</th>
                                        <th>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Ledger Class</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </th>
                                        <th>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Ledger Type</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Bank Name </td>
                                        <td>Bank Class</td>
                                        <td>Bank Type</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Name </td>
                                        <td>Bank Class</td>
                                        <td>Bank Type</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Name </td>
                                        <td>Bank Class</td>
                                        <td>Bank Type</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Name </td>
                                        <td>Bank Class</td>
                                        <td>Bank Type</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-10">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                    <button
                        type="button"
                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                        onClick={previous}
                    >
                        PREVIOUS
                    </button>
                    <button
                        type="button"
                        className="btn next-btn mb-3 mb-md-0"
                        onClick={nextstep}
                    // onClick={() => {
                    //   setPrivateModal(true);
                    // }}
                    >
                        NEXT
                    </button>
                </div>
            </div>
        </>
    );
}
export default Beneficiary;
