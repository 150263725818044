import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrivateId, showLoader } from "../../../action";
import { revokeAuthorization } from "../../../api/Authorization";
import { getDropdownvalues } from "../../../api/dropdown";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import PrivateModal from "../../../Components/PrivateModal";
function RevokeAuthorization() {
    const [data, setData] = useState();
    const [authType, setauthType] = useState();
    const [publicAddress, setPublicAddress] = useState();
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const publicKeys = useSelector((state) => state.keyReducer);
    const [privateModal, setPrivateModal] = useState(false);
    let APPID = "";
    let APIKEY = "";
    const dispatch = useDispatch();

    const closeModal = async () => {
        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
            setPublicAddress(APPID);
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }
        if (APPID && APIKEY) {
            dispatch(showLoader(true));
            const encryptedIssuer = await revokeAuthorization(APPID, APIKEY, data);
            dispatch(showLoader(false));
            const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

            const jsonData = JSON.parse(devryptedIssuer);

            if (jsonData.returnMessage) {
                success(jsonData.returnMessage);
            }
            dispatch(setPrivateId(""));
        }
        setPrivateModal(false);
    };
    const fetchdropdwon = async () => {
        let authType = await getDropdownvalues("LKAuthorizationType");
        setauthType(authType);
    };
    useEffect(() => {
        closeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);

    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);

    const initialValues = {
        authorizationTypecode: "",
        targetPublicAddress: "",
        sourcePublicAddress: publicAddress,
    };

    return (
        <>
            {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
            )}
            {isValidPublicAddress && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        setData(values);
                    }}
                >
                    {(formik) => {
                        const { errors, touched, setFieldValue } = formik;
                        return (
                            <div className="col-md-9 col-lg-9 col-xxl-10">
                                <div className="row  ms-lg-4 ms-xl-5">
                                    <div className="col-12">
                                        <div className="main-heading">
                                            <h3> Authorizations</h3>
                                            <h6>Revoke Authorization</h6>
                                            <p>
                                                This function revokes the authorization of a prior Grant Authorization.

                                            </p>
                                        </div>
                                        <Form className="wallet-information-form m-0">
                                            <div className="wallet-information-box m-0">
                                                <div className="suspend-text wallet-information-form">
                                                    <div class="row">
                                                        <div class="col-sm-6 col-xl-5 col-xxl-4 mb-4 mb-xl-10">
                                                            <div class="mb-5">
                                                                <div class="input-box">
                                                                    <div className="input-box form-floating mb-5">
                                                                        <Field
                                                                            type="text"
                                                                            name="targetPublicAddress"
                                                                            id="targetPublicAddress"
                                                                            placeholder="Authorized Public Wallet Address"
                                                                            className="form-control"
                                                                        />
                                                                        <label className="ps-0 pb-0">Authorized Public Wallet Address</label>

                                                                        <div
                                                                            className={
                                                                                errors.targetPublicAddress &&
                                                                                    touched.targetPublicAddress
                                                                                    ? "input-error"
                                                                                    : null
                                                                            }
                                                                        >
                                                                            <ErrorMessage
                                                                                name="targetPublicAddress"
                                                                                component="span"
                                                                                className="error"
                                                                                style={{ color: "red" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-xl-8 col-xxl-4 mb-4 mb-xl-0">
                                                            <div class="mb-5">
                                                                <div class="input-box">
                                                                    <div className="input-box form-floating mb-5">
                                                                        <Field
                                                                            disabled={true}
                                                                            type="text"
                                                                            value={publicAddress}
                                                                            name="sourcePublicAddress"
                                                                            id="sourcePublicAddress"
                                                                            placeholder="Granting Public Wallet Address"
                                                                            className="form-control"
                                                                        />
                                                                        <label className="ps-0 pb-0">Granting Public Wallet Address</label>

                                                                        <div
                                                                            className={
                                                                                errors.sourcePublicAddress &&
                                                                                    touched.sourcePublicAddress
                                                                                    ? "input-error"
                                                                                    : null
                                                                            }
                                                                        >
                                                                            <ErrorMessage
                                                                                name="sourcePublicAddress"
                                                                                component="span"
                                                                                className="error"
                                                                                style={{ color: "red" }}
                                                                            />
                                                                        </div>
                                                                    </div>{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xl-8 col-xxl-4 mb-4 mb-xl-0">
                                                        <div className="mb-2">
                                                            <label>Authorization Type</label>
                                                            <Field
                                                                as="select"
                                                                className="form-select form-control"
                                                                id="authorizationTypecode"
                                                                name="authorizationTypecode"
                                                                onChange={(e) => {

                                                                    setFieldValue(
                                                                        "authorizationTypecode",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                {authType &&
                                                                    authType.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayName}
                                                                                </option>
                                                                            </>
                                                                        );
                                                                    })}
                                                            </Field>
                                                            <div>
                                                                <ErrorMessage
                                                                    name="authorizationTypecode"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-8">
                                                            <div class="d-flex justify-content-end me-0 me-md-4">
                                                                <button
                                                                    type="button"
                                                                    class="btn cancel-btn mb-3 mb-md-0 me-4"
                                                                >
                                                                    CANCEL
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    class="btn next-btn mb-3 mb-md-0"
                                                                    onClick={async () => {
                                                                        setPrivateModal(true);

                                                                    }}
                                                                >
                                                                    REVOKE
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            )}
        </>
    );
}

export default RevokeAuthorization;
