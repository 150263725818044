import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import { issuresSuspend } from "../../../api/Issures";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import PrivateModal from "../../../Components/PrivateModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from "react-router-dom";


function SanctionsSuspend() {
    const publicKeys = useSelector((state) => state.keyReducer);
    const [privateModal, setPrivateModal] = useState(false);
    const [data, setdata] = useState("")
    const location = useLocation();
    const initialValues = {
        sourcePublicAddress: location.search.replace("?id=", "")
    };
    let APPID = "";
    let APIKEY = "";

    const suspendData = async () => {

        if (publicKeys.publicKey) {
            APPID = publicKeys.publicKey.publicAddress;
        }
        if (publicKeys.privateKey) {
            APIKEY = publicKeys.privateKey.PrivateWalletAddress;
        }
        if (APPID && APIKEY && data) {

            // debugger;
            const encryptedIssuer = await issuresSuspend(APPID, APIKEY, data);
            const devryptedIssuer = await decrypytdata(
                encryptedIssuer?.data,
                APIKEY
            );

            const jsonData = JSON.parse(devryptedIssuer);
            if (jsonData.returnMessage) {
                success(jsonData.returnMessage);
            }
            // debugger;
        }
        setPrivateModal(false);
    };
    // const suspendData=()=>{
    //     console.log("test");
    //     issuresSuspend(publickey, privateKey)
    // }
    useEffect(() => {
        suspendData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);
    return (
        <>
            {/* <Header /> */} {privateModal && (
                <PrivateModal
                    modalpriate={privateModal} closeModal={suspendData} />
            )}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row ms-lg-4 ms-xl-5  m-0">
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
                    <div className="col-12">
                        <div className="main-heading">
                            <h3>Sanctions</h3>
                            <h6>
                                Suspend
                            </h6>
                            <p>This function suspends an individual customer from transacting on the Unicoin network.</p>
                        </div>
                        <div className="wallet-information-box ms-0">
                            <div className="suspend-text wallet-information-form">
                                <Formik
                                    initialValues={initialValues}
                                    // validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        console.log("values", values)
                                        // nextstep();
                                        // debugger;
                                        // CreateIssuersApi(values);
                                        setdata(values);
                                    }}
                                >
                                    {(formik) => {
                                        const { errors, touched } = formik;
                                        return (
                                            <div className="">
                                                <Form className="wallet-information-form">
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        {/* <label>Issuer Code</label> */}
                                                        <div className="input-box mb-5">
                                                            <Field
                                                                type="text"
                                                                name="sourcePublicAddress"
                                                                id="sourcePublicAddress"
                                                                placeholder="Public Sanctions Address"
                                                                className="form-control"
                                                            />
                                                            <div
                                                                className={
                                                                    errors.sourcePublicAddress && touched.sourcePublicAddress
                                                                        ? "input-error"
                                                                        : null
                                                                }
                                                            >
                                                                <ErrorMessage
                                                                    name="sourcePublicAddress"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex me-0 me-md-4 me-xxl-5">
                                                        <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                                        <button
                                                            onClick={() => {
                                                                suspendData();
                                                                setPrivateModal(true);
                                                            }}
                                                            type="submit"
                                                            class="btn next-btn mb-3 mb-md-0"
                                                        >
                                                            SUSPEND
                                                        </button>
                                                    </div>
                                                </Form>
                                            </div>
                                        );
                                    }}
                                </Formik>
                                {/* <div class="row">
                                    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                        <div class="mb-5">
                                            <div class="input-box">
                                                <input type="text" placeholder="Public Wallet Address" class="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex me-0 me-md-4 me-xxl-5">
                                    <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                    <button onClick={() => { suspendData(); setPrivateModal(true); }} type="submit" class="btn next-btn mb-3 mb-md-0">SUSPEND</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default SanctionsSuspend;
