import React, { useEffect } from "react";
import { useState } from "react";
import Originator from "./ModifyAgent";
import Review from "./ReviewAgent";
function ModifyAgent() {
  const [step, setstep] = useState(1);
  useEffect(() => {
    setstep(1);
  }, []);

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return <Originator nextstep={nextstep} previous={previous} />;
      case 2:
        return <Review nextstep={nextstep} previous={previous} />;
      default:
        return false;
    }
  };
  const nextstep = () => {
    if (step < 2) {
      setstep(step + 1);
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };
  return <>{renderstep(step)}</>;
}

export default ModifyAgent;
