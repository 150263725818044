import React, { useState } from "react";
import Money from "./Money";

import ReviewIssuer from "./ReviewIssuer";
import ModifyIssuer from "./ModifyIssuer";

function Unipay() {
  const [step, setstep] = useState(1);
  const [token, setToken] = useState(1);
  // const [tokenData, setTokenData] = useState(1);
  const [value, setValue] = useState();
  console.log("🚀 ~ file: IndexCrtIssuer.js ~ line 14 ~ Unipay ~ value", value);

  // const setdata = (data) => {
  //   // setToken(data);
  //   setValue(data);
  // };
  const cleandata = () => {
    setToken(1);
  };

  const nextstep = () => {
    if (step < 4) {
      setstep(step + 1);
    }
  };

  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <ModifyIssuer
            nextstep={nextstep}
            previous={previous}
            setValue={setValue}
            cleandata={cleandata}
          />
        );
      case 2:
        return (
          <Money
            nextstep={nextstep}
            previous={previous}
            cleandata={cleandata}
            setToken={setToken}
          />
        );
      case 3:
        return (
          <ReviewIssuer
            nextstep={nextstep}
            previous={previous}
            cleandata={cleandata}
            token={token}
            value={value}
          />
        );
      default:
        return false;
    }
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };

  return (
    <>
      <div className="col-md-9 col-lg-9">
        <div className="container-fluid p-0 ">
          <div className="row ms-lg-4 ms-xl-5 ">
            <div className="col-12">
              <div className="deposit-funds-tabs m-0">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    className={
                      step > 1
                        ? "nav-item originator-mr-u originator-mr-t disableElement"
                        : "nav-item originator-mr-u originator-mr-t"
                    }
                  >
                    <button
                      className={step === 1 ? "nav-link active" : "nav-link"}
                      id="profile-tab"
                      type="button"
                    >
                      <div className="beneficiary">
                        <h3>1</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Issuer </h6>
                    </button>
                  </li>
                  <li
                    className={
                      step > 2
                        ? "nav-item money-mr-t disableElement"
                        : "nav-item money-mr-t "
                    }
                  >
                    <button
                      className={step === 2 ? "nav-link active" : "nav-link"}
                      id="contact-tab"
                      type="button"
                    >
                      <div className="money">
                        <h3>2</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Currencies</h6>
                    </button>
                  </li>
                  <li
                    className={
                      step > 3 ? "nav-item  disableElement" : "nav-item  "
                    }
                  >
                    <button
                      className={step === 3 ? "nav-link active" : "nav-link"}
                      id="contact-tab"
                      type="button"
                    >
                      <div className="money mony-last">
                        <h3>3</h3>
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <h6>Review</h6>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home">
                    {renderstep(step)}
                  </div>
                </div>
              </div>
              {/* 
            <div className="col-xxl-11">
              <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                <button
                  type="button"
                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                  onClick={previous}
                >
                  PREVIOUS
                </button>
                <button
                  type="submit"
                  className="btn next-btn mb-3 mb-md-0"
                  onClick={nextstep}
                >
                  Next
                </button>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Unipay;
