import { ADMIN_URL, WEB_WALLET_URL } from "../Constatnts/config.js";
import http, { httpAdmin } from "./httpService.js";
import { hmacencrypt } from "./walletKey";
export async function CreatePayeeApi(publickey, privateKey, issuerData) {
  const getToken = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/CreatePayee",
    issuerData,
    // createIssusersDetails,
    publickey,
    privateKey
  );

  const response = await http.post(
    WEB_WALLET_URL + "/money/CreatePayee",
    issuerData,
    // createIssusersDetails,
    {
      headers: { Authorization: getToken },
    }
  );

  return response;
}

export async function getPayee(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // payeecode: "0",
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/GetPayees",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/GetPayees",
    values,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}

export async function modifyPayee(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/ModifyPayee",
    issuerData,

    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/ModifyPayee",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function SuspendPayee(publickey, privateKey, issuresSuspendData) {
  const modifyIssusersDetails = {
    sourcePublicAddress: publickey,
    issuresSuspendData,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/SuspendPayee",
    modifyIssusersDetails,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/money/SuspendPayee",
    modifyIssusersDetails,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function getBanks(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // payeecode: "0",
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/common/GetBanks",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/common/GetBanks",
    values,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response;
}

export async function ValidateWalletLink(publickey, privateKey, data) {
  let values = {
    // sourcePublicAddress: publickey,
    ...data,
  };

  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/security/ValidateWalletLink",
    values,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/security/ValidateWalletLink",
    values,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  console.log(
    "🚀 ~ file: PayeesApi.js ~ line 153 ~ ValidateWalletLink ~ response",
    response
  );
  return response;
}

export async function GetGeo(publickey, privateKey, data) {
  const token = await hmacencrypt(
    "POST",
    ADMIN_URL + "/common/GetGeo",
    data,
    publickey,
    privateKey
  );
  const response = await httpAdmin.post(
    "/common/GetGeo",
    data,
    // { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  console.log(
    "🚀 ~ file: PayeesApi.js ~ line 153 ~ ValidateWalletLink ~ response",
    response
  );
  return response;
}
