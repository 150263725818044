import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getDropdownvalues } from "../../../../../api/dropdown";
import { ValidateWalletLink } from "../../../../../api/PayeesApi";
import * as Yup from "yup";
import { decrypytdata } from "../../../../../api/walletKey";

import Bank from "../Bank";


function BankAccount({ setVdata, nextstep }) {
  // const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [Acctype, setAccType] = useState("");
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [sourceData, setsourceData] = useState("");
  console.log(
    "🚀 ~ file: Bank Acoount.js ~ line 21 ~ BankAccount ~ sourceData",
    sourceData
  );
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  let APPID = "";
  let APIKEY = "";
  const [data, setData] = useState("");
  console.log("🚀 ~ file: Bank Acoount.js ~ line 27 ~ BankAccount ~ data",typeof data.bankAccountNumber)
  
  const initialValues = {
    walletLinkTypecode: "BANK",
    swiftcode: sourceData.SWIFTCODE ? sourceData.SWIFTCODE : " ",
    bankAccountNumber: data.bankAccountNumber ? String(data.bankAccountNumber) : " ",
    accuityID: sourceData.AccuityID ? sourceData.AccuityID : " ",
    accountTypecode: data.accountTypecode ? data.accountTypecode : " ",
  };
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: Email.js ~ line 26 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      console.log("Bank Account");
      const encryptedIssuer = await ValidateWalletLink(
        APPID,
        APIKEY,
        initialValues
      );
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 42 ~ closeModal ~ encryptedIssuer",
        encryptedIssuer
      );
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      console.log(
        "🚀 ~ file: Bank Acoount.js ~ line 44 ~ closeModal ~ devryptedIssuer",
        devryptedIssuer
      );
      setVdata(() => {
        return {
          accountClasscode: "BANK",
          swiftcode: sourceData.SWIFTCODE ? sourceData.SWIFTCODE : " ",
          bankAccountNumber: data.bankAccountNumber
            ? data.bankAccountNumber
            : 0,
          accuityID: sourceData.AccuityID ? sourceData.AccuityID : " ",
          accountTypecode: data.accountTypecode ? data.accountTypecode : " ",
        };
      });
      nextstep();
      // let jsonData;
      // if (devryptedIssuer) {
      //   jsonData = JSON.parse(devryptedIssuer);
      //   dispatch(setPrivateId(""));
      //   dispatch(setResult(jsonData));
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // } else {
      //   dispatch(setPrivateId(""));
      //   // dispatch(setResult(jsonData))
      //   dispatch(setResponseAPI(encryptedIssuer));
      //   navigate("/result");
      // }
    }
  };
  const fetchdropdwon = async () => {
    let actype = await getDropdownvalues("LKAccountType");
    console.log(
      "🚀 ~ file: Bank Acoount.js ~ line 59 ~ fetchdropdwon ~ actype",
      actype
    );

    setAccType(actype);

    // let dialing = await getDailingDropDown("GetDialingcodes");
    // setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  // const onRowsSelectionHandler = (data) => {
  //   setGrid(data);
  // };

  const validationSchema = Yup.object({
    // accountTypecode: Yup.string().required("Account Type is required!"),

    bankAccountNumber: Yup.number()
     
      .max(9999999,"Must be 7 Digit")
      .min(1111111, "Must be 7 Digit")
      .typeError("you must specify a number")
      .required("Account Number is required!"),
  });

  return (
    <>
      {isValidPublicAddress && (
        <div className="suspend-text wallet-information-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              closeModal();
            }}
          >
            {(formik) => {
              const { errors, touched, setFieldValue } = formik;
              return (
                <Form className="wallet-information-form">
                  <div>
                    {/* <NewGrid
                      row={rows}
                      coloums={Paycolumn}
                      onRowsSelectionHandler={onRowsSelectionHandler}
                    /> */}
                    <Bank setsourceData={setsourceData} />
                  </div>
                  <div className="row mt-5  mb-xl-5">
                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Account Number</label>
                      <div className="input-box mb-5">
                        <Field
                          type="number"
                          name="bankAccountNumber"
                          id="bankAccountNumber"
                          className="form-control"
                          onChange={(e) => {
                            setFieldValue("bankAccountNumber", e.target.value);
                            setData((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            });
                          }}
                          // onBlur={formik.handleBlur}
                          // value={formik.bankAccountNumber}
                        />
                        <div
                          className={
                            errors.bankAccountNumber &&
                            touched.bankAccountNumber
                              ? "input-error"
                              : null
                          }
                        >
                          <ErrorMessage
                            name="bankAccountNumber"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <label>Account Type</label>
                      <div className="input-box ">
                        <Field
                          as="select"
                          name="accountTypecode"
                          id="accountTypecode"
                          className="form-control"
                          onChange={(e) => {
                            // console.log(e.target.value, "Country Code");
                            setFieldValue("accountTypecode", e.target.value);
                            // setVdata((prev) => {
                            //   return {
                            //     ...prev,
                            //     [e.target.name]: e.target.value,
                            //   };
                            // });
                            setData((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            });
                          }}
                          onBlur={formik.handleBlur}
                        >
                          <option selected>Select Account Type</option>
                          {Acctype &&
                            Acctype.map((value, index) => {
                              console.log(
                                "🚀 ~ file: Bank Acoount.js ~ line 187 ~ Acctype.map ~ value",
                                value
                              );

                              return (
                                <>
                                
                                  <option value={value.keycode} key={index}>
                                    {value.displayName}
                                  </option>
                                </>
                              );
                            })}
                        </Field>
                        <div className={
                            errors.accountTypecode &&
                            touched.accountTypecode
                              ? "input-error"
                              : null
                          }>
                          <ErrorMessage
                            name="accountTypecode"
                            component="span"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                      <div className="mb-5">
                        <button type="submit" className="btn next-btn">
                          Validate
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

export default BankAccount;
