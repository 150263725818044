//import { ErrorMessage, Field, Form, Formik } from "formik";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
import { GetTokenSymbols } from "../../../api/LedgerAPI";
import { getTokenBySymbol, TokenPriceAPI } from "../../../api/Token";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";

function TokenSupply() {
  const publicKeys = useSelector((state) => state.keyReducer);
  //   const [privateModal, setPrivateModal] = useState(false);
    const [data, setdata] = useState("");
    const [TokenSymbol, setTokenSymbol] = useState([]);
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    // const [tokenPublicAddress, setTokenPublicAddress] = useState();
    const [tokensData, settokensData] = useState([]);
    const [searchParams] = useSearchParams();
      
    const fetchdropdwon = async () => {
      let tokenSymboldropdownvalues = await GetTokenSymbols("GetTokenSymbols");
      setTokenSymbol(tokenSymboldropdownvalues);
    };
  
    const initialValues = {
      tokenSymbol: tokensData.TokenSymbol ? tokensData.TokenSymbol : "",
      sourcePublicAddress: tokensData.PublicAddress ? tokensData.PublicAddress : "",
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    let APPID = "";
    let APIKEY = "";
  
    const TokenPriceData = async () => {
      if (publicKeys.publicKey) {
        APPID = publicKeys.publicKey.publicAddress;
      }
      if (publicKeys.privateKey) {
        APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      }
      if (APPID && APIKEY) {
        dispatch(showLoader(true));
        const encryptedToken = await TokenPriceAPI(APPID, APIKEY, data);
        dispatch(showLoader(false));
        const devryptedToken = await decrypytdata(encryptedToken.data, APIKEY);
  
        let jsonData;
        if (devryptedToken) {
          jsonData = JSON.parse(devryptedToken);
          console.log("JSONDATA", jsonData);
          dispatch(setPrivateId(""));
          dispatch(setResult(jsonData));
          dispatch(setResponseAPI(encryptedToken));
          navigate("/result");
        }
        if (jsonData.ReturnMessage) {
          success(jsonData.ReturnMessage);
        }
        dispatch(setPrivateId(""));
      }
      // setPrivateModal(false);
    };
    
    useEffect(() => {
      let APPID = "";
      let APIKEY = "";
      const fetchdata = async () => {
          if (publicKeys.publicKey) {
              APPID = publicKeys.publicKey.publicAddress;
          }
          if (publicKeys.privateKey) {
              APIKEY = publicKeys.privateKey.PrivateWalletAddress;
          }
  
          if (APPID && APIKEY) {
              // const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
              const encryptedLedger = await getTokenBySymbol(APPID, APIKEY, searchParams.get("id"));
              const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
              // setRows(JSON.parse(tokens));
              let data = JSON.parse(JSON.parse(tokens).CommandResponse)
              console.log("🚀 ~ file: TokenSupply.js ~ line 90 ~ fetchdata ~ data", data)
              if (data) {
                  let dataArr = []
                  data?.forEach((dataMain, index) => {
                      // multiple data
                      dataArr.push({ ...dataMain, rowid: index })
                  })
                  const dataTokens = (Object.assign({}, ...dataArr))
                  settokensData(dataTokens)
              }
          }
      };
      fetchdata();
      fetchdropdwon();
      if (publicKeys?.publicKey?.publicAddress) {
        setisValidPublicAddress(true);
      }
  }, [publicKeys, searchParams]);
  
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={TokenPriceData} />
      )} */}
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="container-fluid p-0">
            <div className="row ms-lg-4 ms-xl-5 m-0">
              <div className="col-12">
                <div className="main-heading">
                  <h3> Tokens</h3>
                  <h6>Price</h6>
                  <p>
                    This function displays the currenct market price of a token.
                  </p>
                </div>
                <div className="row">
                  <div className="col-xxl-10">
                    <div className="wallet-information-box m-0">
                      <div className="wallet-information-form">
                        <div className="row mb-xl-5">
                          <div className="col-xxl-6 mb-4 mb-xl-0">
                            <Formik
                              initialValues={initialValues}
                              enableReinitialize={true}
                              onSubmit={(values) => {
                                    setdata(values);
                                    console.log("🚀 ~ file: TokenSupply.js ~ line 138 ~ TokenSupply ~ values", values)
                                }}
                            >
                              {(formik) => {
                                const { setFieldValue } = formik;
                                return (
                                  <Form className="col-xxl-6 mb-4 mb-xl-0">
                                    <div className="input-group d-block mb-4">
                                      <label>Token Name</label>
                                      <Field
                                        as="select"
                                        className="form-select form-control w-100"
                                        id="tokenSymbol"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "tokenSymbol",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tokenSymbol}
                                      >
                                        {TokenSymbol &&
                                          TokenSymbol.map((value, index) => {
                                            return (
                                              <>
                                                <option
                                                  name={value.publicAddress}
                                                  value={value.tokenSymbol}
                                                  key={index}
                                                >
                                                  {value.displayname}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </Field>
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage
                                          name="tokenSymbol"
                                          component="span"
                                          className="error"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-5">
                                      <div class="input-box  form-floating">
                                        <Field
                                          type="text"
                                          name="sourcePublicAddress"
                                          id="sourcePublicAddress"
                                          placeholder="Token Public Address"
                                          className="form-control"
                                          disabled={true}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "tokenSymbol",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <label className="ps-0 pb-0">
                                          Token Public Address
                                        </label>
                                        <i className="bi bi-check-lg"></i>
                                      </div>
                                    </div>
                                    <div className="d-flex me-0 me-md-4 me-xxl-5 justify-content-end">
                                      <button
                                        type="button"
                                        class="btn cancel-btn mb-3 mb-md-0 me-4"
                                      >
                                        CANCEL
                                      </button>
                                      <button
                                        type="submit"
                                        class="btn next-btn mb-3 mb-md-0"
                                        onClick={() => {
                                          // setPrivateModal(true);
                                          TokenPriceData();
                                        }}
                                      >
                                        SUBMIT
                                      </button>
                                    </div>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TokenSupply;
