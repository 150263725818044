import React from "react";

function ElectronicCash() {
  return (
    <>
          <div className="unicoin-store">
              <div className="container">
                  <div className="unicoin-electronic-heading unicoin-store-heading text-center">
                      <h3>Unicoin as Electronic Cash</h3>
                  </div>
                  <div className="row">
                      <div className="col-md-6">
                          <div className="unicoin-store-text">
                              <p>The global regulatory framework for transacting electronic cash payments include know your customer (KYC), private and anonymous, traceability, and governance. Because Bitcoin does not support this regulatory framework, it has been a challenge for Bitcoin to gain widespread adoption in the global banking and financial services economy.</p>
                              <p>The banking sector accounts for 14% of the global economy. The size and growth of the financial services and digital banking industry compels the need for robust digital currency and distributed ledger technology tailored to the unique needs of these sectors.</p>
                              <p className="mb-0">The Unicoin network is reserved exclusively for governments, banks, and licensed financial technology (FinTech) companies that operate under a banking or financial services regulatory framework.</p>
                          </div>
                      </div>
                      <div className="col-md-6 text-center">
                          <img src="images/electronic cash.png" className="electronic-cash-img" alt="electronic-cash-img" />
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
}

export default ElectronicCash;
