import React from 'react'

const SpotStaking = () => {
  return (
      <>
          <div className="documentation-accordion">
              <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Issuer">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#issuerOne" aria-expanded="true" aria-controls="issuerOne">
                              Stake
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="issuerOne" className="accordion-collapse collapse show" aria-labelledby="Issuer" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Stake	Enable wallet holders to stake and to earn Unicoin by participating in transaction validation using the Stake Proof of Trust (SPOT) Protocol.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Modify">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyTwo" aria-expanded="false" aria-controls="ModifyTwo">
                              Cashout
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="ModifyTwo" className="accordion-collapse collapse" aria-labelledby="Modify" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Enables a wallet to cashout their accumulated Unicoin reward for being a network transaction validator.
                                                                    </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="ModifyFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ModifyFour" aria-expanded="false" aria-controls="ModifyFour">
                              Reject
                                                                        <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div id="ModifyFour" className="accordion-collapse collapse" aria-labelledby="ModifyFour" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                              Monetary Authorities can permission which regulated banks and licensed FinTech companie can create user wallets and mint digital currReject	Enables a SPOT Protocol participant to reject a financial transaction submitted by the Meganodes to the Unicoin DLT.encies on the Unicoin network. but you can also
                                                                    </div>
                      </div>
                  </div>
                  <hr />
              </div>
          </div>
      </>
  )
}

export default SpotStaking