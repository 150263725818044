import React from "react";
import Header from "../../Components/Header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ADMIN_URL } from "./../../Constatnts/config";
import { success, error } from "./../../Components/alert";
function Subscription() {
  const initialValues = {
    interestArea: "",
    comment: "",
    name: "",
    email: "",
  };

  const validationSchema = Yup.object({
    interestArea: Yup.string().required("interestArea is required!"),
    comment: Yup.string(),
    name: Yup.string().required("name is required!"),
    email: Yup.string().email("email is required!"),
  });
  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-9 col-lg-9 col-xxl-10">
            <div className="balance-page">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-xxl-5 mb-5">
                    <div className="balance-head">
                      <h3>Subscription</h3>
                      <p>
                        Creates a new store of value digital asset for savings,
                        commerce and trade.
                      </p>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { resetForm }) => {
                    const response = await axios.post(
                      ADMIN_URL + "/admin/NewSubscribers",
                      {
                        subscriberTypeID: values.interestArea,
                        subscriber: values.name,
                        displayname: values.name,
                        emailAddress: values.email,
                        dialingCountrycode: null,
                        mobileNumber: null,
                        comment: values.comment,
                      }
                    );
                    if (response.status === 200) {
                      success("Subscribe sucessfully");
                      resetForm();
                    } else {
                      error("Something went wrong");
                    }
                  }}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="row ms-lg-5 justify-content-center">
                          <div className="col-sm-6 col-lg-4 col-xxl-2 mb-5">
                            <div className="subscription">
                              <h3>Interest Area</h3>
                              <div class="form-check">
                                <Field
                                  class="form-check-input"
                                  type="radio"
                                  id="flexCheckDefault"
                                  name="interestArea"
                                  value="1"
                                />
                                <label>Bankings Services</label>
                              </div>
                              <div class="form-check">
                                <Field
                                  class="form-check-input"
                                  type="radio"
                                  id="flexCheckDefault"
                                  name="interestArea"
                                  value="2"
                                />
                                <label>Merchant Services</label>
                              </div>
                              <div class="form-check">
                                <Field
                                  class="form-check-input"
                                  type="radio"
                                  id="flexCheckDefault"
                                  name="interestArea"
                                  value="3"
                                />
                                <label>Purchase Unicoin</label>
                              </div>
                            </div>
                            <ErrorMessage
                              name="interestArea"
                              component="span"
                              style={{ color: "red" }}
                            />
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0 wallet-information-box ms-0    ">
                            <div className="wallet-information-form">
                              <div className="mb-5">
                                <label>Email</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="thomas@gmail.com"
                                    className="form-control"
                                  />
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Name (Optional)</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Thomas"
                                    className="form-control"
                                  />
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="name"
                                    component="span"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Comment (Optional)</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    placeholder="lorem ipsum"
                                    className="form-control"
                                  />
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="comment"
                                    component="span"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>

                              <div className="d-flex justify-content-end me-0 me-md-4 pt-5">
                                <button
                                  type="button"
                                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                                >
                                  {" "}
                                  CANCEL{" "}
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn mb-3 mb-md-0"
                                >
                                  {" "}
                                  SUBMIT{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;
