const Gpi = () => {
  return (
    <section className="section section-2">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6" />
          <div className="col-md-6 col-lg-4 offset-lg-2">
            <p>
              The Global Person Identifier (GPI) is a 20-character,
              alpha-numeric code based on the ISO 17442 standard developed by
              the International Organization for Standardization (ISO).{" "}
            </p>
            <p>
              It connects to key reference information that enables clear and
              unique identification of individuals participating in financial
              transactions.{" "}
            </p>
            <p>
              Each GPI contains information about a person’s identification
              structure and thus answers the questions of 'who is who’.{" "}
            </p>
            <p>
              Simply put, the publicly available GPI data pool can be regarded
              as a global directory, which greatly enhances transparency in the
              global marketplace.
            </p>
            <p>
              For legal business entities, a Global Entity Identifier (GEI) is
              established with its identification structure.
            </p>
            <p>
              A customer transacting across multiple financial institutions will
              have the same GPI or GEI.{" "}
            </p>
            <p>
              Both GPI and GEI are enablers for reducing and preventing tax
              evasion, money laundering, and fraud in cryptocurrency and digital
              currency transactions.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Gpi;
