import React from "react";
import Header from "../../Components/Header"
function Commands1() {
    return (
        <>
            <Header />
            <div className="balance-page">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-10 mb-5">
                            <div className="balance-head">
                                <h3>Unicoin Function</h3>
                            </div>
                            <div className="unicoin-function wallet-information-box ms-0">
                                <h4>Wallet Information</h4>
                                <p>This sentence explains this function.</p>
                                <form className="wallet-information-form">
                                    <div className="row mb-xl-5">
                                        <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                            <input type="text" className="form-control" placeholder="Source Wallet" />
                                        </div>
                                        <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                            <input type="text" className="form-control" placeholder="Target Wallet" />
                                        </div>
                                    </div>
                                    <div className="row mb-xl-5">
                                        <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Units"
                                            />
                                        </div>
                                        <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                                            <input
                                                type="text"
                                                className="form-control "
                                                placeholder="Amount"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="d-flex justify-content-end me-md-4 mt-5">
                                                <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4" > CANCEL </button>
                                                <button type="button" className="btn next-btn mb-3 mb-md-0" > SUBMIT </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Commands1;
