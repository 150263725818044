import React from "react";

function TechnologyFAQ() {
  return (
    <>
          <div className="documentation-accordion ms-0">
              <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Issuer">
                          <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#issuerOne"
                              aria-expanded="true"
                              aria-controls="issuerOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Have you validated your business model?
                                </div>
                              <i className="bi bi-plus-lg none"></i>
                              <i className="bi bi-dash-lg show"></i>
                          </button>
                      </h2>
                      <div
                          id="issuerOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="Issuer"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  {" "}
                                  Yes, the Unicoin leadership team has been
                                  collaborating and building blockchain, DLT,
                                  and digital currency solutions with
                                  governments and central banks since 2013.{" "}
                              </p>

                              <p>
                                  {" "}
                                  During our customer discovery due diligence
                                  central banks in both advanced and emerging
                                  markets, global retail and commercial banks,
                                  and smaller community banks have provided
                                  their views on how their financial
                                  institutions think about digital currency and
                                  how they would potentially leverage it for
                                  their business.
                                </p>

                              <p>
                                  These financial institutions have also
                                  reviewed our business and revenue models and
                                  confirmed that we have a compelling digital
                                  currency monetization model for the banking
                                  and financial services industry.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item ">
                      <h2 className="accordion-header" id="Modify">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ModifyTwo"
                              aria-expanded="false"
                              aria-controls="ModifyTwo"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  How do you envision Unicoin creating and
                                  sustaining value? Unicoin has two primary
                                  monetization business models.
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="ModifyTwo"
                          className="accordion-collapse collapse show"
                          aria-labelledby="Modify"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  First, Unicoin has been uniquely engineered as
                                  an appreciating store of value cryptocurrency.
                                </p>

                              <p>
                                  {" "}
                                  Central banks have special features and
                                  incentives to purchase Unicoin and hold it in
                                  its cash reserves to support their monetary
                                  policy. Retail and commercial banks also have
                                  a Unicoin cash reserve ledger which supports
                                  their cash reserve depository requirements
                                  with central banks.
                                </p>

                              <p>
                                  Banks can potentially create billions of
                                  dollars in monetary value adopting Unicoin as
                                  a cash reserve currency. As the adoption of
                                  Unicoin grows as a cash reserve currency, this
                                  should establish Unicoin as a sustainable and
                                  an appreciating store of value cryptocurrency.
                                </p>

                              <p>
                                  Secondly, the Unicoin network earn minimal
                                  fees on all financial transactions conducted
                                  on the network.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="Suspend">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#SuspendThree"
                              aria-expanded="false"
                              aria-controls="SuspendThree"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  How will you maintain competitive advantage?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="SuspendThree"
                          className="accordion-collapse collapse show"
                          aria-labelledby="Suspend"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              Unicoin estimates we are at least four (4) years
                              ahead of any competitor in the space when
                              considering our innovations and product
                              offerings. Unicoin will continue to invest
                              heavily in R&D to maintain our business and
                              technology innovation leadership position.
                              </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchOne">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#CreateOne"
                              aria-expanded="false"
                              aria-controls="CreateOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Have you identified target market segments?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="CreateOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="BranchOne"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  Central banks, retail and commercial banks,
                                  Fintech companies, merchants, and the
                                  consumers are all constituents in the Unicoin
                                  ecosystem. Unicoin has constructed a precise
                                  value proposition for how each constituent in
                                  our ecosystem can monetize the Unicoin
                                  network.
                                </p>
                              <p>
                                  {" "}
                                  When it comes to central banks and retail and
                                  commercial banks, emerging markets are
                                  demonstrating more willingness to be a first
                                  mover and an early adopter of digital
                                  currencies.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchTwo">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#CreateTwo"
                              aria-expanded="false"
                              aria-controls="CreateTwo"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Have you secured your supply and sales
                                  channels?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="CreateTwo"
                          className="accordion-collapse collapse show"
                          aria-labelledby="BranchTwo"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  {" "}
                                  Yes. Unicoin has established ongoing
                                  communicating relationships with the largest
                                  global banks around the world as well as with
                                  some middle market banks. We have
                                  pre-registered over 200 central banks and over
                                  65,000 commercial and retail bank worldwide
                                  onto our Testnet and are conducting demos of
                                  our platform.
                                </p>
                              <p>
                                  {" "}
                                  showtheless, banks are still in their early
                                  stages of researching and understanding
                                  blockchain, DLTs, and digital currencies. As
                                  this industry develops, Unicoin is in a prime
                                  position to secure traction with some of the
                                  largest and most prominent markets and banking
                                  brands in the space.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="BranchThree">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#CreateThree"
                              aria-expanded="false"
                              aria-controls="CreateThree"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Have you identified the optimal marketing
                                  channels?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="CreateThree"
                          className="accordion-collapse collapse show"
                          aria-labelledby="BranchThree"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              Yes, being based in Washington, D.C., we are
                              continually establishing ongoing relationships
                              with the Ambassadors to the United States of
                              many countries and regions around the world.
                              These Ambassadors are introducing the Unicoin
                              team to their central bank Governors and other
                              government officials at the most senior levels.
                              </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentOne">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#CreateAgentOne"
                              aria-expanded="false"
                              aria-controls="CreateAgentOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  How will your solution meet portability
                                  demands?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="CreateAgentOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="AgentOne"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              Unicoin is developing wallets to work with all
                              the major mobile device manufacturers and
                              operating systems in both advanced markets and
                              emerging markets. We have developed our core
                              mobile wallet in Java technology which is
                              portable across many mobile device operating
                              systems.
                              </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentTwo">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ModifyAgentOne"
                              aria-expanded="false"
                              aria-controls="ModifyAgentOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  What do you see as the principal risks to the
                                  business?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="ModifyAgentOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="AgentTwo"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  The principal risk we see is the uncertainty
                                  of the regulatory environment and the speed of
                                  continual innovation in the competitive
                                  environment.
                                </p>
                              <p>
                                  {" "}
                                  Unicoin has a dedicated research team that is
                                  immensely invaluable. Our research team
                                  relentlessly stays connected to the market and
                                  any breaking news developments and it has been
                                  keenly instrumental in how quick we learn and
                                  respond to market developments.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="AgentThree">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#SuspendAgentOne"
                              aria-expanded="false"
                              aria-controls="SuspendAgentOne"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Legal risk vulnerability?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="SuspendAgentOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="AgentThree"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  {" "}
                                  Because the Unicoin leadership team has been
                                  working with governments and central banks
                                  since 2013, the Unicoin products have been
                                  engineered in consideration of banking,
                                  financial, data protection, securities, and
                                  other legal and compliance regulations.
                                </p>
                              <p>
                                  The DCMA has established an ongoing
                                  communicating relationship with the United
                                  States Securities and Exchange Commission
                                  (SEC) and the United States Treasury. We have
                                  engaged both the U.S. SEC and Treasury in
                                  discussions around out innovations and have
                                  addressed all concerns noted.
                                </p>
                              <p>
                                  Unicoin does not believe it will encounter any
                                  material legal vulnerabilities as we seek to
                                  gain traction.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="FAQOne">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#FAQA"
                              aria-expanded="false"
                              aria-controls="FAQA"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Privacy risk vulnerability?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="FAQA"
                          className="accordion-collapse collapse show"
                          aria-labelledby="FAQOne"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  The Unicoin leadership team worked with the
                                  European Commission on designing and
                                  developing a privacy preserving blockchain and
                                  digital currency solutions for the European
                                  Union since 2014. This work has been featured
                                  by the United Nations and the World Economic
                                  Forum under a prior project named Yetta.
                                </p>
                              <p>
                                  Unicoin has adopted the European Union General
                                  Data Protection Regulations (GDPR) in how
                                  customer data is governed, stored, and
                                  transmitted on the Unicoin network.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="FAQTwo">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#FAQB"
                              aria-expanded="false"
                              aria-controls="FAQB"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Technology risk vulnerability?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="FAQB"
                          className="accordion-collapse collapse show"
                          aria-labelledby="FAQTwo"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              Unicoin has designed and developed all its
                              innovation stack of technology in-house.
                              Technology will continually evolve. The Unicoin
                              team is committed to the continual research and
                              development of technology and innovations in the
                              space and intend to main our current innovation
                              leadership position.
                              </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="FAQThree">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#FAQC"
                              aria-expanded="false"
                              aria-controls="FAQC"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Regulatory risk vulnerability?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="FAQC"
                          className="accordion-collapse collapse show"
                          aria-labelledby="FAQThree"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              Regulatory risks are a general market risk that
                              is applicable, not just to Unicoin, but to every
                              market participant in the digital currency and
                              cryptocurrency market sector. However, because
                              Unicoin has been founded to serve the needs of
                              governments and central banks we are in most
                              strategic position to evolve with any future
                              regulatory requirements.
                              </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="FAQFour">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#FAQD"
                              aria-expanded="false"
                              aria-controls="FAQD"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  IP risk vulnerability?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="FAQD"
                          className="accordion-collapse collapse show"
                          aria-labelledby="FAQFour"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  Unicoin is the world leader in the innovation
                                  of digital currency solutions for governments
                                  and central banks. Our intellectual property
                                  and innovations are keen in establishing our
                                  leadership in the space.
                                </p>
                              <p>
                                  However, in an industry where technology and
                                  innovations are changing at a very rapid pace,
                                  and where competitors are quick to copy
                                  leading innovations as a ‘me too’ player, we
                                  are relying more on strategic banking
                                  relationships and novel business models to
                                  sustain advantage.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="FAQFive">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#FAQE"
                              aria-expanded="false"
                              aria-controls="FAQE"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  Product liability risk vulnerability?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="FAQE"
                          className="accordion-collapse collapse show"
                          aria-labelledby="FAQFive"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              <p>
                                  The minimize any product liability risk, we
                                  are offering central banks pilot programs to
                                  ensure the Unicoin network meets their
                                  strategic and operational requirements. To
                                  select central banks, we have offered an audit
                                  of our source code by a global Tier 1 audit
                                  firm.
                                </p>
                              <p>
                                  The offering of a pilot program with a source
                                  code audit has mitigated product liability
                                  concerns with any potential large strategic
                                  clients.
                                </p>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className="accordion-item">
                      <h2 className="accordion-header" id="FAQSix">
                          <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#FAQF"
                              aria-expanded="false"
                              aria-controls="FAQF"
                          >
                              <div className="d-flex align-items-start">
                                  <i className="bi bi-question-circle me-4"></i>
                                  How will future solution optimizations affect
                                  your risk profile?
                                </div>
                              <i className="bi bi-plus-lg show"></i>
                              <i className="bi bi-dash-lg none"></i>
                          </button>
                      </h2>
                      <div
                          id="FAQF"
                          className="accordion-collapse collapse show"
                          aria-labelledby="FAQSix"
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body ms-5">
                              Unicoin has adopted the best practice approach
                              of continuous product innovation. The continuous
                              optimization of our product to meet the needs of
                              potential customers will only strengthen our
                              market positioning.
                              </div>
                      </div>
                  </div>
                  <hr />
              </div>
          </div>
    </>
  );
}

export default TechnologyFAQ;
