import React from "react";

function BoxListing(props) {
  // console.log("🚀 ~ file: BoxListing.js ~ line 4 ~ BoxListing ~ props", props);
  return (
    <>
      <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4">
              <div className="fxrates-price-box">
                  <img src={props.QuoteImageFN} class="country-flag-img" alt="tn_us-flag 1" />

          <h6>{props.QuoteCountryname}</h6>
          <div className="icon-price">
            <div className="icon-price-box ">
              <h5>{props.BaseCountryname}</h5>
              <img src={props.BaseImageFN} alt="tn_us-flag 1" />
              <div className="price">
                <h4>{props.BaseCurrencycode}</h4>
                <h3>{props.BasePrice}</h3>
              </div>
            </div>
            <div className="icon-price-box unicoin-bg me-0">
              <h5>{props.CoinCountryname}</h5>
              <img src={props.CoinImageFN} alt="U-1-png (1) 6" width="32px" />
              <div className="price">
                <h4>{props.CoinCurrencycode}</h4>
                <h3>{props.CoinPrice}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoxListing;
