import React from "react";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function IssuresConfirm() {
    return (
        <>
            {/* <Header /> */}
            <div className="col-md-9 col-lg-9 col-xxl-10">
                <div className="row m-0">
                    {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
                    <div className="col-12">
                        <div className="main-heading">
                            <i className="bi bi-arrow-left"></i>
                            <h3> Suspend Issures</h3>
                        </div>
                        <div className="wallet-information-box">
                            <div className="suspend-text wallet-information-form">
                                <p>This function will suspend an agent from conducting transaction on the Unicoin Network.</p>

                                <div class="row">
                                    <div class="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                        <div class="mb-5">
                                            <div class="input-box">
                                                <input type="text" placeholder="Public Issures Address" class="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex me-0 me-md-4 me-xxl-5">
                                    <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                    <button type="submit" class="btn next-btn mb-3 mb-md-0">SUSPEND</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bg-darks"></div>
            <div className="error-box">
                <div className="error-box-head">
                    <i class="bi bi-exclamation-circle"></i>
                    <h3>Suspend Issures</h3>
                </div>
                <div className="p-4">
                    <p>Are you sure you want to suspend wallet "John Matthew"? By doing this you will lose your saved data and will not be able to retrieve it.</p>
                    <div className="wallet-information-box">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                            <button type="submit" class="btn next-btn mb-3 mb-md-0">SUSPEND</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default IssuresConfirm;
