import React, { useEffect, useState } from "react";
import PrivateModal from "../../../Components/PrivateModal";
import { getAuthorization } from "../../../api/Authorization";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../api/walletKey";
import { Authorizationcolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { showLoader } from "../../../action";
function AuthorizationsBalances() {
    const [privateModal, setPrivateModal] = useState(false);
    const [rows, setRows] = React.useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);
    let APPID = "";
    let APIKEY = "";
    const closeModal = () => {
      setPrivateModal(false);
    };
    const dispatch = useDispatch()
    const fetchdata = async () => {
      if (publicKeys.publicKey) {
        APPID = publicKeys.publicKey.publicAddress;
      }
      if (publicKeys.privateKey) {
        APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      }
      if (APPID && APIKEY) {

        dispatch(showLoader(true));
        const encryptedAuthorization = await getAuthorization(APPID, APIKEY);
        dispatch(showLoader(false));
        const Authorization = await decrypytdata(encryptedAuthorization.data, APIKEY);
        
        let AuthorizData = JSON.parse(JSON.parse(Authorization).commandResponse)
        
        
        if (AuthorizData) {
          let dataArr = [];
          AuthorizData?.forEach((dataMain, index) => {
            
            dataArr.push({
              ...dataMain,
              rowid: dataMain.RowID,
            });
          });
        // });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Authorizations</h3>
              <h6>Get Auths</h6>
              <p>
                
                This function lists all the authorizations associated with a
                wallet.
              </p>
            </div>
            <div className="row">
              <div className="col-xxl-10">
                <div className="wallet-balances-box m-0">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary "
                      style={{ marginBottom: "10px" }}
                    >
                      + ADD Authorizations
                    </button>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={Authorizationcolumns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default AuthorizationsBalances;
