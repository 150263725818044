import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrivateId } from "../../../action";
import { acceptEscrow } from "../../../api/Escrow";
import { decrypytdata } from "../../../api/walletKey";
import { success } from "../../../Components/alert";
import PrivateModal from "../../../Components/PrivateModal";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
function AcceptEscrow() {
  const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState();
  const publicKeys = useSelector((state) => state.keyReducer);
  // const [countryCode, setCountryCode] = useState([]);
  // const [dailingCode, setDailingCode] = useState([]);

  const initialValues = {
    txhash: "",
  };

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 29 ~ closeModal ~ APIKEY",
        APIKEY
      );
    }
    if (APPID && APIKEY) {
      console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 24 ~ closeModal ~ APPID",
        APPID
      );
      //   console.log(pathname, "pathnamepathname");

      const encryptedIssuer = await acceptEscrow(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
      console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 42 ~ closeModal ~ jsonData",
        jsonData
      );
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      dispatch(setPrivateId(""));
      // debugger;
    }
    setPrivateModal(false);
  };
  const fetchdropdwon = async () => {
    // let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    // setCountryCode(countryType);
    // let dialing = await getDailingDropDown("GetDialingcodes");
    // setDailingCode(dialing);
  };
  useEffect(() => {
    fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys.privateKey.PrivateWalletAddress]);

  return (
    <>
      {/* <Header /> */}
      {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )}
      <div className="col-md-9 col-lg-9 col-xxl-10">
        <div className="row ms-lg-4 ms-xl-5 m-0">
          {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
                      <Sidebar />
                  </div> */}
          <div className="col-12">
            <div className="main-heading">
              <h3> Money Services</h3>
              <h6>Accept Escrow</h6>
              <p>
                This function enables the beneficiary of an escrow to accept the
                terms and conditions.
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(
                  "🚀 ~ file: AcceptEscrow.js ~ line 294 ~ AcceptEscrow ~ values",
                  values
                );
                setdata(values);
                setPrivateModal(true);
              }}
            >
              {(formik) => {
                const { errors, touched, setFieldValue } = formik;
                return (
                  <div className="wallet-information-box m-0">
                    <Form className="suspend-text wallet-information-form">
                      <div class="row">
                        <div class="col-xxl-6 mb-4">
                          <div class="mb-5">
                            <div class="input-box">
                              <label>Accept Escrow Address</label>
                              <Field
                                as="select"
                                className="form-select form-control"
                                id="issuerTypecode"
                                name="issuerTypecode"
                                onChange={(e) => {
                                  // console.log(e.target.value, "Issuer Type");
                                  setFieldValue(
                                    "issuerTypecode",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                              >
                                {/* {dropdown &&
                                                                    dropdown.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayName}
                                                                                </option>
                                                                            </>
                                                                        );
                                                                    })} */}
                              </Field>
                              <div>
                                <ErrorMessage
                                  name="issuerTypecode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-xxl-6 mb-4">
                          <div class="mb-5">
                            <div class="input-box form-floating">
                              <Field
                                type="text"
                                name="Target Escrow"
                                id="Target Escrow"
                                placeholder="Target Escrow"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">Target Escrow</label>
                              <div
                                className={
                                  errors.displayname && touched.displayname
                                    ? "input-error"
                                    : null
                                }
                              >
                                {/* <i className="bi bi-check-lg"></i> */}
                                <ErrorMessage
                                  name="displayname"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-xxl-6 mb-4">
                          <div class="mb-5">
                            <div class="input-box form-floating">
                              <Field
                                type="text"
                                name="txhash"
                                id="txhash"
                                placeholder="Units"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">Units</label>
                              <div
                                className={
                                  errors.Units && touched.Units
                                    ? "input-error"
                                    : null
                                }
                              >
                                {/* <i className="bi bi-check-lg"></i> */}
                                <ErrorMessage
                                  name="Units"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 ">
                          <div className="d-flex me-0 me-md-4 me-xxl-5 justify-content-end">
                            <button
                              type="button"
                              class="btn cancel-btn mb-3 mb-md-0 me-4"
                            >
                              CANCEL
                            </button>
                            <button
                              type="submit"
                              class="btn next-btn mb-3 mb-md-0"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default AcceptEscrow;
